import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../AuthContext';
import axios from 'axios';
import ProspectingCard from '../cards/ProspectingCard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Prospektera from '../prospecting/Prospect'
import ProspektListor from '../prospecting/ProspectLists'

function ProspectingPage() {
  const { auth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [selectedFragment, setSelectedFragment] = useState('Prospektera');

  return (
    <div className="d-flex">
          {selectedFragment === 'Prospektera' && <Prospektera />}
          {selectedFragment === 'Prospektlistor' && <ProspektListor />}

          <div className="side-nav-right d-flex flex-column p-3">
          <ProspectingCard 
                  selectedFragment={selectedFragment} 
                  setSelectedFragment={setSelectedFragment}
                />
            </div>
        </div>
  );
}

export default ProspectingPage;
