import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CreateCompanyModal from '../../../modals/admin/companies/CreateCompany'; // Adjust the import path as necessary
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton from 'react-loading-skeleton';
import { Helmet } from 'react-helmet-async';
import PlusIcon from '@rsuite/icons/Plus';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';

import { Badge, Breadcrumb, IconButton, Whisper, Popover } from 'rsuite';

function AdminCompanyPage() {
  const { auth } = useAuth();
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [size] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  const location = useLocation(); // Add this line

  const handleClose = () => setShow(false);
  
  const handleShow = () => setShow(true);

  const fetchCompanies = async (currentPage = 0, nameQuery = '') => {
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/licences?name=${nameQuery}&page=${currentPage}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });

      if (response.ok) {
        const result = await response.json();
        setCompanies(result.content);
        setTotalPages(result.totalPages);
      } else {
        toast.error('Det gick inte att hämta kunderna.', {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      toast.error('Ett fel inträffade vid hämtning av kunderna.', {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  
  const handleSearchSubmit = (e) => {
    if (e.key === 'Enter') {
      setPage(0);  
      fetchCompanies(0, searchQuery);  
    }
  };

  useEffect(() => {
    fetchCompanies(0, searchQuery);
    if (location.state && location.state.message) {
      toast.success(location.state.message, {
        position: 'bottom-right'
      });
    }
  }, [page, location.state]); // Add location.state as a dependency

  const handleCardClick = (id) => {
    navigate(`/admin/kunder/${id}`);
  };

  return (
    <>
    <Helmet>
          <title>{`Admin - Kunder`}</title>
        </Helmet>
      <div className='px-5 pt-4 w-100'>

        <div className="d-flex mb-2">
          <div>
            <Whisper
              placement="rightStart"
              trigger="click"
              speaker={<Popover>
                <h5>Kunder</h5>
                <p className='mb-2'>Med en licens kan ett företag logga in och göra följande:</p>
                <ul>
                  <li>Skapa moduler</li>
                  <li>Prospektera</li>
                  <li>Bjuda in medarbetare</li>
                </ul>
                </Popover>}
            > 
              <IconButton icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
              Kunder
              </IconButton>
            </Whisper>
          </div>

          <div className='ms-auto'>
            <div className="input-group">
                <input
                  placeholder='Sök kund...'
                  type="text"
                  className='form-control'
                  style={{ flex: '1' }}
                  value={searchQuery}
                  disabled={loading}
                  onChange={handleSearch}
                  onKeyDown={handleSearchSubmit}  
                />
                <span className="input-group-text">
                    <i className="bi bi-search"></i>
                </span>
            </div>
        </div>
      </div>

      <div 
        className="card dashed shadow border-1 rounded mb-2" 
        style={{ cursor: 'pointer' }}
        onClick={handleShow}
      >
        <div className='p-4 text-center'>
          <p className='mb-0'>
            <i className="bi bi-plus me-1"></i>
            Skapa ny kund
          </p>
        </div>
      </div>

        {loading ? (
          <div className='row gy-4'>
            {Array.from({ length: 3 }).map((_, index) => (
              <div key={index} className='col-sm-12 col-lg-4 col-md-12'>
                <div className='card p-3'>
                  <Skeleton height={30} width="80%" />
                  <Skeleton height={10} width="10%" />
                </div>
              </div>
            ))}
          </div>
        ) : companies.length === 0 ? (
          <>
          </>
        ) : (
          <div className='row gy-4'>
            {companies.map((company) => (
              <div
                key={company.id} // Ensure unique key for each child
                className='col-sm-12 col-lg-4 col-md-12'
              >
                <div className='card border-1 p-3'
                  onClick={() => handleCardClick(company.id)}
                  style={{ cursor: 'pointer' }}
                >
                  <div>
                    <h4 className='mb-0'>{company.companyName}</h4>
                  </div>
                  <div>

                  <Badge
                    content={company.active ? 'Aktiv licens' : 'Avstängd licens'}
                    className={`me-2 rounded p-1 mt-2 ${company.active ? 'bg-success' : 'bg-danger'}`}
                  />

                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {totalPages > 1 && (
          <div className='d-flex mt-3'>
            <button className='btn btn-primary me-1' onClick={() => setPage(page - 1)} disabled={page === 0}>Föregående</button>
            <button className='btn btn-primary me-1' onClick={() => setPage(page + 1)} disabled={page + 1 >= totalPages}>Nästa</button>
            <span>Sida {page + 1} av {totalPages}</span>
          </div>
        )}
        <CreateCompanyModal 
        onCompanyCreated={() => fetchCompanies(0, searchQuery)} 
        handleShow={show} 
        handleClose={handleClose} 
      />
      </div>
    </>
  );
}

export default AdminCompanyPage;
