import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Table, Button, IconButton, SelectPicker, Input, Popover, Whisper, Breadcrumb, Loader } from 'rsuite';
import TrashIcon from '@rsuite/icons/Trash';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';

function FunctionEndpoint({ project, moduleFunction, onModuleFunctionEdited }) {
  const { auth } = useAuth();
  const [parameters, setParameters] = useState([{ key: '', required: true, dynamic: 'true', value: '' }]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (moduleFunction) {
      setParameters(moduleFunction.parameters.length
        ? moduleFunction.parameters.map(param => ({
            ...param,
            dynamic: param.dynamic === 'true' || param.dynamic === true, // Ensure boolean
            value: param.value || '', // Default empty value to an empty string
          }))
        : [{ key: '', required: true, dynamic: true, value: '' }] // Default state
      );
    }
  }, [moduleFunction]);
  

  const handleParameterChange = (index, field, value) => {
    const newParameters = [...parameters];
    newParameters[index][field] = value;
    setParameters(newParameters);
};

  

  const handleAddParameter = () => {
    setParameters([...parameters, { key: '', required: true }]);
  };

  const handleDeleteParameter = (index) => {
    const newParameters = parameters.filter((_, i) => i !== index);
    setParameters(newParameters);
  };

  const handleSave = async () => {
    setLoading(true);
  
    // Filter out parameters with empty keys and ensure `dynamic` is saved as boolean
    const filteredParameters = parameters
    .filter(param => param.key.trim() !== '') // Only include parameters with non-empty keys
    .map(param => ({
      ...param,
      // If `param.value` is not a string, default to null; otherwise, trim it and set null if empty
      value: typeof param.value === 'string' && param.value.trim() !== '' 
        ? param.value.trim() 
        : null,
      dynamic: Boolean(param.dynamic), // Ensure boolean
    }));

  
    const moduleFunctionData = {
      id: moduleFunction.id,
      projectId: moduleFunction.projectId,
      name: moduleFunction.name,
      parameters: filteredParameters,
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/edit-function`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`,
        },
        body: JSON.stringify(moduleFunctionData),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        toast.success(result.message, { position: 'bottom-right' });
        const updatedModuleFunction = { ...moduleFunction, ...moduleFunctionData };
        onModuleFunctionEdited(updatedModuleFunction);
      } else {
        toast.error(result.message, { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Misslyckades att spara parametrar: ', error);
      toast.error('Misslyckades att spara parametrar.', { position: 'bottom-right' });
    } finally {
      setLoading(false);
    }
  };
  
  

  return (
    <>
      <Helmet>
        <title>{`${moduleFunction?.name} - Parametrar`}</title>
      </Helmet>
      <div className='d-flex'>
      <Breadcrumb className='mb-0'>
            <Breadcrumb.Item>
              <Link to={`/user/projekt/${project?.id}?fragment=Funktioner`}>{project?.name}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{moduleFunction?.name}</Breadcrumb.Item>
            <Breadcrumb.Item active>Parametrar</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <hr />

      <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Parametrar</h5>
            <p className='mb-2'>Ibland kan parametrar, liknande som variabler användas i anpassade funktioner. Exempel på parametrar:</p>
            <ul>
              <li>Ordernummer</li>
              <li>Datum (för exempelvis möten)</li>
              <li>Kontaktadress</li>
            </ul>
            <p>Behöver du hjälp med anpassade funktioner? Kontakta oss så hjälper vi dig!</p>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Parametrar
          </IconButton>
        </Whisper>

      <div className="table-responsive mb-2 rounded">
        <Table
          autoHeight
          maxHeight={500} // Set a max height
          data={parameters}
          bordered={false}  // Remove borders
          cellBordered={false}  // Remove cell borders
          rowHeight={60}  // Set a specific row height
        >
          <Table.Column width={300} flexGrow={1}>
            <Table.HeaderCell>Nyckel</Table.HeaderCell>
            <Table.Cell>
              {(rowData, rowIndex) => (
                <Input
                  value={rowData.key}
                  onChange={(value) => handleParameterChange(rowIndex, 'key', value)}
                />
              )}
            </Table.Cell>
          </Table.Column>

          <Table.Column width={150} flexGrow={1}>
            <Table.HeaderCell>Valfri?</Table.HeaderCell>
            <Table.Cell>
              {(rowData, rowIndex) => (
                <SelectPicker
                  data={[
                    { label: 'Nödvändig', value: true },  // Map "Nödvändig" to true
                    { label: 'Valfri', value: false },   // Map "Valfri" to false
                  ]}
                  value={rowData.required ?? false} // Default to false if `required` is undefined
                  onChange={(value) => handleParameterChange(rowIndex, 'required', value)} // Pass the value directly
                  searchable={false}
                  cleanable={false} // Disable the clear button
                  style={{ width: 150 }}
                />
              )}
            </Table.Cell>
          </Table.Column>





          <Table.Column width={150} flexGrow={1}>
            <Table.HeaderCell>Statisk/Dynamisk</Table.HeaderCell>
            <Table.Cell>
              {(rowData, rowIndex) => (
                <SelectPicker
                  data={[
                    { label: 'Statisk', value: false },
                    { label: 'Dynamisk', value: true },
                  ]}
                  value={rowData.dynamic} // Use boolean directly
                  onChange={(value) => handleParameterChange(rowIndex, 'dynamic', value)}
                  searchable={false}
                  cleanable={false}
                  style={{ width: 150 }}
                />
              )}
            </Table.Cell>

          </Table.Column>



          <Table.Column width={150} flexGrow={1}>
            <Table.HeaderCell>Värde</Table.HeaderCell>
            <Table.Cell>
              {(rowData, rowIndex) => (
                <Input
                  value={rowData.value}
                  onChange={(value) => handleParameterChange(rowIndex, 'value', value)}
                />
              )}
            </Table.Cell>
          </Table.Column>

          <Table.Column width={50} flexGrow={1} style={{ textAlign: 'right' }}>
            <Table.HeaderCell>Åtgärd</Table.HeaderCell>
            <Table.Cell>
            {(rowData, rowIndex) => (
                <IconButton
                  icon={<TrashIcon />}
                  appearance="subtle"
                  onClick={() => handleDeleteParameter(rowIndex)}
                  disabled={parameters.length === 1}
                />
              )}
            </Table.Cell>
          </Table.Column>
        </Table>
      </div>



      <div className='d-flex'>
        <button className='btn btn-secondary me-2' onClick={handleAddParameter}>
            <i className="bi bi-plus me-1"></i>
            Ny parameter
        </button>
        <button className='btn btn-primary d-flex justify-content-center align-items-center' onClick={handleSave} disabled={loading}>
          {loading ? (
            <>
              <Loader className="me-2" /> Sparar...
            </>
          ) : (
            'Spara'
          )}
        </button>
      </div>
    </>
  );
}

export default FunctionEndpoint;
