import React, { useState } from 'react';
import { Panel, IconButton, Badge, Divider, Button, Nav, Notification, Message } from 'rsuite';
import EditFunctionModal from '../../../modals/company/project/EditFunction';
import { useAuth } from '../../../AuthContext';

// Import icons from @rsuite/icons
import EditIcon from '@rsuite/icons/Edit';
import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';
import ShieldIcon from '@rsuite/icons/legacy/Shield';
import FileTextIcon from '@rsuite/icons/legacy/FileText'; // Replacing TextImage with FileText
import ArrowRightIcon from '@rsuite/icons/legacy/ArrowRight';

function FunctionCard({ project, moduleFunction, selectedFragment, setSelectedFragment, onModuleFunctionUpdate }) {
    const [showEditModal, setShowEditModal] = useState(false);
    const [testResponse, setTestResponse] = useState(null);
    const [loadingTest, setLoadingTest] = useState(false);
    const { auth } = useAuth();

    const handleOpenEditModal = () => {
        setShowEditModal(true);
    };

    const handleCloseEditModal = () => {
        setShowEditModal(false);
    };

    const handleModuleEdited = (updatedModuleFunction) => {
        onModuleFunctionUpdate(updatedModuleFunction);
        setShowEditModal(false);
    };

    const handleTestFunction = async () => {
        setLoadingTest(true);
        setTestResponse(null); // Clear previous response
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/test-endpoint?id=${moduleFunction.id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${auth.token}`
                }
            });
    
            const statusCode = response.status;
            const statusCodeValue = response.statusText;
            const data = await response.text();  // Change this to response.text() to capture raw text
    
            if (response.ok) {
                const updatedModuleFunction = { ...moduleFunction, valid: true };
                setTestResponse({
                    success: true,
                    body: data,
                    statusCode: statusCode,
                    statusCodeValue: statusCodeValue
                });
                handleModuleEdited(updatedModuleFunction);
            } else {
                const updatedModuleFunction = { ...moduleFunction, valid: false };
                setTestResponse({
                    success: false,
                    body: data,
                    statusCode: statusCode,
                    statusCodeValue: statusCodeValue
                });
                handleModuleEdited(updatedModuleFunction);
            }
        } catch (error) {
            const updatedModuleFunction = { ...moduleFunction, valid: false };
            setTestResponse({
                success: false,
                body: 'An error occurred while testing the function.',
                statusCode: 'N/A',
                statusCodeValue: 'N/A'
            });
            handleModuleEdited(updatedModuleFunction);
        } finally {
            setLoadingTest(false);
        }
    };

    return (
        <div className='mb-2'>
            <Panel>
                <div className='d-flex align-items-center'>
                    <h4 style={{ marginBottom: 0 }}>{moduleFunction?.name}</h4>
                    <IconButton
                        icon={<EditIcon />}
                        appearance="subtle"
                        onClick={handleOpenEditModal}
                        style={{ marginLeft: 'auto' }}
                        title="Redigera funktion"
                    />
                </div>
                <Divider style={{margin : "10px 0"}}/>

                {moduleFunction.valid ? (
                    <>
                    <Badge className='bg-success p-1 rounded me-2' content="Fullständig" />
                    {moduleFunction.requestType === 'Zapier' && (
                        <Badge className='bg-primary p-1 rounded' content="ZAPIER" style={{ marginLeft: 'auto' }} />
                    )}
                    {moduleFunction.requestType === 'GET' && (
                        <Badge className='bg-primary p-1 rounded' content="GET" style={{ marginLeft: 'auto' }} />
                    )}
                    {moduleFunction.requestType === 'POST' && (
                        <Badge className='bg-primary p-1 rounded' content="POST" style={{ marginLeft: 'auto' }} />
                    )}
                    {moduleFunction.requestType === 'PUT' && (
                        <Badge className='bg-primary p-1 rounded' content="PUT" style={{ marginLeft: 'auto' }} />
                    )}
                    {moduleFunction.requestType === 'DELETE' && (
                        <Badge className='bg-primary p-1 rounded' content="DELETE" style={{ marginLeft: 'auto' }} />
                    )}
                    </>
                ) : (
                    <Badge className='bg-danger p-1 rounded me-2' content="Validering krävs" />
                )}
                <Divider style={{margin : "10px 0"}}/>
                <Nav
                    vertical
                    activeKey={selectedFragment}
                    onSelect={(eventKey) => setSelectedFragment(eventKey)}
                >
                    <Nav.Item
                        eventKey="Endpoint"
                        className='card-title'
                    >
                        <i className="bi bi-box-arrow-right me-2"></i>
                        Endpoint

                        {moduleFunction?.endpoint ? (
                            <Badge className='bg-success p-1 rounded ms-auto' content="Tillämpad" />
                        ) : (
                            <Badge className='bg-danger p-1 rounded ms-auto' content="Saknas" />
                        )}
                    </Nav.Item>
                    <Nav.Item
                        eventKey="Autentisering"
                        className='card-title'
                    >
                        <i className="bi bi-shield me-2"></i>
                        Autentisering
                    </Nav.Item>
                    <Nav.Item
                        eventKey="Parametrar"
                        className='card-title'
                    >
                         <i className="bi bi-text-paragraph me-2"></i>
                         Parametrar
                    </Nav.Item>
                </Nav>

                <Divider style={{margin : "10px 0"}}/>
                <Button
                    appearance="primary"
                    className='bg-primary'
                    disabled={!moduleFunction?.endpoint || loadingTest}
                    onClick={handleTestFunction}
                >
                    {loadingTest ? 'Laddar...' : <>Validera funktion</>}
                </Button>
            </Panel>

            {testResponse && (
                <div style={{ marginTop: '16px' }}>
                    <Message
                        type={testResponse.success ? 'success' : 'error'}
                        closable
                        showIcon
                        onClose={() => setTestResponse(null)}
                    >
                        <div className='ms-2'>
                            <h5>{testResponse.success ? 'Funktion lyckades!' : 'Misslyckades'}</h5>
                            <p className='mb-0'><strong>Status kod:</strong> {testResponse.statusCode}</p>
                        </div>
                    </Message>
                </div>
            )}

            <EditFunctionModal
                show={showEditModal}
                handleClose={handleCloseEditModal}
                moduleFunction={moduleFunction}
                onFunctionCreated={handleModuleEdited}
                projectId={project.id}
            />
        </div>
    );
}

export default FunctionCard;
