import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useAuth } from '../../../AuthContext';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { Breadcrumb } from 'rsuite';

function ModuleOverview({ module }) {
    const { auth } = useAuth();
    const [selectedItem, setSelectedItem] = useState('Senaste månaden');
    const [conversationsCount, setConversationsCount] = useState(0);
    const [leadsCount, setLeadsCount] = useState(0);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        if (module && module.id) {
            fetchConversations(selectedItem);
        }
    }, [module, selectedItem]);

    const handleSelect = (event) => {
        setSelectedItem(event.target.textContent);
    };

    const getLastRepliedDate = () => {
        const now = new Date();
        if (selectedItem === 'Senaste månaden') {
            return new Date(now.setMonth(now.getMonth() - 1));
        } else if (selectedItem === 'Senaste 3 månader') {
            return new Date(now.setMonth(now.getMonth() - 3));
        } else if (selectedItem === 'Senaste 6 månader') {
            return new Date(now.setMonth(now.getMonth() - 6));
        }
        return null;
    };

    const fetchConversations = async (filter) => {
        try {
            setLoadingData(true);
            const lastReplied = getLastRepliedDate();
    
            // Convert the date to yyyy-MM-dd HH:mm:ss format
            const lastRepliedFormatted = lastReplied 
                ? lastReplied.toLocaleString('sv-SE', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).replace(',', '') 
                : null;
    
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/customer/conversations`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`
                },
                params: {
                    moduleId: module.id,
                    lastReplied: lastRepliedFormatted,  // Pass the formatted date
                    page: 0,
                    size: 1000,
                },
            });
    
            if (response.status === 200) {
                const contacts = response.data.content;
                const totalConversations = contacts.length;
                const leadCount = contacts.filter(contact => contact.leadGenerated).length;
                setConversationsCount(totalConversations);
                setLeadsCount(leadCount);
            }
        } catch (error) {
            console.error('Misslyckades att hämta statistik: ', error);
            toast.error("Misslyckades att hämta statistik.", {
                position: 'bottom-right'
              })
        } finally {
            setLoadingData(false);
        }
    };

    return (
        <>
        <Helmet>
          <title>{`${module?.name} - Översikt`}</title>
        </Helmet>

        <div className='d-flex'>
        <Breadcrumb className='mb-0'>
          <Breadcrumb.Item>
            <Link to={`/customer/moduler`}>Moduler</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{module?.name}</Breadcrumb.Item>
          <Breadcrumb.Item active>Konversationer</Breadcrumb.Item>
        </Breadcrumb>

        </div>
        <hr />

            <div>
                <div className="card p-3">
                    <div className="dropdown">
                        <p
                            className='mb-0 dropdown-toggle'
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            {selectedItem}
                        </p>
                        <ul className="dropdown-menu">
                            <li>
                                <a className="dropdown-item" href="#" onClick={handleSelect}>Senaste månaden</a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="#" onClick={handleSelect}>Senaste 3 månader</a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="#" onClick={handleSelect}>Senaste 6 månader</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <div className="card p-3">
                        <div className="d-flex">
                            <i className="bi bi-info-circle me-2"></i>
                            <p className='mb-0'>Konversationer</p>
                        </div>
                        <h2 className='mb-0'>
                            {loadingData ? <Skeleton width={50} height={40} /> : conversationsCount}
                        </h2>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <div className="card p-3">
                        <div className="d-flex">
                            <i className="bi bi-info-circle me-2"></i>
                            <p className='mb-0'>Leads</p>
                        </div>
                        <h2 className='mb-0'>
                            {loadingData ? <Skeleton width={50} height={40} /> : leadsCount}
                        </h2>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModuleOverview;
