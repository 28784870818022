import React, { useEffect, useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GenerateFileModal from '../../../modals/company/project/GenerateFile';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Pagination } from 'react-bootstrap'; 
import { Helmet } from 'react-helmet-async';
import TrashIcon from '@rsuite/icons/legacy/Trash';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import PlusIcon from '@rsuite/icons/Plus';
import FileUploadIcon from '@rsuite/icons/FileUpload';

import { Badge, Loader, IconButton, Popover, Whisper, Breadcrumb } from 'rsuite';

function TrainingData({ project }) {
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const { auth } = useAuth();
  const [page, setPage] = useState(0);  
  const [totalPages, setTotalPages] = useState(1);  
  const [searchQuery, setSearchQuery] = useState('');
  const [showGenerateFileModal, setShowGenerateFileModal] = useState(false);

  const [deletingRowIds, setDeletingRowIds] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]); // To track files being uploaded


  const handleOpenGenerateFileModal = () => setShowGenerateFileModal(true);
  const handleCloseGenerateFileModal = () => setShowGenerateFileModal(false);

  const fetchFiles = async (currentPage = 0, nameQuery = '') => {
    try {
      const encodedQuery = encodeURIComponent(nameQuery);
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/files?projectId=${project.id}&name=${encodedQuery}&page=${currentPage}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });

      if (response.status === 200) {
        setFiles(response.data.content);
        setPage(response.data.number);  
        setTotalPages(response.data.totalPages);
      } else {
        toast.error('Misslyckades att hämta filer.', { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Misslyckades att hämta filer: ', error);
      toast.error('Misslyckades att hämta filer.', { position: 'bottom-right' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (project) {
      fetchFiles();
    }
  }, [project]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchFiles(newPage, searchQuery);  
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

const handleSearchSubmit = (e) => {
    if (e.key === 'Enter') {  
      setPage(0);  
      fetchFiles(0, searchQuery);  
    }
  };

  const handleFileUpload = async (event) => {
    const selectedFiles = event.target.files || event.dataTransfer.files;
  
    const allowedTypes = [
      // Allowed MIME types
      'text/x-c', 'text/x-csharp', 'text/x-c++', 'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'text/html', 'text/x-java', 'application/json', 'text/markdown',
      'application/pdf', 'text/x-php', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'text/x-python', 'text/x-script.python', 'text/x-ruby', 'text/x-tex',
      'text/plain', 'text/css', 'text/javascript', 'application/x-sh',
      'application/typescript'
    ];
  
    const filesToUpload = [];
  
    for (const file of selectedFiles) {
      if (allowedTypes.includes(file.type)) {
        filesToUpload.push(file);
      } else {
        toast.error(`Fil typ '${file.type}' är inte tillåten.`, { position: 'bottom-right' });
        return; // Stop execution for invalid file type
      }
    }
  
    setUploadingFiles(filesToUpload); // Add files to uploading state
  
    for (const file of filesToUpload) {
      const formData = new FormData();
      formData.append('projectId', project.id);
      formData.append('files', file);
  
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/create-file`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${auth.token}`
          }
        });
  
        if (response.status === 200) {
          toast.success(response.data.message, { position: 'bottom-right' });
        } else {
          toast.error(response.data.message, { position: 'bottom-right' });
        }
      } catch (error) {
        console.error(`Misslyckades att ladda upp ${file.name}:`, error);
        toast.error(`Misslyckades att ladda upp ${file.name}.`, { position: 'bottom-right' });
      } finally {
        setUploadingFiles((prev) => prev.filter((uploadingFile) => uploadingFile !== file)); // Remove file from uploading state
      }
    }
  
    fetchFiles(); // Refresh the file list after upload is complete
  };
  

  const handleFileDownload = async (fileName) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/download-training-file`, {
        headers: {
          'Authorization': `Bearer ${auth.token}`
        },
        params: {
          fileName: fileName  // Pass fileName instead of fileId
        }
      });

      if (response.status === 200) {
        const presignedUrl = response.data.url;
        const link = document.createElement('a');
        link.href = presignedUrl;
        link.target = '_blank'; // Open in new tab
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.error('Misslyckades att hämta filen.', { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Misslyckades att hämta filen: ', error);
      toast.error('Misslyckades att hämta filen.', { position: 'bottom-right' });
    }
};


  const handleFileDelete = async (fileId) => {
    if (!window.confirm('Är du säker på att du vill radera denna fil?')) {
      return; // User canceled the deletion
    }

    setDeletingRowIds((prev) => [...prev, fileId]);

    try {
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/user/delete-file`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        data: { id: fileId }
      });
      if (response.status === 200) {
        toast.warning(response.data.message, { position: 'bottom-right' });
        setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
      } else {
        toast.error(response.data.message, { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Misslyckades att radera fil: ', error);
      toast.error('Misslyckades att radera fil.', { position: 'bottom-right' });
    } finally {
      setDeletingRowIds((prev) => prev.filter((id) => id !== fileId));
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    handleFileUpload(event);
  };

  if (!project) {
    return <p>Project not found.</p>;
  }

  // Helper function to format the timestamp
  const formatTimestamp = (isoString) => {
    const date = new Date(isoString);
  
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,                // Use 24-hour format
      timeZone: 'Europe/Stockholm', // Set the time zone to Sweden
    };
  
    return date.toLocaleString('sv-SE', options);
  };

  return (
    <>
    <Helmet>
          <title>{`${project?.name} - Träningsdata`}</title>
        </Helmet>
      <div className='d-flex'>
      <Breadcrumb className='mb-0'>
                <Breadcrumb.Item>{project?.name}</Breadcrumb.Item>
                <Breadcrumb.Item active>Träningsdata</Breadcrumb.Item>
            </Breadcrumb>
      </div>
      <hr />
      
      <div className="d-flex mb-2">
        <div>
        <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Träningsdata</h5>
            <p>Träningsdata är filer, Moduler lär sig allt som träningsdatan består av.</p>
            <p>Samtliga moduler har lärt sig av följande innehåll:</p>
            <ul>
            {files.map((file, index) => (
            <li>
              {file.name} 
            </li>
            ))}
            </ul>
            </Popover>}
        > 
          <IconButton className='me-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Vad är träningsdata?
          </IconButton>
          </Whisper>
          <IconButton onClick={handleOpenGenerateFileModal}  className='me-2' icon={<PlusIcon className='bg-primary' color='white' />}>
            Generera fil från URL
          </IconButton>
        </div>
      <div className="ms-auto d-flex">
            <div>
            <div className="input-group">
                <input 
                  className='form-control ms-2' 
                  type="text" 
                  placeholder='Sök filnamn' 
                  value={searchQuery}
                  disabled={loading}
                  onChange={handleSearch}
                  onKeyDown={handleSearchSubmit}  
                />
                <span className="input-group-text">
                    <i className="bi bi-search"></i>
                </span>
            </div>
            </div>
      </div>
      </div>

      <div
        className="card dashed shadow border-1 rounded mb-2"
        style={{ cursor: 'pointer' }}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        <input
            type="file"
            multiple
            id="file-upload"
            style={{ display: 'none' }}
            onChange={handleFileUpload}
          />
        {/* Wrap the contents with a label */}
        <label 
          htmlFor="file-upload"  // This targets your existing hidden file input
          style={{ display: 'block', width: '100%', height: '100%', cursor: 'pointer', textAlign: 'center' }}
        >
          <div className='p-3 text-center'>
            <p className='mb-0'>
            <FileUploadIcon className='me-2' />
              Fil från datorn
            </p>
            <p className='text-muted mb-0'>Eller dra och släpp. Max 10MB</p>
          </div>
        </label>
      </div>

      {loading ? (
          <>
            <Skeleton height={55} />  
          </>
      ) : (
        <>
          <div className="mb-2">
        <ul className="list-group">
          {/* Show uploading files with loader */}
          {uploadingFiles.map((file, index) => (
            <li key={`uploading-${index}`} className="list-group-item d-flex justify-content-between align-items-center">
              {file.name} 
              <Loader speed='fast' size="sm" content="Laddar upp..." />
            </li>
          ))}

          {/* Show already uploaded files */}
          {files.map((file) => (
            <li key={file.id} className="list-group-item d-flex justify-content-between align-items-center">
              {file.name}
              <div className="d-flex align-items-center">
                <Badge className="bg-primary p-1 rounded me-2" content={`${(file.size / 1024 / 1024).toFixed(2)} MB`} />
                <Badge className="bg-secondary p-1 rounded me-2" content={file.fileFormat} />
                <Badge className="bg-secondary p-1 rounded me-2" content={formatTimestamp(file.created)} />
                <IconButton
                  icon={<FileDownloadIcon />}
                  appearance="subtle"
                  onClick={() => handleFileDownload(file.name)}
                  title="Ladda ner träningsfil"
                />
                <IconButton
                  icon={
                    deletingRowIds.includes(file.id) ? <Loader speed='fast' size="sm" /> : <TrashIcon />
                  }
                  appearance="subtle"
                  onClick={() => handleFileDelete(file.id)}
                  title="Radera träningsfil"
                  disabled={deletingRowIds.includes(file.id)}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
        </>
      )}

      

      <GenerateFileModal
        show={showGenerateFileModal}
        handleClose={handleCloseGenerateFileModal}
        projectId={project.id}
      />


      <div className="d-flex my-4">
        {totalPages > 1 && (
        <div className="d-flex my-4">
            <Pagination>
            <Pagination.Prev 
                onClick={() => handlePageChange(page - 1)} 
                disabled={page === 0}
            />
            
            {(() => {
                const totalDisplayedPages = 10;  
                let startPage = Math.max(0, page - Math.floor(totalDisplayedPages / 2));
                let endPage = startPage + totalDisplayedPages;

                if (endPage > totalPages) {
                endPage = totalPages;
                startPage = Math.max(0, totalPages - totalDisplayedPages);
                }

                return [...Array(endPage - startPage).keys()].map((p) => (
                <Pagination.Item 
                    key={startPage + p} 
                    active={startPage + p === page} 
                    onClick={() => handlePageChange(startPage + p)}
                >
                    {startPage + p + 1}
                </Pagination.Item>
                ));
            })()}

            <Pagination.Next 
                onClick={() => handlePageChange(page + 1)} 
                disabled={page === totalPages - 1}
            />
            </Pagination>
        </div>
            )}
      </div>
    </>
  );
}

export default TrainingData;
