import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import { Breadcrumb } from 'rsuite';
import CubeIcon from '@rsuite/icons/legacy/Cube';

function LicenceOverview({ company }) {
    const { auth } = useAuth();
    const [licencesCount, setLicencesCount] = useState(0);
    const [modulesCount, setModulesCount] = useState(0);
    const [conversationsCount, setConversationsCount] = useState(0);
    const [leadsCount, setLeadsCount] = useState(0);
    const [loadingLicenses, setLoadingLicenses] = useState(true);
    const [loadingLeads, setLoadingLeads] = useState(true);
    const [loadingModules, setLoadingModules] = useState(true);

    const fetchConversationsAndLeadsCount = async () => {
        setLoadingLeads(true);
    
        try {
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/superadmin/conversations?adminLicenceId=${company.adminLicenceId}`, {
            headers: {
              'Authorization': `Bearer ${auth.token}`,
              'Content-Type': 'application/json',
            },
            params: {
              size: 1000, // Fetch up to 1000 conversations
            },
          });
    
          if (response.status === 200) {
            const conversations = response.data.content;
            
            // Count total conversations
            setConversationsCount(conversations.length);
    
            // Count leads (where leadGenerated is true)
            const leads = conversations.filter(conversation => conversation.leadGenerated === true).length;
            setLeadsCount(leads);
          } else {
            toast.error('Misslyckades att hämta konversationer.', {
              position: 'bottom-right',
            });
          }
        } catch (error) {
          toast.error('Misslyckades att hämta konversationer.', {
            position: 'bottom-right',
          });
        } finally {
          setLoadingLeads(false);
        }
    };

    const fetchModulesCount = async () => {
      setLoadingModules(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/superadmin/modules?adminLicenceId=${company.adminLicenceId}`, {
          headers: {
            'Authorization': `Bearer ${auth.token}`,
            'Content-Type': 'application/json',
          },
          params: {
            size: 100, // Adjust size if necessary
          },
        });
  
        if (response.status === 200) {
          const companies = response.data.content;
          setModulesCount(companies.length); // Total licenses count
        } else {
          toast.error('Misslyckades att hämta moduler.', {
            position: 'bottom-right',
          });
        }
      } catch (error) {
        toast.error('Misslyckades att hämta moduler.', {
          position: 'bottom-right',
        });
      } finally {
        setLoadingModules(false);
      }
    };

    const fetchLicencesCount = async () => {
        setLoadingLicenses(true);
    
        try {
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/superadmin/licences?adminLicenceId=${company.adminLicenceId}`, {
            headers: {
              'Authorization': `Bearer ${auth.token}`,
              'Content-Type': 'application/json',
            },
            params: {
              size: 1000,
              isAdminLicence: false
            },
          });
    
          if (response.status === 200) {
            const licences = response.data.content;
            
            // Count total conversations
            setLicencesCount(licences.length);
          } else {
            toast.error('Misslyckades att hämta antalet licenser.', {
              position: 'bottom-right',
            });
          }
        } catch (error) {
          toast.error('Misslyckades att hämta antalet licenser.', {
            position: 'bottom-right',
          });
        } finally {
            setLoadingLicenses(false);
        }
    };

    // Fetch data on component mount
    useEffect(() => {
        fetchLicencesCount();
        fetchModulesCount();
        fetchConversationsAndLeadsCount();
    }, []);

    return (
        <>
        <Helmet>
        <title>{`${company?.companyName} - Översikt`}</title>
        </Helmet>
            <div className='d-flex'>
            <Breadcrumb className='mb-0'>
                <Breadcrumb.Item>
                    <Link to="/superadmin/licenser">Licenser</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{company?.companyName}</Breadcrumb.Item>
                <Breadcrumb.Item active>Översikt</Breadcrumb.Item>
            </Breadcrumb>
            </div>
            <hr />

            <div className="row mt-3">
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <div className="card shadow p-3 mb-2">
                        <div className="d-flex align-items-center">
                            <CubeIcon className='me-2' />
                            <p className='mb-0'>Licenser</p>
                        </div>
                        <h2 className='mb-0'>
                            {loadingLicenses ? <Skeleton width={50} height={40} /> : licencesCount}
                        </h2>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <div className="card shadow p-3 mb-2">
                        <div className="d-flex">
                            <CubeIcon className='me-2' />
                            <p className='mb-0'>Moduler</p>
                        </div>
                        <h2 className='mb-0'>
                            {loadingModules ? <Skeleton width={50} height={40} /> : modulesCount}
                        </h2>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <div className="card shadow p-3 mb-2">
                        <div className="d-flex">
                            <i className="bi bi-people-fill me-2"></i>
                            <p className='mb-0'>Konversationer</p>
                        </div>
                        <h2 className='mb-0'>
                            {loadingLeads ? <Skeleton width={50} height={40} /> : conversationsCount}
                        </h2>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <div className="card shadow p-3 mb-2">
                        <div className="d-flex">
                            <i className="bi bi-info-circle me-2"></i>
                            <p className='mb-0'>Leads</p>
                        </div>
                        <h2 className='mb-0'>
                            {loadingLeads ? <Skeleton width={50} height={40} /> : leadsCount}
                        </h2>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LicenceOverview;
