import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from 'rsuite';

function EditFooterModal({ show, handleClose, emailAccount, onEmailAccountEdited }) {
  const { auth } = useAuth();
  const [footer, setFooter] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (emailAccount) {
      setFooter(emailAccount.footer);
    }
  }, [emailAccount]);

  const handleSubmit = async () => {
    setLoading(true);
    const emailAccountData = {
      id: emailAccount.id,
      footer
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/edit-email-account`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify(emailAccountData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        onEmailAccountEdited({ ...emailAccount, footer }); // Pass the updated project object
        handleClose();
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      toast.error('Ett fel uppstod vid redigeringen av e-post kontot', {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} animation={true} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{emailAccount ? emailAccount.email : 'Laddar...'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-2">
            <label htmlFor="footer" className='form-label'>Signatur</label>
            <textarea
              className='form-control'
              id="footer"
              rows="12"
              value={footer}
              onChange={(e) => setFooter(e.target.value)}
            />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Stäng
        </Button>
        <Button variant="primary" className="d-flex justify-content-center align-items-center" onClick={handleSubmit} disabled={loading}>
            {loading ? (
              <>
                <Loader className="me-2" /> Sparar...
              </>
            ) : (
              'Spara'
            )}
          </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditFooterModal;
