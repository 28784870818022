// UserNavBar.jsx (Updated with expandable functionality)
import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateProjectModal from '../../../modals/company/project/CreateProject';

import { Sidenav, Nav, Dropdown, Loader, Divider, Button } from 'rsuite';
import ArrowRightIcon from '@rsuite/icons/ArrowRight';
import 'rsuite/dist/rsuite.css';

// Import icons from @rsuite/icons
import DashboardIcon from '@rsuite/icons/legacy/Dashboard';
import FolderIcon from '@rsuite/icons/legacy/FolderOpen'; // Use FolderOpen for a better representation
import UserIcon from '@rsuite/icons/legacy/User';
import PlusIcon from '@rsuite/icons/Plus';

function NavBar({ company, navUpdateRequired, setNavUpdateRequired, expanded, setExpanded }) {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [projects, setProjects] = useState([]);
  const [projectLoading, setProjectLoading] = useState(true);
  const [teamLoaded, setTeamLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const checkTeam = () => {
      const team = localStorage.getItem('team');
      if (team) {
        setTeamLoaded(true);
      } else {
        setTimeout(checkTeam, 100);
      }
    };

    checkTeam();
  }, []);

  const fetchProjects = async () => {
    if (!teamLoaded) return;

    setProjectLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/user/projects`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json',
          },
          params: {
            page: 0,
            size: 100,
            companyId: JSON.parse(localStorage.getItem('team')).id,
          },
        }
      );

      if (response.status === 200) {
        setProjects(response.data.content);
      } else {
        toast.error('Misslyckades att hämta projekt.', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      console.error('Misslyckades att hämta projekt:', error);
      toast.error('Misslyckades att hämta projekt.', {
        position: 'bottom-right',
      });
    } finally {
      setProjectLoading(false);
    }
  };

  useEffect(() => {
    if (auth.token && teamLoaded) {
      fetchProjects();
    }
  }, [auth.token, teamLoaded]);

  // This useEffect now handles both project deletions and updates
  useEffect(() => {
    if (navUpdateRequired) {
      fetchProjects();  // Fetch projects if a project was deleted or updated
      setNavUpdateRequired(false);  // Reset the flag after fetching projects
    }
  }, [navUpdateRequired, setNavUpdateRequired]);

  const handleCreateProject = () => {
    setShowModal(true);
  };

  return (
    <div className="navbar-fixed">
      <Sidenav
        defaultOpenKeys={['projects']}
        activeKey={location.pathname}
        appearance="subtle"
        expanded={expanded} // Use expanded state to control the sidenav
        style={{ width: expanded ? 240 : 56, height: '100vh' }}
      >
        <Sidenav.Header>
          <div
            className="d-flex align-items-center justify-content-center text-center p-3"
            style={{ height: '50px', borderBottom: '1px solid #e5e5e5' }}
          >
            <h5 className="mb-0">
              {expanded ? (company?.companyName || 'Sonnet') : (company?.companyName?.charAt(0) || 'S')}
            </h5>

          </div>
        </Sidenav.Header>
        <Sidenav.Body>
          <Nav>
            <Nav.Item
              eventKey="/user/dashboard"
              icon={<DashboardIcon />}
              componentClass={Link}
              onClick={() => navigate(`/user/dashboard`)}
            >
              {expanded && 'Översikt'}
            </Nav.Item>

            <Dropdown
              eventKey="projects"
              title={expanded ? "Projekt" : ""}
              icon={<FolderIcon />}
              activeKey={location.pathname}
            >
              {projectLoading ? (
                  <Dropdown.Item disabled>
                    <Loader speed='fast' size="sm" content="Laddar projekt..." />
                  </Dropdown.Item>
                ) : (
                  projects.map((project) => (
                    <Dropdown.Item
                      key={project.id}
                      active={location.pathname.includes(`/user/projekt/${project.id}`)}
                      onClick={() => navigate(`/user/projekt/${project.id}`)}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <p className='mb-0 me-2'>/</p>
                      {project.name}
                    </Dropdown.Item>
                  ))
                )}
              {expanded && (
                <div style={{ padding: '8px' }}>
                  <Button
                    appearance="primary"
                    className='bg-primary'
                    block
                    onClick={handleCreateProject}
                  >
                    <PlusIcon style={{ marginRight: '8px' }} />
                    Nytt Projekt
                  </Button>
                </div>
              )}
            </Dropdown>

            <Divider style={{marginTop:"5px", marginBottom:"5px"}}/>

            {company?.prospecting && (
              <Nav.Item
                eventKey="/user/prospektera"
                icon={<UserIcon />}
                componentClass={Link}
                onClick={() => navigate(`/user/prospektera`)}
                active={location.pathname === '/user/prospektera'}
              >
                {expanded && 'Prospektera'}
              </Nav.Item>
            )}
          </Nav>
        </Sidenav.Body>
        {/* Toggle Button */}
        <Sidenav.Toggle onToggle={() => setExpanded(!expanded)} />
      </Sidenav>

      {/* CreateProjectModal */}
      {teamLoaded && (
        <CreateProjectModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          companyId={JSON.parse(localStorage.getItem('team')).id}
          onProjectCreated={fetchProjects}
        />
      )}
    </div>
  );
}

export default NavBar;
