import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from 'rsuite';

function EditCompanyDetailsModal({ show, handleClose, company, onCompanyEdited }) {
  const { auth } = useAuth();
  const [companyName, setCompanyName] = useState('');
  const [orgNr, setOrgNr] = useState('');
  const [contactEmail, setContactEmail] = useState('');

  const [addressLine, setAddressLine] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [vatNumber, setVatNumber] = useState('');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show && company) {
      setCompanyName(company.companyName);
      setOrgNr(company.orgNr);
      setContactEmail(company.contactEmail);

      setAddressLine(company.addressLine)
      setCity(company.city)
      setPostalCode(company.postalCode)
      setVatNumber(company.vatNumber)
    }
  }, [show, company]);

  const handleSubmit = async () => {
    if (!companyName || !contactEmail) {
      toast.error('Samtliga uppgifter måste fyllas i.', {
        position: 'bottom-right'
      });
      return;
    }

    setLoading(true);
    const companyData = {
      id: company.id,
      companyName,
      orgNr,
      contactEmail,
      addressLine,
      city,
      postalCode,
      vatNumber,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/superadmin/edit-licence`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}` // Include token in the request
        },
        body: JSON.stringify(companyData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        onCompanyEdited(companyData); // Call the function to update the company details in the LicenceCard
        handleClose();
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      toast.error('Misslcykades att uppdatera företaget.', {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  
  return (
    <Modal show={show} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>Redigera Företagsuppgifter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label htmlFor="companyName" className='form-label'>Kundnamn</label>
          <input
            type="text"
            className='form-control'
            id="companyName"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>
        <div className='mt-2'>
          <label htmlFor="contactEmail" className='form-label'>Kontakt e-post</label>
          <input
            type="text"
            className='form-control'
            id="contactEmail"
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
          />
        </div>
        <div className='mt-2'>
          <label htmlFor="companyOrg" className='form-label'>Organisationsnummer</label>
          <input
            type="text"
            className='form-control'
            id="companyOrg"
            value={orgNr}
            onChange={(e) => setOrgNr(e.target.value)}
          />
        </div>
        <div className='mt-2'>
          <label htmlFor="addressLine" className='form-label'>Adress</label>
          <input
            type="text"
            className='form-control'
            id="addressLine"
            value={addressLine}
            onChange={(e) => setAddressLine(e.target.value)}
          />
        </div>
        <div className='mt-2'>
          <label htmlFor="city" className='form-label'>Stad</label>
          <input
            type="text"
            className='form-control'
            id="city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>
        <div className='mt-2'>
          <label htmlFor="postalCode" className='form-label'>Postnummer</label>
          <input
            type="text"
            className='form-control'
            id="postalCode"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          />
        </div>
        <div className='mt-2'>
          <label htmlFor="vatNumber" className='form-label'>Momsnummer</label>
          <input
            type="text"
            className='form-control'
            id="vatNumber"
            value={vatNumber}
            onChange={(e) => setVatNumber(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex">
        <div className='ms-auto'>
          <Button variant="secondary" className='me-2' onClick={handleClose}>
            Stäng
          </Button>
          <Button variant="primary" className="justify-content-center align-items-center" onClick={handleSubmit} disabled={loading}>
            {loading ? (
              <>
                <Loader className="me-2" /> Sparar...
              </>
            ) : (
              'Spara'
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default EditCompanyDetailsModal;
