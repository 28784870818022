import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal } from 'react-bootstrap';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { Popover, Whisper, IconButton, Badge, Table } from 'rsuite';
import CopyIcon from '@rsuite/icons/Copy';

function VerifyDomain({ show, handleClose, domain, onDomainVerified }) {
  const { auth } = useAuth();
  const [dnsRecords, setDnsRecords] = useState(null);
  const [loading, setLoading] = useState(false);

  // Function to copy text to clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => toast.success('Kopierat till urklipp!', { position: 'bottom-right' }))
      .catch(() => toast.error('Misslyckades att kopiera.', { position: 'bottom-right' }));
  };

  const fetchDnsRecords = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/dns-records?domainId=${domain.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}` // Include token in the request
        }
      });

      const result = await response.json();

      if (response.ok) {

        // Set the DNS records
        setDnsRecords(result); // Use the fetched DNS records data
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Misslyckades att hämta domänuppgifter', {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  const verifyDomain = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/verify-domain?domainId=${domain.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}` // Include token in the request
        }
      });

      const result = await response.json();
    console.log(result)
      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        handleClose();
        onDomainVerified(); // Call the function to update the company list
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Misslyckades att verifiera domän', {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  // Fetch DNS records when the component mounts or when the domain changes
  useEffect(() => {
    if (show && domain) {
      fetchDnsRecords(); // Call the function to fetch DNS records
    }
  }, [show, domain]);

  return (
    <Modal show={show} onHide={handleClose} animation={true} size="lg"> {/* Large modal */}
      <Modal.Header closeButton>
        <Modal.Title>DNS uppgifter för {domain?.url}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="table-responsive">

          <Whisper
            placement="rightStart"
            trigger="click"
            speaker={<Popover>
              <h5>DNS uppgifter</h5>
              <p className='mb-2'>Detaljer nedan ska läggas i din domäns DNS inställningar</p>
              <p className='mb-2'>Detta gör du i ditt webhotell.</p>
              <p className='mb-2'>Exempel på populära webhotell du kanske använder:</p>
              <ul>
                <li>Loopia</li>
                <li>One.com</li>
                <li>Miss hosting</li>
                <li>Simply.com</li>
              </ul>
              <p className='mb-2'>Det kan ta upp till 24h innan DNS inställningarna är registrerade.</p>
              <p className='mb-2'>Behöver du hjälp med detta? Kontakta oss för hjälp!</p>
            </Popover>}
          >
            <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
              DNS uppgifter
            </IconButton>
          </Whisper>

          <Table
            autoHeight
            data={dnsRecords?.sending_dns_records || []}
            bordered={false}
            cellBordered={false}
            rowHeight={60}
            className="rounded"
          >
            <Table.Column width={100} align="center" fixed>
              <Table.HeaderCell>Typ</Table.HeaderCell>
              <Table.Cell dataKey="record_type" />
            </Table.Column>

            <Table.Column width={200} flexGrow={1}>
              <Table.HeaderCell>Namn</Table.HeaderCell>
              <Table.Cell dataKey="name" />
            </Table.Column>

            <Table.Column width={350} flexGrow={1}>
              <Table.HeaderCell>Värde</Table.HeaderCell>
              <Table.Cell>
                {rowData => (
                  <div className="d-flex">
                    <p
                      className="mb-0"
                      style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      {rowData.value.length > 20 ? `${rowData.value.substring(0, 20)}...` : rowData.value}
                    </p>
                    <div className="ms-auto">
                      <IconButton
                        icon={<CopyIcon />}
                        size="xs"
                        className='ms-3'
                        onClick={() => copyToClipboard(rowData.value)}
                      >
                        Kopiera
                      </IconButton>
                    </div>
                  </div>
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={150} align="center">
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.Cell>
                {rowData => (
                  <div>
                    <Badge content={rowData.valid === 'unknown' ? 'Overifierad' : 'Verifierad'} className={rowData.valid === 'unknown' ? 'bg-danger' : 'bg-success'} />
                  </div>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Stäng
        </Button>
        <Button variant="primary" onClick={verifyDomain} disabled={loading}>
          {loading ? 'Verifierar...' : 'Verifiera'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VerifyDomain;
