import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { Popover, Whisper, IconButton, Loader } from 'rsuite';

function GenerateFileModal({ show, handleClose, projectId }) {
  const { auth } = useAuth();
  const [fileName, setFileName] = useState('');
  const [url, setUrl] = useState('');
  const [contentType, setContentType] = useState('');
  const [detailScale, setDetailScale] = useState('');
  const [loading, setLoading] = useState(false);

  // Reset the form when the modal is closed
  useEffect(() => {
    if (!show) {
        setFileName('');
        setUrl('');
        setLoading(false); // Reset loading state if needed
    }
  }, [show]);

  const handleFileNameChange = (e) => {
    const value = e.target.value;
    if (value.includes('.')) {
      toast.warning('Punkter är inte tillåtna. Filtillägg läggs till automatiskt.', {
        position: 'bottom-right',
      });
      return;
    }
    setFileName(value);
  };



  const handleSubmit = async () => {
    if (!fileName || !url) {
      toast.error('Filnamn och url måste fyllas i.', {
        position: 'bottom-right'
      });
      return;
    }

    setLoading(true);
    const generateFileDate = {
        fileName,
        url,
        contentType,
        detailScale,
        projectId
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/create-filegeneration`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}` // Include token in the request
        },
        body: JSON.stringify(generateFileDate),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        handleClose();
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Ett fel uppstod när filen skulle skapas.', {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>Generera fil</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Generera fil</h5>
            <p className='mb-2'>Här kan du automatiskt generera en träningsfil med hjälp av ai bestående av URLens innehåll.</p>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Generera fil
          </IconButton>
        </Whisper>

  <div className='mb-2'>
    <label htmlFor="fileName" className='form-label'>Filnamn</label>
    <input
      type="text"
      className='form-control'
      id="fileName"
      value={fileName}
      onChange={handleFileNameChange}  // Updated event handler
    />
  </div>
  <div className="mb-2">
  <label htmlFor="url" className='form-label'>Hemsida</label>
    <input
      type="url"
      className='form-control'
      id="url"
      value={url}
      onChange={(e) => setUrl(e.target.value)}
    />
  </div>
  <div className="mb-2">
  <label htmlFor="contentType" className='form-label'>Innehållstyp</label>
    <select
        className='form-control'
        id="contentType"
        value={contentType}
        onChange={(e) => setContentType(e.target.value)}
    >
        <option value="Informative">Informativ</option>
        <option value="Business description">Verksamhetsbeskrivning</option>
        <option value="Product description">Produktbeskrivning</option>
        <option value="Service description">Tjänstebeskrivning</option>
    </select>
  </div>
  <div className="mb-2">
  <label htmlFor="detailScale" className='form-label'>Detaljnivå</label>
    <select
        className='form-control'
        id="detailScale"
        value={detailScale}
        onChange={(e) => setDetailScale(e.target.value)}
    >
    <option value="Overall">Övergripande</option>
    <option value="Detailed">Detaljerad</option>
    <option value="Technical">Teknisk</option>
  </select>
  </div>
</Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Stäng
        </Button>
        <Button variant="primary" className="d-flex justify-content-center align-items-center" onClick={handleSubmit} disabled={loading}>
            {loading ? (
              <>
                <Loader className="me-2" /> Sparar...
              </>
            ) : (
              'Spara'
            )}
          </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default GenerateFileModal;
