import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../AuthContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Pagination } from 'react-bootstrap'; 
import { Helmet } from 'react-helmet-async';

import { Badge, Loader, IconButton, Popover, Whisper, Divider } from 'rsuite';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';

import FileDownloadIcon from '@rsuite/icons/FileDownload';
import TrashIcon from '@rsuite/icons/legacy/Trash';

const ContentWrapper = ({ children }) => (
    <div className='px-5 pt-4 w-100 p-3'>
      {children}
    </div>
  );

function ProspectLists() {
  const { auth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [prospectLists, setProspectLists] = useState([]);
  const [page, setPage] = useState(0);  
  const [totalPages, setTotalPages] = useState(1); 
  
  const [deletingRowIds, setDeletingRowIds] = useState([]);
  const [downloadingRowIds, setDownloadingRowIds] = useState([]);


  const fetchProspectLists = async (currentPage = 0) => {
    setLoading(true);
    try {
      const company = JSON.parse(localStorage.getItem('team'))
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/prospectlists?page=${currentPage}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Content-Type': 'application/json'
        },
        params: {
          page: 0, 
          size: 10,
          teamId: company.id
        }
      });
  
      setProspectLists(response.data.content); // Assuming pagination
      setPage(response.data.number);  
        setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Det gick inte att hämta prospektlistorna: ', error);
      toast.error('Det gick inte att hämta prospektlistorna.', {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProspectLists();
  }, [auth.token]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchProspectLists(newPage);  
  };
  

  // Function to download the CSV file
  const handleDownload = (prospectListId) => {
    setDownloadingRowIds((prev) => [...prev, prospectListId]);

    axios({
      url: `${process.env.REACT_APP_BASE_URL}/user/download-prospectlist`,
      method: 'GET',
      responseType: 'blob', // Important to handle binary data
      headers: {
        Authorization: `Bearer ${auth.token}`,
        'Content-Type': 'application/json'
      },
      params: {
        id: prospectListId
      }
    })
    .then(response => {
      // Create a link to download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Lista_${prospectListId}.csv`);
      document.body.appendChild(link);
      link.click();

      // Cleanup
      link.parentNode.removeChild(link);
      setLoading(false);
    })
    .catch(error => {
      console.error('Det gick inte att ladda ner prospektlistan: ', error);
      toast.error('Det gick inte att ladda ner prospektlistan.', { position: 'bottom-right' });
      setLoading(false);
    })
    .finally(() => {
      setDownloadingRowIds((prev) => prev.filter((id) => id !== prospectListId));
    });
  };
  
  const handleDelete = (prospectListId) => {
    if (!window.confirm('Är du säker på att du vill radera listan?')) {
      return; // User canceled the deletion
    }

    setDeletingRowIds((prev) => [...prev, prospectListId]);
    
    axios.delete(`${process.env.REACT_APP_BASE_URL}/user/delete-prospectlist`, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
        'Content-Type': 'application/json'
      },
      data: {
        id: prospectListId
      }
    })
    .then(response => {
      // Show success toast
      toast.warning(response.data.message, { position: 'bottom-right' });
      // Remove the deleted item from the list in state
      setProspectLists(prospectLists.filter(list => list.id !== prospectListId));
    })
    .catch(error => {
      console.error('Det gick inte att radera prospektlistan.', error);
      toast.error('Det gick inte att radera prospektlistan.', { position: 'bottom-right' });
    })
    .finally(() => {
      setDeletingRowIds((prev) => prev.filter((id) => id !== prospectListId));
    });
};
  

  return (
    <ContentWrapper>
      <Helmet>
          <title>{`Prospektlistor`}</title>
        </Helmet>

      <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Prospektlistor</h5>
            <p className='mb-2'>Här finner du alla hämtade prospektlistor.</p>
            <p className='mb-2'>Prospektlistor består av:</p>
            <ul>
              <li>Prospekter</li>
            </ul>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Prospektlistor
          </IconButton>
        </Whisper>

        <div>
          <ul className="list-group mb-2">
            {loading ? (
              // Show skeletons while loading
              [...Array(5)].map((_, index) => (
                <li key={index} className="list-group-item d-flex justify-content-between align-items-center mb-1">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <Skeleton width={150} />
                    <div className="d-flex align-items-center">
                      <Skeleton width={120} className="me-2" />
                      <Skeleton width={150} />
                    </div>
                  </div>
                </li>
              ))
            ) : (
              // Check if there are any prospect lists
              prospectLists.length > 0 ? (
                // Render the prospect lists when loading is complete
                prospectLists.map((prospectList) => (
                  <li key={prospectList.id} className="list-group-item d-flex justify-content-between align-items-center mb-1">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <span>Lista #{prospectList.id}</span>
                      <div className="d-flex align-items-center">
                        <Badge className="bg-success p-1 rounded me-2" content={`${prospectList.size} st`} />
                        <Badge className="bg-secondary p-1 rounded" content={new Date(prospectList.created).toLocaleString('sv-SE')} />
                        <IconButton
                          icon={
                            downloadingRowIds.includes(prospectList.id) ? (
                              <Loader speed="fast" size="sm" />
                            ) : (
                              <FileDownloadIcon />
                            )
                          }
                          appearance="subtle"
                          onClick={() => handleDownload(prospectList.id)} // Wrap in an arrow function
                          title="Ladda ner"
                          disabled={downloadingRowIds.includes(prospectList.id)}
                        />
                        <IconButton
                          icon={
                            deletingRowIds.includes(prospectList.id) ? (
                              <Loader speed="fast" size="sm" />
                            ) : (
                              <TrashIcon />
                            )
                          }
                          appearance="subtle"
                          onClick={() => handleDelete(prospectList.id)} // Wrap in an arrow function
                          title="Radera prospektlista"
                          disabled={deletingRowIds.includes(prospectList.id)}
                        />
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <div>
                  <hr />
                  <h6 className='text-center mt-4'>Inga prospektlistor hämtade.</h6>
                </div>
              )
            )}
          </ul>
        </div>

      <div className="d-flex my-4">
        {totalPages > 1 && (
        <div className="d-flex my-4">
            <Pagination>
            <Pagination.Prev 
                onClick={() => handlePageChange(page - 1)} 
                disabled={page === 0}
            />
            
            {(() => {
                const totalDisplayedPages = 10;  
                let startPage = Math.max(0, page - Math.floor(totalDisplayedPages / 2));
                let endPage = startPage + totalDisplayedPages;

                if (endPage > totalPages) {
                endPage = totalPages;
                startPage = Math.max(0, totalPages - totalDisplayedPages);
                }

                return [...Array(endPage - startPage).keys()].map((p) => (
                <Pagination.Item 
                    key={startPage + p} 
                    active={startPage + p === page} 
                    onClick={() => handlePageChange(startPage + p)}
                >
                    {startPage + p + 1}
                </Pagination.Item>
                ));
            })()}

            <Pagination.Next 
                onClick={() => handlePageChange(page + 1)} 
                disabled={page === totalPages - 1}
            />
            </Pagination>
        </div>
            )}
      </div>
    </ContentWrapper>
  );
}

export default ProspectLists;
