import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Helmet } from 'react-helmet-async';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { Popover, Whisper, IconButton, Breadcrumb, Loader } from 'rsuite';

function CompanyEmailFolder({ project, module, onModuleEdited }) { 
  const { auth } = useAuth(); 
  const [folderId, setFolderId] = useState(''); // Changed to store folder ID
  const [availableFolders, setAvailableFolders] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (module) {
      setLoading(true)
      setFolderId(module.folderId); // Initialize with module's folder ID
      fetchFolders(); // Fetch available folders when the component mounts
      setLoading(false)
    }
  }, [module]);

  const fetchFolders = async () => {
  
    // Build the query string with module data
    const queryParams = new URLSearchParams({
      id: module.id,
      emailAccountId: module.emailAccountId
    }).toString();
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/available-folders?${queryParams}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });
  
      if (response.ok) {
        const folders = await response.json();
        setAvailableFolders(folders);
      } else {
        const errorData = await response.json(); // Parse the error response body
        console.log(errorData);
        toast.error(errorData.message || 'Misslyckades att hämta mappar.', { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Misslyckades att hämta mappar.', error);
      toast.error('Misslyckades att hämta mappar.', { position: 'bottom-right' });
    }
  };
  
  const handleSave = async () => {
    setLoading(true);

    const moduleData = {
      id: module.id,
      folderId, // Send the selected folder ID
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/edit-module`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify(moduleData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        const updatedModule = { ...module, folderId }; // Update module with new folder ID
        onModuleEdited(updatedModule); 
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.error('Misslyckades att spara ändringar: ', error);
      toast.error('Misslyckades att spara ändringar.', {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Helmet>
          <title>{`${module?.name} - E-post mapp`}</title>
        </Helmet>
        <div className='d-flex'>

        <Breadcrumb className='mb-0'>
              <Breadcrumb.Item>
                <Link to={`/user/projekt/${project?.id}`}>{project?.name}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{module?.name}</Breadcrumb.Item>
              <Breadcrumb.Item active>E-post mapp</Breadcrumb.Item>
        </Breadcrumb>

        </div>
        <hr />
      
      <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>E-post mapp</h5>
            <p className='mb-2'>Här kan du valfritt välja om modulen enbart kan läsa av en specifik mapp i inkorgen.</p>
            <p className='mb-2'>Alternativt, kan modulen läsa av alla mappar i inkorgen.</p>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          E-post mapp
          </IconButton>
        </Whisper>

        {loading ? (
          <>
            <div className="d-flex">
            <Skeleton height={35} width={300} />  
            <Skeleton className='ms-2' height={35} width={80} />  
            </div>
          </>
      ) : (
        <div className="d-flex align-items-center mb-2">
        <select
          className='form-control me-2 w-25'
          value={folderId} // Bind to folderId (selected folder's id)
          onChange={(e) => setFolderId(e.target.value)} // Update the folderId state
        >
          <option key={"Alla mappar"} value={"Alla mappar"}>
              {"Alla mappar"} {/* Show the folder name */}
            </option>
          {availableFolders.map((folder) => (
            <option key={folder.id} value={folder.id}>
              {folder.name} {/* Show the folder name */}
            </option>
          ))}
        </select>
        <button className='btn btn-primary d-flex justify-content-center align-items-center' onClick={handleSave} disabled={loading}>
          {loading ? (
            <>
              <Loader className="me-2" /> Sparar...
            </>
          ) : (
            'Spara'
          )}
        </button>
      </div>
      )}
    </>
  );
}

export default CompanyEmailFolder;
