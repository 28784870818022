import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';  // Import react-select
import { useAuth } from '../../../AuthContext';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css'; // Import rc-slider styles
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';

import { Badge, Breadcrumb, Loader } from 'rsuite';
import FileDownloadIcon from '@rsuite/icons/FileDownload';

const ContentWrapper = ({ children }) => (
  <div className='px-5 pt-4 w-100 p-3'>
    {children}
  </div>
);

function Prospect() {
    const { auth } = useAuth();
    const currentYear = new Date().getFullYear();
  
    // Existing states
    const [yearRange, setYearRange] = useState([1920, currentYear]);
    const [employeesRange, setEmployeesRange] = useState([0, 2000]);
    const [revenueRange, setRevenueRange] = useState([0, 10000000]);
    const [selectedIndustries, setSelectedIndustries] = useState([]);
    const [selectedCounties, setSelectedCounties] = useState([]);
    const [mustContainEmail, setMustContainEmail] = useState(true);
    const [mustContainTelephone, setMustContainTelephone] = useState(false);
    const [prospects, setProspects] = useState([]);
    const [totalProspects, setTotalProspects] = useState(0);
    const [loading, setLoading] = useState(false);
    const [nrToFetch, setNrToFetch] = useState(250); // Starting value of 250


  
    // New state for uBranches (Specific Industries)
    const [uBranchesOptions, setUBranchesOptions] = useState([]);
    const [selectedIndustriesSpecific, setSelectedIndustriesSpecific] = useState([]);

  // Options for Industry and County
  const industryOptions = [
    { value: 'Ambassader & Internationella org.', label: 'Ambassader & Internationella org.' },
    { value: 'Avlopp, Avfall, El & Vatten', label: 'Avlopp, Avfall, El & Vatten' },
    { value: 'Bank, Finans & Försäkring', label: 'Bank, Finans & Försäkring' },
    { value: 'Bemanning & Arbetsförmedling', label: 'Bemanning & Arbetsförmedling' },
    { value: 'Bransch-, Arbetsgivar- & Yrkesorg.', label: 'Bransch-, Arbetsgivar- & Yrkesorg.' },
    { value: 'Bygg-, Design- & Inredningsverksamhet', label: 'Bygg-, Design- & Inredningsverksamhet' },
    { value: 'Data, IT & Telekommunikation', label: 'Data, IT & Telekommunikation' },
    { value: 'Detaljhandel', label: 'Detaljhandel' },
    { value: 'Fastighetsverksamhet', label: 'Fastighetsverksamhet' },
    { value: 'Företagstjänster', label: 'Företagstjänster' },
    { value: 'Hälsa & Sjukvård', label: 'Hälsa & Sjukvård' },
    { value: 'Hår & Skönhetsvård', label: 'Hår & Skönhetsvård' },
    { value: 'Hotell & Restaurang', label: 'Hotell & Restaurang' },
    { value: 'Jordbruk, Skogsbruk, Jakt & Fiske', label: 'Jordbruk, Skogsbruk, Jakt & Fiske' },
    { value: 'Juridik, Ekonomi & Konsulttjänster', label: 'Juridik, Ekonomi & Konsulttjänster' },
    { value: 'Kultur, Nöje & Fritid', label: 'Kultur, Nöje & Fritid' },
    { value: 'Livsmedelsframställning', label: 'Livsmedelsframställning' },
    { value: 'Media', label: 'Media' },
    { value: 'Motorfordonshandel', label: 'Motorfordonshandel' },
    { value: 'Offentlig förvaltning & Samhälle', label: 'Offentlig förvaltning & Samhälle' },
    { value: 'Övriga Konsumenttjänster', label: 'Övriga Konsumenttjänster' },
    { value: 'Partihandel', label: 'Partihandel' },
    { value: 'Reklam, PR & Marknadsundersökning', label: 'Reklam, PR & Marknadsundersökning' },
    { value: 'Reparation & Installation', label: 'Reparation & Installation' },
    { value: 'Resebyrå & Turism', label: 'Resebyrå & Turism' },
    { value: 'Teknisk Konsultverksamhet', label: 'Teknisk Konsultverksamhet' },
    { value: 'Tillverkning & Industri', label: 'Tillverkning & Industri' },
    { value: 'Transport & Magasinering', label: 'Transport & Magasinering' },
    { value: 'Utbildning, Forskning & Utveckling', label: 'Utbildning, Forskning & Utveckling' },
    { value: 'Uthyrning & Leasing', label: 'Uthyrning & Leasing' }
  ];

  const countyOptions = [
    { value: 'Blekinge län', label: 'Blekinge län' },
    { value: 'Dalarnas län', label: 'Dalarnas län' },
    { value: 'Gävleborgs län', label: 'Gävleborgs län' },
    { value: 'Gotlands län', label: 'Gotlands län' },
    { value: 'Hallands län', label: 'Hallands län' },
    { value: 'Jämtlands län', label: 'Jämtlands län' },
    { value: 'Jönköpings län', label: 'Jönköpings län' },
    { value: 'Kalmar län', label: 'Kalmar län' },
    { value: 'Kronobergs län', label: 'Kronobergs län' },
    { value: 'Norrbottens län', label: 'Norrbottens län' },
    { value: 'Örebro län', label: 'Örebro län' },
    { value: 'Östergötlands län', label: 'Östergötlands län' },
    { value: 'Skåne län', label: 'Skåne län' },
    { value: 'Södermanlands län', label: 'Södermanlands län' },
    { value: 'Stockholms län', label: 'Stockholms län' },
    { value: 'Uppsala län', label: 'Uppsala län' },
    { value: 'Värmlands län', label: 'Värmlands län' },
    { value: 'Västerbottens län', label: 'Västerbottens län' },
    { value: 'Västernorrlands län', label: 'Västernorrlands län' },
    { value: 'Västmanlands län', label: 'Västmanlands län' },
    { value: 'Västra Götalands län', label: 'Västra Götalands län' }
    // Add more counties as necessary
  ];

  const saveProspectList = () => {
  
    setLoading(true);  // Start loading and disable the button
  
    const filterData = {
      yearRange,
      employeesRange,
      revenueRange,
      selectedIndustries: selectedIndustries.map(option => option.value),
      selectedCounties: selectedCounties.map(option => option.value),
      selectedIndustriesSpecific: selectedIndustriesSpecific.map(option => option.value),
      mustContainEmail,
      mustContainTelephone
    };
    const company = JSON.parse(localStorage.getItem('team'))
    axios.post(`${process.env.REACT_APP_BASE_URL}/user/prospects/save`, filterData, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
        'Content-Type': 'application/json'
      },
      params: {
        size: nrToFetch,
        teamId: company.id
      }
    })
    .then(response => {
      toast.success(response.data.message, {  // Show success toast
        position: 'bottom-right'
      });
      setLoading(false);  // Stop loading
      fetchProspects()
    })
    .catch(error => {
      console.error('Det gick inte att spara prospekter: ', error);
      setLoading(false);  // Stop loading in case of error
      toast.error("Det gick inte att spara prospekter. " + error.message, {
        position: 'bottom-right'
      });
    })
  };
  

  // Function to fetch uBranches from backend
  const fetchUBranches = () => {
    setLoading(true)
    const filterData = {
      yearRange,
      employeesRange,
      revenueRange,
      selectedIndustries: selectedIndustries.map(option => option.value),
      selectedCounties: selectedCounties.map(option => option.value),
      mustContainEmail,
      mustContainTelephone
    };

    axios.post(`${process.env.REACT_APP_BASE_URL}/user/branches`, filterData, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      const branches = response.data.map(branch => ({ value: branch, label: branch }));
      setUBranchesOptions(branches);  // Set the uBranches options in the state
      setLoading(false);  // Stop loading
    })
    .catch(error => {
      console.error('Det gick inte att hämta branscher: ', error);
      setLoading(false);  // Stop loading
      toast.error("Det gick inte att hämta branscher.", {
        position: 'bottom-right'
      });
    })
  };

  // Delayed API call timeout
  const [debounceTimeout, setDebounceTimeout] = useState(null);


  // Function to fetch prospects data from the backend
  const fetchProspects = () => {
    
    setLoading(true);  // Start loading
  
    const company = JSON.parse(localStorage.getItem('team'))
    const filterData = {
        yearRange,
        employeesRange,
        revenueRange,
        selectedIndustries: selectedIndustries.map(option => option.value),
        selectedCounties: selectedCounties.map(option => option.value),
        selectedIndustriesSpecific: selectedIndustriesSpecific.map(option => option.value),
        mustContainEmail,
        mustContainTelephone,
    };
  
    axios.post(`${process.env.REACT_APP_BASE_URL}/user/prospects`, filterData, {
        headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json'
        },
        params: {
          teamId: company.id
        }
    })
    .then(response => {
        setTotalProspects(response.data.totalElements);
        setProspects(response.data.content); // Assuming you get a paginated response
        setLoading(false);  // Stop loading
    })
    .catch(error => {
        console.error('Det gick inte att hämta prospekter: ', error);
        setLoading(false);  // Stop loading in case of error
        toast.error("Det gick inte att hämta prospekter. " + error.message, {
          position: 'bottom-right'
        });
    });
  };
  

  const handleEmailCheckboxChange = () => {
    setMustContainEmail(!mustContainEmail); // Toggle email checkbox state
  };

  const handleTelephoneCheckboxChange = () => {
    setMustContainTelephone(!mustContainTelephone); // Toggle telephone checkbox state
  };

  useEffect(() => {
    setLoading(true)
    // Clear the existing debounce timeout if any
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
  
    // Set a new debounce timeout to delay the API call for fetching prospects
    const timeout = setTimeout(() => {
      fetchProspects();
    }, 400); // 400 ms delay
  
    setDebounceTimeout(timeout);
  
    // Cleanup function to prevent multiple timeouts
    return () => clearTimeout(timeout);
  
    // Dependencies array ensures this effect is triggered when these state variables change
  }, [yearRange, employeesRange, revenueRange, selectedIndustriesSpecific, selectedIndustries, selectedCounties, mustContainEmail, mustContainTelephone]);
  
  useEffect(() => {
    // Fetch specific industries only when a main industry is selected
    if (selectedIndustries.length > 0) {
      fetchUBranches();
    } else {
      // Clear specific industries when no main industry is selected
      setSelectedIndustriesSpecific([]);
      setUBranchesOptions([]);
    }
  }, [selectedIndustries]); // Only triggers when `selectedIndustries` changes
  

  return (
    <ContentWrapper>
      <Helmet>
          <title>{`Prospektera`}</title>
        </Helmet>

      <div>
        <div className="row">
          <div className="col-sm-12 col-lg-4">
            <div className="card p-3">
              <h3>Filter</h3>
              <hr />
              <div className='mb-4'>
                <label htmlFor="yearRange" className='form-label'>Registreringsdatum</label>
                <Slider
                  range
                  min={1920}
                  max={currentYear}
                  value={yearRange}
                  onChange={setYearRange} // Directly set year range
                />
                <div className="d-flex justify-content-between mt-2">
                  <span>Från år: {yearRange[0]}</span>
                  <span>Till år: {yearRange[1]}</span>
                </div>
              </div>
              
              <div className='mb-4'>
                <label htmlFor="employees" className='form-label'>Antal anställda</label>
                <Slider
                  range
                  min={0}
                  max={2000}
                  value={employeesRange}
                  onChange={setEmployeesRange} // Directly set employees range
                />
                <div className="d-flex justify-content-between mt-2">
                  <span>Från: {employeesRange[0]}</span>
                  <span>Till: {employeesRange[1]}</span>
                </div>
              </div>

              {/* New Revenue (Omsättning) Slider Block */}
              <div className='mb-4'>
                <label htmlFor="revenue" className='form-label'>Omsättning</label>
                <Slider
                  range
                  min={0}
                  max={10000000} // Range for Omsättning (Revenue)
                  step={100000}  // Step value to make the slider more granular
                  value={revenueRange}
                  onChange={setRevenueRange} // Directly set revenue range
                />
                <div className="d-flex justify-content-between mt-2">
                  <span>Från: {revenueRange[0].toLocaleString()} kr</span>
                  <span>Till: {revenueRange[1].toLocaleString()} kr</span>
                </div>
              </div>

              {/* Existing Industry MultiSelect */}
            <div className='mb-4'>
              <label htmlFor="industry" className='form-label'>Bransch</label>
              <Select
                isMulti
                options={industryOptions}
                value={selectedIndustries}
                onChange={setSelectedIndustries} 
              />
            </div>

            {/* Show Specific Industry MultiSelect only if a main industry is selected */}
            {selectedIndustries.length > 0 && (
              <div className='mb-4'>
                <label htmlFor="specificIndustry" className='form-label'>Specifik Bransch</label>
                <Select
                  isMulti
                  options={uBranchesOptions}  // Use uBranches options fetched from backend
                  value={selectedIndustriesSpecific}
                  onChange={setSelectedIndustriesSpecific}  // Set multiple specific industries
                />
              </div>
            )}

              {/* New County MultiSelect Block */}
              <div className='mb-4'>
                <label htmlFor="county" className='form-label'>Län</label>
                <Select
                  isMulti
                  options={countyOptions}
                  value={selectedCounties}
                  onChange={setSelectedCounties} // Set multiple counties
                />
              </div>

              {/* Must Contain Email Checkbox */}
              <div className='mb-4'>
                <label htmlFor="mustContainEmail" className='form-label'>Måste inkludera</label>
                <div className="form-check">
                  <input 
                    className="form-check-input" 
                    type="checkbox" 
                    id="mustContainEmail" 
                    checked={mustContainEmail} 
                    onChange={handleEmailCheckboxChange} 
                  />
                  <label className="form-check-label" htmlFor="mustContainEmail">
                    E-post
                  </label>
                </div>
                <div className="form-check">
                  <input 
                    className="form-check-input" 
                    type="checkbox" 
                    id="mustContainTelephone" 
                    checked={mustContainTelephone} 
                    onChange={handleTelephoneCheckboxChange} 
                  />
                  <label className="form-check-label" htmlFor="mustContainTelephone">
                    Telefonnummer
                  </label>
                </div>
              </div>

            </div>
          </div>

          <div className="col-sm-12 col-lg-8">
            <div className="card p-3 mb-2">
              <h3>Snabbresultat</h3>
              <p className='mb-0 text-muted'>
                {loading ? (
                  <Skeleton width={150} />  // Skeleton loading when data is being fetched
                ) : (
                  `${totalProspects} resultat totalt`  // Display the number of results
                )}
              </p>
            </div>
            <ul className="list-group mb-2">
              {loading ? (
                // Render skeletons when loading
                [...Array(5)].map((_, index) => (
                  <li key={index} className="list-group-item d-flex justify-content-between align-items-center mb-1">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <Skeleton width={150} />
                      <div className="d-flex align-items-center">
                        <Skeleton width={120} className="me-2" />
                        <Skeleton width={100} />
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                // Render prospects when loading is complete
                prospects.map(prospect => (
                  <li key={prospect.id} className="list-group-item d-flex justify-content-between align-items-center mb-1">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <span>{prospect.cmpName}</span>
                      <div className="d-flex align-items-center">
                        {prospect.email && (
                          <Badge className="bg-primary p-1 rounded me-2" content={prospect.email} />
                        )}
                        {prospect.telephone && (
                          <Badge className="bg-secondary p-1 rounded" content={prospect.telephone} />
                        )}
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>

            <div className="d-flex align-items-end">
              <div className='me-2'>
                <label htmlFor="">Antal att hämta</label>
                <input 
                  type="number" 
                  className='form-control' 
                  value={nrToFetch}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    if (value >= 1 && value <= 2500) {
                      setNrToFetch(e.target.value);
                    } else if (value > 2500) {
                      setNrToFetch(2500);
                    } else {
                      setNrToFetch(1);
                    }
                  }} 
                  min={1} 
                />
              </div>
              <div>
                <button className='btn btn-primary d-flex justify-content-center align-items-center' onClick={saveProspectList} disabled={loading}>
                  {loading ? (
                    <>
                      <Loader className="me-2" /> Laddar...
                    </>
                  ) : (
                    <>
                    <FileDownloadIcon className="me-2" /> Hämta lista
                    </>
                  )}
                </button>
              </div>
            </div>


          </div>
        </div>
      </div>
    </ContentWrapper>
  );
}

export default Prospect;
