import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Pagination } from 'react-bootstrap';  // Import Bootstrap Pagination
import { Helmet } from 'react-helmet-async';
import UnvisibleIcon from '@rsuite/icons/Unvisible';

import { Badge, IconButton, Popover, Whisper, Breadcrumb } from 'rsuite';
import { hide } from '@popperjs/core';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';

function ModuleConversations({ module }) {
  const [loading, setLoading] = useState(true);
  const [conversations, setConversations] = useState([]);
  const [page, setPage] = useState(0);  // State for current page
  const [totalPages, setTotalPages] = useState(1);  // State for total pages
  const [searchQuery, setSearchQuery] = useState('');  // State for search query
  const { auth } = useAuth();

  const fetchConversations = async (currentPage = 0, identifierQuery = '') => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/customer/conversations`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        params: {
          moduleId: module.id,
          identifier: identifierQuery,  // Add the search query here
          page: currentPage,
          hidden: false,
          size: 12  // Assuming page size of 10
        }
      });

      if (response.status === 200) {
        setConversations(response.data.content);
        setPage(response.data.number);  // Set the current page
        setTotalPages(response.data.totalPages);  // Set the total number of pages
      } else {
        toast.error('Misslyckades att hämta konversationer.', { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Misslyckades att hämta konversationer: ', error);
      toast.error('Misslyckades att hämta konversationer.', { position: 'bottom-right' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (module) {
        fetchConversations();
    }
  }, [module]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchConversations(newPage, searchQuery);  // Fetch data for the new page with the current search query
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    if (e.key === 'Enter') {  // Trigger search on Enter key press
      setPage(0);  // Reset to the first page
      fetchConversations(0, searchQuery);  // Fetch the list with the search query
    }
  };
  const handleHideContact = async (contactId) => {
    if (!window.confirm('Är du säker på att gömma konversationen tills att den blir återupptagen igen?')) {
      return; 
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/customer/hide-contact`,
        null, // No request body, hence null
        {
          headers: {
            'Authorization': `Bearer ${auth.token}`
          },
          params: {
            contactId: contactId  // Ensure contactId is passed correctly here
          }
        }
      );

      if (response.status === 200) {
        toast.warning(response.data.message, { position: 'bottom-right' });
        fetchConversations(page, searchQuery);  // Refresh the conversation list with the current query
      } else {
        toast.error('Misslyckades att gömma kontakten.', { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Misslyckades att gömma kontakten:', error);
      toast.error('Misslyckades att gömma kontakten.', { position: 'bottom-right' });
    }
  };


  if (!module) {
    return <p>Lista hittades inte.</p>;
  }

  const handleSeen = (conversationId) => {
    setConversations((prevConversations) =>
      prevConversations.map((conv) =>
        conv.id === conversationId ? { ...conv, seenCustomer: true } : conv
      )
    );
  };

  return (
    <>
    <Helmet>
          <title>{`Konversationer`}</title>
        </Helmet>

      <div className='d-flex'>
        <Breadcrumb className='mb-0'>
          <Breadcrumb.Item>
            <Link to={`/customer/moduler`}>Moduler</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{module?.name}</Breadcrumb.Item>
          <Breadcrumb.Item active>Konversationer</Breadcrumb.Item>
        </Breadcrumb>

      </div>
      <hr />
      
      <div className="d-flex mb-2">
      <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Konversationer</h5>
            <p className='mb-2'>Här ser du alla konversationer som modulen har hanterat.</p>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Konversationer
          </IconButton>
        </Whisper>
        <div className='ms-auto'>
            <div className="input-group">
                <input 
                  className='form-control' 
                  type="text" 
                  placeholder='Sök identifierare' 
                  value={searchQuery}
                  onChange={handleSearch}
                  onKeyDown={handleSearchSubmit}  // Trigger search on Enter key press
                />
                <span className="input-group-text">
                    <i className="bi bi-search"></i>
                </span>
            </div>
        </div>
      </div>

      {loading ? (
          <>
            <Skeleton height={60} />  
          </>
      ) : (
        <>
        <div className="mb-2">
      {conversations.length > 0 ? (
        <ul className="list-group">
            {conversations.map((conversation) => (
            <li key={conversation.id} className="list-group-item d-flex justify-content-between align-items-center border-1">
                <Link
                to={`/customer/moduler/${module?.id}/konversationer/${conversation.id}`}
                onClick={(e) => handleSeen(conversation.id)}
                className="text-decoration-none w-100"
                target="_blank"  // Opens the link in a new tab or window
                rel="noopener noreferrer"  // Security best practice to prevent potential vulnerabilities
                >
                <div className="d-flex justify-content-between align-items-center w-100">
                    <span>{conversation.identifier}</span>
                    <div className="d-flex align-items-center">
                    {!conversation.seenCustomer && (
                      <Badge className='bg-success p-1 rounded me-2' content="Nytt innehåll!" />
                    )}
                    {conversation.leadGenerated && (
                      <Badge className='bg-success p-1 rounded me-2' content="Lead genererad!" />
                    )}
                    {!conversation.answered && (
                      <Badge className='bg-danger p-1 rounded me-2' content="Ignorerad" />
                    )}
                    {conversation.pendingReply && (
                      <Badge className='bg-warning p-1 rounded me-2' content="Genererar svar" />
                    )}
                    {conversation.answered && (
                      <Badge className='bg-primary p-1 rounded me-2' content="Svarad" />
                    )}

                    <Badge 
                    className='bg-secondary p-1 rounded me-2' 
                    content={new Date(conversation.lastReply).toLocaleString('sv-SE', {
                        timeZone: 'Europe/Stockholm',
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit'
                    })}/>
                    <IconButton
                          icon={<UnvisibleIcon />}
                          size='sm'
                          onClick={(e) => { 
                            e.preventDefault(); 
                            handleHideContact(conversation.id); 
                          }}
                          appearance="subtle"
                          title="Göm konversation"
                        />
                    </div>
                </div>
                </Link>
            </li>
            ))}
        </ul>
        ) : (
          <div>
            <hr />
            <h6 className='text-center mt-4'>Inga konversationer har förekommit.</h6>
          </div>
        )}
        </div>
        </>
      )}

      {/* Pagination Component */}
      <div className="d-flex my-4">
      {/* Pagination Component */}
{totalPages > 1 && (
  <div className="d-flex my-4">
    <Pagination>
      <Pagination.Prev 
        onClick={() => handlePageChange(page - 1)} 
        disabled={page === 0}
      />
      
      {(() => {
        // Determine the start and end page numbers to display in the pagination
        const totalDisplayedPages = 10;  // Number of pages to display
        let startPage = Math.max(0, page - Math.floor(totalDisplayedPages / 2));
        let endPage = startPage + totalDisplayedPages;

        // Ensure the last page doesn't go beyond totalPages
        if (endPage > totalPages) {
          endPage = totalPages;
          startPage = Math.max(0, totalPages - totalDisplayedPages);
        }

        // Generate the range of pages to display
        return [...Array(endPage - startPage).keys()].map((p) => (
          <Pagination.Item 
            key={startPage + p} 
            active={startPage + p === page} 
            onClick={() => handlePageChange(startPage + p)}
          >
            {startPage + p + 1}
          </Pagination.Item>
        ));
      })()}

      <Pagination.Next 
        onClick={() => handlePageChange(page + 1)} 
        disabled={page === totalPages - 1}
      />
    </Pagination>
    </div>
    )}
      </div>
    </>
  );
}

export default ModuleConversations;
