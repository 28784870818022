import React, { useState, useEffect } from 'react';
import axios from 'axios';
import usImage from '../../../images/personal.png';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Helmet } from 'react-helmet-async';
import CubeIcon from '@rsuite/icons/legacy/Cube'; // Equivalent to moduler

function CustomerDashboardPage() {
    const { auth } = useAuth();
    const [modulesCount, setModulesCount] = useState(0);
    const [leadsCount, setLeadsCount] = useState(0);
    const [conversationsCount, setConversationsCount] = useState(0);
    const [loadingModules, setLoadingModules] = useState(true);
    const [loadingConversations, setLoadingConversations] = useState(true);
    const [loadingLeads, setLoadingLeads] = useState(true);

    const fetchModulesCount = async () => {
        setLoadingModules(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/customer/modules`, {
                headers: {
                    'Authorization': `Bearer ${auth.token}`,
                    'Content-Type': 'application/json',
                },
                params: {
                    size: 100, // Fetch up to 100 modules
                },
            });

            if (response.status === 200) {
                const modules = response.data.content;

                // Total modules count
                const totalModules = modules.length;

                setModulesCount(totalModules);
            } else {
                toast.error('Misslyckades att hämta antalet moduler.', {
                    position: 'bottom-right',
                });
            }
        } catch (error) {
            toast.error('Misslyckades att hämta antalet moduler.', {
                position: 'bottom-right',
            });
        } finally {
            setLoadingModules(false);
        }
    };

    const fetchConversationsCount = async () => {
        setLoadingConversations(true);
        setLoadingLeads(true); // Since leads come from conversations as well

        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/customer/conversations`, {
                headers: {
                    'Authorization': `Bearer ${auth.token}`,
                    'Content-Type': 'application/json',
                },
                params: {
                    size: 1000, // Increase the size limit to get a higher number of conversations
                },
            });

            if (response.status === 200) {
                const conversations = response.data.content;

                // Total conversations count
                const totalConversations = conversations.length;

                console.log(conversations)
                // Count of conversations where leadGenerated is true
                const totalLeadsFromConversations = conversations.filter(convo => convo.leadGenerated === true).length;
                setConversationsCount(totalConversations);
                setLeadsCount(totalLeadsFromConversations); // Add leads from conversations
            } else {
                toast.error('Misslyckades att hämta antalet konversationer.', {
                    position: 'bottom-right',
                });
            }
        } catch (error) {
            toast.error('Misslyckades att hämta antalet konversationer.', {
                position: 'bottom-right',
            });
        } finally {
            setLoadingConversations(false);
            setLoadingLeads(false);
        }
    };

    useEffect(() => {
        fetchModulesCount();
        fetchConversationsCount();
    }, []);

    return (
        <>
        <Helmet>
          <title>{`Översikt`}</title>
        </Helmet>
            <div className='px-5 pt-3 mt-4 w-100'>
                <h4 className="mb-2">
                    {/* Conditionally render the user's first name or show a skeleton if still loading */}
                    {`Välkommen Tillbaka, ${auth?.firstName || 'Gäst'}! 👋`}
                </h4>
                <p className="text-muted">Här är din översikt</p>
                <hr />


                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
                        <div className="card shadow p-3">
                        <div className="d-flex align-items-center">
                            <CubeIcon className='me-2' />
                            <p className='mb-0'>Moduler</p>
                        </div>
                        <h2 className='mb-0'>
                            {loadingModules ? <Skeleton width={50} height={40} /> : modulesCount}
                        </h2>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
                        <div className="card shadow p-3">
                        <div className="d-flex">
                            <i className="bi bi-people-fill me-2"></i>
                            <p className='mb-0'>Konversationer</p>
                        </div>
                        <h2 className='mb-0'>
                            {loadingConversations ? <Skeleton width={50} height={40} /> : conversationsCount}
                        </h2>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
                        <div className="card shadow p-3">
                        <div className="d-flex">
                            <i className="bi bi-info-circle me-2"></i>
                            <p className='mb-0'>Leads genererade</p>
                        </div>
                        <h2 className='mb-0'>
                            {loadingConversations ? <Skeleton width={50} height={40} /> : leadsCount}
                        </h2>
                        </div>
                    </div>
                </div>
                <section className="pt-5">
                <h4 className="large-text">Behöver du support?</h4>
                <div className="contact">

                    <p className="pt-2 text-muted pb-0 mb-1">+46(0)70-738 18 31</p>
                    <p className="text-muted">elliot.heathnyberg@gmail.com</p>
                </div>
                </section>

                <section className="pt-1 pb-3">
                  <img
                    style={{ width: "500px", borderRadius: "200px" }}
                    src={usImage}
                    alt="personal"
                  />
                </section>
            </div>
        </>
    );
}

export default CustomerDashboardPage;
