import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useSearchParams } from 'react-router-dom'; // Import useSearchParams for token
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import { Loader } from 'rsuite';

function ResetPasswordPage() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const [searchParams] = useSearchParams(); // To get the token from the URL
  const navigate = useNavigate(); // Initialize useNavigate

  const token = searchParams.get('token');

  useEffect(() => {
    // Validate token on page load
    const validateToken = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/validate-reset-token?token=${token}`);
        if (response.ok) {
          setValidToken(true);
        } else {
          toast.error('Ogiltig eller föråldrad återställningslänk.', { position: 'bottom-right' });
          navigate('/');
        }
      } catch (error) {
        toast.error('Fel vid validering av återställningslänk.', { position: 'bottom-right' });
        navigate('/');
      }
    };

    if (token) {
      validateToken();
    } else {
      toast.error('Ingen återställningstoken hittades.', { position: 'bottom-right' });
      navigate('/');
    }
  }, [token, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (password !== confirmPassword) {
      toast.error("Lösenorden matchar inte.", { position: 'bottom-right' });
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/reset-password?token=${token}&password=${password}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, password }),
      });

      if (!response.ok) {
        toast.error("Återställning misslyckades: " + response.status, { position: 'bottom-right' });
        throw new Error('Återställning misslyckades, ' + response.status);
      }

      toast.success('Lösenordet har återställts.', { position: 'bottom-right' });
      navigate('/login');

    } catch (error) {
      toast.error("Återställning misslyckades: " + error, { position: 'bottom-right' });
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Helmet>
          <title>{`Återställ lösenord`}</title>
        </Helmet>
    <div className='background-color'>
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className='card p-4 shadow-lg' style={{ width: '400px' }}>
          {validToken ? (
            <form onSubmit={handleSubmit}>
              <div className='mb-4'>
                <label className='form-label' htmlFor="password">Nytt Lösenord</label>
                <input
                  className='form-control'
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <label className='form-label' htmlFor="confirmPassword">Bekräfta Lösenord</label>
                <input
                  className='form-control'
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
              <button className='btn btn-primary w-100 d-flex justify-content-center align-items-center' type="submit" disabled={loading}>
              {loading ? (
                <>
                  <Loader className="me-2" /> Återställer...
                </>
              ) : (
                'Återställ Lösenord'
              )}
            </button>
            </form>
          ) : (
            <p>Laddar...</p>
          )}
          <div className="pt-3 mt-3">
             {/*<p className="copywritten-text">© 2024 Sonnet</p>*/} 
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default ResetPasswordPage;
