import React, { useEffect, useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { Link } from 'react-router-dom';
import CreateModuleModal from '../../../modals/company/project/CreateModule'; // Adjust the import path as necessary
import EditModuleModal from '../../../modals/company/project/EditModule'; // Adjust the import path as necessary
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Pagination } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

import { Dropdown, IconButton, Badge, Loader, Popover, Whisper, Breadcrumb } from 'rsuite';
import { Gear } from '@rsuite/icons';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';

function Modules({ company, project }) {
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [page, setPage] = useState(0);  
  const [totalPages, setTotalPages] = useState(1);  
  const [searchQuery, setSearchQuery] = useState('');

  const [deletingRowIds, setDeletingRowIds] = useState([]);


  const fetchModules = async (currentPage = 0, nameQuery = '') => {
    if (!project?.id || !company?.id) return;

    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/modules?projectId=${project.id}&name=${nameQuery}&page=${currentPage}&companyId=${company.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setModules(data.content); // Assuming your backend returns the modules in a "content" field
        setPage(data.number);  
        setTotalPages(data.totalPages);
      } else {
        const errorText = await response.text();
        throw new Error(`Misslyckades att hämta moduler: ${errorText}`);
      }
    } catch (error) {
      console.error('Misslyckades att hämta moduler: ', error);
      toast.error(error.message, {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Only fetch modules on the first load or when project or company actually change
    if (project?.id && company?.id) {
      fetchModules();
    }
  }, [project?.id, company?.id]); // This effect will now only run when these specific values change
  
  

  
const handlePageChange = (newPage) => {
  setPage(newPage);
  fetchModules(newPage, searchQuery);  
};

const handleSearch = (e) => {
  setSearchQuery(e.target.value);
};

const handleSearchSubmit = (e) => {
  if (e.key === 'Enter') {
    setPage(0);  
    fetchModules(0, searchQuery);  
  }
};
  

  const handleEditModalShow = (module) => {
    setSelectedModule(module);
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setSelectedModule(null);
  };

  const handleModuleEdited = (updatedModule) => {
    setModules((prevModules) => 
      prevModules.map((mod) => 
        mod.id === updatedModule.id ? updatedModule : mod
      )
    );
  };

  const handleDeleteModule = async (module) => {
    if (!window.confirm('Är du säker på att du vill radera denna modul?')) {
      return; // User canceled the deletion
    }

    setDeletingRowIds((prev) => [...prev, module.id]);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/delete-module`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}` // Include token in the request
        },
        body: JSON.stringify({ id: module.id }),
      });

      const result = await response.json();

      if (response.ok) {
        toast.warning(result.message, {
          position: 'bottom-right'
        });
        setModules((prevModules) => prevModules.filter((mod) => mod.id !== module.id));
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Ett fel uppstod vid raderingen av modulen.', {
        position: 'bottom-right'
      });
    } finally {
      setDeletingRowIds((prev) => prev.filter((id) => id !== module.id));
    }
  };

  return (
    <>
    <Helmet>
          <title>{`${project?.name} - Moduler`}</title>
        </Helmet>
      <div className='d-flex'>
      <Breadcrumb className='mb-0'>
                <Breadcrumb.Item>{project?.name}</Breadcrumb.Item>
                <Breadcrumb.Item active>Moduler</Breadcrumb.Item>
            </Breadcrumb>

      </div>
      <hr />
      
      <div className="d-flex mb-2">
        <div>
        <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Moduler</h5>
            <p className='mb-2'>Moduler kan utföra följande:</p>
            <ul>
              <li>Läsa av din mejlinkorg</li>
              <li>Vara en chattbot på hemsida</li>
              <li>Utföra ett mejlutskick</li>
            </ul>
            <p className='mb-2'>Moduler kan valfritt tränas upp med hjälp av följande:</p>
            <ul>
              <li>Träningsdata</li>
              <li>Anpassade funktioner</li>
              <li>Flöden</li>
              <li>Rollbeskrivning</li>
              <li>Personlighetsbeskrivning</li>
              <li>Regler</li>
            </ul>
            </Popover>}
        > 
          <IconButton icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Vad är en modul?
          </IconButton>
        </Whisper>
        </div>

        <div className='ms-auto'>
            <div className="input-group">
                <input 
                  className='form-control' 
                  type="text" 
                  placeholder='Sök modul' 
                  value={searchQuery}
                  disabled={loading}
                  onChange={handleSearch}
                  onKeyDown={handleSearchSubmit}  
                />
                <span className="input-group-text">
                    <i className="bi bi-search"></i>
                </span>
            </div>
        </div>
      </div>

      <div className="card dashed shadow border-1 dashed rounded mb-2" style={{ cursor: 'pointer' }} onClick={() => setShowCreateModal(true)}>
        <div className='p-4 text-center'>
          <p className='mb-0'>
            <i className="bi bi-plus me-1"></i>
            Skapa ny modul
          </p>
        </div>
      </div>

      {loading ? (
          <>
            <Skeleton height={55} />  
          </>
      ) : (
        <>
          <div className="row">
      {modules.map((module) => (
        <div className="col-md-12 col-lg-12">
        <Link to={`/user/projekt/${project.id}/moduler/${module.id}`} className="text-decoration-none" key={module.id}>
        <div className="card border-1 mb-2" style={{ cursor: 'pointer' }}>
          <div className="p-2">
            <div className="d-flex align-items-center">

              {/* Module Info */}
              <h5 className='mb-1 ms-2'>{module.name}</h5>  {/* Added `me-3` to create space */}

              <div className='ms-auto me-2'>
                {module?.moduleType === 'Chattbot' && (
                <>
                  {module.active ? (
                    <Badge className='bg-success p-1 rounded me-2' content="Chattboten visas på hemsidan" />
                  ) : (
                    <Badge className='bg-warning p-1 rounded me-2' content="Chattboten visas inte på hemsidan" />
                  )}
                </>
                )}

                {module?.moduleType === 'E-post' && (
                  <>
                    {module.active ? (
                      <>
                        <Badge className='bg-success p-1 rounded me-2' content="Modulen läser aktivt av inkorgen" />
                        <Badge
                          className='bg-secondary p-1 rounded me-2'
                          content={`Fr. o.m. ${
                            module.timestamp
                              ? new Date(module.timestamp).toLocaleString('se-SE', {
                                  month: 'short',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  second: 'numeric',
                                })
                              : 'N/A'
                          }`}
                        />
                      </>
                    ) : (
                      <Badge className='bg-warning p-1 rounded me-2' content="Modulen läser inte av inkorgen" />
                    )}
                  </>
                )}

                {module?.moduleType === 'Mejlutskick' && (
                  <>
                    {module?.finished ? (
                      <Badge className='bg-success p-1 rounded me-2' content="Färdigt mejlutskick" />
                    ) : (
                      <>
                        {module?.active ? (
                          <Badge className='bg-success p-1 rounded me-2' content="Mejlutskicket är aktivt" />
                        ) : (
                          <Badge className='bg-warning p-1 rounded me-2' content="Mejlutskicket är pausat" />
                        )}
                      </>
                    )}
                  </>
                )}
                
                <Badge className='bg-primary p-1 rounded' content={module.moduleType} />
                </div>

                <div
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}  
                >
                  
                  <Dropdown
                    trigger={['click']}
                    renderToggle={(props, ref) => (
                      <IconButton
                        {...props}
                        ref={ref}
                        icon={
                          deletingRowIds.includes(module.id) ? (  // Check if the module is being deleted
                            <Loader speed='fast' size="sm" />
                          ) : (
                            <Gear />
                          )
                        }
                        appearance="subtle"
                      />
                    )}
                    
                    placement="bottomEnd"
                  >
                    <Dropdown.Item
                      onClick={(e) => {
                        e.preventDefault();
                        handleEditModalShow(module);
                      }}
                    >
                      Ändra namn
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleDeleteModule(module);
                      }}
                    >
                      Radera
                    </Dropdown.Item>
                  </Dropdown>
                </div>
            </div>
          </div>
        </div>


      </Link>
      </div>
      ))}
      </div>
        </>
      )}

      <div className="d-flex my-4">
        {totalPages > 1 && (
        <div className="d-flex my-4">
            <Pagination>
            <Pagination.Prev 
                onClick={() => handlePageChange(page - 1)} 
                disabled={page === 0}
            />
            
            {(() => {
                const totalDisplayedPages = 10;  
                let startPage = Math.max(0, page - Math.floor(totalDisplayedPages / 2));
                let endPage = startPage + totalDisplayedPages;

                if (endPage > totalPages) {
                endPage = totalPages;
                startPage = Math.max(0, totalPages - totalDisplayedPages);
                }

                return [...Array(endPage - startPage).keys()].map((p) => (
                <Pagination.Item 
                    key={startPage + p} 
                    active={startPage + p === page} 
                    onClick={() => handlePageChange(startPage + p)}
                >
                    {startPage + p + 1}
                </Pagination.Item>
                ));
            })()}

            <Pagination.Next 
                onClick={() => handlePageChange(page + 1)} 
                disabled={page === totalPages - 1}
            />
            </Pagination>
        </div>
            )}
      </div>

      <CreateModuleModal
        show={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        project={project}
        company={company}
        onModuleCreated={fetchModules}
      />

      <EditModuleModal
        show={showEditModal}
        handleClose={handleEditModalClose}
        module={selectedModule}
        onModuleEdited={handleModuleEdited}
      />
    </>
  );
}

export default Modules;
