import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader, IconButton } from 'rsuite';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import TrashIcon from '@rsuite/icons/legacy/Trash';

function ChatModal({ show, handleClose, module }) {
  const { auth } = useAuth();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [loading, setLoading] = useState(true); // New loading state
  const [isDeleting, setIsDeleting] = useState(false); // New loading state
  const chatContentRef = useRef(null);

  useEffect(() => {
    if (show) {
      fetchPreviousMessages();
    }
  }, [show]);

  useEffect(() => {
    scrollToLatestMessage();
  }, [messages, isTyping]);

  // Fetch previous messages from backend
  const fetchPreviousMessages = async () => {
    setLoading(true); // Set loading to true when fetching messages
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/previous-messages?userId=${auth.userId}-${module?.id}`, {
        headers: { 'Content-Type': 'application/json' }
      });
      
      // Parse the response as an array of messages
      const data = await response.json();
  
      // Check if data exists and is an array
      if (Array.isArray(data) && data.length > 0) {
        // Parse and set messages based on the role
        const parsedMessages = data.map((message) => {
          const sender = message.role === 'user' ? 'User' : 'System';
          const text = message.content[0]?.text?.value || ""; // Assuming the text is inside the content array
  
          return { sender, text };
        });
  
        // Reverse the order of the parsed messages so that the newest messages are at the bottom
        const reversedMessages = parsedMessages.reverse();
  
        // Add the messages (with optional welcome message) to the chat
        setMessages(reversedMessages);
      } else {
        console.warn("Inga meddelanden hittades: ", data); // Log if no messages are present
      }
    } catch (error) {
      console.error("Misslyckades att hämta tidigare meddelanden.", error);
    } finally {
      setLoading(false); // Set loading to false after fetching messages
    }
  };

  const handleSendMessage = () => {
    if (inputValue.trim()) {
      const userMessage = inputValue.trim();
      // Add user message immediately
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'User', text: userMessage }
      ]);
      setInputValue(''); // Clear input field
      sendMessage(userMessage); // Call sendMessage with user message
    }
  };
  
  const sendMessage = async (message) => {
    setIsTyping(true);
    try {
      // Create form data to send as request body
      const formData = new FormData();
      formData.append('userId', auth.userId + "-" + module?.id);
      formData.append('message', message);
      formData.append('chatbotId', module.id);
  
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/post-message`, {
        method: 'POST',
        // Content-Type is not needed for FormData
        body: formData // Use form data here
      });
  
      const data = await response.json();
  
      // Ensure the response contains the expected data
      if (data && data.response) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'System', text: data.response }
        ]);
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      toast.error('Misslyckades att skicka meddelande.', { position: 'bottom-right' });
    }
    setIsTyping(false);
  };
  

  const scrollToLatestMessage = () => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  };

  const handleClearConversation = async () => {
    if (!window.confirm('Är du säker på att du återställa konversation?')) {
      return; // User canceled the deletion
    }
    setIsDeleting(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/clear-conversation?userId=${auth.userId}-${module?.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        let updatedMessages = [];

        // Set the updated messages (with optional welcome message)
        setMessages(updatedMessages);
      } else {
        const result = await response.json();
        console.error("Misslyckades att rensa konversation: ", result.message);
      }
      } catch (error) {
        console.error("Misslyckades att rensa konversation: ", error);
      } finally {
        setIsDeleting(false);
      }
  };

  return (
    <Modal fullscreen={true} show={show} onHide={handleClose} animation={true}>
      <Modal.Header className="d-flex align-items-center">
        <Modal.Title className="me-auto">Testa skriva med {module?.label}</Modal.Title>
        <IconButton
          icon={(isDeleting) ? <Loader /> : <TrashIcon />}
          appearance="subtle"
          size='lg'
          onClick={() => handleClearConversation()}
          title="Återställ konversation"
          className="me-2" // Remove padding and add margin-end
          disabled={isDeleting || messages.length<2}
        />
        <button type="button" className="btn-close m-0" aria-label="Close" onClick={handleClose}></button>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column" style={{ height: '100vh' }}>
        {/* Chat messages container */}
        <div
          ref={chatContentRef}
          className="flex-grow-1 overflow-auto p-2 mb-2"
        >
          {/* Display skeletons while loading */}
          {loading ? (
            <>
              <div className="d-flex mb-3 justify-content-start">
                <Skeleton count={1} width={400} height={50} />
              </div>
              <div className="d-flex mb-3 justify-content-end">
                <Skeleton count={1} width={300} height={50} />
              </div>
              <div className="d-flex mb-3 justify-content-start">
                <Skeleton count={1} width={600} height={50} />
              </div>
              <div className="d-flex mb-3 justify-content-end">
                <Skeleton count={1} width={300} height={50} />
              </div>
              <div className="d-flex mb-3 justify-content-start">
                <Skeleton count={1} width={100} height={50} />
              </div>
            </>
          ) : (
            <>
              <div className="d-flex mb-3 justify-content-start">
                <div
                  className="p-3 rounded bg-light text-dark"
                  style={{ maxWidth: '60%' }}
                >
                  Hej, testa att skriva med mig för att spegla hur jag beter mig i en live miljö.
                </div>
              </div>
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`d-flex mb-3 ${message.sender === 'User' ? 'justify-content-end' : 'justify-content-start'}`}
                >
                  <div
                    className={`p-3 rounded ${message.sender === 'User' ? 'bg-primary text-white' : 'bg-light text-dark'}`}
                    style={{ maxWidth: '60%' }} // Ensures message box doesn't exceed 75%
                  >
                    {(message.text && typeof message.text === 'string') ? (
                      message.text.split('\n').map((line, i) => (
                        <React.Fragment key={i}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))
                    ) : (
                      <span>Meddelande kunde levereras.</span>
                    )}
                  </div>
                </div>
              ))}
              {isTyping && (
                <div className="d-flex justify-content-start my-2">
                  <div className="p-3 rounded bg-light text-dark" style={{ maxWidth: '75%' }}>
                    <Loader className='mb-0' />
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        {/* Input field container */}
        <div className="d-flex align-items-center p-0">
          <input
            type="text"
            className="form-control me-2"
            placeholder={`Skriv med ${module?.label}...`}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
            disabled={isTyping || loading || isDeleting}
          />
          <Button onClick={handleSendMessage} disabled={isTyping || loading || isDeleting}>
            Skicka
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ChatModal;
