import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../../AuthContext';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { Badge, IconButton, Loader, Breadcrumb } from 'rsuite';
import ReloadIcon from '@rsuite/icons/Reload';
import axios from 'axios';

const ContentWrapper = ({ children }) => (
  <div className='px-5 pt-4 w-100 p-3'>
    {children}
  </div>
);

function ConversationThreadPage() {
  const { moduleId, contactId } = useParams();
  const { auth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState(null);
  const [module, setModule] = useState(null);
  const [messages, setMessages] = useState([]); // State to store messages

  const fetchModule = async () => {
    if (!moduleId) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/customer/modules?id=${moduleId}&hidden=null`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setModule(data.content[0]);
      } else {
        const errorText = await response.text();
        throw new Error(`Misslyckades att hämta modul: ${errorText}`);
      }
    } catch (error) {
      console.error('Misslyckades att hämta modul: ', error);
      toast.error(error.message, {
        position: 'bottom-right'
      });
    }
  };

  const fetchContact = async () => {
    if (!contactId) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/customer/conversations?id=${contactId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setContact(data.content[0]);
      } else {
        const errorText = await response.text();
        throw new Error(`Misslyckades att hämta kontakt: ${errorText}`);
      }
    } catch (error) {
      console.error('Misslyckades att hämta kontakt:', error);
      toast.error(error.message, {
        position: 'bottom-right'
      });
    }
  };

  // Function to fetch previous messages based on contactId
  const fetchPreviousMessages = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/customer/previous-messages?contactId=${contactId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });
  
      const data = await response.json();
      console.log("Response data:", data);
  
      if (Array.isArray(data) && data.length > 0) {
        // Map each interaction to separate user and optionally system messages
        const parsedMessages = data.flatMap((interaction) => {
          const userMessage = {
            sender: 'User',
            text: interaction.content.replaceAll('<br>', '\n'),
            leadGenerated: interaction.leadGenerated,
            timestamp: interaction.timestamp,     
            ignored: interaction.ignored,  
            functionCalled: interaction.functionCalled,
            id: interaction.id,
            pendingReply: interaction.pendingReply,
          };
  
          // Initialize an array with the user message
          const messages = [userMessage];
  
          // Conditionally add the system message if moduleResponse is not null/empty
          if (interaction.moduleResponse) {
            const systemMessage = {
              sender: 'System',
              text: interaction.moduleResponse.replaceAll('<br>', '\n'),
              leadGenerated: interaction.leadGenerated,
              timestamp: interaction.timestamp,
              functionCalled: interaction.functionCalled,
              id: interaction.id,
              pendingReply: interaction.pendingReply,
            };
            messages.push(systemMessage);  // Add the system message only if it exists
          }
  
          return messages;  // Return the array of messages (userMessage, optionally systemMessage)
        });
  
        // Reverse the order of the messages to display the newest ones last
        setMessages(parsedMessages);
      }
    } catch (error) {
      console.error("Misslyckades att hämta tidigare meddelanden.", error);
      toast.error("Misslyckades att hämta tidigare meddelanden.", {
        position: 'bottom-right'
      });
    }
  };
  const updateSeen = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/customer/update-contact-seen?contactId=${contactId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.token}`
            }
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error("Misslyckades att uppdatera sedd parameter.", errorData);
        }
    } catch (error) {
        console.error("Misslyckades att uppdatera sedd parameter.", error);
    }
};

// Helper function to format the timestamp
const formatTimestamp = (isoString) => {
  const date = new Date(isoString);

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,                // Use 24-hour format
    timeZone: 'Europe/Stockholm', // Set the time zone to Sweden
  };

  return date.toLocaleString('sv-SE', options);
};

const forceResponse = async (interactionId) => {
  if (!window.confirm('Är du säker på att tvinga modulen på en respons? Detta är oftast inte rekommenderat p.g.a autosvar från användaren.')) {
    return; 
  }
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/customer/force-response`,
      null, // No request body, hence null
      {
        headers: {
          'Authorization': `Bearer ${auth.token}`
        },
        params: {
          interactionId
        }
      }
    );

    if (response.status === 200) {
      toast.success(response.data.message, { position: 'bottom-right' });
      setMessages(messages.map(message => message.id === interactionId ? { ...message, pendingReply:true } : message)); 
    } else {
      toast.error('Misslyckades att tvinga respons.', { position: 'bottom-right' });
    }
  } catch (error) {
    console.error('Misslyckades att tvinga respons: ', error);
    toast.error('Misslyckades att tvinga respons.', { position: 'bottom-right' });
  }
};

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchContact();
      await updateSeen();
      await fetchModule();
      await fetchPreviousMessages();
      setLoading(false);
    };
    fetchData();
  }, [moduleId, contactId, auth.token]);

  return (
    <>
    <Helmet>
          <title>{`${contact?.identifier} - Konversation`}</title>
        </Helmet>
        <ContentWrapper>

        <div className='d-flex'>
        <Breadcrumb className='mb-0'>
          <Breadcrumb.Item>
            <Link to={`/customer/moduler`}>Moduler</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={module ? `/customer/moduler/${module.id}` : '#'}>
              {module?.name}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Konversationer</Breadcrumb.Item>
          <Breadcrumb.Item active>{contact?.identifier}</Breadcrumb.Item>
        </Breadcrumb>

      </div>
      <hr />
        {loading ? (
          <>
          <Loader speed='fast' /> Laddar...
          </>
        ) : (
          <>
            {messages.length > 0 ? (
              <div className="system-chatbot-content" style={{ backgroundColor: 'transparent' }}>
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`d-block system-chatbot-message ${message.sender === 'System' ? 'system' : 'user'}`}
                  >
                    <div>
                      {message.text.split('\n').map((line, i) => (
                        <React.Fragment key={i}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </div>

                    <div>
                      {/* Add a break line before displaying leadGenerated and functionCalled, but only for user messages */}
                    {message.sender === 'User' && <br />}
                    {message.sender === 'System' && (message.leadGenerated || message.functionCalled) && <br />}

                    {/* Only show leadGenerated and functionCalled badges for user messages */}
                    {message.sender === 'System' && message.leadGenerated && (
                      <div>
                        <Badge className='bg-success p-1 rounded me-2 mt-1' content="Lead genererad!" />
                      </div>
                    )}
                    {message.sender === 'User' && (message.ignored && !message.pendingReply) && (
                      <div>
                        <Badge className='bg-danger p-1 rounded me-1 mt-1' content="Ignorerad" />
                        <IconButton
                          icon={<ReloadIcon />}
                          size='sm'
                          onClick={() => forceResponse(message.id)}
                          appearance="subtle"
                          title="Tvinga ett svar"
                        />
                      </div>
                    )}
                    {message.sender === 'User' && message.pendingReply && (
                      <div>
                        <Badge className='bg-warning p-1 rounded me-2 mt-1' content="Genererar svar" />
                      </div>
                    )}
                    {message.sender === 'System' && message.functionCalled && (
                      <div>
                        <Badge className='bg-primary p-1 rounded me-2 mt-1' content={`Funktion: ${message.functionCalled}`}  />
                      </div>
                    )}
                    {message.sender === 'User' && (
                      <Badge className='bg-secondary p-1 rounded me-2 mt-1' content={formatTimestamp(message.timestamp)} />
                    
                    )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className='text-center'>Inga meddelanden hittades i tråden.</p>
            )}

          </>
        )}
        </ContentWrapper>
    </>
  );
}

export default ConversationThreadPage;
