import React, { useState, useEffect, useRef } from "react";
import emptyAvatar from '../../../images/empty-avatar.svg';
import { ClipLoader } from 'react-spinners';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "../../../chatbot.css";

const EmptyChatbot = ({ module, chatbotAttributes }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isVisible, setIsVisible] = useState(false); // New state for controlling visibility

  const chatContentRef = useRef(null);
  const chatbotContainerRef = useRef(null);

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

  // Initialize userId and chatbot container on mount
  useEffect(() => {
    createChatbotContainer();
    setWelcomeMessage(module.welcomeMessage);
  }, []);

  const setWelcomeMessage = (welcomeMessage) => {
    if (welcomeMessage?.trim()) {
      setMessages([{ sender: 'System', text: welcomeMessage }]);
    }
  };

  // Create chatbot container if it doesn't exist
  const createChatbotContainer = () => {
    if (!chatbotContainerRef.current) {
      const chatbotContainer = document.createElement('div');
      chatbotContainer.id = 'chatbot-container';
      document.body.appendChild(chatbotContainer);
      chatbotContainerRef.current = chatbotContainer;
    }
  };

  // Toggle chatbox visibility
  const toggleChat = () => setIsOpen(!isOpen);

  // Handle message send action
  const handleSendMessage = async () => {
    if (inputValue.trim()) {
      const userMessage = inputValue;
      addMessage('User', userMessage);
      setInputValue('');

      await sendMessageToBackend(userMessage);
    }
  };

  // Add a message to the chat
  const addMessage = (sender, text) => {
    setMessages((prevMessages) => [...prevMessages, { sender, text }]);
  };

  // Send message and files to backend and handle response
  const sendMessageToBackend = async (userMessage) => {
    setIsTyping(true);
    try {
      addMessage('System', "Test respons");
    } catch (error) {
      addMessage('System', 'Jag kan tyvärr inte svara för närvarande.');
    }

    setIsTyping(false);
  };

  useEffect(() => {
    if(isOpen){
      setIsVisible(true);
    }
  }, [isOpen]);

  if (!chatbotAttributes) return null;


  return (
    <div ref={chatbotContainerRef}>
      {!isOpen && (
        <button
          className="system-chatbot-button"
          onClick={toggleChat}
          style={{
            backgroundColor: chatbotAttributes?.systemChatbotButtonBackgroundColor,
            borderRadius: `${chatbotAttributes?.systemChatbotButtonBorderRadius}px`,
          }}
        >
          <span
            id="system-chatbot-button-text"
            className="system-chatbot-button-text"
            style={{
              color: chatbotAttributes?.systemChatbotButtonTextColor,
              marginRight: chatbotAttributes?.systemChatbotButtonText ? '10px' : '0px',
              marginLeft: chatbotAttributes?.systemChatbotButtonText ? '10px' : '0px'
            }}
          >
            {chatbotAttributes?.systemChatbotButtonText || ""}
          </span>

          <i
            id="system-chatbot-icon"
            className={chatbotAttributes?.systemChatbotIcon || "bi bi-chat-fill"}
            style={{ 
              fontSize: '26px', 
              width: '26px', 
              height: '26px', 
              marginRight: chatbotAttributes?.systemChatbotButtonText ? '10px' : '0px',
              color: chatbotAttributes?.systemChatbotIconColor || 'inherit'  // Apply the color dynamically
            }}
          ></i>


        </button>
      )}

      <div className={`system-chatbot-box ${isOpen ? "open" : "close"}`}
      style={{ visibility: isVisible ? 'visible' : 'hidden' }}>
        <div
          className="system-chatbot-header"
          style={{ backgroundColor: chatbotAttributes?.systemChatbotHeaderBackgroundColor }}
        >
          <div className="chatbot-display-flex">
            <img
              className="system-chatbot-avatar"
              id="system-chatbot-avatar"
              src={chatbotAttributes?.systemChatbotAvatarSrc || emptyAvatar}
              alt="Chatbot Avatar"
            />
            <div>
              <h4
                id="system-chatbot-label"
                className="system-chatbot-label chatbot-margin-0"
                style={{ color: chatbotAttributes?.systemChatbotLabelColor }}
              >
                {chatbotAttributes?.label || "Chatbot"} {/* Use label here */}
              </h4>
              <p style={{ color: chatbotAttributes?.systemChatbotLabelColor }} className="chatbot-margin-0">online</p>
            </div>
          </div>
          <div>
            <button className="system-chatbot-buttons">
              <div>
                    <i
                    aria-label="Clear Chatbot"
                    className="bi bi-trash system-delete-button"
                    style={{ color: chatbotAttributes?.systemDeleteButtonColor }}
                  ></i>
              </div>
            </button>

            <button className="system-chatbot-buttons" onClick={toggleChat}>
              <div>
                <i
                    aria-label="Close Chatbot"
                  className="bi bi-x-lg system-close-button"
                  style={{ color: chatbotAttributes?.systemCloseButtonColor }}
                ></i>
              </div>
            </button>
          </div>

        </div>

        <div
          className="system-chatbot-content"
          ref={chatContentRef}
          style={{ backgroundColor: chatbotAttributes?.systemChatbotContentBackgroundColor }}
        >
          {messages.map((message, index) => (
            <div
              key={index}
              className={`system-chatbot-message ${message.sender === 'System' ? 'system' : 'user'}`}
              style={{
                color: message.sender === 'System'
                  ? chatbotAttributes?.systemChatbotMessageSystemColor
                  : chatbotAttributes?.systemChatbotMessageUserColor,
                backgroundColor: message.sender === 'System'
                  ? chatbotAttributes?.systemChatbotMessageSystemBackgroundColor
                  : chatbotAttributes?.systemChatbotMessageUserBackgroundColor,
              }}
            >
              {message.text}
            </div>
          ))}

          {isTyping && (
            <div className="system-chatbot-message system" style={{ backgroundColor: chatbotAttributes?.systemChatbotMessageSystemBackgroundColor }}>
              <span style={{ marginRight: '10px', color: chatbotAttributes?.systemChatbotMessageSystemColor }}>
                {chatbotAttributes?.label || "Chatbot"} skriver...
              </span>
              <ClipLoader 
                size={20} 
                color={chatbotAttributes?.systemChatbotMessageSystemColor || "#fff"} // Apply dynamic color here
              />
            </div>          
          )}

        </div>

        <div
          className="system-chatbot-input"
          style={{ backgroundColor: chatbotAttributes?.systemChatbotInputBackgroundColor }}
        >
          <div className="system-chatbot-input-wrapper">
            <div className="upload-button">
              <label
                className="system-chatbot-upload-button"
                style={{
                  color: chatbotAttributes?.systemChatbotUploadButtonColor,
                }}
              >
                <i className="bi bi-file-earmark-arrow-up"></i>
                <input
                  type="file"
                  multiple
                  style={{ display: 'none' }}
                />
              </label>
            </div>
            <input
              type="text"
              placeholder={`Skriv med ${chatbotAttributes?.label || "Chatbot"}...`}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
            />
            <button
              className="system-chatbot-send-button"
              onClick={handleSendMessage}
              style={{
                color: chatbotAttributes?.systemChatbotSendButtonColor,
                backgroundColor: chatbotAttributes?.systemChatbotSendButtonBackgroundColor,
              }}
            >
              ➤
            </button>
          </div>
          <p
            className="system-chatbot-created-by"
            style={{ color: chatbotAttributes?.systemChatbotCreatedByColor }}
          >
            <a
              href="https://ai.sonnet.se"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              Chattbot skapad av Sonnet
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmptyChatbot;
