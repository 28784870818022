import React, { useState, useEffect } from 'react';
import FunctionCard from '../cards/FunctionCard'; // Adjust the import path as necessary
import FunctionEndpoint from '../function/FunctionEndpoint'; // Import your Projects fragment
import FunctionParameters from '../function/FunctionParameters'; // Import your Projects fragment
import FunctionAuthorization from '../function/FunctionAuthorization'; // Import your Projects fragment
import CommentsCard from '../../Comments';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../../AuthContext';
import { Loader } from 'rsuite';

const ContentWrapper = ({ children }) => (
  <div className='px-5 pt-4 w-100 p-3'>
    {children}
  </div>
);

function FunctionPage() {
  const { projectId, funktionId } = useParams();
  const { auth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [selectedFragment, setSelectedFragment] = useState('Endpoint'); // State to track selected fragment
  const [project, setProject] = useState(null);
  const [moduleFunction, setModuleFunction] = useState(null);

  const fetchModuleFunction = async () => {
    if (!funktionId) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/functions?id=${funktionId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setModuleFunction(data.content[0]); // Assuming your backend returns the modules in a "content" field
      } else {
        const errorText = await response.text();
        throw new Error(`Misslyckades att hämta funktion: ${errorText}`);
      }
    } catch (error) {
      console.error('Misslyckades att hämta funktion: ', error);
      toast.error(error.message, {
        position: 'bottom-right'
      });
    }
  };

  const fetchProject = async () => {
    if (!projectId) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/projects?id=${projectId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setProject(data.content[0]); // Assuming your backend returns the projects in a "content" field
      } else {
        const errorText = await response.text();
        throw new Error(`Misslyckades att hämta projekt: ${errorText}`);
      }
    } catch (error) {
      console.error('Misslyckades att hämta projekt: ', error);
      toast.error(error.message, {
        position: 'bottom-right'
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchProject();
      await fetchModuleFunction();
      setLoading(false);
    };
    fetchData();
  }, [projectId, funktionId, auth.token]);

  const handleModuleFunctionUpdate = (updatedModuleFunction) => {
    fetchModuleFunction(updatedModuleFunction);
  };

  return (
    <div className="d-flex">
        {loading ? (
          <ContentWrapper>
            <Loader speed='fast' /> Laddar...
          </ContentWrapper>
        ) : (
          <>
            <ContentWrapper>
              {selectedFragment === 'Endpoint' && module && <FunctionEndpoint project={project} moduleFunction={moduleFunction} onModuleFunctionEdited={handleModuleFunctionUpdate} />}
              {selectedFragment === 'Parametrar' && module && <FunctionParameters project={project} moduleFunction={moduleFunction} onModuleFunctionEdited={handleModuleFunctionUpdate} />}
              {selectedFragment === 'Autentisering' && module && <FunctionAuthorization project={project} moduleFunction={moduleFunction} onModuleFunctionEdited={handleModuleFunctionUpdate} />}
            </ContentWrapper>
            <div className="side-nav-right d-flex flex-column p-3">
              <FunctionCard 
                project={project}
                moduleFunction={moduleFunction}
                selectedFragment={selectedFragment} 
                setSelectedFragment={setSelectedFragment}
                onModuleFunctionUpdate={handleModuleFunctionUpdate} 
              />
              <CommentsCard 
                label={"funktion"}
                identifierId={moduleFunction.id}
                maxHeight={380}
              />
            </div>
          </>
        )}
      </div>
  );
}

export default FunctionPage;
