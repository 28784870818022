import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ children }) => {
  const { auth } = useAuth();
  const location = useLocation();

  const publicPaths = ['/login', '/registrera'];

  if (publicPaths.includes(location.pathname)) {
    return children;
  }

  return auth.token ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
