import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from 'rsuite';

function EditCompanyModal({ show, handleClose, company, onCompanyEdited }) {
  const { auth } = useAuth();
  const [prospecting, setProspecting] = useState(false);
  const [active, setActive] = useState(false);
  const [createMailDispatch, setCreateMailDispatch] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show && company) {
      setProspecting(company.prospecting);
      setActive(company.active);
      setCreateMailDispatch(company.createMailDispatch);
    }
  }, [show, company]);

  const handleSubmit = async () => {

    setLoading(true);
    const companyData = {
      id: company.id,
      active,
      prospecting,
      createMailDispatch,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/edit-licence`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}` // Include token in the request
        },
        body: JSON.stringify(companyData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        onCompanyEdited(companyData); // Call the function to update the company details in the LicenceCard
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      toast.error('Misslcykades att uppdatera företaget.', {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  
  return (
    <Modal show={show} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>Redigera Licens</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label>Behörigheter</label>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault3"
              checked={createMailDispatch}
              onChange={(e) => setCreateMailDispatch(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault3">
              Mejlutskick
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault4"
              checked={prospecting}
              onChange={(e) => setProspecting(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault4">
              Prospektering
            </label>
          </div>
        </div>
        <div className='mt-2'>
          <label className="form-label">Status</label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="activeSwitch"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="activeSwitch">
              {active ? 'Aktiv licens' : 'Avstängd licens'}
            </label>
          </div>
        </div>
        
      </Modal.Body>
      <Modal.Footer className="d-flex">
        <div className='ms-auto'>
          <Button variant="secondary" className='me-2' onClick={handleClose}>
            Stäng
          </Button>
          <Button variant="primary" className="justify-content-center align-items-center" onClick={handleSubmit} disabled={loading}>
            {loading ? (
              <>
                <Loader className="me-2" /> Sparar...
              </>
            ) : (
              'Spara'
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default EditCompanyModal;
