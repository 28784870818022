import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from 'rsuite';

function CreateFlowModal({ show, handleClose, moduleId, onFlowCreated }) {
  const { auth } = useAuth();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);

  // Reset the form when the modal is closed
  useEffect(() => {
    if (!show) {
      setName('');
      setDescription('')
      setLoading(false); // Reset loading state if needed
    }
  }, [show]);

  const handleDescriptionChange = (e) => {
    const maxLength = 200; // Set the maximum number of characters
    if (e.target.value.length <= maxLength) {
      setDescription(e.target.value);
    }
  };

  const handleSubmit = async () => {
    if (!name || !description) {
      toast.error('Namn och beskrivning måste fyllas i.', {
        position: 'bottom-right'
      });
      return;
    }

    setLoading(true);
    const flowData = {
      name,
      description,
      moduleId
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/create-flow`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}` // Include token in the request
        },
        body: JSON.stringify(flowData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        handleClose();
        onFlowCreated(); // Call the function to update the company list
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Misslyckades att skapa flödet: ', error, {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>Nytt flöde</Modal.Title>
      </Modal.Header>
      <Modal.Body>
  <div className='mb-2'>
    <label htmlFor="name" className='form-label'>Namn</label>
    <input
      type="text"
      className='form-control'
      id="name"
      value={name}
      onChange={(e) => setName(e.target.value)}
    />
    <label htmlFor="description" className='form-label'>Beskrivning</label>
    <textarea
    className='form-control'
    id="description"
    value={description}
    placeholder='Använd detta flöde om kunden frågar om ...'
    onChange={handleDescriptionChange}
    rows="4"
    />
  </div>
</Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Stäng
        </Button>
        <Button variant="primary" className="d-flex justify-content-center align-items-center" onClick={handleSubmit} disabled={loading}>
            {loading ? (
              <>
                <Loader className="me-2" /> Sparar...
              </>
            ) : (
              'Spara'
            )}
          </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateFlowModal;
