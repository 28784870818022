import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IconButton, Badge, Popover, Whisper } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
import MinusIcon from '@rsuite/icons/Minus';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';


function TeamsAddUser({ show, handleClose, team }) {
  const { auth } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [assigningUserId, setAssigningUserId] = useState(null); // To track loading state for assigning users

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/users?size=100`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();

        // No need to parse teamIds as JSON, they are already a list of Long
        setUsers(data.content); // Directly set users without modification
      } else {
        const errorText = await response.text();
        throw new Error(`Misslyckades att hämta användare: ${errorText}`);
      }
    } catch (error) {
      console.error('Misslyckades att hämta användare: ', error);
      toast.error(error.message, {
        position: 'bottom-right',
      });
    } finally {
      setLoading(false);
    }
  };

  const addUserToTeam = async (userId) => {
    setAssigningUserId(userId); // Set the loading state for the specific user
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/add-user-team?userId=${userId}&teamId=${team.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        toast.success(data.message, {
          position: 'bottom-right'
        });
        
        // Update the users array after adding the user to the team
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === userId
              ? { ...user, teamIds: [...user.teamIds, team.id] } // Add team.id as Long
              : user
          )
        );
      } else {
        const errorText = await response.text();
        throw new Error(`Misslyckades att tilldela användare: ${errorText}`);
      }
    } catch (error) {
      console.error('Misslyckades att tilldela användare: ', error);
      toast.error(error.message, {
        position: 'bottom-right'
      });
    } finally {
      setAssigningUserId(null); // Clear the loading state for the specific user
    }
  };

  const removeUserFromTeam = async (userId) => {
    setAssigningUserId(userId); // Set the loading state for the specific user
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/remove-user-team?userId=${userId}&teamId=${team.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        toast.success(data.message, {
          position: 'bottom-right'
        });

        // Update the users array after removing the user from the team
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === userId
              ? { ...user, teamIds: user.teamIds.filter((id) => id !== team.id) } // Remove team.id as Long
              : user
          )
        );
      } else {
        const errorText = await response.text();
        throw new Error(`Misslyckades att avsätta användare: ${errorText}`);
      }
    } catch (error) {
      console.error('Misslyckades att avsätta användare: ', error);
      toast.error(error.message, {
        position: 'bottom-right'
      });
    } finally {
      setAssigningUserId(null); // Clear the loading state for the specific user
    }
  };

  // Only fetch users when the modal is open (when 'show' is true)
  useEffect(() => {
    if (show) {
      fetchUsers(); // Only fetch users when the modal is shown
    }
  }, [show]); // 'show' as a dependency

  return (
    <Modal show={show} onHide={handleClose} animation={true} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{team ? team.companyName : 'Laddar...'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Tilldela användare</h5>
            <p className='mb-2'>Här kan du tilldela en användare till ett team.</p>
            <p className='mb-2'>Att vara tilldelad till team innebär att man kan:</p>
            <ul>
              <li>Se moduler som är skapade under ett team</li>
              <li>Se statistik för respektive modul</li>
              <li>Pausa moduler</li>
              <li>Återuppta moduler</li>
            </ul>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Tilldela användare
          </IconButton>
        </Whisper>

        {/* Skeleton Loader while loading */}
        {loading ? (
          <>
            {[...Array(1)].map((_, index) => (  // Create 1 skeleton
              <div className="col-12" key={index}>
                <Skeleton height={40} />  {/* Email skeleton */}
              </div>
            ))}
          </>
        ) : (
          users.map((user) => {
            const isAssigned = user.teamIds.includes(team.id); // Check if team.id is in user's teamIds as a Long
            return (
              <div className="col-12" key={user.id}>
                <div className="card mb-2">
                  <div className="p-2">
                    <div className="d-flex align-items-center">
                      <p className="mb-0">{user.email}</p>
                      <div className="ms-auto">
                        {isAssigned && user.role !== "ROLE_USER" && (
                          <>
                            <Badge className="bg-success p-1 rounded me-2" content="Tilldelad" />
                            <IconButton
                              icon={<MinusIcon />}
                              color="red"
                              size="xs"
                              appearance="primary"
                              onClick={() => removeUserFromTeam(user.id)}  // Remove user from team
                              title="Avsätt användare"
                            />
                          </>
                        )}
                        {!isAssigned && user.role !== "ROLE_USER" && (
                          <>
                            <Badge className="bg-secondary p-1 rounded me-2" content="Ej tilldelad" />
                            <IconButton
                              icon={assigningUserId === user.id ? <Skeleton circle width={20} height={20} /> : <PlusIcon />}  // Show loader while assigning
                              size="xs"
                              appearance="subtle"
                              title="Tilldela användare"
                              onClick={() => addUserToTeam(user.id)}  // Assign user to team
                              disabled={assigningUserId === user.id}  // Disable button while assigning
                            />
                          </>
                        )}
                        {user.role === "ROLE_USER" && (
                          <>
                            <Badge className="bg-warning p-1 rounded me-2" content="Administrator" />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Stäng
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TeamsAddUser;
