// LayoutAdmin.jsx
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from './AdminNavBar'; // Adjust the import path as necessary
import AdminHeader from './AdminHeader'; // Adjust the import path as necessary
import 'bootstrap/dist/css/bootstrap.min.css';

const LayoutAdmin = () => {
  // State to manage Sidenav expansion
  const [expanded, setExpanded] = useState(true);

  return (
    <div className="d-flex">
      {/* Pass expanded and setExpanded as props to NavBar */}
      <NavBar expanded={expanded} setExpanded={setExpanded} />
      <div
        className="flex-grow-1 background-color"
        style={{
          marginLeft: expanded ? 240 : 56, // Adjust margin based on expanded state
          transition: 'margin-left 0.3s ease', // Smooth transition
        }}
      >
        <AdminHeader />
        {/* Outlet to render child routes */}
        <div className="main-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default LayoutAdmin;
