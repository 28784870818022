import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from 'rsuite';

function DNSEditAccount({ show, handleClose, account, onAccountCreated }) {
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [accountType, setAccountType] = useState(''); // To hold the selected account type
  const [accountFields, setAccountFields] = useState({}); // To hold the values for account fields

  // Account types configuration
  const accountTypes = {
    "Outlook": {
      email: { label: "E-post", type: "email" },
      clientId: { label: "Klient Id", type: "text" },
      organizationId: { label: "Klientorganisations id", type: "text" },
      secret: { label: "Hemlig nyckel", type: "text" }
    },
    "IMAP": {
      imapServer: { label: "IMAP Server", type: "text" },
      port: { label: "Port", type: "number" },
      email: { label: "E-post", type: "email" },
      password: { label: "Lösenord", type: "password" }
    }
  };

  // Load existing account data into the form
  useEffect(() => {
    if (account) {
      setAccountType(account.emailProvider);
      try {
        const credentials = JSON.parse(account.jsonCredentials);
        setAccountFields(credentials);
      } catch (error) {
        console.error('Misslyckades att läsa JSON specifikationer: ', error);
      }
    }
  }, [account]);

  const handleAccountFieldChange = (field, value) => {
    setAccountFields({
      ...accountFields,
      [field]: value
    });
  };

  const handleSubmit = async () => {
    setLoading(true);

    const accountData = {
      id: account.id,
      emailProvider: accountType,
      jsonCredentials: JSON.stringify(accountFields) // Serialize account fields to JSON
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/edit-email-account`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify(accountData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        onAccountCreated(result.account);
        handleClose();
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.error(error);
      toast.error('Misslyckades att spara kontot.', {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>{account ? account.email : 'Laddar...'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-2">
          <select
            className="form-select"
            value={accountType}
            onChange={(e) => {
              setAccountType(e.target.value);
              setAccountFields({}); // Reset account fields on type change
            }}
            disabled={!!account} // Disable if editing existing account to prevent changing type
          >
            <option value="" disabled>Välj e-post leverantör</option>
            {Object.keys(accountTypes).map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          {Object.keys(accountTypes[accountType] || {}).map((field) => (
            <div key={field} className="mb-2">
              <label>{accountTypes[accountType][field].label}</label>
              <input
                type={accountTypes[accountType][field].type}
                className="form-control"
                value={accountFields[field] || ''}
                onChange={(e) => handleAccountFieldChange(field, e.target.value)}
              />
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Stäng
        </Button>
        <Button variant="primary" className="d-flex justify-content-center align-items-center" onClick={handleSubmit} disabled={loading || !accountType}>
            {loading ? (
              <>
                <Loader className="me-2" /> Sparar...
              </>
            ) : (
              'Spara'
            )}
          </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DNSEditAccount;
