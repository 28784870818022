import React, { useEffect, useState } from 'react';
import axios from 'axios';
import usImage from '../../../images/personal.png';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Helmet } from 'react-helmet-async';
import PageIcon from '@rsuite/icons/Page';
import CubeIcon from '@rsuite/icons/legacy/Cube';

function AdminDashboardPage() {
  const { auth } = useAuth();
  const [licensesCount, setLicensesCount] = useState(0);
  const [modulesCount, setModulesCount] = useState(0);
  const [conversationsCount, setConversationsCount] = useState(0);
  const [leadsCount, setLeadsCount] = useState(0);
  const [loadingLicenses, setLoadingLicenses] = useState(true);
  const [loadingConversations, setLoadingConversations] = useState(true);
  const [loadingModules, setLoadingModules] = useState(true);

  // Fetch Licenses (Companies)
  const fetchLicensesCount = async () => {
    setLoadingLicenses(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/superadmin/licences`, {
        headers: {
          'Authorization': `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
        },
        params: {
          size: 100,
          isAdminLicence: true
        },
      });

      if (response.status === 200) {
        const companies = response.data.content;
        setLicensesCount(companies.length); // Total licenses count
      } else {
        toast.error('Misslyckades att hämta licenser.', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      toast.error('Misslyckades att hämta licenser.', {
        position: 'bottom-right',
      });
    } finally {
      setLoadingLicenses(false);
    }
  };

  const fetchModulesCount = async () => {
    setLoadingModules(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/superadmin/modules`, {
        headers: {
          'Authorization': `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
        },
        params: {
          size: 100, // Adjust size if necessary
        },
      });

      if (response.status === 200) {
        const companies = response.data.content;
        setModulesCount(companies.length); // Total licenses count
      } else {
        toast.error('Misslyckades att hämta moduler.', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      toast.error('Misslyckades att hämta moduler.', {
        position: 'bottom-right',
      });
    } finally {
      setLoadingModules(false);
    }
  };

  const fetchConversationsAndLeadsCount = async () => {
    setLoadingConversations(true);

    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/superadmin/conversations`, {
        headers: {
          'Authorization': `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
        },
        params: {
          size: 1000, // Fetch up to 1000 conversations
        },
      });

      if (response.status === 200) {
        const conversations = response.data.content;
        
        // Count total conversations
        setConversationsCount(conversations.length);

        // Count leads (where leadGenerated is true)
        const leads = conversations.filter(conversation => conversation.leadGenerated === true).length;
        setLeadsCount(leads);
      } else {
        toast.error('Misslyckades att hämta konversationer.', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      toast.error('Misslyckades att hämta konversationer.', {
        position: 'bottom-right',
      });
    } finally {
      setLoadingConversations(false);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchLicensesCount();
    fetchModulesCount();
    fetchConversationsAndLeadsCount();
  }, []);

  return (
    <>
    <Helmet>
          <title>{`Admin - Översikt`}</title>
        </Helmet>
      <div className='px-5 pt-4 w-100'>
        <h4 className="mb-2">Välkommen Tillbaka, {auth.firstName}! 👋</h4>
        <p className="text-muted">Här är din översikt</p>
        <hr />


        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
            <div className="card shadow p-3">
              <div className="d-flex align-items-center">
                  <PageIcon className='me-2' />
                  <p className='mb-0'>Admin licenser</p>
              </div>
              <h2 className='mb-0'>
                {loadingLicenses ? <Skeleton width={50} height={40} /> : licensesCount}
              </h2>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4 mb-">
            <div className="card shadow p-3">
                <div className="d-flex">
                    <CubeIcon className='me-2' />
                    <p className='mb-0'>Moduler</p>
                </div>
                <h2 className='mb-0'>
                  {loadingModules ? <Skeleton width={50} height={40} /> : modulesCount}
                </h2>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4 mb-">
            <div className="card shadow p-3">
                <div className="d-flex">
                    <i className="bi bi-people-fill me-2"></i>
                    <p className='mb-0'>Konversationer</p>
                </div>
                <h2 className='mb-0'>
                  {loadingConversations ? <Skeleton width={50} height={40} /> : conversationsCount}
                </h2>
            </div>
          </div>
          <div className='col-sm-12 col-md-12 col-lg-4 mb-'>
          <div className="card shadow p-3">
              <div className="d-flex">
                  <i className="bi bi-info-circle me-2"></i>
                  <p className='mb-0'>Leads genererade</p>
              </div>
              <h2 className='mb-0'>
                {loadingConversations ? <Skeleton width={50} height={40} /> : leadsCount}
              </h2>
            </div>
          </div>
        </div>
        <section className="pt-5">
                <h4 className="large-text">Behöver du support?</h4>
                <div className="contact">

                    <p className="pt-2 text-muted pb-0 mb-1">+46(0)70-738 18 31</p>
                    <p className="text-muted">elliot.heathnyberg@gmail.com</p>
                </div>
                </section>

                <section className="pt-1 pb-3">
                  <img
                    style={{ width: "120px", borderRadius: "200px" }}
                    src={usImage}
                    alt="personal"
                  />
                </section>
      </div>
    </>
  );
}

export default AdminDashboardPage;
