import React, { useEffect, useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Pagination } from 'react-bootstrap'; 
import CreateBlacklistAddressModal from '../../../modals/company/general/CreateBlacklistAddress';
import { Helmet } from 'react-helmet-async';
import { Badge, Loader, Popover, Whisper, IconButton, Breadcrumb } from 'rsuite';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import PlusIcon from '@rsuite/icons/Plus';

function CompanyBlacklist() {
  const [loading, setLoading] = useState(true);
  const [blacklist, setBlacklist] = useState([]);
  const [page, setPage] = useState(0);  
  const [totalPages, setTotalPages] = useState(1);  
  const [searchQuery, setSearchQuery] = useState('');  
  const { auth } = useAuth();

  const [uploadingFiles, setUploadingFiles] = useState([]); // To track files being uploaded

  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const handleShowModal = () => setShowModal(true);  // Function to show modal
  const handleCloseModal = () => setShowModal(false); // Function to hide modal

  const fetchBlacklist = async (currentPage = 0, emailQuery = '') => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/blacklist`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        params: {
            email: emailQuery,
            page: currentPage,
            size: 12  
        }
      });

      if (response.status === 200) {
        setBlacklist(response.data.content);
        setPage(response.data.number);  
        setTotalPages(response.data.totalPages);  
      } else {
        toast.error('Misslyckades att hämta svartlistade e-postadresser.', { position: 'bottom-right' });
      }
    } catch (error) {
        toast.error('Misslyckades att hämta svartlistade e-postadresser.', { position: 'bottom-right' });
        console.error('Misslyckades att hämta svartlistade e-postadresser: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlacklist();  
  }, []);
  

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchBlacklist(newPage, searchQuery);  
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleBlacklistCreated = (e) => {
    // Show toast first
    fetchBlacklist();
    // Add a small delay before re-fetching the blacklist
    setTimeout(() => {
        toast.success("Svartlistad e-postadress tillagd!", { position: 'bottom-right' });
    }, 100); // 100ms delay to ensure the toast shows first
  };

  const handleFileUpload = async (event) => {
    const selectedFiles = event.target.files || event.dataTransfer.files;
  
    const allowedTypes = [
      // Allowed MIME types
      'text/csv'
    ];
  
    const filesToUpload = [];
  
    for (const file of selectedFiles) {
      if (allowedTypes.includes(file.type)) {
        filesToUpload.push(file);
      } else {
        toast.error(`Fil typ '${file.type}' är inte tillåten.`, { position: 'bottom-right' });
        return; // Stop execution for invalid file type
      }
    }
  
    setUploadingFiles(filesToUpload); // Add files to uploading state
  
    for (const file of filesToUpload) {
      const formData = new FormData();
      formData.append('files', file);
  
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/create-blacklist-file`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${auth.token}`
          }
        });
  
        if (response.status === 200) {
          toast.success(response.data.message, { position: 'bottom-right' });
        } else {
          toast.error(response.data.message, { position: 'bottom-right' });
        }
      } catch (error) {
        console.error(`Misslyckades att ladda upp ${file.name}:`, error);
        toast.error(`Misslyckades att ladda upp ${file.name}.`, { position: 'bottom-right' });
      } finally {
        setUploadingFiles((prev) => prev.filter((uploadingFile) => uploadingFile !== file)); // Remove file from uploading state
      }
    }
  
    fetchBlacklist(); // Refresh the file list after upload is complete
  };

  const handleDrop = (event) => {
    event.preventDefault();
    handleFileUpload(event);
  };
  

  const handleSearchSubmit = (e) => {
    if (e.key === 'Enter') {  
      setPage(0);  
      fetchBlacklist(0, searchQuery);  
    }
  };

  return (
    <>
      <Helmet>
          <title>{`Svartlista`}</title>
        </Helmet>
        <div className='px-5 pt-4 w-100'>
      
      <div className="d-flex">

        <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Svartlista e-postadresser</h5>
            <p className='mb-2'>Här kan du ladda upp e-postadresser som inte kan nås genom:</p>
            <ul>
              <li>Mejlutskick</li>
              <li>Prospektering</li>
            </ul>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Svartlista
          </IconButton>
        </Whisper>
        <IconButton className='mb-2 ms-2' onClick={handleShowModal} icon={<PlusIcon className='bg-danger' color='white' />}>
          Svartlista en e-postadress
        </IconButton>

        <div className='ms-auto'>
            <div className="input-group">
                <input 
                  className='form-control' 
                  type="text" 
                  placeholder='Sök e-post' 
                  value={searchQuery}
                  disabled={loading}
                  onChange={handleSearch}
                  onKeyDown={handleSearchSubmit}  
                />
                <span className="input-group-text">
                    <i className="bi bi-search"></i>
                </span>
            </div>
        </div>
      </div>

      <input
            type="file"
            multiple
            id="file-upload"
            style={{ display: 'none' }}
            onChange={handleFileUpload}
          />

      <div
        className="card dashed shadow border-1 rounded mb-2"
        style={{ cursor: 'pointer' }}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        {/* Wrap the contents with a label */}
        <label 
          htmlFor="file-upload"  // This targets your existing hidden file input
          style={{ display: 'block', width: '100%', height: '100%', cursor: 'pointer', textAlign: 'center' }}
        >
          <div className='p-3 text-center'>
            <p className='mb-0'>
              <i className="bi bi-upload me-2"></i>
              Ladda upp lista från datorn
            </p>
            <p className='text-muted mb-0'>Eller dra och släpp. Max 10MB</p>
          </div>
        </label>
      </div>

      {loading ? (
          <>
            <Skeleton height={50} />  
          </>
      ) : (
        <>
        <div className="mb-2">
        <ul className='list-group'>

        {uploadingFiles.map((file, index) => (
            <li key={`uploading-${index}`} className="list-group-item d-flex justify-content-between align-items-center">
              {file.name} 
              <Loader speed='fast' size="sm" content="Laddar upp..." />
            </li>
          ))}
            {blacklist.length > 0 && (
            blacklist.map((item) => (
                <li key={item.id} className="list-group-item d-flex justify-content-between align-items-center p-2">
                <div className="d-flex justify-content-between align-items-center w-100">
                    <span>{item.email}</span>
                    <div className="d-flex align-items-center">
                    <Badge className='bg-secondary p-1 rounded me-2' content={new Date(item.created).toLocaleString()} />
                    </div>
                </div>
                </li>
            ))
            )}
        </ul>
        </div>
        </>
      )}

        <div className="d-flex my-4">
        {totalPages > 1 && (
        <div className="d-flex my-4">
            <Pagination>
            <Pagination.Prev 
                onClick={() => handlePageChange(page - 1)} 
                disabled={page === 0}
            />
            
            {(() => {
                const totalDisplayedPages = 10;  
                let startPage = Math.max(0, page - Math.floor(totalDisplayedPages / 2));
                let endPage = startPage + totalDisplayedPages;

                if (endPage > totalPages) {
                endPage = totalPages;
                startPage = Math.max(0, totalPages - totalDisplayedPages);
                }

                return [...Array(endPage - startPage).keys()].map((p) => (
                <Pagination.Item 
                    key={startPage + p} 
                    active={startPage + p === page} 
                    onClick={() => handlePageChange(startPage + p)}
                >
                    {startPage + p + 1}
                </Pagination.Item>
                ));
            })()}

            <Pagination.Next 
                onClick={() => handlePageChange(page + 1)} 
                disabled={page === totalPages - 1}
            />
            </Pagination>
        </div>
            )}
        </div>
      <CreateBlacklistAddressModal 
        show={showModal} 
        onHide={handleCloseModal} 
        onBlacklistedAdressCreated={handleBlacklistCreated} 
      />
        </div>
    </>
  );
}

export default CompanyBlacklist;
