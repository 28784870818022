import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useSearchParams } from 'react-router-dom'; // Import useSearchParams for token
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import { Loader } from 'rsuite';

function ResetPasswordPage() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false); // State for checkbox
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const [searchParams] = useSearchParams(); // To get the token from the URL
  const navigate = useNavigate(); // Initialize useNavigate

  const token = searchParams.get('token');

  useEffect(() => {
    // Validate token on page load
    const validateToken = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/validate-reset-token?token=${token}`);
        if (response.ok) {
          setValidToken(true);
        } else {
          toast.error('Ogiltig eller föråldrad inbjudningslänk.', { position: 'bottom-right' });
        }
      } catch (error) {
        toast.error('Fel vid validering av inbjudningslänk.', { position: 'bottom-right' });
      }
    };

    if (token) {
      validateToken();
    } else {
      toast.error('Ingen inbjudningslänk hittades.', { position: 'bottom-right' });
    }
  }, [token]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    // Check if passwords match
    if (password !== confirmPassword) {
      toast.error("Lösenorden matchar inte.", { position: 'bottom-right' });
      setLoading(false);
      return;
    }

    // Check if the terms are accepted
    if (!termsAccepted) {
      toast.error("Du måste godkänna användarvillkoren.", { position: 'bottom-right' });
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/reset-password?token=${token}&password=${password}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, password }),
      });

      if (!response.ok) {
        toast.error("Misslyckades att skapa konto: " + response.status, { position: 'bottom-right' });
        throw new Error('Misslyckades att skapa konto, ' + response.status);
      }

      toast.success('Konto skapat!', { position: 'bottom-right' });
      navigate('/login');

    } catch (error) {
      toast.error("Misslyckades att skapa konto: " + error, { position: 'bottom-right' });
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{`Skapa konto`}</title>
      </Helmet>
      <div className='background-color'>
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div className='card p-4 shadow-lg' style={{ width: '400px' }}>
            {validToken ? (
              <form onSubmit={handleSubmit}>
                <div className='mb-4'>
                  <label className='form-label' htmlFor="password">Lösenord</label>
                  <input
                    className='form-control'
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <label className='form-label' htmlFor="confirmPassword">Bekräfta Lösenord</label>
                  <input
                    className='form-control'
                    type="password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>

                {/* Checkbox for terms and conditions */}
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="termsAccepted"
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                    required
                  />
                  <label className="form-check-label" htmlFor="termsAccepted">
                    När du skapar ett konto godkänner du <a href="/terms-of-service" target="_blank">användarvillkoren</a>.
                  </label>
                </div>

                <button className='btn btn-primary w-100 d-flex justify-content-center align-items-center' type="submit" disabled={loading}>
                  {loading ? (
                    <>
                      <Loader className="me-2" /> Skapar konto...
                    </>
                  ) : (
                    'Skapa konto'
                  )}
                </button>
              </form>
            ) : (
              <p className='mb-0'>Skapa konto länken är ogiltig.</p>
            )}
            <div className="pt-3 mt-3">
               {/*<p className="copywritten-text">© 2024 Sonnet</p>*/} 
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPasswordPage;
