import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emptyAvatar from '../../../images/empty-avatar.svg';
import EmptyChatbot from './EmptyChatbot';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Helmet } from 'react-helmet-async';
import { Breadcrumb, Loader, IconButton, Whsiepr, Popover } from 'rsuite';
import ReloadIcon from '@rsuite/icons/Reload';

function ModuleChatbotDesign({ project, module }) {
  const { auth } = useAuth(); // Get auth token from context
  const [chatbotAttributes, setChatbotAttributes] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState('');
  const [avatarFile, setAvatarFile] = useState(null); // State to hold the selected file

  // Reference for the file input field
  const fileInputRef = useRef(null);

  // Icons list
  const icons = [
    "bi bi-chat",
    "bi bi-chat-dots",
    "bi bi-chat-dots-fill",
    "bi bi-chat-fill",
    "bi bi-chat-heart",
    "bi bi-chat-left",
    "bi bi-chat-left-dots",
    "bi bi-chat-left-dots-fill",
    "bi bi-chat-left-fill",
    "bi bi-chat-left-text",
    "bi bi-chat-left-text-fill",
    "bi bi-chat-text",
    "bi bi-chat-text-fill"
  ];

  // Fetch chatbot attributes on mount
  useEffect(() => {
    fetchChatbotAttributes();
  }, [module.id, auth]);

  // Fetch chatbot attributes from backend
  const fetchChatbotAttributes = async () => {
    setLoading(true);
    try {
      const response = await fetchChatbotData();
      if (response.ok) {
        const data = await response.json();
        const attributes = data.content[0]; // Assuming data.content is paginated
        updateAttributes(attributes);
      } else {
        handleError("Misslyckades att hämta chatbot attributen.");
      }
    } catch (error) {
      handleError("Något gick fel vid hämtningen av chatbot attributen.");
    } finally {
      setLoading(false);
    }
  };

  // Handle avatar file change
  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAvatarFile(file);
      setChatbotAttributes({
        ...chatbotAttributes,
        systemChatbotAvatarSrc: URL.createObjectURL(file)
      });
    }
  };

  // Handle icon selection
  const handleIconSelect = (icon) => {
    setSelectedIcon(icon);
    setChatbotAttributes({ ...chatbotAttributes, systemChatbotIcon: icon });
  };

  // Handle attribute change dynamically
  const handleAttributeChange = (e, attributeName) => {
    setChatbotAttributes({ ...chatbotAttributes, [attributeName]: e.target.value });
  };

  // Reset attributes to default via backend API call
  const resetAttributes = async () => {
    if (!window.confirm('Är du säker på att du vill återställa designen?')) {
      return;
    }
    try {
      const response = await resetChatbotAttributes();
      if (response.ok) {
        toast.success((await response.json()).message, { position: 'top-right' });
        fetchChatbotAttributes(); // Re-fetch attributes after reset
      } else {
        handleError((await response.json()).message);
      }
    } catch (error) {
      handleError("Något gick fel vid återställning av designen.");
    }
  };

  // Save chatbot attributes
  const handleSave = async () => {
    setLoading(true);
    try {
      const response = await saveChatbotAttributes();
      if (response.ok) {
        toast.success((await response.json()).message, { position: 'top-right' });
        clearAvatarFile();
        fetchChatbotAttributes(); // Optionally re-fetch attributes
      } else {
        handleError((await response.json()).message);
      }
    } catch (error) {
      handleError("Något gick fel vid sparning av ändringarna.");
    } finally {
      setLoading(false);
    }
  };

  // Utility Functions

  // Fetch chatbot attributes from the backend
  const fetchChatbotData = () => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/chatbot-attributes?moduleId=${module.id}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    });
  };

  // Reset chatbot attributes via the backend
  const resetChatbotAttributes = () => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/user/reset-chatbot-attributes?moduleId=${module.id}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${auth.token}`,
        'Content-Type': 'application/json',
      }
    });
  };

  // Save chatbot attributes and avatar file via the backend
  const saveChatbotAttributes = () => {
    const formData = new FormData();
    const blob = new Blob([JSON.stringify(chatbotAttributes)], { type: 'application/json' });
    formData.append('chatbotAttributes', blob);

    if (avatarFile) {
      formData.append('avatarFile', avatarFile);
    }

    return fetch(`${process.env.REACT_APP_BASE_URL}/user/edit-chatbot-attributes`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${auth.token}`,
      },
      body: formData,
    });
  };

  // Update chatbot attributes state
  const updateAttributes = (attributes) => {
    setChatbotAttributes(attributes);
    setSelectedIcon(attributes.systemChatbotIcon);
  };

  // Clear avatar file input
  const clearAvatarFile = () => {
    setAvatarFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear file input
    }
  };

  // Handle and show errors
  const handleError = (message) => {
    setError(true);
    toast.error(message, { position: 'top-right' });
  };

  if (error) {
    return <p>Det gick inte att hämta chatbot attributen.</p>;
  }

  return (
    <>
      <Helmet>
        <title>{`${module?.name} - Chattbot design`}</title>
      </Helmet>
      {/* Header Section */}
      <div className='d-flex'>
      <Breadcrumb className='mb-0'>
            <Breadcrumb.Item>
              <Link to={`/user/projekt/${project?.id}`}>{project?.name}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{module?.name}</Breadcrumb.Item>
            <Breadcrumb.Item active>Kontaktperson</Breadcrumb.Item>
      </Breadcrumb>


        <div className="ms-auto">
          <IconButton className='ms-2' onClick={() => resetAttributes()} icon={<ReloadIcon className='bg-secondary' color='white' />}>
            Återställ design
          </IconButton>
        </div>
      </div>

      <hr />

      {loading ? (
          <>
            <Skeleton height={60} />  
          </>
      ) : (
        <>
        <div>
        <div className="d-flex mb-2">
          <i className="bi bi-info-circle me-2"></i>
          <p className='mb-0'>Vilken profilbild ska chattboten ha?</p>
        </div>
        <label htmlFor="avatar-upload">
          <img
            src={chatbotAttributes?.systemChatbotAvatarSrc ? chatbotAttributes.systemChatbotAvatarSrc : emptyAvatar}
            alt="Chatbot Avatar"
            style={{ width: '100px', cursor: 'pointer' }} // Set cursor to pointer
          />
        </label>
        <input
          id="avatar-upload"
          type="file"
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleAvatarChange} // Handle file change
          ref={fileInputRef} // Attach the ref to the input element
        />
      </div>


      <hr />

      {/* Button Design Section */}
      <div>
        <div className="d-flex mb-2">
          <i className="bi bi-info-circle me-2"></i>
          <p className='mb-0'>Hur ska chattbottens knapp se ut?</p>
        </div>
        <div className="row mb-2 p-3">
          <div className="col-auto">
          <input
            type="color"
            title="Bubbla färg"
            value={chatbotAttributes.systemChatbotButtonBackgroundColor || '#000000'}
            onChange={(e) => handleAttributeChange(e, 'systemChatbotButtonBackgroundColor')}
            className="form-control color-pick border border-dark rounded-circle me-4"
          />
          </div>
          <input
            type="color"
            title="Chatticon färg"
            value={chatbotAttributes.systemChatbotIconColor || '#000000'}
            onChange={(e) => handleAttributeChange(e, 'systemChatbotIconColor')}
            className="form-control color-pick border border-dark rounded-circle me-4"
          />
          <input
            type="color"
            title="Text färg"
            value={chatbotAttributes.systemChatbotButtonTextColor || '#000000'}
            onChange={(e) => handleAttributeChange(e, 'systemChatbotButtonTextColor')}
            className="form-control color-pick border border-dark rounded-circle me-4"
          />
        </div>
        <div className="row mb-2">
          <div className='mb-2'>
            <label htmlFor="">Bublans text</label>
            <input
              value={chatbotAttributes.systemChatbotButtonText || ''}
              onChange={(e) => handleAttributeChange(e, 'systemChatbotButtonText')}
              className='form-control w-50'
              type="text"
            />
          </div>
          <div>
            <label htmlFor="">Bubblans radius</label>
            <input
              value={chatbotAttributes.systemChatbotButtonBorderRadius || ''}
              onChange={(e) => handleAttributeChange(e, 'systemChatbotButtonBorderRadius')}
              className='form-control w-25'
              type="number"
            />
          </div>
        </div>

        {/* Icon Selection Section */}
        <label htmlFor="">Chatt icon</label>
        <div className="mb-2 d-flex flex-wrap">
          {icons.map((icon, index) => (
            <div
              key={index}
              className="d-flex align-items-center"
              style={{ marginRight: '15px', cursor: 'pointer' }}
              onClick={() => handleIconSelect(icon)}
            >
              <i
                className={icon}
                style={{
                  fontSize: '24px',
                  marginRight: '8px',
                }}
              ></i>
              <input
                type="radio"
                name="chatbot-icon"
                checked={selectedIcon === icon}
                onChange={() => handleIconSelect(icon)}
                style={{
                  marginLeft: '5px',
                  cursor: 'pointer',
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <hr />

      <div>
        <div className="d-flex mb-2">
              <i className="bi bi-info-circle me-2"></i>
              <p className='mb-0'>Hur ska chattbottens huvudmeny se ut?</p>
        </div>
        <div className="row mb-2 p-3">
                    <input
                    type="color"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Chattbotens färg på namn"
                    value={chatbotAttributes.systemChatbotLabelColor || '#000000'}
                    onChange={(e) => handleAttributeChange(e, 'systemChatbotLabelColor')}
                    className="form-control color-pick border border-dark rounded-circle me-4"
                  />
                  <input
                    type="color"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Bakgrundsfärg"
                    value={chatbotAttributes.systemChatbotHeaderBackgroundColor || '#000000'}
                    onChange={(e) => handleAttributeChange(e, 'systemChatbotHeaderBackgroundColor')}
                    className="form-control color-pick border border-dark rounded-circle me-4"
                  />
                  <input
                    type="color"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Stäng chattbot knapp"
                    value={chatbotAttributes.systemCloseButtonColor || '#000000'}
                    onChange={(e) => handleAttributeChange(e, 'systemCloseButtonColor')}
                    className="form-control color-pick border border-dark rounded-circle me-4"
                  />
                  <input
                    type="color"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Radera konversation knapp"
                    value={chatbotAttributes.systemDeleteButtonColor || '#000000'}
                    onChange={(e) => handleAttributeChange(e, 'systemDeleteButtonColor')}
                    className="form-control color-pick border border-dark rounded-circle me-4"
                  />
        </div>
      </div>
      <hr />

      <div>
        <div className="d-flex mb-2">
              <i className="bi bi-info-circle me-2"></i>
              <p className='mb-0'>Hur ska chattbottens huvudinnehåll se ut?</p>
        </div>
        <div className="row mb-2 p-3">
                    <input
                    type="color"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Chattbotens huvudinnehåll bakgrundsfärg"
                    value={chatbotAttributes.systemChatbotContentBackgroundColor || '#000000'}
                    onChange={(e) => handleAttributeChange(e, 'systemChatbotContentBackgroundColor')}
                    className="form-control color-pick border border-dark rounded-circle me-4"
                  />
                  <input
                    type="color"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Chattbotens ai system meddelande, textfärg"
                    value={chatbotAttributes.systemChatbotMessageSystemColor || '#000000'}
                    onChange={(e) => handleAttributeChange(e, 'systemChatbotMessageSystemColor')}
                    className="form-control color-pick border border-dark rounded-circle me-4"
                  />
                  <input
                    type="color"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Chattbotens ai system meddelande, bakgrundsfärg"
                    value={chatbotAttributes.systemChatbotMessageSystemBackgroundColor || '#000000'}
                    onChange={(e) => handleAttributeChange(e, 'systemChatbotMessageSystemBackgroundColor')}
                    className="form-control color-pick border border-dark rounded-circle me-4"
                  />
                  <input
                    type="color"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Chattbotens kund meddelande, textfärg"
                    value={chatbotAttributes.systemChatbotMessageUserColor || '#000000'}
                    onChange={(e) => handleAttributeChange(e, 'systemChatbotMessageUserColor')}
                    className="form-control color-pick border border-dark rounded-circle me-4"
                  />
                  <input
                    type="color"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Chattbotens kund meddelande, bakgrundsfärg"
                    value={chatbotAttributes.systemChatbotMessageUserBackgroundColor || '#000000'}
                    onChange={(e) => handleAttributeChange(e, 'systemChatbotMessageUserBackgroundColor')}
                    className="form-control color-pick border border-dark rounded-circle me-4"
                  />
        </div>
      </div>
      <hr />

      <div>
        <div className="d-flex mb-2">
              <i className="bi bi-info-circle me-2"></i>
              <p className='mb-0'>Hur ska chattbottens inputsektion se ut?</p>
        </div>
        <div className="row mb-2 p-3">
                    <input
                    type="color"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Chattbotens huvudinnehåll bakgrundsfärg"
                    value={chatbotAttributes.systemChatbotInputBackgroundColor || '#000000'}
                    onChange={(e) => handleAttributeChange(e, 'systemChatbotInputBackgroundColor')}
                    className="form-control color-pick border border-dark rounded-circle me-4"
                  />
                  <input
                    type="color"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Chattbotens skapad av text, textfärg"
                    value={chatbotAttributes.systemChatbotCreatedByColor || '#000000'}
                    onChange={(e) => handleAttributeChange(e, 'systemChatbotCreatedByColor')}
                    className="form-control color-pick border border-dark rounded-circle me-4"
                  />
                  <input
                    type="color"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Chattbotens skicka knapp, iconfärg"
                    value={chatbotAttributes.systemChatbotSendButtonColor || '#000000'}
                    onChange={(e) => handleAttributeChange(e, 'systemChatbotSendButtonColor')}
                    className="form-control color-pick border border-dark rounded-circle me-4"
                  />
                  <input
                    type="color"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Chattbotens skicka knapp, bakgrundsmeddelande"
                    value={chatbotAttributes.systemChatbotSendButtonBackgroundColor || '#000000'}
                    onChange={(e) => handleAttributeChange(e, 'systemChatbotSendButtonBackgroundColor')}
                    className="form-control color-pick border border-dark rounded-circle me-4"
                  />
                  <input
                    type="color"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Chattbotens ladda upp icon, iconfärg"
                    value={chatbotAttributes.systemChatbotUploadButtonColor || '#000000'}
                    onChange={(e) => handleAttributeChange(e, 'systemChatbotUploadButtonColor')}
                    className="form-control color-pick border border-dark rounded-circle me-4"
                  />
        </div>
      </div>
      {/* Avatar Section */}
      
      <hr />

      <button className='btn btn-primary d-flex justify-content-center align-items-center' onClick={handleSave} disabled={loading}>
          {loading ? (
            <>
              <Loader className="me-2" /> Sparar...
            </>
          ) : (
            'Spara ändringar'
          )}
        </button>
        </>
      )}

      <EmptyChatbot module={module} chatbotAttributes={chatbotAttributes} />
    </>
  );
}

export default ModuleChatbotDesign;
