import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IconButton, Badge, Whisper, Popover } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
import MinusIcon from '@rsuite/icons/Minus';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import axios from 'axios';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';


function UserAddTeams({ show, handleClose, user, setUsers }) {
  const { auth } = useAuth();
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [assigningTeamId, setAssigningTeamId] = useState(null); // To track loading state for assigning teams

  // Function to return user's teamIds as a List of Long
  const getUserTeamIds = () => {
    return user && Array.isArray(user.teamIds) ? user.teamIds : []; // Ensure it's always a list of Long
  };

  const fetchTeams = async () => {
    setLoading(true); // Set loading true at the start of the request
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/companies`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      });
      if (response.status === 200) {
        const teams = response.data.content;
        setTeams(teams);
        setLoading(false); // Set loading to false after the request is successful
      } else {
        toast.error('Misslyckades att hämta team', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      toast.error('Misslyckades att hämta team', {
        position: 'bottom-right',
      });
      setLoading(false); // Also set loading to false in case of error
    }
  };

  const addUserToTeam = async (teamId) => {
    if (!user) return;
    setAssigningTeamId(teamId);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/add-user-team?userId=${user.id}&teamId=${teamId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        toast.success(data.message, {
          position: 'bottom-right',
        });

        // Update the user's teamIds locally after success
        const updatedTeamIds = [...getUserTeamIds(), teamId]; // Add the teamId to teamIds as Long

        // Update users with the updated teamIds
        setUsers((prevUsers) =>
          prevUsers.map((u) =>
            u.id === user.id
              ? { ...u, teamIds: updatedTeamIds } // No need to convert to string, handle as List<Long>
              : u
          )
        );
      } else {
        const errorText = await response.text();
        throw new Error(`Misslyckades att tilldela team: ${errorText}`);
      }
    } catch (error) {
      console.error('Misslyckades att tilldela team: ', error);
      toast.error(error.message, {
        position: 'bottom-right',
      });
    } finally {
      setAssigningTeamId(null); // Clear the loading state for the specific team
    }
  };

  const removeTeamFromUser = async (teamId) => {
    if (!user) return;
    setAssigningTeamId(teamId);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/remove-user-team?userId=${user.id}&teamId=${teamId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        toast.success(data.message, {
          position: 'bottom-right',
        });

        // Update the user's teamIds locally after success
        const updatedTeamIds = getUserTeamIds().filter((id) => id !== teamId); // Remove the teamId from teamIds as Long

        // Update users with the updated teamIds
        setUsers((prevUsers) =>
          prevUsers.map((u) =>
            u.id === user.id
              ? { ...u, teamIds: updatedTeamIds } // No need to convert to string, handle as List<Long>
              : u
          )
        );
      } else {
        const errorText = await response.text();
        throw new Error(`Misslyckades att avsätta team: ${errorText}`);
      }
    } catch (error) {
      console.error('Misslyckades att avsätta team: ', error);
      toast.error(error.message, {
        position: 'bottom-right',
      });
    } finally {
      setAssigningTeamId(null); // Clear the loading state for the specific team
    }
  };

  // Only fetch teams when the modal is open (when 'show' is true)
  useEffect(() => {
    if (show && user) {
      fetchTeams(); // Fetch teams when the modal is shown
    }
  }, [show, user]); // 'show' and 'user' as dependencies

  if (!user) {
    return null; // Do not render the modal if the user is not defined
  }

  return (
    <Modal show={show} onHide={handleClose} animation={true} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{user ? user.email : 'Laddar...'}</Modal.Title> {/* Use user.email instead of team */}
      </Modal.Header>
      <Modal.Body>

        <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Tilldela användare</h5>
            <p className='mb-2'>Här kan du tilldela en användare till ett team.</p>
            <p className='mb-2'>Att vara tilldelad till team innebär att man kan:</p>
            <ul>
              <li>Se moduler som är skapade under ett team</li>
              <li>Se statistik för respektive modul</li>
              <li>Pausa moduler</li>
              <li>Återuppta moduler</li>
            </ul>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Tilldela användare
          </IconButton>
        </Whisper>

        {/* Skeleton Loader while loading */}
        {loading ? (
          <>
            {[...Array(1)].map((_, index) => (  // Create 1 skeleton
              <div className="col-12" key={index}>
                <Skeleton height={40} />  {/* Team skeleton */}
              </div>
            ))}
          </>
        ) : (
          teams.map((team) => {
            const isAssigned = getUserTeamIds().includes(team.id); // Ensure teamIds is always an array of Long
            return (
              <div className="col-12" key={team.id}>
                <div className="card mb-2">
                  <div className="p-2">
                    <div className="d-flex align-items-center">
                      <p className="mb-0">{team.companyName}</p>
                      <div className="ms-auto">
                        {isAssigned ? (
                          <>
                            <Badge className="bg-success p-1 rounded me-2" content="Tilldelad" />
                            <IconButton
                              icon={<MinusIcon />}
                              color="red"
                              size="xs"
                              appearance="primary"
                              onClick={() => removeTeamFromUser(team.id)}  // Remove team from user
                              title="Avsätt team"
                            />
                          </>
                        ) : (
                          <>
                            <Badge className="bg-secondary p-1 rounded me-2" content="Ej tilldelad" />
                            <IconButton
                              icon={assigningTeamId === team.id ? <Skeleton circle width={20} height={20} /> : <PlusIcon />}  // Show loader while assigning
                              size="xs"
                              appearance="subtle"
                              title="Tilldela team"
                              onClick={() => addUserToTeam(team.id)}  // Assign team to user
                              disabled={assigningTeamId === team.id}  // Disable button while assigning
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Stäng
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UserAddTeams;
