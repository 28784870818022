import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useAuth } from '../../../AuthContext';
import { Helmet } from 'react-helmet-async';
import { Breadcrumb } from 'rsuite';

function ModuleOverview({ project, dispatch, module }) {
    const { auth } = useAuth();
    const [totalItems, setTotalItems] = useState(0);
    const [sentItems, setSentItems] = useState(0);
    const [failedItems, setFailedItems] = useState(0);  // Add state for failed items
    const [loadingItems, setLoadingItems] = useState(true);

    useEffect(() => {
        if (dispatch && dispatch.id) {
            fetchDispatchItems(dispatch.id);
        }
    }, [dispatch]);

    const fetchDispatchItems = async (dispatchId) => {
        try {
            setLoadingItems(true);
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/contactlist`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`
                },
                params: {
                    dispatchId: dispatchId,
                    page: 0,
                    size: 10000, // Fetch a large number to count all items
                },
            });

            if (response.status === 200) {
                const dispatchItems = response.data.content;

                // Count total items, sent items, and failed items
                const total = dispatchItems.length;
                const sentCount = dispatchItems.filter(item => item.sent).length;
                const failedCount = dispatchItems.filter(item => !item.sent && item.sentDate).length;  // Count failed items

                setTotalItems(total);
                setSentItems(sentCount);
                setFailedItems(failedCount);  // Set failed items
            }
        } catch (error) {
            console.error('Misslyckades att hämta statistik: ', error);
            toast.error("Misslyckades att hämta statistik.", {
                position: 'bottom-right'
              })
        } finally {
            setLoadingItems(false);
        }
    };

    return (
        <>
        <Helmet>
          <title>{`${module?.name} - Översikt`}</title>
        </Helmet>
        <div className='d-flex'>

        <Breadcrumb className='mb-0'>
            <Breadcrumb.Item>
                <Link to={`/user/projekt/${project?.id}`}>{project?.name}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{module?.name}</Breadcrumb.Item>
            <Breadcrumb.Item active>Översikt</Breadcrumb.Item>
        </Breadcrumb>

        </div>
        <hr />
            
            <div className="row mt-3">
                <div className="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <div className="card shadow p-3">
                        <div className="d-flex">
                            <i className="bi bi-info-circle me-2"></i>
                            <p className='mb-0'>Kontaktlista</p>
                        </div>
                        <h2 className='mb-0'>
                            {loadingItems ? <Skeleton width={50} height={40} /> : totalItems}
                        </h2>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <div className="card shadow p-3">
                        <div className="d-flex">
                            <i className="bi bi-info-circle me-2"></i>
                            <p className='mb-0'>Skickade mejl</p>
                        </div>
                        <h2 className='mb-0'>
                            {loadingItems ? <Skeleton width={50} height={40} /> : sentItems}
                        </h2>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <div className="card shadow p-3">
                        <div className="d-flex">
                            <i className="bi bi-info-circle me-2"></i>
                            <p className='mb-0'>Misslyckade</p>
                        </div>
                        <h2 className='mb-0'>
                            {loadingItems ? <Skeleton width={50} height={40} /> : failedItems}
                        </h2>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <div className="card shadow p-3">
                        <div className="d-flex">
                            <i className="bi bi-info-circle me-2"></i>
                            <p className='mb-0'>Startdatum</p>
                        </div>
                        <h2 className='mb-0'>
                        {new Date(dispatch?.dispatchDate).toLocaleString('sv-SE', {
                            timeZone: 'Europe/Stockholm',
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit'
                        })}
                        </h2>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModuleOverview;
