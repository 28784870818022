import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { end } from '@popperjs/core';
import { Helmet } from 'react-helmet-async';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { Popover, Whisper, IconButton, Breadcrumb, Loader } from 'rsuite';

function FunctionEndpoint({ project, moduleFunction, onModuleFunctionEdited }) { // Added onModuleEdited as prop
  const { auth } = useAuth(); // Get auth token from context
  const [endpoint, setEndpoint] = useState('');
  const [requestType, setRequestType] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (moduleFunction) {
        setEndpoint(moduleFunction?.endpoint);
        setRequestType(moduleFunction?.requestType || 'GET');
    }
  }, [moduleFunction]);

  const handleSave = async () => {

    if (!endpoint && !requestType) {
        toast.error('Endpoint måste fyllas i.', {
          position: 'bottom-right'
        });
        return;
      }
    setLoading(true);

    const moduleFunctionData = {
      id: moduleFunction.id,
      projectId: moduleFunction.projectId,
      name: moduleFunction.name,
      endpoint,
      requestType
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/edit-function`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify(moduleFunctionData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        const updatedModuleFunction = { ...moduleFunction, ...moduleFunctionData };
        onModuleFunctionEdited(updatedModuleFunction); // Use the merged module object
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.error('Misslyckades att spara endpoint: ', error);
      toast.error('Misslyckades att spara endpoint.', {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Helmet>
          <title>{`${moduleFunction?.name} - Endpoint`}</title>
        </Helmet>
      <div className='d-flex'>
      <Breadcrumb className='mb-0'>
            <Breadcrumb.Item>
              <Link to={`/user/projekt/${project?.id}?fragment=Funktioner`}>{project?.name}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{moduleFunction?.name}</Breadcrumb.Item>
            <Breadcrumb.Item active>Endpoint</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <hr />

        <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Endpoint</h5>
            <p className='mb-2'>En anpassad funktion kräver en endpoint som anslutning till ett tredjeparts system. Följande metoder finns tillgängliga:</p>
            <ul>
              <li>GET</li>
              <li>POST</li>
              <li>PUT</li>
              <li>DELETE</li>
            </ul>
            <p><a target="_blank" href="https://zapier.com/">Zapier</a> är en rekomenderad tjänst som erbjuder flera integrationer till populära tredjeparts system.</p>
            <p>Behöver du hjälp med anpassade funktioner? Kontakta oss så hjälper vi dig!</p>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Endpoint
          </IconButton>
        </Whisper>

      <div className="d-flex align-items-center mb-2">
        <input
            type="url"
            className='form-control me-2 w-50'
            id="endpoint"
            value={endpoint}
            placeholder='www.endpoint.com'
            onChange={(e) => setEndpoint(e.target.value)}
        />
        <select class="form-select me-2 w-25" value={requestType} onChange={(e) => setRequestType(e.target.value)} aria-label="Default select example">
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="PUT">PUT</option>
            <option value="DELETE">DELETE</option>
        </select>
        <button className='btn btn-primary d-flex justify-content-center align-items-center' onClick={handleSave} disabled={loading}>
          {loading ? (
            <>
              <Loader className="me-2" /> Sparar...
            </>
          ) : (
            'Spara'
          )}
        </button>
    </div>
    </>
  );
}

export default FunctionEndpoint;
