import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { Popover, Whisper, IconButton, Breadcrumb, Loader } from 'rsuite';

function FunctionAuthorization({ project, moduleFunction, onModuleFunctionEdited }) {
  const { auth } = useAuth(); 
  const [authType, setAuthType] = useState(''); // To hold the selected auth type
  const [authFields, setAuthFields] = useState({}); // To hold the values for auth fields
  const [loading, setLoading] = useState(false);

  // Authorization types configuration
  const authTypes = {
    "None": {},
    "API Key": {
      key: { label: "API Key", type: "text" }
    },
    "Bearer Token": {
      token: { label: "Token", type: "text" }
    },
    "Basic Auth": {
      username: { label: "Username", type: "text" },
      password: { label: "Password", type: "text" }
    }
  };

  useEffect(() => {
    if (moduleFunction) {
        setAuthType(moduleFunction?.authorizationType || 'None');
        setAuthFields(moduleFunction?.authorizationKeys ? JSON.parse(moduleFunction?.authorizationKeys) : {});
    }
  }, [moduleFunction]);

  const handleAuthFieldChange = (field, value) => {
    setAuthFields({
      ...authFields,
      [field]: value
    });
  };

  const handleSave = async () => {
    setLoading(true);

    const moduleFunctionData = {
      id: moduleFunction.id,
      projectId: moduleFunction.projectId,
      name: moduleFunction.name,
      authorizationType: authType,
      authorizationKeys: JSON.stringify(authFields) // Serialize auth fields to JSON
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/edit-function`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify(moduleFunctionData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        const updatedModuleFunction = { ...moduleFunction, ...moduleFunctionData };
        onModuleFunctionEdited(updatedModuleFunction);
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.error('Misslyckades att spara autentisering: ', error);
      toast.error('Misslyckades att spara autentisering.', {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Helmet>
          <title>{`${moduleFunction?.name} - Autentisering`}</title>
        </Helmet>
      <div className='d-flex'>
      
        <Breadcrumb className='mb-0'>
            <Breadcrumb.Item>
              <Link to={`/user/projekt/${project?.id}?fragment=Funktioner`}>{project?.name}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{moduleFunction?.name}</Breadcrumb.Item>
            <Breadcrumb.Item active>Autentisering</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <hr />

      <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Autentisering</h5>
            <p className='mb-2'>Ibland kan ett tredjeparts system kräva någon form av autentisering. Följande autentiseringar finns tillgängliga:</p>
            <ul>
              <li>API Key</li>
              <li>Bearer token</li>
              <li>Basic Auth</li>
            </ul>
            <p>Behöver du hjälp med anpassade funktioner? Kontakta oss så hjälper vi dig!</p>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Autentisering
          </IconButton>
        </Whisper>

      <div className="d-flex mb-2">
        <select
          className="form-select w-25"
          value={authType}
          onChange={(e) => {
            setAuthType(e.target.value);
            setAuthFields({}); // Reset auth fields on type change
          }}
        >
          {Object.keys(authTypes).map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-3 w-50">
        {Object.keys(authTypes[authType] || {}).map((field) => ( // Add a fallback for undefined
          <div key={field} className="mb-2">
            <label>{authTypes[authType][field].label}</label>
            <input
              type={authTypes[authType][field].type}
              className="form-control"
              value={authFields[field] || ''}
              onChange={(e) => handleAuthFieldChange(field, e.target.value)}
            />
          </div>
        ))}
      </div>

      <div className="d-flex align-items-center">
        <button className='btn btn-primary d-flex justify-content-center align-items-center' onClick={handleSave} disabled={loading}>
          {loading ? (
            <>
              <Loader className="me-2" /> Sparar...
            </>
          ) : (
            'Spara'
          )}
        </button>
      </div>
    </>
  );
}

export default FunctionAuthorization;
