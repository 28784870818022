import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const CustomerRoute = ({ children }) => {
  const { auth } = useAuth();

  const hasToken = !!auth.token;
  const isRole = auth.role === 'ROLE_CUSTOMER';


  if (hasToken && isRole) {
    return children;
  } else {
    console.warn('User access denied. Redirecting to login.'); // Debug statement
    return <Navigate to="/login" />;
  }
};

export default CustomerRoute;
