import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { Popover, Whisper, IconButton, Breadcrumb, Loader } from 'rsuite';

function ModuleDispatchDate({ project, dispatch, module, onDispatchEdited }) {
  const { auth } = useAuth();
  const [dispatchDate, setDispatchDate] = useState(''); // Holds the formatted date for the input field
  const [loading, setLoading] = useState(false);

  // Convert dispatch timestamp to yyyy-mm-dd format
  useEffect(() => {
    if (dispatch?.dispatchDate) {
      const date = new Date(dispatch.dispatchDate);
      const formattedDate = date.toISOString().split('T')[0]; // Convert to yyyy-mm-dd format
      setDispatchDate(formattedDate); // Set to the input field
    }
  }, [dispatch]);

  const handleSave = async () => {
    if (!dispatchDate) {
      toast.error('Datum för utskick måste fyllas i.', {
        position: 'bottom-right'
      });
      return;
    }

    setLoading(true);

    // Convert the selected date to UTC timestamp
    const selectedDate = new Date(dispatchDate); // The date from input is local
    const utcTimestamp = selectedDate.toISOString(); // Convert to UTC string

    const dispatchData = {
      id: dispatch.id,
      dispatchDate: utcTimestamp, // Send the UTC timestamp to backend
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/edit-dispatch`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify(dispatchData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        const updatedDispatch = { ...dispatch, ...dispatchData };
        onDispatchEdited(updatedDispatch); // Use the merged module object
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.error('Error saving dispatch:', error);
      toast.error('Misslyckades att spara ändringar.', {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Helmet>
          <title>{`${module?.name} - Startdatum`}</title>
        </Helmet>
        <div className='d-flex'>

        <Breadcrumb className='mb-0'>
              <Breadcrumb.Item>
                <Link to={`/user/projekt/${project?.id}`}>{project?.name}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{module?.name}</Breadcrumb.Item>
              <Breadcrumb.Item active>Datum för utskick</Breadcrumb.Item>
        </Breadcrumb>

        </div>
        <hr />
      
      <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Datum för utskick</h5>
            <p className='mb-2'>Här kan du specifiera vilket datum ditt mejlutskick ska börja.</p>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Datum för utskick
          </IconButton>
        </Whisper>
      <div className="d-flex align-items-center mb-2">
        <input
            type="date"
            className='form-control me-2 w-25'
            id="dispatchDate"
            value={dispatchDate}
            onChange={(e) => setDispatchDate(e.target.value)}
        />
        <button className='btn btn-primary d-flex justify-content-center align-items-center' onClick={handleSave} disabled={loading}>
          {loading ? (
            <>
              <Loader className="me-2" /> Sparar...
            </>
          ) : (
            'Spara'
          )}
        </button>
    </div>
    </>
  );
}

export default ModuleDispatchDate;
