import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from 'rsuite';

function CreateUserModal({ show, handleClose, companyId, onUserCreated }) {
  const { auth } = useAuth();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleSubmit = async () => {
    if (!email || !firstName || !lastName) {
      toast.error('Förnamn, efternamn och e-post måste fyllas i.', {
        position: 'bottom-right'
      });
      return;
    }

    setLoading(true);
    const userData = {
      email,
      firstName,
      lastName,
      companyId,
      role: isAdmin ? 'ROLE_USER' : 'ROLE_CUSTOMER' // Set role based on isAdmin
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/create-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}` // Include token in the request
        },
        body: JSON.stringify(userData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        handleClose();
        onUserCreated(); // Call the function to update the user list
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.log(error)
      toast.error('Misslyckades att skapa användaren: ', error, {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>Ny användare</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className="col-6">
            <label htmlFor="firstName" className='form-label'>Förnamn</label>
            <input
              type="text"
              className='form-control'
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="col-6">
            <label htmlFor="lastName" className='form-label'>Efternamn</label>
            <input
              type="text"
              className='form-control'
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className='mt-2'>
          <label htmlFor="userEmail" className='form-label'>E-post</label>
          <input
            type="text"
            className='form-control'
            id="userEmail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className='mt-2'>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="adminSwitch"
              checked={isAdmin}
              onChange={(e) => setIsAdmin(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="adminSwitch">
              {isAdmin ? 'Administratör' : 'Observatör'}
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Stäng
        </Button>
        <Button variant="primary" className="d-flex justify-content-center align-items-center" onClick={handleSubmit} disabled={loading}>
            {loading ? (
              <>
                <Loader className="me-2" /> Sparar...
              </>
            ) : (
              'Spara'
            )}
          </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateUserModal;
