import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from 'rsuite';

function EditFunctionModal({ show, handleClose, moduleFunction, onFunctionCreated, projectId }) {
  const { auth } = useAuth();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [leadGenerator, setLeadGenerator] = useState('');

  // Regular expression to allow only alphanumeric characters and underscores, no spaces or special characters
  const validNamePattern = /^[A-Za-z0-9_]+$/;

  // Reset the form when the modal is closed
  useEffect(() => {
    if (show && moduleFunction) {
      setName(moduleFunction.name || '');
      setDescription(moduleFunction.description || '');
      setLeadGenerator(moduleFunction.leadGenerator || false);
    }
  }, [show, moduleFunction]);
  

  const handleSubmit = async () => {
    if (!name && !description) {
      toast.error('Namn måste fyllas i.', {
        position: 'bottom-right'
      });
      return;
    }

    // Validate the name with the regular expression
    if (!validNamePattern.test(name)) {
      toast.warning('Namnet får endast innehålla engelska bokstäver (a-z), siffror och understreck. Å, Ä, Ö och andra specialtecken är inte tillåtna.', {
        position: 'bottom-right'
      });
      return;
    }

    setLoading(true);
    const flowData = {
      name,
      description,
      id: moduleFunction.id,
      projectId,
      leadGenerator
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/edit-function`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}` // Include token in the request
        },
        body: JSON.stringify(flowData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        handleClose();
        onFunctionCreated(); // Call the function to update the company list
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Misslyckades att skapa funktionen: ', error, {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleNameChange = (e) => {
    const input = e.target.value;
    if (validNamePattern.test(input) || input === '') {
      setName(input);
    } else {
      toast.warning('Namnet får endast innehålla engelska bokstäver (a-z), siffror och understreck. Å, Ä, Ö och andra specialtecken är inte tillåtna.', {
        position: 'bottom-right'
      });      
    }
  };

  const handleDescriptionChange = (e) => {
    const maxLength = 200; // Set the maximum number of characters
    if (e.target.value.length <= maxLength) {
      setDescription(e.target.value);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>Redigera funktion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
  <div className='mb-2'>
    <label htmlFor="name" className='form-label'>Namn</label>
    <input
      type="text"
      className='form-control mb-2'
      id="name"
      value={name}
      onChange={handleNameChange} // Validate the name input
    />
    <label htmlFor="name" className='form-label'>Beskrivning</label>
    <textarea
    className='form-control'
    id="description"
    value={description}
    placeholder='Kalla på denna funktion för att ...'
    onChange={handleDescriptionChange}
    rows="4"
    />
  </div>
  <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault2"
              checked={leadGenerator}
              onChange={(e) => setLeadGenerator(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault2">
              Leadsinsamling
            </label>
          </div>
</Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Stäng
        </Button>
        <Button variant="primary" className="d-flex justify-content-center align-items-center" onClick={handleSubmit} disabled={loading}>
            {loading ? (
              <>
                <Loader className="me-2" /> Sparar...
              </>
            ) : (
              'Spara'
            )}
          </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditFunctionModal;
