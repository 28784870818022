import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import CreateDomain from '../../../modals/admin/connections/CreateDomain';
import VerifyDomain from '../../../modals/admin/connections/VerifyDomain';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Pagination } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

import { Dropdown, IconButton, Badge, Loader, Popover, Whisper, Breadcrumb } from 'rsuite';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { Gear } from '@rsuite/icons';

function Domains() { 
    const { auth } = useAuth(); 
    const [showCreateModal, setShowCreateModal] = useState(false); 
    const [showVerifyModal, setShowVerifyModal] = useState(false); 
    const [showDnsModal, setShowDnsModal] = useState(false); 
    const [selectedDomain, setSelectedDomain] = useState(null); 
    const [domains, setDomains] = useState([]); 
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);  
    const [totalPages, setTotalPages] = useState(1);  
    const [searchQuery, setSearchQuery] = useState('');

    const [deletingRowIds, setDeletingRowIds] = useState([]);
  
    // Fetch domains only after company is set
    const fetchDomains = async (currentPage = 0, urlQuery = '') => {
  
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/domains?url=${urlQuery}&page=${currentPage}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.token}`
          }
        });
  
        if (response.ok) {
          const data = await response.json();
          setDomains(data.content || []); 
          setPage(data.number);  
          setTotalPages(data.totalPages);
        } else {
          const errorText = await response.text();
          throw new Error(errorText);
        }
      } catch (error) {
        console.error('Misslyckades att hämta domäner: ', error);
        toast.error('Misslyckades att hämta domäner.', {
          position: 'bottom-right'
        });
      } finally {
        setLoading(false);
      }
    };
  
    const handlePageChange = (newPage) => {
      setPage(newPage);
      fetchDomains(newPage, searchQuery);  
    };
  
    const handleSearch = (e) => {
      setSearchQuery(e.target.value);
    };
  
    const handleSearchSubmit = (e) => {
      if (e.key === 'Enter') {  
        setPage(0);  
        fetchDomains(0, searchQuery);  
      }
    };
  
    const handleDeleteDomain = async (domainId) => {
      if (!window.confirm('Är du säker på att du vill radera denna domän?')) {
        return; 
      }

      setDeletingRowIds((prev) => [...prev, domainId]);
  
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/delete-domain`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.token}`
          },
          body: JSON.stringify({ id: domainId })
        });
  
        const result = await response.json();
  
        if (response.ok) {
          toast.warning(result.message, {
            position: 'bottom-right'
          });
          setDomains(domains.filter(domain => domain.id !== domainId));
        } else {
            toast.error(result.message, {
                position: 'bottom-right'
            });
        }
      } catch (error) {
        console.error('Misslyckades att radera domän: ', error);
        toast.error('Misslyckades att radera domän.', {
          position: 'bottom-right'
        });
      } finally {
        setDeletingRowIds((prev) => prev.filter((id) => id !== domainId));
      }
    };
  
    // Fetch domains when company is set
    useEffect(() => {
        fetchDomains();
    }, []);
  
    const handleShowCreateModal = () => {
      setShowCreateModal(true);
    };
    const handleShowVerifyModal = (domain) => {
        setSelectedDomain(domain); 
        setShowVerifyModal(true);
      };
  
    const handleCloseCreateModal = () => setShowCreateModal(false);
    const handleCloseVerifyModal = () => {
        setShowVerifyModal(false)
        setSelectedDomain(null); 
    };
  
  
    const handleDomainCreated = () => {
        fetchDomains();
    };

  return (
    <>
    <Helmet>
          <title>{`Admin - Domäner`}</title>
        </Helmet>
      <div className='px-5 pt-4 w-100'>
      
      <div className="d-flex">

        <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Domäner</h5>
            <p className='mb-2'>Här kan du se alla dina verifierade domäner.</p>
            <p className='mb-2'>Det är fördelaktigt att verifiera din domän för att:</p>
            <ul>
              <li>Inte hamna i skräppost</li>
            </ul>
            <p className='mb-2'>Verifierade domäner ersätter automatiskt <a target='_blank' href="https://en.wikipedia.org/wiki/Internet_Message_Access_Protocol">IMAP</a> för dina e-post konton och nås av alla konton under samma domän.</p>
            <p className='mb-2'>Domäner som skapas i adminvyn finns tillgängligt, men gömda för alla kunder.</p>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Domäner
          </IconButton>
        </Whisper>

        <div className='ms-auto'>
            <div className="input-group">
                <input 
                  className='form-control' 
                  type="text" 
                  placeholder='Sök domän' 
                  value={searchQuery}
                  onChange={handleSearch}
                  onKeyDown={handleSearchSubmit}  
                />
                <span className="input-group-text">
                    <i className="bi bi-search"></i>
                </span>
            </div>
        </div>
      </div>
      
      <div className="card dashed border-1 shadow rounded" style={{ cursor: 'pointer' }} onClick={handleShowCreateModal}>
          <div className='p-4 text-center'>
            <p className='mb-0'>
              <i className="bi bi-plus me-1"></i>
              Ny domän
            </p>
          </div>
        </div>

      {loading ? (
          <>
            <div className="row">
                    {[...Array(2)].map((_, index) => (
                        <div key={index} className="col-sm-12 col-md-12 col-lg-4 mt-2">
                        <div className="card p-3">
                            <Skeleton width={`60%`} height={20} />
                        </div>
                        </div>
                    ))}
                </div>
          </>
      ) : (
        <>
        <div className="row mb-2">
      {domains.map(domain => (
          <div
            key={domain.id}
            className="col-sm-12 col-md-12 col-lg-4 mt-2"
          >
            <div className="card border-1 p-3">

                <div className="d-flex">
                  <div className='d-grid'>
                    <div>
                        <p className='mb-2'>{domain.url}</p>
                    </div>
                    <div className="d-flex">
                    {domain.verified ? (
                        <Badge className='bg-success p-1 rounded me-2' content="Verifierad" />
                        ) : (
                        <>
                        <Badge className='bg-danger p-1 rounded me-2' content="Overifierad" />
                        </>
                    )}
                    </div>
                  </div>
                  <div className="ms-auto">
                    <Dropdown
                        trigger={['click']}
                        renderToggle={(props, ref) => (
                        <IconButton
                            {...props}
                            ref={ref}
                            icon={
                            deletingRowIds.includes(domain.id) ? (  // Check if the module is being deleted
                                <Loader speed='fast' size="sm" />
                            ) : (
                                <Gear />
                            )
                            }
                            appearance="subtle"
                        />
                        )}
                        placement="bottomEnd"
                        >
                            <Dropdown.Item onClick={() => handleShowVerifyModal(domain)}>
                                Verifiera
                            </Dropdown.Item>
                            <Dropdown.Item
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteDomain(domain.id);
                            }}
                            >
                            Radera
                            </Dropdown.Item>
                    </Dropdown>
                  </div>
                </div>
              
            </div>
          </div>
        ))}

        </div>
        </>
      )}

        <div className="d-flex my-4">
        {totalPages > 1 && (
        <div className="d-flex my-4">
            <Pagination>
            <Pagination.Prev 
                onClick={() => handlePageChange(page - 1)} 
                disabled={page === 0}
            />
            
            {(() => {
                const totalDisplayedPages = 10;  
                let startPage = Math.max(0, page - Math.floor(totalDisplayedPages / 2));
                let endPage = startPage + totalDisplayedPages;

                if (endPage > totalPages) {
                endPage = totalPages;
                startPage = Math.max(0, totalPages - totalDisplayedPages);
                }

                return [...Array(endPage - startPage).keys()].map((p) => (
                <Pagination.Item 
                    key={startPage + p} 
                    active={startPage + p === page} 
                    onClick={() => handlePageChange(startPage + p)}
                >
                    {startPage + p + 1}
                </Pagination.Item>
                ));
            })()}

            <Pagination.Next 
                onClick={() => handlePageChange(page + 1)} 
                disabled={page === totalPages - 1}
            />
            </Pagination>
        </div>
            )}
        </div>

      <CreateDomain
        show={showCreateModal}
        handleClose={handleCloseCreateModal}
        onDomainCreated={handleDomainCreated}
      />
      {selectedDomain && (
        <VerifyDomain
        show={showVerifyModal}
        handleClose={handleCloseVerifyModal}
        domain={selectedDomain}
        onDomainVerified={handleDomainCreated}
      />
      )}
      </div>
    </>
  );
}

export default Domains;
