import React, { useState } from 'react';
import { Panel, IconButton, Nav, Divider, Badge, Button, Loader } from 'rsuite';
import EditCompanyModal from '../../../modals/admin/companies/EditCompany';
import EditCompanyDetailsModal from '../../../modals/admin/companies/EditCompanyDetails';
import { toast } from 'react-toastify';
import { useAuth } from '../../../AuthContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import GearIcon from '@rsuite/icons/Gear';
import TrashIcon from '@rsuite/icons/legacy/Trash';
import 'rsuite/dist/rsuite.min.css'; // Ensure RSuite styles are imported
import PauseRoundIcon from '@rsuite/icons/PauseRound';
import LocationIcon from '@rsuite/icons/Location';

function LicenceCard({ company, selectedFragment, setSelectedFragment, onCompanyUpdate }) {
  const { login, auth } = useAuth();
  const navigate = useNavigate();

  const [showEditModal, setShowEditModal] = useState(false);
  const [showEditDetailsModal, setShowEditDetailsModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingShutdown, setLoadingShutdown] = useState(false);

  const handleEditModalClose = () => setShowEditModal(false);
  const handleEditModalShow = () => setShowEditModal(true);

  const handleEditDetailsModalClose = () => setShowEditDetailsModal(false);
  const handleEditDetailsModalShow = () => setShowEditDetailsModal(true);

  const handleCompanyEdited = (updatedCompany) => {
    onCompanyUpdate(updatedCompany);
    handleEditModalClose();
  };

  // handleDelete function to remove the company
  const deleteLicence = async () => {
    if (!window.confirm('Är du säker på att du vill radera detta företag?')) {
      return; // User canceled the deletion
    }

    setLoadingDelete(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/delete-licence?id=${company.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}` // Include token in the request
        }
      });

      if (response.ok) {
        const result = await response.json(); // Parse response
        toast.warning(result.message, {
          position: 'bottom-right'
        });
        navigate(`/admin/kunder/`); // Redirect after deletion
      } else {
        const errorResult = await response.json();
        toast.error(errorResult.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      toast.error('Ett fel inträffade vid borttagning av kunden.', {
        position: 'bottom-right'
      });
    } finally {
      setLoadingDelete(false);
    }
  };

  const disableLicence = async () => {
    if (!window.confirm('Är du säker på att du vill stänga av kundens moduler?')) {
      return; // User canceled the deletion
    }

    setLoadingShutdown(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/disable-license?licenceId=${company.licenceId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}` // Include token in the request
        }
      });

      if (response.ok) {
        const result = await response.json(); // Parse response
        toast.warning(result.message, {
          position: 'bottom-right'
        });
      } else {
        const errorResult = await response.json();
        toast.error(errorResult.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      toast.error('Ett fel inträffade vid avstängningen av kunden.', {
        position: 'bottom-right'
      });
    } finally {
      setLoadingShutdown(false);
    }
  };

  const handleLoginToLicence = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/login-licence`, null, {
        headers: {
          'Authorization': `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
        },
        params: { id: company.id },
      });

      if (response.status === 200) {
        login(response.data.token);
        toast.success('Lyckades att logga in på kund.', {
          position: 'bottom-right'
        });
        navigate('/user/dashboard');
      } else {
        toast.error('Misslyckades att logga in på kund.', {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      toast.error(`Misslyckades att logga in på kund: ${error.message}`, {
        position: 'bottom-right'
      });
    }
  };

  return (
    <div className='mb-2'>
      <Panel>
        <div className='d-flex align-items-center'>
          <h4 style={{ marginBottom: 0 }}>{company?.companyName}</h4>
          <div className='ms-auto'>
            <IconButton
              icon={loadingDelete ? <Loader speed='fast' size="sm" /> : <TrashIcon />}
              appearance="subtle"
              onClick={deleteLicence}
              title="Radera kund"
              disabled={loadingDelete || loadingShutdown}
            />
            <IconButton
              icon={loadingShutdown ? <Loader speed='fast' size="sm" /> : <PauseRoundIcon />}
              appearance="subtle"
              onClick={disableLicence}
              title="Stäng av moduler"
              disabled={loadingDelete || loadingShutdown}
            />
          <IconButton
            icon={<GearIcon />}
            appearance="subtle"
            onClick={handleEditModalShow}
            title="Redigera företag"
            disabled={loadingDelete || loadingShutdown}
          />
          <IconButton
              icon={<LocationIcon />}
              appearance="subtle"
              onClick={handleEditDetailsModalShow}
              title="Uppdatera företagsuppgifter"
              disabled={loadingDelete || loadingShutdown}
            />
          </div>
        </div>
        <p style={{ margin: '0 0 10px 0' }}>{company.orgNr}</p>
        <Divider style={{ margin: "10px 0" }} />
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
          <Badge 
            content={company.autoCharge ? 'Auto fakturering' : 'Manuell fakturering'} 
            className={`rounded p-1 ${company.autoCharge ? 'bg-secondary' : 'bg-success'}`}
            />
          <Badge 
            content={company.active ? 'Aktiv licens' : 'Avstängd licens'} 
            className={`rounded p-1 ${company.active ? 'bg-success' : 'bg-danger'}`}
            />
        </div>

        <Divider style={{ margin: "10px 0" }} />

        <Nav
          vertical
          activeKey={selectedFragment}
          onSelect={(eventKey) => setSelectedFragment(eventKey)}
        >
          <Nav.Item
            eventKey="Översikt"
            className='card-title'
          >
            <i className="bi bi-eye me-2"></i>
            Översikt
          </Nav.Item>
          <Nav.Item
            eventKey="Användare"
            className='card-title'
          >
            <i className="bi bi-people me-2"></i>
            Användare
          </Nav.Item>
          <Nav.Item
            eventKey="Modulpriser"
            className='card-title'
          >
            <i className="bi bi-cash me-2"></i>
            Modulpriser
          </Nav.Item>
          <Nav.Item
            eventKey="Skapa faktura"
            className='card-title'
          >
            <i className="bi bi-receipt me-2"></i>
            Fakturera
          </Nav.Item>
          <Nav.Item
            eventKey="Fakturor"
            className='card-title'
          >
            <i className="bi bi-file-earmark-bar-graph me-2"></i>
            Fakturor
          </Nav.Item>
        </Nav>

        <Divider style={{ margin: "10px 0" }} />

        <Button appearance="primary" className='bg-primary' onClick={handleLoginToLicence} block>
          <i className="bi bi-door-open me-2"></i>
          Öppna kund
        </Button>
      </Panel>

      {/* Edit Company Modal */}
      <EditCompanyModal
        show={showEditModal}
        handleClose={handleEditModalClose}
        company={company}
        onCompanyEdited={handleCompanyEdited}
      />
      <EditCompanyDetailsModal
        show={showEditDetailsModal}
        handleClose={handleEditDetailsModalClose}
        company={company}
        onCompanyEdited={handleCompanyEdited}
      />
    </div>
  );
}

export default LicenceCard;
