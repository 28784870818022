import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Pagination } from 'react-bootstrap';  // Import Bootstrap Pagination
import { Helmet } from 'react-helmet-async';

import { Badge, Popover, Whisper, IconButton, Breadcrumb } from 'rsuite';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';

function EmailList({ dispatch, module }) {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(0);  // State for current page
  const [totalPages, setTotalPages] = useState(1);  // State for total pages
  const [searchQuery, setSearchQuery] = useState('');  // State for search query
  const { auth } = useAuth();
  const fileInputRef = useRef(null);  // Reference to the hidden file input

  const fetchList = async (currentPage = 0, emailQuery = '') => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/customer/contactlist`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        params: {
          dispatchId: dispatch.id,
          email: emailQuery,  // Add the search query here
          page: currentPage,
          size: 12  // Assuming page size of 10
        }
      });

      if (response.status === 200) {
        setList(response.data.content);
        setPage(response.data.number);  // Set the current page
        setTotalPages(response.data.totalPages);  // Set the total number of pages
      } else {
        toast.error('Misslyckades att hämta lista.', { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Misslyckades att hämta lista: ', error);
      toast.error('Misslyckades att hämta lista.', { position: 'bottom-right' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dispatch) {
      fetchList();
    }
  }, [dispatch]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchList(newPage, searchQuery);  // Fetch data for the new page with the current search query
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    if (e.key === 'Enter') {  // Trigger search on Enter key press
      setPage(0);  // Reset to the first page
      fetchList(0, searchQuery);  // Fetch the list with the search query
    }
  };

  const handleItemDelete = async (addressId) => {
    if (!window.confirm('Är du säker på att du vill radera denna adress?')) {
      return;  // User canceled the deletion
    }
    setLoading(true);

    try {
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/customer/delete-contactaddress`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        data: { id: addressId }
      });

      if (response.status === 200) {
        toast.warning(response.data.message, { position: 'bottom-right' });
        await fetchList(page, searchQuery);  // Fetch the list and pass the current page and search query
      } else {
        toast.error(response.data.message, { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Misslyckades att radera adress: ', error);
      toast.error('Misslyckades att radera adress.', { position: 'bottom-right' });
    } finally {
      setLoading(false);
    }
  };
  

  if (!dispatch) {
    return <p>Lista hittades inte.</p>;
  }

  return (
    <>
    <Helmet>
          <title>{`${module?.name} - Kontaktlista`}</title>
        </Helmet>
        <div className='d-flex'>
        <Breadcrumb className='mb-0'>
          <Breadcrumb.Item>
            <Link to={`/customer/moduler`}>Moduler</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{module?.name}</Breadcrumb.Item>
          <Breadcrumb.Item active>Kontaktlista</Breadcrumb.Item>
        </Breadcrumb>

      </div>
      <hr />
      
      <div className="d-flex mb-2">
        <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Kontaktlista</h5>
            <p className='mb-2'>Här kan du se vilka adresser som mejlutskicket kommer nå ut till.</p>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Kontaktlista
          </IconButton>
        </Whisper>
        <div className='ms-auto'>
            <div className="input-group">
                <input 
                  className='form-control' 
                  type="text" 
                  placeholder='Sök adress' 
                  value={searchQuery}
                  onChange={handleSearch}
                  onKeyDown={handleSearchSubmit}  // Trigger search on Enter key press
                />
                <span className="input-group-text">
                    <i className="bi bi-search"></i>
                </span>
            </div>
        </div>
      </div>

      {loading ? (
          <>
            <Skeleton height={60} />  
          </>
      ) : (
        <>
        <div className="mb-2">
        <ul className="list-group">
          {list
          .filter((adress) => !adress.header)  // Filter out rows where header is true
          .map((adress) => (
            <li key={adress.id} className="list-group-item d-flex justify-content-between align-items-center">
              {adress.email}
              <div className="d-flex align-items-center">
              
              {adress.sentDate && (
                <>
                <Badge 
                  className='bg-primary p-1 rounded me-2' 
                  content={`Skickades ${new Date(adress.sentDate).toLocaleString('sv-SE', {
                      timeZone: 'Europe/Stockholm',
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit'
                  })}`} 
                />
                 {!adress.sent && (
                      <Badge className='bg-danger p-1 rounded me-2' content="Misslyckades" />
                    )}
                </>
                )}


                <div 
                  style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} 
                  onClick={() => handleItemDelete(adress.id)}
                >
                  <i className="bi bi-trash"></i>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
        </>
      )}

      
      <div className="d-flex my-4">
      {/* Pagination Component */}
{totalPages > 1 && (
  <div className="d-flex my-4">
    <Pagination>
      <Pagination.Prev 
        onClick={() => handlePageChange(page - 1)} 
        disabled={page === 0}
      />
      
      {(() => {
        // Determine the start and end page numbers to display in the pagination
        const totalDisplayedPages = 10;  // Number of pages to display
        let startPage = Math.max(0, page - Math.floor(totalDisplayedPages / 2));
        let endPage = startPage + totalDisplayedPages;

        // Ensure the last page doesn't go beyond totalPages
        if (endPage > totalPages) {
          endPage = totalPages;
          startPage = Math.max(0, totalPages - totalDisplayedPages);
        }

        // Generate the range of pages to display
        return [...Array(endPage - startPage).keys()].map((p) => (
          <Pagination.Item 
            key={startPage + p} 
            active={startPage + p === page} 
            onClick={() => handlePageChange(startPage + p)}
          >
            {startPage + p + 1}
          </Pagination.Item>
        ));
      })()}

      <Pagination.Next 
        onClick={() => handlePageChange(page + 1)} 
        disabled={page === totalPages - 1}
      />
    </Pagination>
    </div>
    )}
      </div>
    </>
  );
}

export default EmailList;
