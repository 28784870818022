import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'; // To handle API requests
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { Popover, Whisper, IconButton, Breadcrumb, Loader } from 'rsuite';

function CreateModuleModal({ show, handleClose, project, company, onModuleCreated }) {
  const { auth } = useAuth();
  const [name, setName] = useState('');
  const [moduleType, setModuleType] = useState('Chattbot');
  const [loading, setLoading] = useState(false);
  const [loggedInLicence, setLoggedInLicence] = useState(null); // Default company name (optional)

  const fetchLoggedInLicence = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/current-licence`, {
        headers: {
          'Authorization': `Bearer ${auth.token}`, // Use auth token for authorization
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200 && response.data.content.length > 0) {
        setLoggedInLicence(response.data.content[0]); // Set the company name in state
      } else {
        toast.error("Det gick inte att hämta licensen.", {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      toast.error("Det gick inte att hämta licensen.", {
        position: 'bottom-right',
      });
    } finally {
      setLoading(false); // Set loading to false after the fetch
    }
  };

  const handleSubmit = async () => {
    if (!name) {
      toast.error('Titel måste fyllas i.', {
        position: 'bottom-right'
      });
      return;
    }

    setLoading(true);
    const moduleData = {
      name,
      projectId: project.id,
      companyId: company.id,
      licenceId: company.licenceId,
      moduleType
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/create-module`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}` // Include token in the request
        },
        body: JSON.stringify(moduleData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        handleClose();
        onModuleCreated(); // Call the function to update the company list
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      toast.error('Misslyckades att skapa modul: ', error, {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  // Fetch the logged-in license when the component mounts
  useEffect(() => {
    if (auth.token) {
      fetchLoggedInLicence(); // Only fetch the license if the auth token exists
    }
  }, [auth.token]); // Fetch the license when the token changes

  return (
    <Modal show={show} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>Ny modul</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Modultyper</h5>
            <p className='mb-2'>När du skapar en modul kan du välja mellan 3 typer.</p>
            <p className='mb-2'>Chattbot</p>
            <ul>
              <li>Visas på din hemsida</li>
              <li>Implementeras med en enkel script tagg</li>
              <li>Går att ändra design</li>
              <li>Upplärningsbar assistent</li>
            </ul>
            <p className='mb-2'>E-post</p>
            <ul>
              <li>Läsa av din inkorg</li>
              <li>Alternativt läsa av specifik mapp</li>
              <li>Skicka mejl från ditt konto</li>
              <li>Upplärningsbar assistent</li>
            </ul>
            <p className='mb-2'>Mejlutskick</p>
            <ul>
              <li>Når ut till angiven kontaktlista</li>
              <li>Mall med variabler</li>
              <li>Ej upplärningsbar</li>
            </ul>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Modultyper
          </IconButton>
        </Whisper>

        <div className='mb-2'>
          <label htmlFor="title" className='form-label'>Titel</label>
          <input
            type="text"
            className='form-control'
            id="title"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className='mb-2'>
          <label className='form-label'>Modultyp</label>
          <div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="type"
                id="Chattbot"
                value="Chattbot"
                checked={moduleType === "Chattbot"}
                onChange={(e) => setModuleType(e.target.value)}
              />
              <label className="form-check-label" htmlFor="Chattbot">Chattbot</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="type"
                id="E-post"
                value="E-post"
                checked={moduleType === "E-post"}
                onChange={(e) => setModuleType(e.target.value)}
              />
              <label className="form-check-label" htmlFor="E-post">E-post</label>
            </div>
            {loggedInLicence?.createMailDispatch && (
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="type"
                id="Mejlutskick"
                value="Mejlutskick"
                checked={moduleType === "Mejlutskick"}
                onChange={(e) => setModuleType(e.target.value)}
              />
              <label className="form-check-label" htmlFor="Mejlutskick">Mejlutskick</label>
            </div>
            )}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Stäng
        </Button>
        <Button variant="primary" className="d-flex justify-content-center align-items-center" onClick={handleSubmit} disabled={loading}>
            {loading ? (
              <>
                <Loader className="me-2" /> Sparar...
              </>
            ) : (
              'Spara'
            )}
          </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateModuleModal;
