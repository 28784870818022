import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        return { token, role: decodedToken.role, firstName: decodedToken.firstName, lastName: decodedToken.lastName, userId: decodedToken.userId };
      } catch (error) {
        console.error('Invalid token on initialization', error);
        return { token: null, role: null, firstName: null, lastName: null, userId: null};
      }
    } else {
      return { token: null, role: null, firstName: null, lastName: null, userId: null };
    }
  });

  const login = (token) => {
    localStorage.removeItem('team');
    localStorage.setItem('token', token);
    try {
      const decodedToken = jwtDecode(token);
      setAuth({
        token,
        role: decodedToken.role,
        firstName: decodedToken.firstName,
        lastName: decodedToken.lastName,
        userId: decodedToken.userId
      });
    } catch (error) {
      console.error('Invalid token during login', error);
      setAuth({ token: null, role: null, firstName: null, lastName: null, userId: null });
    }
  };

  const logout = () => {
    localStorage.removeItem('team');
    localStorage.removeItem('token');
    setAuth({ token: null, role: null, firstName: null, lastName: null, userId: null });
    toast.warning("Utloggad", {
      position: 'bottom-right'
    });
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setAuth({
          token,
          role: decodedToken.role,
          firstName: decodedToken.firstName,
          lastName: decodedToken.lastName,
          userId: decodedToken.userId
        });
      } catch (error) {
        console.error('Invalid token in useEffect', error);
        setAuth({ token: null, role: null, firstName: null, lastName: null, userId: null });
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};