import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from 'rsuite';

function SpecifyFlowModal({ show, handleClose, flow, onFlowUpdated }) {
  const { auth } = useAuth();
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [textareas, setTextareas] = useState(['']);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (show && flow) {
      setName(flow.name || '');
      setLoading(false); // Reset loading state if needed

      // Parse JSON string from flow.specifications if it exists
      try {
        const specifications = flow.specifications ? JSON.parse(flow.specifications) : [''];
        setTextareas(specifications.length ? specifications : ['']);
      } catch (error) {
        console.error('Misslyckades att läsa JSON specifikationer: ', error);
        setTextareas(['']);
      }
    } else {
      resetModal(); // Reset modal when it is closed
    }
  }, [show, flow]);

  const resetModal = () => {
    setName('');
    setLoading(false);
    setTextareas(['']);
    setErrors([]);
  };

  const handleSubmit = async () => {
    if (!name) {
      toast.error('Namn måste fyllas i.', {
        position: 'bottom-right'
      });
      return;
    }

    const validTextareas = textareas.filter((textarea) => textarea.trim() !== ''); // Exclude empty textareas

    if (validTextareas.length === 0) {
      setErrors(['Alla textområden är tomma.']);
      return;
    }

    setLoading(true);
    const flowData = {
      id: flow.id,
      specifications: validTextareas // Include only valid textareas data as an array
    };
    console.log(flowData);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/specify-flow`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}` // Include token in the request
        },
        body: JSON.stringify(flowData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        handleClose();

        const updatedFlow = {
          ...flow,
          specifications: JSON.stringify(flowData.specifications), // Ensure the updated flow has specifications as a JSON string
        };
        onFlowUpdated(updatedFlow); // Use the merged flow object
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Misslyckades att specifiera flödet: ', error, {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  const addTextarea = () => {
    setTextareas([...textareas, '']);
  };
  const handleDeleteTextarea = (index) => {
    const updatedTextareas = [...textareas];
    updatedTextareas.splice(index, 1);
    setTextareas(updatedTextareas);  // Update state with the new array
  
    const updatedErrors = [...errors];
    updatedErrors.splice(index, 1);
    setErrors(updatedErrors);  // Update the errors array if necessary
  };
  

  const handleTextareaChange = (index, value) => {
    const newTextareas = [...textareas];
    newTextareas[index] = value;
    setTextareas(newTextareas);

    // Clear the specific error when the user changes the textarea content
    setErrors([]);
  };

  return (
    <Modal show={show} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>{flow?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {textareas.map((textarea, index) => (
        <div key={index} className="mb-3">
          <label htmlFor={`textarea-${index}`} className="form-label">{`Instruktion ${index + 1}`}</label>
          <div className="d-flex">
            <textarea
              id={`textarea-${index}`}
              className="form-control flex-grow-1"
              placeholder={`Steg ${index + 1}...`}
              value={textarea}
              onChange={(e) => handleTextareaChange(index, e.target.value)}
            />
            <div>
              <button
                type="button"
                className="btn btn-danger ms-2"
                onClick={() => handleDeleteTextarea(index)}
                disabled={textareas.length === 1}
              >
                <i className="bi bi-trash"></i>
              </button>
            </div>
          </div>
          {errors[index] && <div className="text-danger">{errors[index]}</div>}
        </div>
      ))}



        <Button variant="primary" onClick={addTextarea}>
          <i className="bi bi-plus me-1"></i>
          Lägg till instruktion
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Stäng
        </Button>
        <Button variant="primary" className="d-flex justify-content-center align-items-center" onClick={handleSubmit} disabled={loading}>
            {loading ? (
              <>
                <Loader className="me-2" /> Sparar...
              </>
            ) : (
              'Spara'
            )}
          </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SpecifyFlowModal;
