import React, { useState, useEffect } from 'react';
import ModulesCard from '../cards/ModuleCard.jsx';
import ModuleOverview from '../module/ModuleOverview.jsx';
import ModuleOverviewDispatch from '../module/ModuleOverviewDispatch.jsx';
import ModuleFlows from '../module/ModuleFlows.jsx';
import CompanyConversations from '../module/CompanyConversations.jsx';
import ModuleEmailAccount from '../module/CompanyEmailAccount.jsx';
import CompanyEmailFolder from '../module/CompanyEmailFolder.jsx';
import ModuleRole from '../module/CompanyRole.jsx';
import CompanyEmailTemplate from '../module/CompanyEmailTemplate.jsx';
import CompanyEmailList from '../module/CompanyEmailList.jsx';
import CompanyDispatchDate from '../module/CompanyDispatchDate.jsx';
import ModulePersonality from '../module/CompanyPersonality.jsx';
import ModuleChatbotDesign from '../module/CompanyChatbotDesign.jsx';
import ModuleContactPerson from '../module/CompanyContactPerson.jsx';
import ModuleGPTVersion from '../module/CompanyGPTVersion.jsx';
import ModuleWelcomeMessage from '../module/CompanyWelcomeMessage.jsx';
import ModuleIgnoreConditions from '../module/ModuleIgnoreConditions.jsx';
import ModuleWhitelist from '../module/ModuleWhitelist.jsx';
import ModuleBlocklist from '../module/ModuleBlocklist.jsx';
import ModuleRules from '../module/ModuleRules.jsx';
import CompanyPrices from '../module/CompanyPrices.jsx'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../../AuthContext.jsx';
import { Loader } from 'rsuite';

const ContentWrapper = ({ children }) => (
  <div className='px-5 pt-4 w-100 p-3'>
    {children}
  </div>
);

function ModulesPage() {
  const { projectId, moduleId } = useParams(); // Extract path parameters

  const { auth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [selectedFragment, setSelectedFragment] = useState('Översikt');
  const [project, setProject] = useState(null);
  const [module, setModule] = useState(null);
  const [dispatch, setDispatch] = useState(null);

  const fetchModule = async () => {
    if (!moduleId) return;
    try {
      const company = JSON.parse(localStorage.getItem('team'))
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/modules?id=${moduleId}&companyId=${company.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        const fetchedModule = data.content[0];
        setModule(fetchedModule);
      } else {
        const errorText = await response.text();
        throw new Error(`Misslyckades att hämta modul: ${errorText}`);
      }
    } catch (error) {
      console.error('Misslyckades att hämta modul: ', error);
      toast.error(error.message, {
        position: 'bottom-right'
      });
    }
  };

  const fetchDispatch = async (moduleId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/dispatches?moduleId=${moduleId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const fetchedDispatch = data.content?.[0]; // Assuming the API returns a paginated result and you want the first dispatch
        setDispatch(fetchedDispatch || null);
      } else {
        toast.error('Misslyckades att hämta utskick.', { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Misslyckades att hämta utskick: ', error);
      toast.error('Misslyckades att hämta utskick.', { position: 'bottom-right' });
    }
  };

  const fetchProject = async () => {
    if (!projectId) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/projects?id=${projectId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setProject(data.content[0]);
      } else {
        const errorText = await response.text();
        throw new Error(`Misslyckades att hämta projekt: ${errorText}`);
      }
    } catch (error) {
      console.error('Misslyckades att hämta projekt: ', error);
      toast.error(error.message, {
        position: 'bottom-right'
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchProject();
      await fetchModule();
      setLoading(false);
    };
    fetchData();
  }, [projectId, moduleId, auth.token]);

  // Fetch the dispatch after the module is fetched
  useEffect(() => {
    if (module) {
      fetchDispatch(module.id);
    }
  }, [module]);

  // Handle module updates without overwriting the dispatch or other module properties
  const handleModuleUpdate = (updatedModule) => {
    setModule(prevModule => ({
      ...prevModule,
      ...updatedModule // Only update the specific fields that are changed
    }));
  };

  // Handle dispatch updates without affecting the module state
  const handleDispatchUpdate = (updatedDispatch) => {
    setDispatch(prevDispatch => ({
      ...prevDispatch,
      ...updatedDispatch // Only update the fields that are changed
    }));
  };

  return (
    <div className="d-flex">
        {loading ? (
          <ContentWrapper>
            <Loader speed='fast' /> Laddar...
          </ContentWrapper>
        ) : (
          <>
            <ContentWrapper>
              {selectedFragment === 'Översikt' && module && (
                (module.moduleType === 'Chattbot' || module.moduleType === 'E-post') ? (
                  <ModuleOverview project={project} module={module} />
                ) : (
                  module.moduleType === 'Mejlutskick' && (
                    <ModuleOverviewDispatch project={project} module={module} dispatch={dispatch} />
                  )
                )
              )}
              {selectedFragment === 'Flöden' && module && <ModuleFlows project={project} module={module} />}
              {selectedFragment === 'Konversationer' && module && <CompanyConversations project={project} module={module} />}
              {selectedFragment === 'E-post konto' && module && <ModuleEmailAccount dispatch={dispatch} project={project} module={module} onModuleEdited={handleModuleUpdate} />}
              {selectedFragment === 'Priser' && module && <CompanyPrices dispatch={dispatch} project={project} module={module} onModuleEdited={handleModuleUpdate} />}
              {selectedFragment === 'E-post mapp' && module && <CompanyEmailFolder dispatch={dispatch} project={project} module={module} onModuleEdited={handleModuleUpdate} />}
              {selectedFragment === 'Rollbeskrivning' && module && <ModuleRole project={project} module={module} onModuleEdited={handleModuleUpdate} />}
              {selectedFragment === 'Personlighet' && module && <ModulePersonality project={project} module={module} onModuleEdited={handleModuleUpdate} />}
              {selectedFragment === 'Design' && module && <ModuleChatbotDesign project={project} module={module} onModuleEdited={handleModuleUpdate} />}
              {selectedFragment === 'Kontaktperson' && module && <ModuleContactPerson project={project} module={module} onModuleEdited={handleModuleUpdate} />}
              {selectedFragment === 'GPT Version' && module && <ModuleGPTVersion project={project} module={module} onModuleEdited={handleModuleUpdate} />}
              {selectedFragment === 'Hälsningsmeddelande' && module && <ModuleWelcomeMessage project={project} module={module} onModuleEdited={handleModuleUpdate} />}
              {selectedFragment === 'Ignore' && module && <ModuleIgnoreConditions project={project} module={module} onModuleEdited={handleModuleUpdate} />}
              {selectedFragment === 'Whitelist' && module && <ModuleWhitelist project={project} module={module} onModuleEdited={handleModuleUpdate} />}
              {selectedFragment === 'Blockera e-poster' && module && <ModuleBlocklist project={project} module={module} onModuleEdited={handleModuleUpdate} />}
              {selectedFragment === 'Regler' && module && <ModuleRules project={project} module={module} onModuleEdited={handleModuleUpdate} />}
              {selectedFragment === 'Mejlmall' && module && <CompanyEmailTemplate dispatch={dispatch} project={project} module={module} onDispatchEdited={handleDispatchUpdate} />}
              {selectedFragment === 'Startdatum' && module && <CompanyDispatchDate dispatch={dispatch} project={project} module={module} onDispatchEdited={handleDispatchUpdate} />}
              {selectedFragment === 'Kontaktlista' && module && <CompanyEmailList dispatch={dispatch} project={project} module={module} onDispatchEdited={handleDispatchUpdate} />}
            </ContentWrapper>
            <div className="side-nav-right d-flex flex-column p-3">
              <ModulesCard
                dispatch={dispatch}
                module={module}
                selectedFragment={selectedFragment} 
                setSelectedFragment={setSelectedFragment} 
                onModuleUpdate={handleModuleUpdate} 
              />
            </div>
          </>
        )}
      </div>
  );
}

export default ModulesPage;
