import React, { useEffect, useState, useNav } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Helmet } from 'react-helmet-async';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';

import { Badge, IconButton, Loader, Popover, Whisper, Breadcrumb } from 'rsuite';
import TrashIcon from '@rsuite/icons/Trash';
import PlusIcon from '@rsuite/icons/Plus';

function ModuleEmailAccount({ project, module, onModuleEdited }) {
    const { auth } = useAuth();
    const [emailAccounts, setEmailAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [selectRowIds, setSelectRowIds] = useState([]);

    const navigateToEmailAccounts = () => {
        navigate("/user/epost-konton")
    };

    useEffect(() => {
        fetchEmailAccounts();
    }, []);

    const fetchEmailAccounts = async () => {
        setLoading(true);
        try {
            // Build query parameters
            const params = new URLSearchParams({
                companyId: project?.companyId
            });
    
            // Conditionally add the "ignoreOccupied" parameter
            if (module.moduleType === "Mejlutskick" || module.moduleType === "Chattbot") {
                params.append('ignoreOccupied', 'true');
            }
    
            // Make the request with the query parameters
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/email-accounts/accepted?${params.toString()}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${auth.token}`,
                    'Content-Type': 'application/json',
                },
            });
    
            if (!response.ok) {
                throw new Error('Misslyckades att hämta e-post konton.');
            }
    
            const data = await response.json();
            setEmailAccounts(data.content); // Assuming your API returns a paginated response
        } catch (error) {
            console.error('Misslyckades att hämta DNS-konton: ', error);
            toast.error('Misslyckades att hämta DNS-konton.', {
                position: 'bottom-right'
            });
        } finally {
            setLoading(false);
        }
    };
    

    const handleAccountSelection = async (selectedAccount) => {
      try {

        setSelectRowIds((prev) => [...prev, selectedAccount.id]);
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/select-account`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',  // Change to form-urlencoded
                  'Authorization': `Bearer ${auth.token}`
              },
              body: new URLSearchParams({
                  emailAccountId: selectedAccount.id,
                  moduleId: module.id
              }).toString()
          });
  
          if (!response.ok) {
              const errorResult = await response.json();
              toast.error(errorResult.message || 'Ett fel uppstod vid uppdatering av e-postkontot.', {
                  position: 'bottom-right'
              });
              return;
          }
  
          const result = await response.json();
          onModuleEdited({ ...module, emailAccountId: selectedAccount.id });
          toast.success(result.message, {
              position: 'bottom-right'
          });
      } catch (error) {
          console.error('Misslyckades att uppdatera e-postkontot: ', error);
          toast.error('Misslyckades att uppdatera e-postkontot.', {
              position: 'bottom-right'
          });
      } finally {
        setSelectRowIds((prev) => prev.filter((id) => id !== selectedAccount.id));
      }
  };
  const handleAccountDeSelection = async (selectedAccount) => {
    try {
        setSelectRowIds((prev) => [...prev, selectedAccount.id]);
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/deselect-account`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',  // Change to form-urlencoded
                'Authorization': `Bearer ${auth.token}`
            },
            body: new URLSearchParams({
                moduleId: module.id
            }).toString()
        });

        if (!response.ok) {
            const errorResult = await response.json();
            toast.error(errorResult.message || 'Ett fel uppstod vid uppdatering av e-postkontot.', {
                position: 'bottom-right'
            });
            return;
        }

        const result = await response.json();
        onModuleEdited({ ...module, emailAccountId: 0, active: false });
        toast.warning(result.message, {
            position: 'bottom-right'
        });
    } catch (error) {
        console.error('Misslyckades att uppdatera e-postkontot: ', error);
        toast.error('Misslyckades att uppdatera e-postkontot.', {
            position: 'bottom-right'
        });
    } finally {
        setSelectRowIds((prev) => prev.filter((id) => id !== selectedAccount.id));
      }
};

    return (
        <>
        <Helmet>
          <title>{`${module?.name} - E-post konto`}</title>
        </Helmet>
        <div className='d-flex'>

        <Breadcrumb className='mb-0'>
            <Breadcrumb.Item>
                <Link to={`/user/projekt/${project?.id}`}>{project?.name}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{module?.name}</Breadcrumb.Item>
            <Breadcrumb.Item active>E-post konto</Breadcrumb.Item>
        </Breadcrumb>

        </div>
        <hr />

            <Whisper
            placement="rightStart"
            trigger="click"
            speaker={<Popover>
                <h5>E-post konto</h5>
                {module?.moduleType === 'Mejlutskick' && (
                    <p className='mb-2'>Här kan du välja vilket e-post konto som ska användas vid mejlutskicket.</p>
                )}
                {module?.moduleType === 'Chattbot' && (
                    <p className='mb-2'>Här kan du välja vilket e-post konto som ska användas av chattboten vid användning av en anpassad funktion.</p>
                )}
                {module?.moduleType === 'E-post' && (
                    <p className='mb-2'>Här kan du välja vilket e-post konto modulen läsa av inkorgen från.</p>
                )}

                <p className='mb-2'>Behöver du ett annat konto som inte finns listat?</p>
                <a href='#' onClick={navigateToEmailAccounts}>Skapa ett nytt!</a>
                </Popover>}
            > 
            <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
            E-post konto
            </IconButton>
            </Whisper>
            
            {loading ? (
                <div className="row">
                    {[...Array(2)].map((_, index) => (
                        <div key={index} className="col-sm-12 col-md-12 col-lg-4 mt-2">
                        <div className="card p-3">
                            <Skeleton width={`60%`} height={20} />
                            <div className="d-flex mt-2">
                            <Skeleton width={45} height={25} className="me-2" />
                            <Skeleton width={55} height={25} />
                            </div>
                        </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="row">
                    {emailAccounts.map((account) => (
                        <div key={account.id} className="col-sm-12 col-md-12 col-lg-4 mt-2">
                            <div className="card p-3">
                                <div className="d-flex">
                                    <p className='mb-0'>{account.email}</p>
                                    {!account.alreadyUsed && account.id !== module.emailAccountId && account.email !== module.contactPerson ? (
                                        <IconButton
                                        icon={
                                            selectRowIds.includes(account.id) ? (  // Check if the module is being deleted
                                                <PlusIcon className='bg-success' color='white' />
                                            ) : (
                                                <PlusIcon className='bg-primary' color='white' />
                                            )
                                        }
                                        className='ms-auto'
                                        size='xs'
                                        onClick={() => handleAccountSelection(account)}  // Wrap in an arrow function
                                        title="Välj e-post konto"
                                        >Välj</IconButton>
                                    ) : account.id === module.emailAccountId ? (
                                        <IconButton
                                        icon={
                                            selectRowIds.includes(account.id) ? (  // Check if the module is being deleted
                                                <TrashIcon className='bg-warning' color='white' />
                                            ) : (
                                                <TrashIcon className='bg-primary' color='white' />
                                            )
                                        }
                                        className='ms-auto'
                                        size='xs'
                                        onClick={() => handleAccountDeSelection(account)}  // Wrap in an arrow function
                                        title="Avmarkera e-post konto"
                                        >Avmarkera</IconButton>
                                    ) : null}



                                </div>
                                <div className="mt-1">
                                    <Badge className='bg-primary p-1 rounded me-1' content={account.emailProvider} />
                                    
                                    {account.id === module.emailAccountId ? (
                                        <Badge className='bg-success p-1 rounded me-1' content="Vald på modul" />
                                    ) : (
                                        account.alreadyUsed && (
                                            <Badge className='bg-danger p-1 rounded me-1' content="Konto används av annan modul" />
                                        )
                                    )}

                                    {account.email === module.contactPerson && (
                                        <Badge className='bg-warning p-1 rounded me-1' content="Används som kontaktperson" />
                                    )}
                                    {account.demo && (
                                        <Badge className='bg-secondary p-1 rounded' content="Demo konto" />
                                    )}
                                    </div>

                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}

export default ModuleEmailAccount;
