import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import { Loader } from 'rsuite';

function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false); // New state for disabling the button after success
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/forgot-password?email=${email}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.ok) {
        const result = await response.json();
        toast.success(result.message, {
          position: 'bottom-right'
        });
        setIsDisabled(true); // Disable the button after success
      } else {
        const result = await response.json();
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }

    } catch (error) {
      toast.error("Misslyckades att skicka återställningslänk: " + error, {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Helmet>
          <title>{`Glömt lösenord`}</title>
        </Helmet>
    <div className='background-color'>
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className='card p-4 shadow-lg' style={{ width: '400px' }}>
          <form onSubmit={handleSubmit}>
            <div className='mb-4'>
              <label className='form-label' htmlFor="email">E-post</label>
              <input
                className='form-control'
                type="text"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={isDisabled} // Disable input if the button is disabled
              />
            </div>
            <button className='btn btn-primary w-100 mb-2 d-flex justify-content-center align-items-center' type="submit" disabled={loading || isDisabled}>
                  {loading ? (
                    <>
                      <Loader className="me-2" /> Laddar...
                    </>
                  ) : (
                    'Återställ'
                  )}
            </button>
            <Link to="/login">
              <p className='mb-0'>Logga in</p>
            </Link>
          </form>
          <div className="pt-3 mt-3">
            {/*<p className="copywritten-text">© 2024 Sonnet</p>*/} 
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default ForgotPasswordPage;
