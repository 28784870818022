import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import DNSCreateAccount from '../../../modals/admin/connections/CreateEmailAccount';
import DNSEditAccount from '../../../modals/admin/connections/EditEmailAccount';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Pagination } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import EditFooterModal from '../../../modals/company/general/EmailFooter';
import { useNavigate } from 'react-router-dom';

import { Dropdown, IconButton, Badge, Loader, Popover, Whisper, Breadcrumb } from 'rsuite';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { Gear } from '@rsuite/icons';

function EmailAccounts() { 
    const { auth } = useAuth(); 
    const [showCreateModal, setShowCreateModal] = useState(false); 
    const [showEditModal, setShowEditModal] = useState(false); 
    const [accounts, setAccounts] = useState([]); 
    const [loading, setLoading] = useState(true);
    const [selectedAccount, setSelectedAccount] = useState(null); 
    const [page, setPage] = useState(0);  
    const [totalPages, setTotalPages] = useState(1);  
    const [searchQuery, setSearchQuery] = useState('');
    const [showFooterModal, setShowFooterModal] = useState(false);
    const [selectedFooterAccount, setSelectedFooterAccount] = useState(null);
    const navigate = useNavigate();

    const [deletingRowIds, setDeletingRowIds] = useState([]);
    const [updatingRowIds, setUpdatingRowIds] = useState([]);
  
    // Function to handle showing the Footer Modal
    const handleShowFooterModal = (account) => {
      setSelectedFooterAccount(account); 
      setShowFooterModal(true); 
    };
  
    // Function to handle closing the Footer Modal
    const handleCloseFooterModal = () => {
      setShowFooterModal(false);
      setSelectedFooterAccount(null);
    };
  
    // Fetch accounts only after company is set
    const fetchAccounts = async (currentPage = 0, emailQuery = '') => {
  
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/email-accounts?email=${emailQuery}&page=${currentPage}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.token}`
          }
        });
  
        if (response.ok) {
          const data = await response.json();
          setAccounts(data.content || []); 
          setPage(data.number);  
          setTotalPages(data.totalPages);
        } else {
          const errorText = await response.text();
          throw new Error(errorText);
        }
      } catch (error) {
        console.error('Misslyckades att hämta konton: ', error);
        toast.error('Misslyckades att hämta konton.', {
          position: 'bottom-right'
        });
      } finally {
        setLoading(false);
      }
    };
    const setRootEmail = async (emailAccountId) => {
      try {
        setUpdatingRowIds((prev) => [...prev, emailAccountId]);
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/set-root-email?id=${emailAccountId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.token}`,
          }
        });
    
        const result = await response.json();
    
        if (response.ok) {
          toast.success(result.message, {
            position: 'bottom-right',
          });
    
          // Set rootEmail to false on all other email accounts and true on the selected account
          setAccounts((prevAccounts) =>
            prevAccounts.map((account) =>
              account.id === emailAccountId
                ? { ...account, rootEmail: true }
                : { ...account, rootEmail: false }
            )
          );
        } else {
          toast.error(result.message, {
            position: 'bottom-right',
          });
        }
      } catch (error) {
        console.error('Ett fel inträffade vid uppdateringen av rot e-post: ', error);
        toast.error('Ett fel inträffade vid uppdateringen av rot e-post.', {
          position: 'bottom-right',
        });
      } finally {
        setUpdatingRowIds((prev) => prev.filter((id) => id !== emailAccountId));
      }
    };
    
  
    const handlePageChange = (newPage) => {
      setPage(newPage);
      fetchAccounts(newPage, searchQuery);  
    };
  
    const handleSearch = (e) => {
      setSearchQuery(e.target.value);
    };
  
    const handleSearchSubmit = (e) => {
      if (e.key === 'Enter') {  
        setPage(0);  
        fetchAccounts(0, searchQuery);  
      }
    };
  
    const handleDeleteAccount = async (accountId) => {
      if (!window.confirm('Är du säker på att du vill radera detta e-post konto?')) {
        return; 
      }

      setDeletingRowIds((prev) => [...prev, accountId]);
  
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/delete-email-account`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.token}`
          },
          body: JSON.stringify({ id: accountId })
        });
  
        const result = await response.json();
  
        if (response.ok) {
          toast.warning(result.message, {
            position: 'bottom-right'
          });
          setAccounts(accounts.filter(account => account.id !== accountId));
        } else {
            toast.error(result.message, {
                position: 'bottom-right'
            });
        }
      } catch (error) {
        console.error('Misslyckades att radera e-post kontot: ', error);
        toast.error('Misslyckades att radera e-post kontot.', {
          position: 'bottom-right'
        });
      } finally {
        setDeletingRowIds((prev) => prev.filter((id) => id !== accountId));
      }
    };
  
    // Fetch accounts when company is set
    useEffect(() => {
        fetchAccounts();
    }, []);
  
    const handleShowCreateModal = () => {
      setShowCreateModal(true);
      setShowFooterModal(false);
    };
  
    const handleCloseCreateModal = () => setShowCreateModal(false);
  
    const handleShowEditModal = (account) => {
      setSelectedAccount(account); 
      setShowEditModal(true); 
    };
  
    const handleCloseEditModal = () => {
      setShowEditModal(false);
      setSelectedAccount(null); 
    };
  
    const handleAccountCreated = () => {
        fetchAccounts();
    };

  return (
    <>
    <Helmet>
          <title>{`Admin - E-post konton`}</title>
        </Helmet>
      <div className='px-5 pt-4 w-100'>
      
      <div className="d-flex">

        <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>E-post konton</h5>
            <p className='mb-2'>Här kan du se alla dina e-post konton.</p>
            <p className='mb-2'>E-post konton kan användas av:</p>
            <ul>
              <li>Moduler</li>
            </ul>
            <p className='mb-2'>Det är också fördelaktigt att verifiera domänen som e-postadressen är registrerad av.</p>
            <p className='mb-2'>Detta, av anledningen att minimera risken för att hamna i spam.</p>
            <p className='mb-2'>E-post konton som skapas i adminvyn kommer att kunna användas av alla kunder som test konto.</p>
            </Popover>}
        > 
          <IconButton className='mb-2 me-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          E-post konton
          </IconButton>
        </Whisper>
        <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Rot e-post</h5>
            <p className='mb-2'>Genom en rot e-post skickas alla system meddelanden till kunder.</p>
            <p className='mb-2'>Rot e-post bör inte användas av kunder.</p>
            <p className='mb-2'>Exempel på system meddelanden:</p>
            <ul>
              <li>Felmeddelanden</li>
              <li>Notiser</li>
              <li>Inbjudningar för medarbetare</li>
            </ul>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Rot e-post
          </IconButton>
        </Whisper> 

        <div className='ms-auto'>
            <div className="input-group">
                <input 
                  className='form-control' 
                  type="text" 
                  placeholder='Sök e-post' 
                  value={searchQuery}
                  disabled={loading}
                  onChange={handleSearch}
                  onKeyDown={handleSearchSubmit}  
                />
                <span className="input-group-text">
                    <i className="bi bi-search"></i>
                </span>
            </div>
        </div>
      </div>
      
      <div className="card dashed border-1 rounded shadow" style={{ cursor: 'pointer' }} onClick={handleShowCreateModal}>
          <div className='p-4 text-center'>
            <p className='mb-0'>
              <i className="bi bi-plus me-1"></i>
              Skapa nytt konto
            </p>
          </div>
        </div>

        {loading ? (
          <>
            <div className="row">
                    {[...Array(2)].map((_, index) => (
                        <div key={index} className="col-sm-12 col-md-12 col-lg-4 mt-2">
                        <div className="card p-3">
                            <Skeleton width={`60%`} height={20} />
                            <div className="d-flex mt-2">
                            <Skeleton width={45} height={25} className="me-2" />
                            <Skeleton width={55} height={25} />
                            </div>
                        </div>
                        </div>
                    ))}
                </div>
          </>
      ) : (
        <>
        <div className="row mb-2">
          {accounts.map(account => (
            <div key={account.id} className="col-sm-12 col-md-12 col-lg-4 mt-2">
              <div className="card border-1 p-3">
                <div>
                  <div className='d-flex'>
                    <p className='mb-2'>{account.email}</p>
                    <div className="ms-auto">
                      <Dropdown 
                          trigger={['click']}  // Open on both hover and click
                          renderToggle={(props, ref) => (
                            <IconButton {...props} ref={ref} icon={
                              (deletingRowIds.includes(account.id) || updatingRowIds.includes(account.id))? (  // Check if the module is being deleted
                                <Loader speed='fast' size="sm" />
                              ) : (
                                <Gear />
                              )
                            } appearance="subtle" />
                          )} 
                          placement="bottomEnd" 
                        >
                          <Dropdown.Item onClick={() => handleShowFooterModal(account)}>Signatur</Dropdown.Item>
                          {!account.rootEmail && (
                              <Dropdown.Item onClick={() => setRootEmail(account.id)}>Använd som rot e-post</Dropdown.Item>
                          )}
                          <Dropdown.Item onClick={() => handleDeleteAccount(account.id)}>Radera</Dropdown.Item>
                        </Dropdown>
                    </div>
                  </div>
                  <div className="d-flex">
                    <Badge className='bg-secondary p-1 rounded me-2' content={account.emailProvider} />
                    {(account.demo && !account.rootEmail) && (
                        <Badge className='bg-warning p-1 rounded me-2' content="Test konto" />
                    )}
                    {account.rootEmail && (
                        <Badge className='bg-primary p-1 rounded me-2' content="Rot e-post" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        </>
      )}
      

        <div className="d-flex my-4">
        {totalPages > 1 && (
        <div className="d-flex my-4">
            <Pagination>
            <Pagination.Prev 
                onClick={() => handlePageChange(page - 1)} 
                disabled={page === 0}
            />
            
            {(() => {
                const totalDisplayedPages = 10;  
                let startPage = Math.max(0, page - Math.floor(totalDisplayedPages / 2));
                let endPage = startPage + totalDisplayedPages;

                if (endPage > totalPages) {
                endPage = totalPages;
                startPage = Math.max(0, totalPages - totalDisplayedPages);
                }

                return [...Array(endPage - startPage).keys()].map((p) => (
                <Pagination.Item 
                    key={startPage + p} 
                    active={startPage + p === page} 
                    onClick={() => handlePageChange(startPage + p)}
                >
                    {startPage + p + 1}
                </Pagination.Item>
                ));
            })()}

            <Pagination.Next 
                onClick={() => handlePageChange(page + 1)} 
                disabled={page === totalPages - 1}
            />
            </Pagination>
        </div>
            )}
        </div>

      <DNSCreateAccount
        show={showCreateModal}
        handleClose={handleCloseCreateModal}
        onAccountCreated={handleAccountCreated}
      />
      {selectedFooterAccount && (
        <EditFooterModal
          show={showFooterModal}
          handleClose={handleCloseFooterModal}
          emailAccount={selectedFooterAccount}
          onEmailAccountEdited={handleAccountCreated} // Callback to refresh the accounts after editing
        />
      )}

      {selectedAccount && (
        <DNSEditAccount
          show={showEditModal}
          handleClose={handleCloseEditModal}
          account={selectedAccount}
          onAccountCreated={handleAccountCreated}
        />
      )}
      </div>
    </>
  );
}

export default EmailAccounts;
