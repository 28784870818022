// LayoutCustomer.jsx
import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavBar from './CustomerNavBar'; // Adjust the import path as necessary
import CustomerHeader from './CustomerHeader'; // Adjust the import path as necessary
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from '../../../AuthContext'; // Import authentication context
import { toast } from 'react-toastify';
import { Loader } from 'rsuite';

const LayoutCustomer = () => {
  const { auth } = useAuth(); // Get auth data from the context
  const navigate = useNavigate();
  const [licence, setLicence] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(true); // State to manage Sidenav expansion

  const fetchLoggedInLicence = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/customer/current-licence`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200 && response.data.content.length > 0) {
        setLicence(response.data.content[0]);
      } else {
        toast.error('Det gick inte att hämta företaget.', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      navigate('/login');
      toast.error('Det gick inte att hämta företaget.', {
        position: 'bottom-right',
      });
    } finally {
      setLoading(false); // Set loading to false after the fetch
    }
  };

  useEffect(() => {
    if (auth.token) {
      fetchLoggedInLicence();
    }
  }, [auth.token]);

  return (
    <div className="d-flex">
      {/* Pass expanded and setExpanded as props to NavBar */}
      <NavBar licence={licence} loading={loading} expanded={expanded} setExpanded={setExpanded} />
      <div
        className="flex-grow-1 background-color"
        style={{
          marginLeft: expanded ? 240 : 56, // Adjust margin based on expanded state
          transition: 'margin-left 0.3s ease', // Smooth transition for margin
        }}
      >
        <CustomerHeader licence={licence} loading={loading} />
        {/* Render the nested routes with Outlet */}
        <div className="main-content">
          {!loading ? <Outlet /> : 
            <div className='px-5 pt-4 w-100'>
              <Loader speed='fast' /> Laddar...
            </div>}
        </div>
      </div>
    </div>
  );
};

export default LayoutCustomer;
