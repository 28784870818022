import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { Popover, Whisper, IconButton, Breadcrumb, Loader } from 'rsuite';

function ModuleContactPerson({ project, module, onModuleEdited }) { // Added onModuleEdited as prop
  const { auth } = useAuth(); // Get auth token from context
  const [contactPerson, setContactPerson] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (module) {
        setContactPerson(module.contactPerson);
    }
  }, [module]);

  const handleSave = async () => {

    if (!contactPerson) {
        toast.error('Kontaktperson måste fyllas i.', {
          position: 'bottom-right'
        });
        return;
      }
    setLoading(true);

    const moduleData = {
      id: module.id,
      contactPerson,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/edit-module`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify(moduleData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        const updatedModule = { ...module, ...moduleData };
        onModuleEdited(updatedModule); // Use the merged module object
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.error('Misslyckades att spara ändringar: ', error);
      toast.error('Misslyckades att spara ändringar.', {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Helmet>
          <title>{`${module?.name} - Kontaktperson`}</title>
        </Helmet>
      <div className='d-flex'>

      <Breadcrumb className='mb-0'>
            <Breadcrumb.Item>
              <Link to={`/user/projekt/${project?.id}`}>{project?.name}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{module?.name}</Breadcrumb.Item>
            <Breadcrumb.Item active>Kontaktperson</Breadcrumb.Item>
      </Breadcrumb>

      </div>
      <hr />

      <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Kontaktperson</h5>
            <p className='mb-2'>Kontaktpersonen använd som kontaktadress när en anpassad funktion används. När en kontaktperson används:</p>
            <ul>
              <li>Bokat möte</li>
              <li>Vidarebefodring av mejl eller ärende</li>
            </ul>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Kontaktperson
          </IconButton>
        </Whisper>

      <div className="d-flex align-items-center mb-2">
        <input
            type="text"
            className='form-control me-2 w-25'
            id="contactPerson"
            value={contactPerson}
            placeholder='namn@namnsson.se'
            onChange={(e) => setContactPerson(e.target.value)}
        />
        <button className='btn btn-primary d-flex justify-content-center align-items-center' onClick={handleSave} disabled={loading}>
          {loading ? (
            <>
              <Loader className="me-2" /> Sparar...
            </>
          ) : (
            'Spara'
          )}
        </button>
    </div>
    </>
  );
}

export default ModuleContactPerson;
