import React, { useEffect, useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Pagination } from 'react-bootstrap'; // Import Bootstrap Pagination
import { Helmet } from 'react-helmet-async';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { Popover, Whisper, IconButton } from 'rsuite';

import { Badge, Breadcrumb } from 'rsuite';

function Modules() {
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const { auth } = useAuth();

  const fetchModules = async (currentPage = 0, query = '') => {

    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/customer/modules?page=${currentPage}&name=${query}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setModules(data.content); // Assuming your backend returns the modules in a "content" field
        setPage(data.number); // Set current page
        setTotalPages(data.totalPages); // Set total pages
      } else {
        const errorText = await response.text();
        throw new Error(`Misslyckades att hämta modul: ${errorText}`);
      }
    } catch (error) {
      console.error('Misslyckades att hämta modul: ', error);
      toast.error(error.message, {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
  }, [auth.token]);

  useEffect(() => {
    fetchModules();
  }, [auth.token]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    if (e.key === 'Enter') { // Trigger search on Enter key press
      setPage(0); // Reset to the first page
      fetchModules(0, searchQuery); // Fetch the list with the search query
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchModules(newPage, searchQuery); // Fetch data for the new page with the current search query
  };

  return (
    <>
      <Helmet>
          <title>{`Moduler`}</title>
        </Helmet>
        <div className='px-5 pt-3 mt-4 w-100'>
          
          <div className="d-flex mt-2">

            <div>
            <Whisper
              placement="rightStart"
              trigger="click"
              speaker={<Popover>
                  <h5>Moduler</h5>
                  <p className='mb-2'>Moduler kan utföra följande:</p>
                  <ul>
                    <li>Läsa av din mejlinkorg</li>
                    <li>Vara en chattbot på hemsida</li>
                    <li>Utföra ett mejlutskick</li>
                  </ul>
                  <p className='mb-2'>Klicka på en modul för att se dess statistik!</p>
                  </Popover>}
            > 
              <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
              Vad är en modul?
              </IconButton>
            </Whisper>
            </div>



            <div className='ms-auto'>
                <div className="input-group">
                <input 
                    className='form-control' 
                    type="text" 
                    placeholder='Sök moduler' 
                    value={searchQuery}
                    disabled={loading}
                    onChange={handleSearch}
                    onKeyDown={handleSearchSubmit}  // Trigger search on Enter key press
                />
                <span className="input-group-text">
                    <i className="bi bi-search"></i>
                </span>
                </div>
            </div>
          </div>

          {loading ? (
          <>
            <Skeleton height={60} />  
          </>
          ) : (
            <>
            <div className="row mb-2">
            {modules.length > 0 && (
              modules.map((module) => (
                <div className="col-md-12 col-lg-12" key={module.id}>
                  <Link to={`/customer/moduler/${module.id}`} className="text-decoration-none">
                    <div className="card mb-2 border-1" style={{ cursor: 'pointer' }}>
                      <div className="p-3">

                        <div className="d-flex">
                          <h5 className='mb-0'>{module.name}</h5>
                        
                          <div className="ms-auto">
                            {module?.moduleType === 'Chattbot' && (
                            <>
                              {module.active ? (
                                <Badge className='bg-success p-1 rounded me-2' content="Chattboten visas på hemsidan" />
                              ) : (
                                <Badge className='bg-warning p-1 rounded me-2' content="Chattboten visas inte på hemsidan" />
                              )}
                            </>
                            )}

                            {module?.moduleType === 'E-post' && (
                              <>
                                {module.active ? (
                                  <>
                                    <Badge className='bg-success p-1 rounded me-2' content="Modulen läser aktivt av inkorgen" />
                                    <Badge
                                      className='bg-secondary p-1 rounded me-2'
                                      content={`Fr. o.m. ${
                                        module.timestamp
                                          ? new Date(module.timestamp).toLocaleString('se-SE', {
                                              month: 'short',
                                              day: 'numeric',
                                              hour: 'numeric',
                                              minute: 'numeric',
                                              second: 'numeric',
                                            })
                                          : 'N/A'
                                      }`}
                                    />
                                  </>
                                ) : (
                                  <Badge className='bg-warning p-1 rounded me-2' content="Modulen läser inte av inkorgen" />
                                )}
                              </>
                            )}

                            {module?.moduleType === 'Mejlutskick' && (
                              <>
                                {module?.finished ? (
                                  <Badge className='bg-success p-1 rounded me-2' content="Färdigt mejlutskick" />
                                ) : (
                                  <>
                                    {module?.active ? (
                                      <Badge className='bg-success p-1 rounded me-2' content="Mejlutskicket är aktivt" />
                                    ) : (
                                      <Badge className='bg-warning p-1 rounded me-2' content="Mejlutskicket är pausat" />
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            <Badge className='bg-primary p-1 rounded me-2' content={module.moduleType} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            )}
          </div>
            </>
          )}
              

          {/* Pagination Component */}
          {totalPages > 1 && (
            <div className="d-flex justify-content-center my-4">
              <Pagination>
                <Pagination.Prev 
                  onClick={() => handlePageChange(page - 1)} 
                  disabled={page === 0}
                />
                
                {(() => {
                  const totalDisplayedPages = 10;
                  let startPage = Math.max(0, page - Math.floor(totalDisplayedPages / 2));
                  let endPage = startPage + totalDisplayedPages;

                  if (endPage > totalPages) {
                    endPage = totalPages;
                    startPage = Math.max(0, totalPages - totalDisplayedPages);
                  }

                  return [...Array(endPage - startPage).keys()].map((p) => (
                    <Pagination.Item 
                      key={startPage + p} 
                      active={startPage + p === page} 
                      onClick={() => handlePageChange(startPage + p)}
                    >
                      {startPage + p + 1}
                    </Pagination.Item>
                  ));
                })()}

                <Pagination.Next 
                  onClick={() => handlePageChange(page + 1)} 
                  disabled={page === totalPages - 1}
                />
              </Pagination>
            </div>
          )}
        </div>
    </>
  );
}

export default Modules;
