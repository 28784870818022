import React, { useState, useEffect } from 'react';
import ModulesCard from '../cards/ModuleCard';
import ModuleOverview from '../module/ModuleOverview';
import ModuleConversations from '../module/ModuleConversations'; // Correct import for ModuleConversations
import ModuleEmailList from '../module/ModuleEmailList'; // Correct import for ModuleConversations
import ModuleOverviewDispatch from '../module/ModuleOverviewDispatch';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../../AuthContext';
import { Loader } from 'rsuite';

const ContentWrapper = ({ children }) => (
  <div className='px-5 pt-4 w-100 p-3'>
    {children}
  </div>
);

function ModulesPage() {
  const { moduleId } = useParams();

  const { auth } = useAuth();
  const [loadingModule, setLoadingModule] = useState(true);
  const [loadingDispatch, setLoadingDispatch] = useState(true);
  const [selectedFragment, setSelectedFragment] = useState('Översikt');
  const [module, setModule] = useState(null);
  const [dispatch, setDispatch] = useState(null);

  const fetchModule = async () => {
    if (!moduleId) return;
    try {
      setLoadingModule(true)
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/customer/modules?id=${moduleId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        const fetchedModule = data.content[0];
        setModule(fetchedModule);
      } else {
        const errorText = await response.text();
        toast.error("Det gick inte att hämta modulen.", {
          position: 'bottom-right'
        });
        throw new Error(`Misslyckades att hämta modul: ${errorText}`);
      }
    } catch (error) {
      console.error('Misslyckades att hämta modul: ', error);
      toast.error(error.message, {
        position: 'bottom-right'
      });
    } finally{
      setLoadingModule(false);
    }
  };
  const fetchDispatch = async (moduleId) => {
    try {
      setLoadingDispatch(true)
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/customer/dispatches?moduleId=${moduleId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const fetchedDispatch = data.content?.[0]; // Assuming the API returns a paginated result and you want the first dispatch
        setDispatch(fetchedDispatch || null);
      } else {
        toast.error('Misslyckades att hämta utskick.', { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Misslyckades att hämta utskick: ', error);
      toast.error('Misslyckades att hämta utskick.', { position: 'bottom-right' });
    } finally {
      setLoadingDispatch(false)
    }
  };

  const handleModuleUpdate = (updatedModule) => {
    setModule(prevModule => ({
      ...prevModule,
      ...updatedModule // Only update the specific fields that are changed
    }));
  };

  // Fetch the module when moduleId changes
  useEffect(() => {
    fetchModule();
  }, [moduleId]);

  // Fetch the dispatch after the module is fetched
  useEffect(() => {
    if (module) {
      fetchDispatch(module.id);
    }
  }, [module]);

  // Handle dispatch updates without affecting the module state
  const handleDispatchUpdate = (updatedDispatch) => {
    setDispatch(prevDispatch => ({
      ...prevDispatch,
      ...updatedDispatch // Only update the fields that are changed
    }));
  };

  if (loadingModule || loadingDispatch) {
    return (
      <ContentWrapper>
          <Loader speed='fast' /> Laddar...
      </ContentWrapper>
    );
  }

  return (
    <div className="d-flex">
        <ContentWrapper>
        {selectedFragment === 'Översikt' && module && (
                (module.moduleType === 'Chattbot' || module.moduleType === 'E-post') ? (
                  <ModuleOverview module={module} />
                ) : (
                  module.moduleType === 'Mejlutskick' && (
                    <ModuleOverviewDispatch module={module} dispatch={dispatch} loadingDispatch={loadingDispatch} />
                  )
                )
              )}
          {selectedFragment === 'Konversationer' && <ModuleConversations module={module} />}
          {selectedFragment === 'Kontaktlista' && <ModuleEmailList module={module} dispatch={dispatch}/>}
        </ContentWrapper>
        <div className="side-nav-right d-flex flex-column p-3">
          <ModulesCard 
            module={module}
            dispatch={dispatch}
            selectedFragment={selectedFragment} 
            setSelectedFragment={setSelectedFragment} 
            onModuleUpdate={handleModuleUpdate}
          />
        </div>
      </div>
  );
}

export default ModulesPage;
