import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from 'rsuite';

function EditLabelModal({ show, handleClose, module, onModuleEdited }) {
  const { auth } = useAuth();
  const [label, setLabel] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (module) {
      setLabel(module.label);
    }
  }, [module]);

  const handleSubmit = async () => {
    if (!label) {
      toast.error('Namn måste fyllas i.', {
        position: 'bottom-right'
      });
      return;
    }

    setLoading(true);
    const moduleData = {
      label,
      id: module.id,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/edit-module`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify(moduleData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        handleClose();
        const updatedModule = { ...module, ...moduleData };
        onModuleEdited(updatedModule); // Use the merged module object
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Misslyckades att redigera modulen: ', error, {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>{module ? module.label : 'Laddar...'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label htmlFor="label" className='form-label'>Namn</label>
          <input
            type="text"
            className='form-control'
            id="label"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Stäng
        </Button>
        <Button variant="primary" className="d-flex justify-content-center align-items-center" onClick={handleSubmit} disabled={loading}>
            {loading ? (
              <>
                <Loader className="me-2" /> Sparar...
              </>
            ) : (
              'Spara'
            )}
          </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditLabelModal;
