import React, { useEffect, useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Pagination } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Helmet } from 'react-helmet-async';

import { Badge, IconButton, Popover, Whisper, Breadcrumb } from 'rsuite';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import CreditCardPlusIcon from '@rsuite/icons/CreditCardPlus';

function InvoicePage({}) {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const { auth } = useAuth();
  const [startingAfter, setStartingAfter] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);

  const fetchInvoices = async (newStartingAfter = null) => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/invoices?limit=10&startingAfter=${newStartingAfter || ''}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });

      if (response.status === 200) {
        const invoiceData = response.data;
        const invoicesList = invoiceData.invoices || [];
        const hasMore = invoiceData.has_more || false;

        setInvoices(invoicesList);
        setStartingAfter(invoicesList.length > 0 ? invoicesList[invoicesList.length - 1].id : null);
        setHasNextPage(hasMore);
        setHasPrevPage(!!newStartingAfter);
      } else {
        toast.error('Misslyckades att hämta fakturor.', { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Misslyckades att hämta fakturor:', error);
      toast.error('Misslyckades att hämta fakturor.', { position: 'bottom-right' });
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    fetchInvoices(startingAfter);
  };

  const handlePrevPage = () => {
    fetchInvoices(null);
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  return (
    <>
    <Helmet>
          <title>{`Fakturor`}</title>
        </Helmet>
        <div className='px-5 pt-4 w-100'>

          <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Fakturor</h5>
            <p className='mb-2'>Här är en översikt över alla fakturor till licensen.</p>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Fakturor
          </IconButton>
        </Whisper>

          {loading ? (
          <>
            <Skeleton height={60} />  
          </>
          ) : (
            <>
            <div className="mb-2">
            <ul className="list-group">
              {invoices.map((invoice) => (
                <li className="list-group-item d-flex justify-content-between align-items-center" key={invoice.id}>
                  Faktura #{invoice.number}
                  <div className="d-flex align-items-center">
                    {invoice.status === 'open' && (
                      <Badge content={`Förfaller ${new Date(invoice.dueDate * 1000).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}`} className="bg-secondary p-1 rounded me-2" />
                    )}
                    <Badge p-1 rounded content={
                      invoice.status === 'paid' ? 'Betalad' : 
                      invoice.status === 'open' ? 'Obetalad' : 
                      invoice.status === 'void' ? 'Upphävd' : invoice.status
                    }
                      className={`me-2 p-1 rounded me-2 ${invoice.status === 'paid' ? 'bg-success' : 
                        invoice.status === 'open' ? 'bg-warning' : 
                        invoice.status === 'void' ? 'bg-danger' : 'bg-secondary'}`} 
                    />
                    <IconButton
                      icon={<FileDownloadIcon />}
                      appearance="subtle"
                      onClick={() => window.open(invoice.invoicePdf)}  // Wrap in an arrow function
                      title="Ladda ner faktura"
                    />
                    {invoice.status === 'open' && (
                      <IconButton
                      className='ms-2'
                      icon={<CreditCardPlusIcon />}
                      appearance="subtle"
                      onClick={() => window.open(invoice.hostedInvoiceUrl, '_blank')}  // Wrap in an arrow function
                      title="Betala faktura"
                    />
                    )}
                  </div>
                </li>
              ))}
              {invoices.length === 0 && (
                  <p className='mt-4 text-center'>Inga fakturor hittades.</p>
              )}

            </ul>
          </div>
          <div className="d-flex my-4 justify-content-center">
            <Pagination>
              <Pagination.Prev onClick={handlePrevPage} disabled={!hasPrevPage} />
              <Pagination.Next onClick={handleNextPage} disabled={!hasNextPage} />
            </Pagination>
          </div>
            </>
          )}

        </div>
    </>
  );
}

export default InvoicePage;
