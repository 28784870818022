import React, { useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { Link } from 'react-router-dom';
import CreateFunctionModal from '../../../modals/company/project/CreateFunction'; // Adjust the import path as necessary
import EditFunctionModal from '../../../modals/company/project/EditFunction'; // Adjust the import path as necessary
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Pagination } from 'react-bootstrap'; 

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Helmet } from 'react-helmet-async';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';

import { Dropdown, IconButton, Badge, Loader, Popover, Whisper, Breadcrumb } from 'rsuite';
import { Gear } from '@rsuite/icons';

function Functions({ project }) {
  const { auth } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [functions, setFunctions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [page, setPage] = useState(0);  
  const [totalPages, setTotalPages] = useState(1);  
  const [searchQuery, setSearchQuery] = useState('');

  const [deletingRowIds, setDeletingRowIds] = useState([]);

  // Function to fetch functions from the backend
  const fetchFunctions = async (currentPage = 0, nameQuery = '') => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/functions?name=${nameQuery}&page=${currentPage}`, {
        headers: {
          'Authorization': `Bearer ${auth.token}`
        },
        params: {
          projectId: project.id
        }
      });
      setFunctions(response.data.content); // Assuming the data is paginated
      setPage(response.data.number);  
      setTotalPages(response.data.totalPages);
    } catch (error) {
      toast.error('Misslyckades att hämta funktioner.', {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  // Fetch functions when the component mounts
  useEffect(() => {
    if (project) {
      fetchFunctions();
    }
  }, [project]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchFunctions(newPage, searchQuery);  
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

const handleSearchSubmit = (e) => {
    if (e.key === 'Enter') {  
      setPage(0);  
      fetchFunctions(0, searchQuery);  
    }
  };

  // Function to handle the closing of the create modal
  const handleCloseModal = () => setShowModal(false);

  // Function to handle the opening of the create modal
  const handleOpenModal = () => setShowModal(true);

  // Function to handle the opening of the edit modal
  const handleOpenEditModal = (func) => {
    setSelectedFunction(func);
    setShowEditModal(true);
  };

  // Function to handle the closing of the edit modal
  const handleCloseEditModal = () => {
    setSelectedFunction(null);
    setShowEditModal(false);
  };

  // Callback for when a function is created or edited
  const handleFunctionCreated = () => {
    fetchFunctions(); // Fetch the functions again after creation
    setShowModal(false); // Close the create modal
    setShowEditModal(false); // Close the edit modal
  };

  const deleteFunction = async (functionId) => {
    if (!window.confirm('Är du säker på att du vill radera denna funktion?')) {
      return; // User canceled the deletion
    }

    setDeletingRowIds((prev) => [...prev, functionId]);

    try {
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/user/delete-function`, {
        headers: {
          'Authorization': `Bearer ${auth.token}`,
          'Content-Type': 'application/json'
        },
        data: {
          id: functionId
        }
      });

      if (response.status === 200) {
        toast.warning('Funktionen har raderats.', {
          position: 'bottom-right'
        });
        setFunctions(prevFunctions => prevFunctions.filter(func => func.id !== functionId)); // Remove the deleted function from the state
      } else {
        toast.error('Misslyckades att radera funktionen.', {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      toast.error('Misslyckades att radera funktionen.', {
        position: 'bottom-right'
      });
    } finally {
      setDeletingRowIds((prev) => prev.filter((id) => id !== functionId));
    }
  };

  return (
    <>
    <Helmet>
          <title>{`${project?.name} - Anpassade funktioner`}</title>
        </Helmet>
      <div className='d-flex'>
      <Breadcrumb className='mb-0'>
                <Breadcrumb.Item>{project?.name}</Breadcrumb.Item>
                <Breadcrumb.Item active>Anpassade funktioner</Breadcrumb.Item>
            </Breadcrumb>

      </div>

      <hr />
      
      <div className="d-flex mb-2">
        <div>
        <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Anpassade funktioner</h5>
            <p className='mb-2'>Moduler kan på eget initiativ utföra uppgifter, detta med hjälp av anpassade funktioner.</p>
            <p>Exempel på vissa utföranden:</p>
            <ul>
              <li>Hämta orderuppgifter från en databas</li>
              <li>Boka digitala möten</li>
              <li>Utföra tredjeparts systems funktioner</li>
            </ul>
            </Popover>}
        > 
          <IconButton icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Vad är anpassade funktioner?
          </IconButton>
        </Whisper>
        </div>

        <div className='ms-auto'>
            <div className="input-group">
                <input 
                  className='form-control' 
                  type="text" 
                  placeholder='Sök funktion' 
                  value={searchQuery}
                  disabled={loading}
                  onChange={handleSearch}
                  onKeyDown={handleSearchSubmit}  
                />
                <span className="input-group-text">
                    <i className="bi bi-search"></i>
                </span>
            </div>
        </div>
      </div>

      <div className="card dashed shadow border-1 rounded mb-2" style={{ cursor: 'pointer' }} onClick={handleOpenModal}>
          <div className='p-4 text-center'>
            <p className='mb-0'>
              <i className="bi bi-plus me-1"></i>
              Skapa ny funktion
            </p>
          </div>
        </div>

      {loading ? (
          <>
            <Skeleton height={55} />  
          </>
      ) : (
        <>
          <div className="row mb-2">
        {functions.map((func) => (
            <div className="col-md-12 col-lg-12">
              <Link 
              to={`/user/projekt/${project?.id}/funktioner/${func.id}`} 
              key={func.id} 
              style={{ textDecoration: 'none' }} // Optional: Remove underline from link
            >
              <div className="card border-1 mb-2" style={{ cursor: 'pointer' }}>
                <div className="p-2">
                  <div className="d-flex align-items-center">

                  <h5 className='mb-1 ms-1'>{func.name}</h5>  {/* Added `me-3` to create space */}

                  <div className="d-flex ms-auto me-2">
                    {!func.valid ? (
                      <Badge className='bg-danger p-1 rounded me-2' content="Validering krävs" />
                    ) : (
                      <Badge className='bg-success p-1 rounded me-2' content="Fullständig" />
                    )}

                    <div className='d-flex'>
                      {func.requestType === 'Zapier' && (
                        <Badge className='bg-warning p-1 rounded me-2' content="ZAPIER" />
                      )}
                      {func.requestType === 'GET' && (
                        <Badge className='bg-primary p-1 rounded me-2' content="GET REQUEST" />
                      )}
                      {func.requestType === 'POST' && (
                        <Badge className='bg-primary p-1 rounded me-2' content="POST REQUEST" />
                      )}
                      {func.parameters.length == 0 ? (
                      <Badge className='bg-secondary p-1 rounded me-2' content="Inga parametrar" />
                    ) : (
                      <>
                      <Badge className='bg-primary p-1 rounded me-2' content={`${func.parameters.length} Parametrar`} />
                      </>
                    )}

                    </div>
                    </div>

                  <div
                  onClick={(e) => e.preventDefault()}
                >
                  <Dropdown
                    trigger={['click']}
                    renderToggle={(props, ref) => (
                      <IconButton
                        {...props}
                        ref={ref}
                        icon={
                          deletingRowIds.includes(func.id) ? (  // Check if the module is being deleted
                            <Loader speed='fast' size="sm" />
                          ) : (
                            <Gear />
                          )
                        }
                        appearance="subtle"
                      />
                    )}
                    placement="bottomEnd"
                  >
                    <Dropdown.Item
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenEditModal(func); // Open the edit modal with the selected function
                      }}
                    >
                      Ändra
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to={`/user/projekt/${project?.id}/funktioner/${func.id}`} 
                      style={{ textDecoration: 'none' }}
                      className='text-dark'>
                      Specifiera
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        e.preventDefault();
                        deleteFunction(func.id);
                      }}
                    >
                      Radera
                    </Dropdown.Item>
                  </Dropdown>
                </div>

                  </div>
                 
                </div>
              </div>
            </Link>
            </div>
          ))}
      </div>
        </>
      )}

        <div className="d-flex my-4">
        {totalPages > 1 && (
        <div className="d-flex my-4">
            <Pagination>
            <Pagination.Prev 
                onClick={() => handlePageChange(page - 1)} 
                disabled={page === 0}
            />
            
            {(() => {
                const totalDisplayedPages = 10;  
                let startPage = Math.max(0, page - Math.floor(totalDisplayedPages / 2));
                let endPage = startPage + totalDisplayedPages;

                if (endPage > totalPages) {
                endPage = totalPages;
                startPage = Math.max(0, totalPages - totalDisplayedPages);
                }

                return [...Array(endPage - startPage).keys()].map((p) => (
                <Pagination.Item 
                    key={startPage + p} 
                    active={startPage + p === page} 
                    onClick={() => handlePageChange(startPage + p)}
                >
                    {startPage + p + 1}
                </Pagination.Item>
                ));
            })()}

            <Pagination.Next 
                onClick={() => handlePageChange(page + 1)} 
                disabled={page === totalPages - 1}
            />
            </Pagination>
        </div>
            )}
        </div>

      {/* Render the create modal */}
      <CreateFunctionModal
        show={showModal}
        handleClose={handleCloseModal}
        projectId={project.id}
        onFunctionCreated={handleFunctionCreated}
      />

      {/* Render the edit modal */}
      <EditFunctionModal
        show={showEditModal}
        handleClose={handleCloseEditModal}
        moduleFunction={selectedFunction} // Pass the selected function to the edit modal
        onFunctionCreated={handleFunctionCreated}
        projectId={project.id}
      />
    </>
  );
}

export default Functions;
