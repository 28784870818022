import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Pagination } from 'react-bootstrap';  // Import Bootstrap Pagination
import { Helmet } from 'react-helmet-async';
import TrashIcon from '@rsuite/icons/legacy/Trash';
import { IconButton, Loader, Popover, Whisper, Breadcrumb } from 'rsuite';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import FileUploadIcon from '@rsuite/icons/FileUpload';

function EmailList({ project, dispatch, module, onDispatchEdited }) {
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(0);  // State for current page
  const [totalPages, setTotalPages] = useState(1);  // State for total pages
  const [searchQuery, setSearchQuery] = useState('');  // State for search query
  const { auth } = useAuth();
  const fileInputRef = useRef(null);  // Reference to the hidden file input

  const [deletingRowIds, setDeletingRowIds] = useState([]);

  const fetchList = async (currentPage = 0, emailQuery = '') => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/contactlist`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        params: {
          dispatchId: dispatch.id,
          email: emailQuery,  // Add the search query here
          page: currentPage,
          size: 12  // Assuming page size of 10
        }
      });

      if (response.status === 200) {
        setList(response.data.content);
        setPage(response.data.number);  // Set the current page
        setTotalPages(response.data.totalPages);  // Set the total number of pages
      } else {
        toast.error('Misslyckades att hämta lista.', { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Misslyckades att hämta lista: ', error);
      toast.error('Misslyckades att hämta lista.', { position: 'bottom-right' });
    } finally {
      setLoading(false);
    }
  };

  
  useEffect(() => {
    if (project) {
      fetchList();
    }
  }, [project]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchList(newPage, searchQuery);  // Fetch data for the new page with the current search query
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    if (e.key === 'Enter') {  // Trigger search on Enter key press
      setPage(0);  // Reset to the first page
      fetchList(0, searchQuery);  // Fetch the list with the search query
    }
  };

  const handleListUpload = async (event) => {
    const selectedFiles = event.target.files || event.dataTransfer.files;

    if (selectedFiles.length > 1) {
      toast.error('Endast en fil kan laddas upp åt gången.', { position: 'bottom-right' });
      return; // Prevent multiple file uploads
    }

    const formData = new FormData();
    formData.append('dispatchId', dispatch.id);
    formData.append('files', selectedFiles[0]);  // Add only the first file

    try {
      setUploading(true)
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/create-contactaddresses`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${auth.token}`
        }
      });

      if (response.status === 200) {
        toast.success(response.data.message, { position: 'bottom-right' });
        const updatedDispatch = { ...dispatch };
        onDispatchEdited(updatedDispatch);  // Notify parent of changes
        await fetchList(page, searchQuery);  // Refresh the file list
      } else {
        toast.error(response.data.message, { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Misslyckades att ladda upp lista: ', error);
      toast.error('Misslyckades att ladda upp lista.', { position: 'bottom-right' });
    } finally {
      setUploading(false);
    }
  };

  const handleItemDelete = async (addressId) => {
    if (!window.confirm('Är du säker på att du vill radera denna adress?')) {
      return;  // User canceled the deletion
    }
    
    setDeletingRowIds((prev) => [...prev, addressId]);

    try {
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/user/delete-contactaddress`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        data: { 
          id: addressId,
          dispatchId : dispatch.id
         }
      });

      if (response.status === 200) {
        toast.warning(response.data.message, { position: 'bottom-right' });
        setList((prevList) => prevList.filter((list) => list.id !== addressId));
      } else {
        toast.error(response.data.message, { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Misslyckades att radera adress: ', error);
      toast.error('Misslyckades att radera adress.', { position: 'bottom-right' });
    } finally {
      setDeletingRowIds((prev) => prev.filter((id) => id !== addressId));
    }
  };
  const deleteList = async () => {

    if (!window.confirm('Är du säker på att du vill radera kontaktlista?')) {
      return;  // User canceled the deletion
    }
    setDeletingRowIds(list.map(item => item.id));
    setDeleting(true);
  
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/user/delete-contactaddresses`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        data: {
          id: dispatch.id // Assuming `dispatch.id` is available as the identifier
        }
      });
      if (response.status === 200) {
        toast.warning(response.data.message, { position: 'bottom-right' });
        const updatedDispatch = { ...dispatch, uploaded: false };
        onDispatchEdited(updatedDispatch);  // Notify parent of changes
        await fetchList(0, searchQuery);  // Fetch the list again after deletion
      } else {
        toast.error(response.data.message, { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Misslyckades att radera kontaktadresser: ', error);
      toast.error('Misslyckades att radera kontaktadresser.', { position: 'bottom-right' });
    } finally {
      setDeleting(false)
      setDeletingRowIds([]); // This will clear all items from the deletingRowIds array.
    }
  };
  


  const handleDrop = (event) => {
    event.preventDefault();
    handleListUpload(event);
  };

  // Trigger file input click when the card is clicked
  const handleCardClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();  // Programmatically trigger the hidden file input
    }
  };

  if (!dispatch) {
    return <p>Lista hittades inte.</p>;
  }

  return (
    <>
    <Helmet>
          <title>{`${module?.name} - Kontaktlista`}</title>
        </Helmet>
        <div className='d-flex'>

        <Breadcrumb className='mb-0'>
              <Breadcrumb.Item>
                <Link to={`/user/projekt/${project?.id}`}>{project?.name}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{module?.name}</Breadcrumb.Item>
              <Breadcrumb.Item active>Kontaktlista</Breadcrumb.Item>
        </Breadcrumb>

        </div>
        <hr />
      
      <div className="d-flex">
        <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Kontaktlista</h5>
            <p className='mb-2'>Här kan du ladda upp en lista med e-postadresser som ditt mejlutskick kommer nå ut till.</p>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Kontaktlista
          </IconButton>
        </Whisper>
          {list.length>0 && (
            <IconButton onClick={() => deleteList()} disabled={deleting} className='mb-2 ms-2' icon={<TrashIcon className='bg-danger' color='white' />
            }>
            Radera kontaktlista
            </IconButton>
          )}

        <div className='ms-auto'>
            <div className="input-group">
                <input 
                  className='form-control' 
                  type="text" 
                  placeholder='Sök adress' 
                  value={searchQuery}
                  disabled={loading}
                  onChange={handleSearch}
                  onKeyDown={handleSearchSubmit}  // Trigger search on Enter key press
                />
                <span className="input-group-text">
                    <i className="bi bi-search"></i>
                </span>
            </div>
        </div>
      </div>

      {list.length > 0 ? (
        <div
          className="card dashed border-1 rounded upload-occupied mb-2"
          style={{ cursor: 'pointer' }}
        >
          <div className='p-3 text-center'>
            <p className='mb-1'>
              <i className="bi bi-stopwatch me-2"></i>
              Lista redan uppladdad
            </p>
            <p className='text-muted mb-0'>Radera kontaktlistan för att ladda upp en ny.</p>
          </div>
        </div>
      ) : (
        <div
          className="card dashed shadow border-1 rounded mb-2"
          style={{ cursor: 'pointer' }}
          onClick={handleCardClick}  // Handle click to trigger file input
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className='p-3 text-center'>
            <p className='mb-1'>
              <FileUploadIcon className='me-2' />
              Ladda upp kontaktlista
            </p>
            <p className='text-muted mb-0'>Eller dra och släpp. Max 10MB</p>
          </div>
        </div>
      )}

      {loading ? (
        <Skeleton height={60} /> // Render Skeleton if loading
      ) : (
        <>
          {uploading ? (
            <div className="mb-2">
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                <Loader speed='fast' size="sm" content="Laddar upp lista..." />
                </li>
              </ul>
            </div>
          ) : (
            <div className="mb-2">
              <ul className="list-group">
                {list
                .filter((adress) => !adress.header)  // Filter out rows where header is true
                .map((adress) => (
                  <li key={adress.id} className="list-group-item d-flex justify-content-between align-items-center">
                    {adress.email}
                    <div className="d-flex align-items-center">
                    
                    {adress.sentDate && (
                      <>
                          <span className="badge bg-primary me-2">
                          Skickades {new Date(adress.sentDate).toLocaleString('sv-SE', {
                              timeZone: 'Europe/Stockholm',
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit'
                          })}
                          </span>

                          {!adress.sent && (
                          <span className="badge bg-danger me-2">Misslyckades</span>
                          )}
                      </>
                      )}


                      <IconButton
                        icon={
                          deletingRowIds.includes(adress.id) ? (  // Check if the module is being deleted
                            <Loader speed='fast' size="sm" />
                          ) : (
                            <TrashIcon />
                          )
                        }
                        appearance="subtle"
                        onClick={() => handleItemDelete(adress.id)}  // Wrap in an arrow function
                        title="Radera kontaktaddress"
                        disabled={deletingRowIds.includes(adress.id)}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}


      {/* Pagination Component */}
      <div className="d-flex my-4">
      {/* Pagination Component */}
{totalPages > 1 && (
  <div className="d-flex my-4">
    <Pagination>
      <Pagination.Prev 
        onClick={() => handlePageChange(page - 1)} 
        disabled={page === 0}
      />
      
      {(() => {
        // Determine the start and end page numbers to display in the pagination
        const totalDisplayedPages = 10;  // Number of pages to display
        let startPage = Math.max(0, page - Math.floor(totalDisplayedPages / 2));
        let endPage = startPage + totalDisplayedPages;

        // Ensure the last page doesn't go beyond totalPages
        if (endPage > totalPages) {
          endPage = totalPages;
          startPage = Math.max(0, totalPages - totalDisplayedPages);
        }

        // Generate the range of pages to display
        return [...Array(endPage - startPage).keys()].map((p) => (
          <Pagination.Item 
            key={startPage + p} 
            active={startPage + p === page} 
            onClick={() => handlePageChange(startPage + p)}
          >
            {startPage + p + 1}
          </Pagination.Item>
        ));
      })()}

      <Pagination.Next 
        onClick={() => handlePageChange(page + 1)} 
        disabled={page === totalPages - 1}
      />
    </Pagination>
    </div>
    )}
      </div>

      {/* Hidden file input */}
      <input
        type="file"
        accept=".csv"  // Restrict to .csv files
        ref={fileInputRef}  // Attach the ref to the input
        style={{ display: 'none' }}  // Hide the input
        onChange={handleListUpload}  // Handle file upload on change
      />
    </>
  );
}

export default EmailList;
