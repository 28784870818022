import React, { useState, useEffect } from 'react';
import { Dropdown, Button, IconButton, Badge, Divider, Loader } from 'rsuite';
import { useAuth } from '../../../AuthContext'; // Import useAuth for authentication
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/sv'; // Import Swedish locale
import BellIcon from '@rsuite/icons/legacy/Bell';
import TrashIcon from '@rsuite/icons/legacy/Trash';


const AdminHeader = () => {
  const [showNotificationsMenu, setShowNotificationsMenu] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const { auth, logout } = useAuth(); // Get auth and logout from useAuth
  const navigate = useNavigate();

  const [deletingNotificationIds, setDeletingNotificationIds] = useState([]);

  dayjs.extend(relativeTime);
  dayjs.locale('sv'); // Set Swedish as the locale

  const formatNotificationDate = (createdAt) => {
    const notificationDate = dayjs(createdAt);
    const now = dayjs();

    if (now.isSame(notificationDate, 'day')) {
      // If the notification was created today, show the time
      return notificationDate.format('HH:mm');
    } else {
      // Otherwise, show how many days ago
      return notificationDate.fromNow(); // e.g., "2 days ago"
    }
  };

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/superadmin/notifications`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      });

      if (response.status === 200) {
        const notifications = response.data.content;
        setNotifications(notifications);
      } else {
        toast.error('Misslyckades att hämta notiser', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      toast.error('Misslyckades att hämta notiser', {
        position: 'bottom-right',
      });
    }
  };

  const deleteNotification = async (notificationId) => {
    try {
      setDeletingNotificationIds((prev) => [...prev, notificationId]);
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/superadmin/delete-notification`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
        },
        data: {
          id: notificationId,
        },
      });

      if (response.status === 200) {
        toast.warning(response.data.message, {
          position: 'bottom-right',
        });
        fetchNotifications(); // Refresh the notifications list
      } else {
        toast.error('Misslyckades att radera notis.', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      toast.error('Misslyckades att radera notis.', {
        position: 'bottom-right',
      });
    } finally {
      setDeletingNotificationIds((prev) => prev.filter((id) => id !== notificationId));
    }
  };

  const deleteNotifications = async () => {
    if (!window.confirm('Är du säker på att du vill rensa notiser?')) {
      return;
    }
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/superadmin/delete-notifications`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        toast.warning(response.data.message, {
          position: 'bottom-right',
        });
        fetchNotifications(); // Refresh the notifications list
      } else {
        toast.error('Misslyckades att rensa notiser.', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      toast.error('Misslyckades att rensa notiser.', {
        position: 'bottom-right',
      });
    }
  };

  const navigateToEvent = (notification) => {
    navigate(`/superadmin/licenser/${notification.companyLicenceId}`);
  };

  useEffect(() => {
    fetchNotifications();
    const interval = setInterval(fetchNotifications, 30000); // Fetch every 30 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <header className="bg-light py-2 px-4 border-bottom shadow-sm header-fixed">
      <div className="d-flex justify-content-between align-items-center">

        {/* Right: Notifications and Team Dropdowns */}
        <div className="d-flex align-items-center ms-auto">
          
          {/* Notifications */}
          <Badge content={notifications.length > 9 ? '+9' : notifications.length} style={{ zIndex: "999" }}>
          <Dropdown
            title="Notiser"
            trigger={['hover', 'click']}  // Open on both hover and click
            icon={<BellIcon />}
            placement="bottomEnd"
          >
              <Dropdown.Item
                className="d-flex justify-content-between align-items-center"
                style={{ minWidth: "300px", pointerEvents: "none", cursor:"default" }} // Simulate disabled state
              >
                <div className="d-flex align-items-center" style={{ pointerEvents: "all" }}>
                  <BellIcon className="me-2" />
                  <h5 className="mb-0">Notiser</h5>
                  <div className="ms-auto">
                    <a
                      href="#"
                      className=""
                      onClick={(e) => {
                        e.preventDefault(); // Prevent default link behavior if needed
                        deleteNotifications(); // Your function to delete notifications
                      }}
                    >
                      Rensa notiser
                    </a>
                  </div>
                </div>
              </Dropdown.Item>

              <Divider style={{marginTop:"0px", marginBottom:"0px"}}/>

              {loading ? (
                <Dropdown.Item>
                  <Loader speed='fast' content="Laddar notiser..." />
                </Dropdown.Item>
              ) : notifications.length === 0 ? (
                <Dropdown.Item disabled>
                  Inga notiser att visa
                </Dropdown.Item>
              ) : (
                notifications.map((notification, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => navigateToEvent(notification)}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <div className='d-flex'>
                      <p className="mb-0 me-2">{notification.message}</p>
                      <IconButton icon={
                            deletingNotificationIds.includes(notification.id) ? (  // Check if the module is being deleted
                                <Loader speed='fast' />
                            ) : (
                                <TrashIcon />
                            )
                            } className='ms-auto ' size="xs" 
                      onClick={(e) => {
                        e.preventDefault(); // Prevent default link behavior if needed
                        e.stopPropagation(); // Stop the event from bubbling up
                        deleteNotification(notification.id)
                      }}/>
                      </div>
                      <p className="mb-0 text-muted">{formatNotificationDate(notification.created)}</p>
                    </div>
                  </Dropdown.Item>
                ))
              )}
          </Dropdown>
          </Badge>


          {/* Divider between icons */}
          <Divider vertical style={{ height: '40px', margin: '0 16px' }} />

          {/* User Dropdown */}
          <Dropdown
              title={
                <div className='text-start'>
                  <span>{auth ? auth.firstName + ' ' +auth.lastName : 'Laddar...'}  {/* Display 'Laddar...' if company is not loaded */}</span>
                  <br />
                  <small style={{ fontSize: '12px', color: '#888' }}>Superadmin</small>
                </div>
              }
              trigger={['hover', 'click']}  // Open on both hover and click
              placement="bottomEnd"
            >
                <Dropdown.Item onClick={logout}>
                  <i className='bi bi-box-arrow-left me-2 text-danger'></i> Logga ut
                </Dropdown.Item>
            </Dropdown>
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
