import React, { useState } from 'react';
import { Panel, Toggle, Badge, Divider, Nav, Button } from 'rsuite';
import { toast } from 'react-toastify';
import { useAuth } from '../../../AuthContext';

// Import rsuite icons
import EditIcon from '@rsuite/icons/Edit';
import Code from '@rsuite/icons/legacy/Code';

function ModuleCard({ module, dispatch, selectedFragment, setSelectedFragment, onModuleUpdate }) {
  const { auth } = useAuth();

  const toggleModuleStatus = async () => {
    let canActivate = false;

    switch (module.moduleType) {
      case 'Mejlutskick':
        canActivate = module.emailAccountId !== 0 && !!dispatch.subject && !!dispatch.content;
        break;
      case 'Chattbot':
      case 'E-post':
        canActivate = module.emailAccountId !== 0 && !!module.contactPerson;
        break;
      default:
        canActivate = true;
    }

    if (!canActivate && !module.active) {
      toast.error('Alla obligatoriska fält måste vara ifyllda innan modulen kan aktiveras.', {
        position: 'bottom-right',
      });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/customer/toggle-module`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
        body: JSON.stringify({
          id: module.id,
          active: !module.active,
        }),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right',
        });
        onModuleUpdate({
          ...module,
          active: !module.active,
          timestamp: Date.now(),
        });
      } else {
        toast.error(result.message, {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      toast.error('Ett fel uppstod vid uppdatering av modulstatus.', {
        position: 'bottom-right',
      });
    }
  };

  return (
    <div className='mb-2'>
      <Panel>
        <div className='d-flex align-items-center'>
          <h4 className='mb-0'>{module?.name}</h4>
        </div>
        <Divider style={{ margin: '10px 0' }} />

        <div>
          {module?.moduleType === 'Chattbot' && (
            <>
              {module.active ? (
                <Badge className="bg-success p-1 rounded" content="Chattboten visas på hemsidan" />
              ) : (
                <Badge className="bg-warning p-1 rounded" content="Chattboten visas inte på hemsidan" />
              )}
            </>
          )}

          {module?.moduleType === 'E-post' && (
            <>
              {module.active ? (
                <>
                  <Badge className="bg-success p-1 rounded" content="Modulen läser aktivt av inkorgen" />
                  <Badge
                    className="bg-secondary p-1 rounded"
                    content={`Fr. o.m. ${
                      module.timestamp
                        ? new Date(module.timestamp).toLocaleString('se-SE', {
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                          })
                        : 'N/A'
                    }`}
                  />
                </>
              ) : (
                <Badge className="bg-warning p-1 rounded" content="Modulen läser inte av inkorgen" />
              )}
            </>
          )}

          {module?.moduleType === 'Mejlutskick' && (
            <>
              {module?.finished ? (
                <Badge className="bg-success p-1 rounded" content="Färdigt mejlutskick" />
              ) : (
                <>
                  {module.active ? (
                    dispatch?.dispatchDate && new Date(dispatch.dispatchDate) > new Date() ? (
                      <Badge className="bg-warning p-1 rounded" content="Mejlutskicket inväntar startdatum" />
                    ) : (
                      <>
                        <Badge className="bg-success p-1 rounded" content="Mejlutskicket är aktivt" />
                        <p className="text-muted mb-0" style={{ fontStyle: 'italic' }}>
                          Vardagar 8:00-17:00
                        </p>
                      </>
                    )
                  ) : (
                    <Badge className="bg-warning p-1 rounded" content="Mejlutskicket är pausat" />
                  )}
                </>
              )}
            </>
          )}
        </div>

        <div className='mt-1 mb-1'>
          <Toggle
            checked={module.active}
            onChange={toggleModuleStatus}
            size="md"
            checkedChildren="Aktiv"
            unCheckedChildren="Inaktiv"
          />
        </div>

        <Divider style={{ margin: '10px 0' }} />

        {/* Navigation for module fragments */}
        <Nav
          vertical
          activeKey={selectedFragment}
          onSelect={(eventKey) => setSelectedFragment(eventKey)}
        >
          <Nav.Item eventKey="Översikt" className="card-title">
            <i className="bi bi-eye me-2"></i>
            Översikt
          </Nav.Item>

          {module.moduleType === 'Mejlutskick' && (
            <Nav.Item eventKey="Kontaktlista" className="card-title">
              <i className="bi bi-list-columns-reverse me-2"></i>
              Kontaktlista
            </Nav.Item>
          )}

          {(module?.moduleType === 'Chattbot' || module?.moduleType === 'E-post') && (
            <Nav.Item eventKey="Konversationer" className="card-title">
              <i className="bi bi-people-fill me-2"></i>
              Konversationer
            </Nav.Item>
          )}
        </Nav>

      </Panel>
    </div>
  );
}

export default ModuleCard;
