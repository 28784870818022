import React, { useState, useEffect } from 'react';
import ProjectCard from '../cards/ProjectCard';
import Modules from '../project/Modules';
import TrainingData from '../project/TrainingData';
import Functions from '../project/Functions';
import { useParams, useLocation, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import CommentsCard from '../../Comments';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../../AuthContext';
import { Loader } from 'rsuite';

const ContentWrapper = ({ children }) => (
  <div className='px-5 pt-4 w-100 p-3'>
    {children}
  </div>
);

function ProjectPage() {
  const { projectId } = useParams();
  const location = useLocation(); // Get location to access query parameters

  // Extract fragment from the query string
  const queryParams = new URLSearchParams(location.search);
  const fragmentFromUrl = queryParams.get('fragment'); // Get the 'fragment' query parameter

  const { auth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [selectedFragment, setSelectedFragment] = useState(fragmentFromUrl || 'Modules');
  const [project, setProject] = useState(null);
  const [company, setCompany] = useState(null);

  // Retrieve both onProjectDeleted and onProjectUpdate callbacks from the context
  const { onProjectDeleted, onProjectUpdated } = useOutletContext();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const projectResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/user/projects?id=${projectId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.token}`
          }
        });

        if (projectResponse.ok) {
          const projectData = await projectResponse.json();
          setProject(projectData.content[0]);
        } else {
          const errorText = await projectResponse.text();
          throw new Error(`Misslyckade att hämta projekt: ${errorText}`);
        }

        // Retrieve company from localStorage
        const storedCompany = localStorage.getItem('team');
        if (storedCompany) {
          setCompany(JSON.parse(storedCompany));
        } else {
          toast.error("Ingen team hittades i localStorage", {
            position: 'bottom-right'
          });
          throw new Error('Ingen team hittades i localStorage');
        }

        setLoading(false);
      } catch (error) {
        toast.error(error.message, {
          position: 'bottom-right'
        });
        setLoading(false);
      }
    };

    if (projectId) {
      fetchProject();
    }
  }, [projectId, auth.token]);

  const handleProjectUpdate = (updatedProject) => {
    console.log("get here")
    setProject(updatedProject);
    // Call the passed onProjectUpdate function from context
    onProjectUpdated();
  };

  return (
    <div className="d-flex">
        {loading ? (
          <ContentWrapper>
            <Loader speed='fast' /> Laddar...
          </ContentWrapper>
        ) : (
          <>
            <ContentWrapper>
              {selectedFragment === 'Modules' && project && company && <Modules company={company} project={project} />}
              {selectedFragment === 'TrainingData' && project && <TrainingData project={project} />}
              {selectedFragment === 'Funktioner' && project && <Functions project={project} />}
            </ContentWrapper>
            <div className="side-nav-right d-flex flex-column p-3">
              <ProjectCard 
                project={project} 
                selectedFragment={selectedFragment} 
                setSelectedFragment={setSelectedFragment} 
                onProjectUpdate={handleProjectUpdate} 
                onProjectDeleted={onProjectDeleted}
              />
              <CommentsCard 
                label={"projekt"}
                identifierId={project.id}
                maxHeight={500}
              />
            </div>
          </>
        )}
      </div>
  );
}

export default ProjectPage;
