import React, { useEffect, useState } from 'react';
import { Panel, Button, Input, Divider, IconButton, Loader } from 'rsuite';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../AuthContext';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import TrashIcon from '@rsuite/icons/Trash'; // React Suite Trash Icon
import PlusIcon from '@rsuite/icons/Plus';   // React Suite Plus Icon

function CommentsPage({ label, identifierId, maxHeight }) {
  const { auth } = useAuth();
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState('');
  const [loading, setLoading] = useState(false);
  const [addingComment, setAddingComment] = useState(false); // For adding new comments

  const [deletingRowIds, setDeletingRowIds] = useState([]);

  const fetchComments = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/comments?identifierId=${identifierId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setComments(data.content); // Assuming your backend returns the comments in a "content" field
      } else {
        const errorText = await response.text();
        throw new Error(`Misslyckades att hämta anteckningar: ${errorText}`);
      }
    } catch (error) {
      console.error('Misslyckades att hämta anteckningar: ', error);
      toast.error(error.message, {
        position: 'bottom-right'
      });
    }
  };

  const handleDelete = async (commentId) => {
    setDeletingRowIds((prev) => [...prev, commentId]);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/delete-comment`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify({ id: commentId })
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        setComments(comments.filter(comment => comment.id !== commentId));
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.error('Misslyckades att radera anteckning: ', error);
      toast.error('Misslyckades att radera anteckning: ', error, {
        position: 'bottom-right'
      });
    } finally {
      setDeletingRowIds((prev) => prev.filter((id) => id !== commentId));
    }
  };

  const updateComment = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevents the default newline insertion
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    if (commentText.trim() === '') {
      toast.error('Anteckning måste fyllas i.', {
        position: 'bottom-right'
      });
      return;
    }

    setAddingComment(true);
    const commentData = {
      identifierId,
      text: commentText
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/create-comment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}` // Include token in the request
        },
        body: JSON.stringify(commentData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        fetchComments(); // Update the comment list after submitting a new comment
        setCommentText(''); // Reset the comment text area
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Misslyckades att skapa anteckningar: ', error, {
        position: 'bottom-right'
      });
    } finally {
      setAddingComment(false);
    }
  };

  useEffect(() => {
    setLoading(true)
    fetchComments();
    setLoading(false)
  }, [label, identifierId]);

  return (
    <div>
      <Divider style={{margin : "10px 0"}}/>
      <Input
        as="textarea"
        rows={1}
        placeholder={"Antecka " + label + "..."}
        value={commentText}
        onChange={(value) => setCommentText(value)}
        onKeyDown={updateComment}
        style={{
          resize: 'vertical', // Allow vertical resizing only
          maxHeight: '250px',  // Maximum height for the textarea
          overflowY: 'auto'    // Add vertical scrollbar if needed
        }}
      />

      {/* Scrollable comments container */}
      <div
        className="scrollable-comments mt-1"
        style={{
          maxHeight: `${maxHeight}px`, // Maximum height for the comments container
          overflowY: 'auto',           // Enable scrolling
        }}
      >
        {loading ? (
          // Render skeletons as placeholders while loading
          <Skeleton height={100} className="mt-2" />
        ) : (
          <>
            {addingComment && (
              // Show skeleton only for adding a new comment
              <Skeleton height={100} className="mt-2" />
            )}
            {comments.length > 0 ? (
              comments.map((comment) => (
                <Panel key={comment.id} bordered className="mt-2 p-2">
                  <div className="d-flex mb-2">
                    <p className="text-muted mb-0">{comment.commenterName}</p>
                    <div className="ms-auto d-flex">
                      <p className="text-muted mb-0">
                        {new Date(comment.created).toLocaleString()}
                      </p>
                      <IconButton
                        icon={
                          deletingRowIds.includes(comment.id) ? <Loader speed='fast' /> : <TrashIcon />
                        }
                        size="xs"
                        onClick={() => handleDelete(comment.id)}
                        appearance="subtle"
                        style={{ marginLeft: '8px' }}
                      />
                    </div>
                  </div>
                  {/* Display the comment text with newlines */}
                  <p className="mb-0" style={{ whiteSpace: 'pre-wrap' }}>{comment.text}</p>
                </Panel>
              ))
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default CommentsPage;
