import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import NavBar from './CompanyNavBar';
import CompanyHeader from './CompanyHeader';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from '../../../AuthContext';
import { Loader } from 'rsuite';

const LayoutUser = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [navUpdateRequired, setNavUpdateRequired] = useState(false); // Unified state for both actions
  const [expanded, setExpanded] = useState(true); // State to manage Sidenav expansion

  // Function to handle both project deletion and update
  const handleProjectDeleted = () => {
    setNavUpdateRequired(true); // Mark that a project has been deleted or updated
    navigate('/user/dashboard'); // Redirect after deletion or update
  };
  
  const handleProjectUpdated = () => {
    setNavUpdateRequired(true); // Mark that a project has been deleted or updated
  };

  const handleTeamUpdated = () => {
    setNavUpdateRequired(true); // Mark that a project has been deleted or updated
  };

  const fetchLoggedInLicence = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/current-licence`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200 && response.data.content.length > 0) {
        setCompany(response.data.content[0]);
      } else {
        toast.error('Det gick inte att hämta licensen.', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      navigate('/login');
      toast.error('Det gick inte att hämta licensen.', {
        position: 'bottom-right',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth.token) {
      fetchLoggedInLicence();
    }
  }, [auth.token]);

  return (
    <div className="d-flex">
      {/* Pass expanded and setExpanded as props to NavBar */}
      <NavBar 
        company={company} 
        loading={loading} 
        navUpdateRequired={navUpdateRequired} 
        setNavUpdateRequired={setNavUpdateRequired}
        expanded={expanded} // Pass expanded state
        setExpanded={setExpanded} // Pass state update function
      />
      <div
        className="flex-grow-1 background-color"
        style={{
          marginLeft: expanded ? 240 : 56, // Adjust margin based on expanded state
          transition: 'margin-left 0.3s ease', // Smooth transition for margin
        }}
      >
        <CompanyHeader 
          company={company} 
          loading={loading} 
          onTeamUpdated={handleTeamUpdated} 
        />
        <div className="main-content">
          {!loading ? (
            <Outlet 
              context={{ 
                onProjectDeleted: handleProjectDeleted, 
                onProjectUpdated: handleProjectUpdated 
              }}
            />
          ) : (
            <div className='px-5 pt-4 w-100'>
              <Loader speed='fast' /> Laddar...
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LayoutUser;
