import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from 'rsuite';

function RegisterPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    if (!email || !password || !firstName || !lastName) {
      toast.error('Uppgifter måste fyllas i.', { position: 'bottom-right' });
      return;
    }
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, firstName, lastName }),
      });

      if (!response.ok) {
        throw new Error('Registreringen misslyckades, ' + response.status);
      }

      const data = await response.json();
      console.log('Register successful:', data);
      // Handle successful register (e.g., redirect to another page, store user info, etc.)

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='background-color'>
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className='card p-4 shadow-lg' style={{ width: '400px'}}>
          {error && <div className="alert alert-danger mb-0 mt-2">{error}</div>}
          <form onSubmit={handleSubmit}>
            <div className='mb-4'>
              <label className='form-label' htmlFor="email">E-post</label>
              <input
                className='form-control'
                type="text"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label className='form-label' htmlFor="password">Lösenord</label>
              <input
                className='form-control'
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <label className='form-label' htmlFor="firstName">Förnamn</label>
              <input
                className='form-control'
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
              <label className='form-label' htmlFor="lastName">Efternamn</label>
              <input
                className='form-control'
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            <button className='btn btn-primary w-100 d-flex justify-content-center align-items-center' type="submit" disabled={loading}>
              {loading ? (
                <>
                  <Loader className="me-2" /> Registrerar...
                </>
              ) : (
                'Registrera'
              )}
            </button>
          </form>
          <div className="mt-2">
             {/*<p className="copywritten-text">© 2024 Sonnet</p>*/} 
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
