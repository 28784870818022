import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import CubeIcon from '@rsuite/icons/legacy/Cube'; // Equivalent to moduler
import { Breadcrumb } from 'rsuite';

function LicenceOverview({ company }) {
    const { auth } = useAuth();
    const [loadingModules, setLoadingModules] = useState(true);
    const [loadingLeads, setLoadingLeads] = useState(true);
    const [modulesCount, setModulesCount] = useState(0);
    const [conversationsCount, setConversationsCount] = useState(0);
    const [leadsCount, setLeadsCount] = useState(0);

    // Fetch Modules
    const fetchModules = async () => {
        setLoadingModules(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/modules?licenceId=${company.licenceId}`, {
                headers: {
                    'Authorization': `Bearer ${auth.token}`,
                    'Content-Type': 'application/json',
                },
                params: {
                    size: 1000, // Increase limit to get more modules
                },
            });
            

            if (response.status === 200) {
                const modules = response.data.content;
                setModulesCount(modules.length);
            } else {
                toast.error('Misslyckades att hämta moduler.', {
                    position: 'bottom-right',
                });
            }
        } catch (error) {
            toast.error('Misslyckades att hämta moduler.', {
                position: 'bottom-right',
            });
        } finally {
            setLoadingModules(false);
        }
    };

    // Fetch Conversations and Leads
    const fetchConversationsAndLeads = async () => {
        setLoadingLeads(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/conversations?licenceId=${company.licenceId}`, {
                headers: {
                    'Authorization': `Bearer ${auth.token}`,
                    'Content-Type': 'application/json',
                },
                params: {
                    size: 1000, // Increase the limit to get more conversations
                },
            });

            if (response.status === 200) {
                const conversations = response.data.content;
                setConversationsCount(conversations.length);

                // Count the number of leads generated
                const leads = conversations.filter(conversation => conversation.leadGenerated === true).length;
                setLeadsCount(leads);
            } else {
                toast.error('Misslyckades att hämta konversationer.', {
                    position: 'bottom-right',
                });
            }
        } catch (error) {
            toast.error('Misslyckades att hämta konversationer.', {
                position: 'bottom-right',
            });
        } finally {
            setLoadingLeads(false);
        }
    };

    // Use useEffect to fetch the data when the component mounts
    useEffect(() => {
        fetchModules();
        fetchConversationsAndLeads();
    }, []);

    return (
        <>
        <Helmet>
        <title>{`${company?.companyName} - Översikt`}</title>
        </Helmet>
            <div className='d-flex'>
            <Breadcrumb className='mb-0'>
                <Breadcrumb.Item>
                    <Link to="/admin/kunder">Kunder</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{company?.companyName}</Breadcrumb.Item>
                <Breadcrumb.Item active>Översikt</Breadcrumb.Item>
            </Breadcrumb>
            </div>
            <hr />

            <div className="row mt-3">
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <div className="card shadow p-3">
                        <div className="d-flex align-items-center">
                            <CubeIcon className='me-2' />
                            <p className='mb-0'>Moduler</p>
                        </div>
                        <h2 className='mb-0'>
                            {loadingModules ? <Skeleton width={50} height={40} /> : modulesCount}
                        </h2>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <div className="card shadow p-3">
                        <div className="d-flex">
                            <i className="bi bi-people-fill me-2"></i>
                            <p className='mb-0'>Konversationer</p>
                        </div>
                        <h2 className='mb-0'>
                            {loadingLeads ? <Skeleton width={50} height={40} /> : conversationsCount}
                        </h2>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <div className="card shadow p-3">
                        <div className="d-flex">
                            <i className="bi bi-info-circle me-2"></i>
                            <p className='mb-0'>Leads</p>
                        </div>
                        <h2 className='mb-0'>
                            {loadingLeads ? <Skeleton width={50} height={40} /> : leadsCount}
                        </h2>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LicenceOverview;
