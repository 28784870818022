// AdminNavBar.jsx
import React from 'react';
import { useAuth } from '../../../AuthContext';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Sidenav, Nav, Divider } from 'rsuite';
import 'rsuite/dist/rsuite.css';

// Import icons from rsuite/icons
import DashboardIcon from '@rsuite/icons/legacy/Dashboard';
import KeyIcon from '@rsuite/icons/legacy/Key';
import SignOutIcon from '@rsuite/icons/legacy/SignOut';
import PageIcon from '@rsuite/icons/Page';

function NavBar({ expanded, setExpanded }) { // Receive props
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Function to handle logout
  const handleLogout = () => {
    logout();
    navigate('/login'); // Redirect to login page after logout
  };

  // Function to determine the active path
  const getActiveKey = () => {
    if (location.pathname.startsWith('/superadmin/dashboard')) {
      return 'dashboard';
    }
    if (location.pathname.startsWith('/superadmin/licenser')) {
      return 'licenser';
    }
    // Add more cases if needed
    return '';
  };

  const activeKey = getActiveKey();

  return (
    <div className="navbar-fixed">
      <Sidenav
        appearance="subtle"
        expanded={expanded} // Use expanded state to control the sidenav
        activeKey={activeKey}
        style={{ width: expanded ? 240 : 56, height: '100vh' }}
      >
        <Sidenav.Header>
          <div
            className="d-flex align-items-center justify-content-center text-center p-3"
            style={{ height: '50px', borderBottom: '1px solid #e5e5e5' }}
          >
            <h5 className="mb-0">
              {expanded ? 'Sonnet' : 'S'}
            </h5>
          </div>
        </Sidenav.Header>
        <Sidenav.Body>
          <Nav>
            {/* Dashboard Link */}
            <Nav.Item
              eventKey="dashboard"
              icon={<DashboardIcon />}
              as={Link}
              to="/superadmin/dashboard"
            >
              {expanded && 'Översikt'}
            </Nav.Item>

            {/* Licenser Link */}
            <Nav.Item
              eventKey="licenser"
              icon={<PageIcon />}
              as={Link}
              to="/superadmin/licenser"
            >
              {expanded && 'Licenser'}
            </Nav.Item>
          </Nav>
        </Sidenav.Body>
        {/* Toggle Button */}
        <Sidenav.Toggle onToggle={() => setExpanded(!expanded)} />
      </Sidenav>
    </div>
  );
}

export default NavBar;
