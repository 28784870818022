import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { Popover, Whisper, IconButton, Breadcrumb, Loader } from 'rsuite';

function ModulePersonality({ project, module, onModuleEdited }) { // Added onModuleEdited as prop
  const { auth } = useAuth(); // Get auth token from context
  const [personality, setPersonality] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (module) {
        setPersonality(module.personality);
    }
  }, [module]);

  const handleSave = async () => {
    setLoading(true);

    const moduleData = {
      id: module.id,
      personality,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/edit-module`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify(moduleData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        const updatedModule = { ...module, ...moduleData };
        onModuleEdited(updatedModule); // Use the merged module object
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.error('Misslyckades att spara ändringar: ', error);
      toast.error('Misslyckades att spara ändringar.', {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Helmet>
          <title>{`${module?.name} - Personlighet`}</title>
        </Helmet>
        <div className='d-flex'>

        <Breadcrumb className='mb-0'>
              <Breadcrumb.Item>
                <Link to={`/user/projekt/${project?.id}`}>{project?.name}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{module?.name}</Breadcrumb.Item>
              <Breadcrumb.Item active>Personlighetsbeskrivning</Breadcrumb.Item>
        </Breadcrumb>

        </div>
        <hr />

      <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Personlighet</h5>
            <p className='mb-2'>Här kan du ställa in vilken personlighet modulen ska använda.</p>
            <p className='mb-2'>Rekommenderade personligheter:</p>
            <ul>
              <li>Snäll</li>
              <li>Hjälpsam</li>
              <li>Serviceinriktad</li>
            </ul>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Personlighet
          </IconButton>
        </Whisper>

      <textarea
        className='form-control mb-2'
        id="role"
        value={personality}
        placeholder='Du har en ... personlighet'
        onChange={(e) => setPersonality(e.target.value)}
        rows="16"
      />
      <button className='btn btn-primary d-flex justify-content-center align-items-center' onClick={handleSave} disabled={loading}>
          {loading ? (
            <>
              <Loader className="me-2" /> Sparar...
            </>
          ) : (
            'Spara'
          )}
        </button>
    </>
  );
}

export default ModulePersonality;
