import React, { useEffect, useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Pagination } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Helmet } from 'react-helmet-async';
import { Badge, Loader, IconButton, Popover, Whisper, Breadcrumb } from 'rsuite';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import CreditCardPlusIcon from '@rsuite/icons/CreditCardPlus';
import TrashIcon from '@rsuite/icons/legacy/Trash';

function InvoicePage({ company }) {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const { auth } = useAuth();
  const [startingAfter, setStartingAfter] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);

  const [deletingRowIds, setDeletingRowIds] = useState([]);

  const fetchInvoices = async (newStartingAfter = null) => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/invoices?licenceId=${company.licenceId}&limit=10&startingAfter=${newStartingAfter || ''}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });

      if (response.status === 200) {
        const invoiceData = response.data;
        const invoicesList = invoiceData.invoices || [];
        const hasMore = invoiceData.has_more || false;

        console.log(invoicesList)
        setInvoices(invoicesList);
        setStartingAfter(invoicesList.length > 0 ? invoicesList[invoicesList.length - 1].id : null);
        setHasNextPage(hasMore);
        setHasPrevPage(!!newStartingAfter);
      } else {
        toast.error('Misslyckades att hämta fakturor', { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Misslyckades att hämta fakturor:', error);
      toast.error('Misslyckades att hämta fakturor', { position: 'bottom-right' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (company) {
      fetchInvoices();
    }
  }, [company]);
  

  const handleDeleteInvoice = async (invoiceId) => {
    if (!window.confirm('Är du säker på att du vill upphäva fakturan?')) {
      return; // User canceled the deletion
  }
    // Set the loading state to true for the specific invoice
    setDeletingRowIds((prev) => [...prev, invoiceId]);

    try {
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/admin/void-invoice?invoiceId=${invoiceId}&licenceId=${company.licenceId}`, {
        headers: {
          'Authorization': `Bearer ${auth.token}`
        }
      });

      if (response.status === 200) {
        toast.warning(response.data.message, { position: 'bottom-right' });
        setInvoices(invoices.filter(invoice => invoice.id !== invoiceId)); // Remove voided invoice from the list
      } else {
        toast.error('Borttagning misslyckades.', { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Borttagning misslyckades:', error);
      toast.error('Borttagning misslyckades.', { position: 'bottom-right' });
    } finally {
      setDeletingRowIds((prev) => prev.filter((id) => id !== invoiceId));
    }
  };

  const handleNextPage = () => {
    fetchInvoices(startingAfter);
  };

  const handlePrevPage = () => {
    fetchInvoices(null);
  };

  return (
    <>
    <Helmet>
          <title>{`${company?.companyName} - Fakturor`}</title>
        </Helmet>
      <div className='d-flex'>
      <Breadcrumb className='mb-0'>
                <Breadcrumb.Item>
                    <Link to="/admin/kunder">Kunder</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{company?.companyName}</Breadcrumb.Item>
                <Breadcrumb.Item active>Fakturor</Breadcrumb.Item>
            </Breadcrumb>
      </div>
      <hr />

      <Whisper
        placement="rightStart"
        trigger="click"
        speaker={<Popover>
            <h5>Fakturor</h5>
            <p className='mb-2'>Här kan du se licensens alla fakturor.</p>
            </Popover>}
        > 
        <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
        Fakturor
        </IconButton>
      </Whisper>

      {loading ? (
          <>
            <Skeleton height={50} />  
          </>
      ) : (
        <>
        <div className="mb-2">
        <ul className="list-group">
          {invoices.map((invoice) => (
            <li className="list-group-item d-flex justify-content-between align-items-center" key={invoice.id}>
              Faktura #{invoice.number}
              <div className="d-flex align-items-center">
                {invoice.status === 'open' && (
                  <Badge content={`Förfaller ${new Date(invoice.dueDate * 1000).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}`} className="bg-secondary p-1 rounded me-2" />
                )}

                <Badge p-1 rounded content={
                      invoice.status === 'paid' ? 'Betalad' : 
                      invoice.status === 'open' ? 'Obetalad' : 
                      invoice.status === 'void' ? 'Upphävd' : invoice.status
                    }
                      className={`me-2 p-1 rounded me-2 ${invoice.status === 'paid' ? 'bg-success' : 
                        invoice.status === 'open' ? 'bg-warning' : 
                        invoice.status === 'void' ? 'bg-danger' : 'bg-secondary'}`} 
                    />

                <IconButton
                      icon={<FileDownloadIcon />}
                      appearance="subtle"
                      onClick={() => window.open(invoice.invoicePdf)}  // Wrap in an arrow function
                      title="Ladda ner faktura"
                    />
                    {invoice.status === 'open' && (
                      <IconButton
                      icon={<CreditCardPlusIcon />}
                      appearance="subtle"
                      onClick={() => window.open(invoice.hostedInvoiceUrl, '_blank')}  // Wrap in an arrow function
                      title="Betala faktura"
                    />
                    )}
                {invoice.status !== 'paid' && invoice.status !== 'void' && (
                    <>
                    <IconButton
                      icon={
                        deletingRowIds.includes(invoice.id) ? (  // Check if the module is being deleted
                          <Loader speed='fast' size="sm" />
                        ) : (
                          <TrashIcon />
                        )
                      }
                      appearance="subtle"
                      onClick={() => handleDeleteInvoice(invoice.id)}  // Wrap in an arrow function
                      title="Upphäv faktura"
                      disabled={deletingRowIds.includes(invoice.id)}
                    />
                </>
                )}
              </div>
            </li>
          ))}
          {invoices.length === 0 && (
                  <p className='mt-4 text-center'>Inga fakturor hittades.</p>
              )}
        </ul>
      </div>

      <div className="d-flex my-4 justify-content-center">
        <Pagination>
          <Pagination.Prev onClick={handlePrevPage} disabled={!hasPrevPage} />
          <Pagination.Next onClick={handleNextPage} disabled={!hasNextPage} />
        </Pagination>
      </div>
        </>
      )}

      
    </>
  );
}

export default InvoicePage;
