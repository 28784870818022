// LayoutAdmin.jsx
import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import NavBar from './AdminNavBar'; // Adjust the import path as necessary
import AdminHeader from './AdminHeader'; // Adjust the import path as necessary
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';
import { useAuth } from '../../../AuthContext';
import axios from 'axios';

const LayoutAdmin = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(true);
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchLoggedInLicence = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/current-licence`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200 && response.data.content.length > 0) {
        setCompany(response.data.content[0]);
      } else {
        toast.error('Det gick inte att hämta kunden.', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      navigate('/login');
      toast.error('Det gick inte att hämta kunden.', {
        position: 'bottom-right',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth.token) {
      fetchLoggedInLicence();
    }
  }, [auth.token]);

  return (
    <div className="d-flex">
      {/* Pass expanded and setExpanded as props to NavBar */}
      <NavBar company={company} expanded={expanded} setExpanded={setExpanded} />
      <div
        className="flex-grow-1 background-color"
        style={{
          marginLeft: expanded ? 240 : 56, // Adjust margin based on expanded state
          transition: 'margin-left 0.3s ease', // Smooth transition
        }}
      >
        <AdminHeader company={company} />
        {/* Outlet to render child routes */}
        <div className="main-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default LayoutAdmin;
