import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from '../AuthContext'; // Import useAuth
import { jwtDecode } from 'jwt-decode'; // Import jwt-decode
import { Helmet } from 'react-helmet-async';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from 'rsuite';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate
  const { login } = useAuth(); // Destructure login from useAuth

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        if(response.status === 401){
          toast.error("Ogiltiga inloggningsuppgifter.", {
            position: 'bottom-right'
          });
        }
        else{
          toast.error("Inloggning misslyckades: " + response.status, {
            position: 'bottom-right'
          });
        }
        throw new Error('Inloggning misslyckades: ' + response.status);
      }

      const data = await response.json();
      localStorage.setItem('token', data.token);

      // Decode the token to get user role
      const decoded = jwtDecode(data.token);

      // Update auth state
      login(data.token);


      // Navigate to dashboard based on role
      if (decoded.role === 'ROLE_ADMIN') {
        navigate('/admin/dashboard');
        toast.success("Välkommen tillbaka, " + decoded.firstName + "! 👋", {
          position: 'bottom-right'
        });
      } else if(decoded.role === 'ROLE_USER') {
        navigate('/user/dashboard');
        toast.success("Välkommen tillbaka, " + decoded.firstName + "! 👋", {
          position: 'bottom-right'
        });
      }
      else if(decoded.role === 'ROLE_CUSTOMER') {
        navigate('/customer/dashboard');
        toast.success("Välkommen tillbaka, " + decoded.firstName + "! 👋", {
          position: 'bottom-right'
        });
      } else if(decoded.role === 'ROLE_SUPERADMIN') {
        navigate('/superadmin/dashboard');
        toast.success("Välkommen tillbaka, " + decoded.firstName + "! 👋", {
          position: 'bottom-right'
        });
      }
      else if(decoded.role === 'ROLE_NONE') {
        toast.error("Kontot finns inte.", {
          position: 'bottom-right'
        });
      }

    } catch (error) {
      toast.error("Inloggning misslyckades: " + error, {
        position: 'bottom-right'
      });
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Helmet>
          <title>{`Logga in`}</title>
        </Helmet>
    <div className='background-color'>
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className='card p-4 shadow-lg' style={{ width: '400px'}}>
          <form onSubmit={handleSubmit}>
            <div className='mb-4'>
              <label className='form-label' htmlFor="email">E-post</label>
              <input
                className='form-control'
                type="text"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required/>
              <label className='form-label' htmlFor="password">Lösenord</label>
              <input
                className='form-control'
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button className='btn btn-primary w-100 mb-2 d-flex justify-content-center align-items-center' type="submit" disabled={loading}>
              {loading ? (
                <>
                  <Loader className="me-2" /> Loggar in...
                </>
              ) : (
                'Logga in'
              )}
            </button>
            <Link to="/forgot-password">
              <p className='mb-0'>Återställ lösenord</p>
            </Link>
          </form>
          <div className="mt-2">
            {/*<p className="copywritten-text">© 2024 Sonnet</p>*/} 
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default LoginPage;
