import React, { useEffect, useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Table, IconButton, Input, InputNumber, DatePicker, Checkbox, Popover, Whisper, Breadcrumb } from 'rsuite';
import TrashIcon from '@rsuite/icons/Trash';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';

function ModulePrices({ company }) {
    const { auth } = useAuth();
    const [loading, setLoading] = useState(false);
    const [modules, setModules] = useState([]);
    const [selectedModules, setSelectedModules] = useState(new Set()); // Track selected modules
    const [hourlyRate, setHourlyRate] = useState(500); // Default hourly rate
    const [hoursWorked, setHoursWorked] = useState(0); // Default hours worked

    // Update the getOneMonthAhead function
    const getOneMonthAhead = () => {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() + 1);
        return currentDate; // Return Date object
    };

    // Update the dueDate state to store a Date object
    const [dueDate, setDueDate] = useState(getOneMonthAhead());

    // Function to fetch data from the API
    const fetchUsageData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/usage?invoiceGenerated=false`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`
                },
                params: {
                    licenceId: company.licenceId,
                    invoiceGenerated: false,
                    page: 0,
                    size: 100
                }
            });

            const mappedModules = response.data.content.map(item => ({
                id: item.id,
                moduleId: item.moduleId,
                projectId: item.projectId,
                completionTokens: item.completionTokens,
                promtTokens: item.promtTokens,
                totalTokens: item.totalTokens,
                fileGenerationType: item.fileGenerationType,
                companyId: item.companyId,
                licenceId: item.licenceId,
                adminLicenceId: item.adminLicenceId,
                moduleName: item.moduleName,
                moduleType: item.moduleType,
                timePeriod: item.timePeriod,
                tokenPrice: item.tokenPrice,
                flatPrice: item.flatPrice,
                total: item.totalTokens * item.tokenPrice + item.flatPrice
            }));
            setModules(mappedModules);
        } catch (error) {
            console.error('Misslyckades att hämta användardata: ', error);
        } finally {
            setLoading(false);
        }
    };

  // Function to delete selected modules
  const deleteModule = async (module) => {
    if (!window.confirm('Är du säker på att du vill radera denna intäkt?')) {
        return; // User canceled the deletion
    }

    setLoading(true);
    try {
        // Remove the module from the modules array
        const remainingModules = modules.filter(
            (m) => (m.id ?? m.tempId) !== (module.id ?? module.tempId)
        );

        // If the module has an ID, delete it from the backend
        if (module.id) {
            try {
                const response = await axios.delete(
                    `${process.env.REACT_APP_BASE_URL}/admin/delete-tokentracker`,
                    {
                        headers: {
                            Authorization: `Bearer ${auth.token}`,
                        },
                        params: { id: module.id },
                    }
                );

                if (response.status === 200) {
                    toast.warning(response.data.message, { position: 'bottom-right' });
                } else {
                    toast.error('Borttagning misslyckades. Försök igen.', {
                        position: 'bottom-right',
                    });
                }
            } catch (error) {
                console.error(`Misslyckades att radera token tracker med id ${module.id}: `, error);
                toast.error(
                    `Det gick inte att radera token med id ${module.id}: ${
                        error.response?.data?.message || error.message
                    }`,
                    { position: 'bottom-right' }
                );
            }
        }

        // Update the state with the remaining modules
        setModules(remainingModules);

        // Remove from selectedModules if necessary
        const key = module.id ?? module.tempId;
        const updatedSelectedModules = new Set(selectedModules);
        updatedSelectedModules.delete(key);
        setSelectedModules(updatedSelectedModules);
    } catch (error) {
        console.error('Misslyckades att ta bort token tracker: ', error);
    } finally {
        setLoading(false);
    }
};




    useEffect(() => {
        fetchUsageData();
    }, [company]);

    // Function to handle input changes in the table
    const handleInputChange = (index, field, value) => {
        const updatedModules = [...modules];
    
        // Ensure that tokenPrice, flatPrice, and totalTokens never go below 0, and default to 0 if empty
        if (field === 'tokenPrice' || field === 'flatPrice' || field === 'totalTokens') {
            const numericValue = parseFloat(value);
            updatedModules[index][field] = isNaN(numericValue) || numericValue < 0 ? 0 : numericValue;
        } else {
            updatedModules[index][field] = value;
        }
    
        // If moduleName becomes empty, remove the module from selectedModules
        if (field === 'moduleName' && value.trim() === '') {
            const updatedSelectedModules = new Set(selectedModules);
            updatedSelectedModules.delete(updatedModules[index].id);
            setSelectedModules(updatedSelectedModules);
        }
    
        // Ensure total is calculated correctly even if tokenPrice, flatPrice, or totalTokens are empty or 0
        updatedModules[index].total =
            (updatedModules[index].totalTokens || 0) * (updatedModules[index].tokenPrice || 0) + (parseFloat(updatedModules[index].flatPrice) || 0);
    
        setModules(updatedModules);
    };
    


    

    const handleCheckboxChange = (moduleId, tempId) => {
        const updatedSelectedModules = new Set(selectedModules);
    
        const key = moduleId ?? tempId; // Use moduleId if available, otherwise tempId
    
        if (updatedSelectedModules.has(key)) {
            updatedSelectedModules.delete(key); // Deselect
        } else {
            updatedSelectedModules.add(key); // Select
        }
    
        setSelectedModules(updatedSelectedModules);
    };
    

    const addNewRow = () => {
        const newModule = {
            id: null, // Keep this as null for backend
            tempId: `temp-${Date.now()}`, // Unique temporary ID for UI tracking
            moduleName: '',
            timePeriod: '',
            totalTokens: 0,
            tokenPrice: 0.0005,
            flatPrice: 0,
            total: 0
        };
        setModules([...modules, newModule]);
    };

    // Function to send the invoice
    const sendInvoice = async () => {
        // Check if at least one checkbox is selected or both hourlyRate and hoursWorked are greater than 0
        if (
            selectedModules.size === 0 && 
            (parseFloat(hourlyRate) <= 0 || isNaN(hourlyRate)) && 
            (parseFloat(hoursWorked) <= 0 || isNaN(hoursWorked))
        ) {
            toast.error('Fakturering misslyckades: Kontrollera att du har markerat moduler eller angett timkostnad och timmar.', {
                position: 'bottom-right'
            });
            return;
        }
        
        
        if (dueDate.setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) {
            toast.error('Fakturering misslyckades: Förfallodatumet måste vara efter dagens datum.', {
                position: 'bottom-right'
            });
            return;
        }
        
        
    
        setLoading(true);
        try {
            // Filter the selected modules to send to the backend
            const selectedTokenTrackers = modules.filter(module => selectedModules.has(module.id ?? module.tempId));
    
            const invoiceRequest = {
                hourlyRate: parseFloat(hourlyRate),
                hoursWorked: parseFloat(hoursWorked),
                dueDate,
                licenceId: company.licenceId,
                adminLicenceId: company.adminLicenceId,
                tokenTrackerList: selectedTokenTrackers
            };
    
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/create-invoice`, invoiceRequest, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`
                }
            });
    
            toast.success(response.data.message, {
                position: 'bottom-right'
            });
            // Clear selected modules
            setSelectedModules(new Set());
            fetchUsageData();
        } catch (error) {
            console.error('Misslyckades att skicka faktura: ', error);
            toast.error(`${error.response?.data?.message || error.message}`, {
                position: 'bottom-right'
            });
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <>
        <Helmet>
        <title>{`${company?.companyName} - Skapa faktura`}</title>
        </Helmet>
            <div className='d-flex'>
                <Breadcrumb className='mb-0'>
                    <Breadcrumb.Item>
                        <Link to="/admin/kunder">Kunder</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{company?.companyName}</Breadcrumb.Item>
                    <Breadcrumb.Item active>Skapa faktura</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <hr />

            <Whisper
                placement="rightStart"
                trigger="click"
                speaker={<Popover>
                    <h5>Skapa faktura</h5>
                    <p className='mb-2'>Här skapar du en faktura åt kunden.</p>
                    </Popover>}
                > 
                <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
                Skapa faktura
                </IconButton>
                </Whisper>

            <div>
                {/* Hours Worked and Hour Rate Inputs */}
                <div className="d-flex mb-2 align-items-end">
                    <div className='me-2'>
                        <label htmlFor="hoursWorked">Timmar jobbat</label>
                        <InputNumber
                            id="hoursWorked"
                            value={hoursWorked}
                            onChange={setHoursWorked}
                            min={0}
                            placeholder="Timmar jobbat"
                            className="w-100"
                        />
                    </div>
                    <div className='me-2'>
                        <label htmlFor="hourRate">Timpris</label>
                        <InputNumber
                            id="hourRate"
                            value={hourlyRate}
                            onChange={setHourlyRate}
                            min={0}
                            step={1}
                            placeholder="Timpris"
                            className="w-100"
                        />
                    </div>
                    <div>
                        <label htmlFor="dueDate">Sista betalningsdag</label>
                        <DatePicker
                            id="dueDate"
                            value={dueDate}
                            onChange={(value) => setDueDate(value)}
                            format="yyyy-MM-dd"
                            oneTap
                            placeholder="Sista betalningsdag"
                            className="w-100"
                        />
                    </div>
                </div>

                {loading ? (
                    <>
                        <Skeleton height={50} className='mb-2' />  
                    </>
                ) : (
                    <>
                    <div className='mb-2'>
                {modules.length > 0 && (
                    <Table autoHeight data={modules} bordered={false} cellBordered={false} rowHeight={60} className='rounded'>
                        {/* First column: Checkbox */}
                        <Table.Column width={50}>
                            <Table.HeaderCell>
                                <Checkbox disabled /> {/* Placeholder Checkbox */}
                            </Table.HeaderCell>
                            <Table.Cell>
                                {(rowData, rowIndex) => (
                                    <Checkbox
                                        checked={selectedModules.has(rowData.id ?? rowData.tempId)}
                                        onChange={() => handleCheckboxChange(rowData.id, rowData.tempId)}
                                        disabled={rowData.moduleName.trim() === ''}
                                    />
                                )}
                            </Table.Cell>
                        </Table.Column>

                        {/* Second column: Namn */}
                        <Table.Column width={200} flexGrow={1}>
                            <Table.HeaderCell>Namn</Table.HeaderCell>
                            <Table.Cell>
                                {(rowData, rowIndex) => (
                                    <Input
                                        value={rowData.moduleName}
                                        onChange={(value) => handleInputChange(rowIndex, 'moduleName', value)}
                                        placeholder="Modul"
                                    />
                                )}
                            </Table.Cell>
                        </Table.Column>

                        {/* Third column: Period */}
                        <Table.Column width={150} flexGrow={1}>
                            <Table.HeaderCell>Period</Table.HeaderCell>
                            <Table.Cell>
                                {(rowData) => (
                                    <p className="form-control-plaintext">
                                        {rowData.timePeriod
                                            ? new Date(rowData.timePeriod).toLocaleDateString(undefined, {
                                                year: 'numeric',
                                                month: '2-digit',
                                            })
                                            : ''}
                                    </p>
                                )}
                            </Table.Cell>
                        </Table.Column>

                        {/* Fourth column: Tokens */}
                        <Table.Column width={150} flexGrow={1}>
                            <Table.HeaderCell>Tokens</Table.HeaderCell>
                            <Table.Cell>
                                {(rowData, rowIndex) => (
                                    <InputNumber
                                        value={rowData.totalTokens}
                                        onChange={(value) => handleInputChange(rowIndex, 'totalTokens', value)}
                                        min={0}
                                        placeholder="Tokens"
                                        className="w-100"
                                    />
                                )}
                            </Table.Cell>
                        </Table.Column>

                        {/* Fifth column: Pris per token (SEK) */}
                        <Table.Column width={150} flexGrow={1}>
                            <Table.HeaderCell>Pris per token (SEK)</Table.HeaderCell>
                            <Table.Cell>
                                {(rowData, rowIndex) => (
                                    <InputNumber
                                        value={rowData.tokenPrice}
                                        onChange={(value) => handleInputChange(rowIndex, 'tokenPrice', value)}
                                        min={0}
                                        step={0.0001}
                                        placeholder="Pris per token"
                                        className="w-100"
                                    />
                                )}
                            </Table.Cell>
                        </Table.Column>

                        {/* Sixth column: Fast pris (SEK) */}
                        <Table.Column width={150} flexGrow={1}>
                            <Table.HeaderCell>Fast pris (SEK)</Table.HeaderCell>
                            <Table.Cell>
                                {(rowData, rowIndex) => (
                                    <InputNumber
                                        value={rowData.flatPrice}
                                        onChange={(value) => handleInputChange(rowIndex, 'flatPrice', value)}
                                        min={0}
                                        placeholder="Fast pris"
                                        className="w-100"
                                    />
                                )}
                            </Table.Cell>
                        </Table.Column>

                        {/* Seventh column: Totalt (SEK) */}
                        <Table.Column width={150} flexGrow={1}>
                            <Table.HeaderCell>Totalt (SEK)</Table.HeaderCell>
                            <Table.Cell>
                                {(rowData) => `${rowData.total.toFixed(2)} SEK`}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column width={40} flexGrow={1} className="text-end">
                            <Table.HeaderCell>Radera</Table.HeaderCell>
                            <Table.Cell>
                                {(rowData) => (
                                    <IconButton
                                        icon={<TrashIcon />}
                                        appearance="subtle"
                                        onClick={() => deleteModule(rowData)}
                                        disabled={loading}
                                    />
                                )}
                            </Table.Cell>
                        </Table.Column>
                    </Table>
                )}
            </div>
                    </>
                )}

 

        <div className="d-flex align-items-center">
            <button className="btn btn-secondary me-2" onClick={addNewRow} disabled={loading}>
                    <i className="bi bi-plus-circle me-2"></i>Lägg till ny rad
            </button>

            <button 
                className='btn btn-primary me-2' 
                onClick={sendInvoice} 
                disabled={
                    loading || (
                        selectedModules.size === 0 && (
                            parseFloat(hourlyRate) <= 0 || isNaN(parseFloat(hourlyRate)) ||
                            parseFloat(hoursWorked) <= 0 || isNaN(parseFloat(hoursWorked))
                        )
                    ) || dueDate.setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)
                }
            >

                <i className="bi bi-receipt me-2"></i>
                {loading ? 'Skickar faktura...' : 'Skicka faktura'}
            </button>
        </div>


    </div>
    </>
    );
}

export default ModulePrices;
