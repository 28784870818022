import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from 'rsuite';

function CreateDomain({ show, handleClose, onDomainCreated }) {
  const { auth } = useAuth();
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);

  // Reset the form when the modal is closed
  useEffect(() => {
    if (!show) {
        setUrl('');
      setLoading(false); // Reset loading state if needed
    }
  }, [show]);

  const handleSubmit = async () => {
    if (!url) {
      toast.error('Domän måste fyllas i.', {
        position: 'bottom-right'
      });
      return;
    }

    setLoading(true);
    const domainData = {
      url,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/create-domain`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}` // Include token in the request
        },
        body: JSON.stringify(domainData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        handleClose();
        onDomainCreated();
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Misslyckades att skapa domän: ', error, {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>Ny domän</Modal.Title>
      </Modal.Header>
      <Modal.Body>
  <div className='mb-2'>
    <label htmlFor="url" className='form-label'>Domän</label>
    <input
      type="text"
      className='form-control'
      id="url"
      placeholder='domän.se'
      value={url}
      onChange={(e) => setUrl(e.target.value)}
    />
  </div>
</Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Stäng
        </Button>
        <Button variant="primary" className="d-flex justify-content-center align-items-center" onClick={handleSubmit} disabled={loading}>
            {loading ? (
              <>
                <Loader className="me-2" /> Sparar...
              </>
            ) : (
              'Spara'
            )}
          </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateDomain;
