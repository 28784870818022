import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { Popover, Whisper, IconButton, Breadcrumb, Loader } from 'rsuite';

function ModuleIgnoreConditions({ project, module, onModuleEdited }) {
  const { auth } = useAuth();
  const [ignoreConditions, setIgnoreConditions] = useState(['']); // Initialize with a single empty string
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (module) {
      setIgnoreConditions(module.ignoreConditions.length ? module.ignoreConditions : ['']);
    }
  }, [module]);
  

  const handleSave = async () => {
    const validConditions = ignoreConditions.filter(condition => condition.trim() !== '');
  
    setLoading(true);
  
    const moduleData = {
      id: module.id,
      ignoreConditions: validConditions,  // Send as a list
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/edit-module`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify(moduleData),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        const updatedModule = { ...module, ...moduleData };
        onModuleEdited(updatedModule); // Use the merged module object
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.error('Misslyckades att spara ändringar: ', error);
      toast.error('Misslyckades att spara ändringar.', {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  const addCondition = () => {
    setIgnoreConditions([...ignoreConditions, '']);
  };

  const removeCondition = (index) => {
    setIgnoreConditions(ignoreConditions.filter((_, i) => i !== index));
  };

  const handleConditionChange = (index, value) => {
    const newConditions = [...ignoreConditions];
    newConditions[index] = value;
    setIgnoreConditions(newConditions);
  };

  return (
    <>
    <Helmet>
          <title>{`${module?.name} - Ignorera villkor`}</title>
        </Helmet>
        <div className='d-flex'>

        <Breadcrumb className='mb-0'>
              <Breadcrumb.Item>
                <Link to={`/user/projekt/${project?.id}`}>{project?.name}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{module?.name}</Breadcrumb.Item>
              <Breadcrumb.Item active>Ignorera villkor</Breadcrumb.Item>
        </Breadcrumb>

        </div>
        <hr />

      <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover style={{maxWidth: '500px'}}>
            <h5>Mejl att ignorera</h5>
            <p className='mb-2'>Här kan du specifiera vilka mejl som modulen ska undvika att svara på.</p>
            <p className='mb-2'>Detta behövs för att inte hamna i loopar med autosvar, eller bara undvika mejl av andra anledningar.</p>

            <p className='mb-2'>Hårdkodade standardvillkor för mejl att undvika:</p>
            <ul>
              <li>The content appears to be an autoreply or procedural response. Examples include phrases like 'case number', 'we will get back to you', 'will be back', or 'thank you for contacting us'. These should not be considered engaging.</li>
              <li>The email is from a 'noreply' address.</li>
              <li>The content looks autogenerated or uses generic language with no personalization.</li>
            </ul>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Mejl att ignorera
          </IconButton>
        </Whisper>

      {ignoreConditions.map((condition, index) => (
        <div key={index} className="mb-3">
          <div className="d-flex w-50">
          <textarea
              id={`condition-${index}`}
              className="form-control me-2"
              value={condition}
              rows={4}
              placeholder='Mejl som innehåller någon form av...'
              onChange={(e) => handleConditionChange(index, e.target.value)}
          />
            <div>
            <button
              className="btn btn-danger"
              onClick={() => removeCondition(index)}
              disabled={ignoreConditions.length === 1} // Prevent removing the last input field
            >
              <i className="bi bi-trash"></i>
            </button>
            </div>
          </div>
        </div>
      ))}

      <div className="d-flex">
      <button className="btn btn-secondary me-2" onClick={addCondition}>
        <i className="bi bi-plus"></i> Lägg till rad
      </button>

        <button className='btn btn-primary d-flex justify-content-center align-items-center' onClick={handleSave} disabled={loading}>
          {loading ? (
            <>
              <Loader className="me-2" /> Sparar...
            </>
          ) : (
            'Spara'
          )}
        </button>
      </div>
    </>
  );
}

export default ModuleIgnoreConditions;
