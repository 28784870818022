import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { Popover, Whisper, IconButton, Breadcrumb, Loader } from 'rsuite';

function ModuleBlocklist({ project, module, onModuleEdited }) { 
  const { auth } = useAuth();
  const [blocklist, setBlocklist] = useState(['']); // Initialize with a single empty string
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (module) {
      setBlocklist(module.blocklist.length ? module.blocklist : ['']);
    }
  }, [module]);

  const handleSave = async () => {
    const validBlocklist = blocklist.filter(condition => condition.trim() !== '');
  
    setLoading(true);
  
    const moduleData = {
      id: module.id,
      blocklist: validBlocklist,  // Send as a list
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/edit-module`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify(moduleData),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        const updatedModule = { ...module, ...moduleData };
        onModuleEdited(updatedModule); // Use the merged module object
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.error('Misslyckades att spara ändringar: ', error);
      toast.error('Misslyckades att spara ändringar.', {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  const addCondition = () => {
    setBlocklist([...blocklist, '']);
  };

  const removeCondition = (index) => {
    setBlocklist(blocklist.filter((_, i) => i !== index));
  };

  const handleConditionChange = (index, value) => {
    const newBlocklist = [...blocklist];
    newBlocklist[index] = value;
    setBlocklist(newBlocklist);
  };

  return (
    <>
    <Helmet>
          <title>{`${module?.name} - Blocklista`}</title>
        </Helmet>
        <div className='d-flex'>

        <Breadcrumb className='mb-0'>
              <Breadcrumb.Item>
                <Link to={`/user/projekt/${project?.id}`}>{project?.name}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{module?.name}</Breadcrumb.Item>
              <Breadcrumb.Item active>Blocklista</Breadcrumb.Item>
        </Breadcrumb>

        </div>
        <hr />

      <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Blockera e-poster</h5>
            <p className='mb-2'>Här kan du specifiera e-postadresser som modulen inte får svara på.</p>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Blockera e-poster
          </IconButton>
        </Whisper>

      {blocklist.map((condition, index) => (
        <div key={index} className="mb-3">
          <div className="d-flex w-50">
            <input
              id={`blocklist-${index}`}
              type="text"
              className="form-control me-2"
              value={condition}
              placeholder='namn@namnsson.se'
              onChange={(e) => handleConditionChange(index, e.target.value)}
            />
            <button
              className="btn btn-danger"
              onClick={() => removeCondition(index)}
              disabled={blocklist.length === 1} // Prevent removing the last input field
            >
              <i className="bi bi-trash"></i>
            </button>
          </div>
        </div>
      ))}

      <div className="d-flex">
      <button className="btn btn-secondary me-2" onClick={addCondition}>
        <i className="bi bi-plus"></i> Lägg till rad
      </button>

      <button className='btn btn-primary d-flex justify-content-center align-items-center' onClick={handleSave} disabled={loading}>
          {loading ? (
            <>
              <Loader className="me-2" /> Sparar...
            </>
          ) : (
            'Spara'
          )}
        </button>
      </div>
    </>
  );
}

export default ModuleBlocklist;
