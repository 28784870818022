import React, { useState } from 'react';
import { Panel, IconButton, Toggle, Button, Badge, Divider, Nav } from 'rsuite';
import { toast } from 'react-toastify';
import { useAuth } from '../../../AuthContext';
import EditModuleModal from '../../../modals/company/project/EditModule';
import EditLabelModal from '../../../modals/company/module/EditLabel';
import ChatModal from '../../../modals/company/module/TestChat';
import TokenTracker from '../../../modals/company/general/TokenTracker';
import { FaRedo } from 'react-icons/fa';

// Import icons from @rsuite/icons
import EditIcon from '@rsuite/icons/Edit';
import { IoChatbubbleOutline } from "react-icons/io5";


function ModuleCard({ dispatch, module, selectedFragment, setSelectedFragment, onModuleUpdate }) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const [showLabelModal, setShowLabelModal] = useState(false);

  const { auth } = useAuth();

  const handleEditModalClose = () => setShowEditModal(false);
  const handleEditModalShow = () => setShowEditModal(true);

  const handleChatModalClose = () => setShowChatModal(false);
  const handleChatModalShow = () => setShowChatModal(true);

  const handleLabelModalClose = () => setShowLabelModal(false);
  const handleLabelModalShow = () => setShowLabelModal(true);

  const handleCopyScriptTag = () => {
    const currentUrl = window.location.origin;

    const scriptTag = `<script
      id="chatbot-script"
      src="${currentUrl}/dist/chatbot.bundle.js"
      data-container-id="chatbot-container"
      data-chatbot-id="${module?.id}"
      data-base-url="${process.env.REACT_APP_BASE_URL}">
      </script>`;

    navigator.clipboard.writeText(scriptTag).then(() => {
      toast.success('Script taggen kopierades!', { position: 'bottom-right' });
    }).catch(() => {
      toast.error('Det gick inte att kopiera script taggen.', { position: 'bottom-right' });
    });
  };

  const toggleModuleStatus = async () => {
    let canActivate = false;
    switch (module?.moduleType) {
      case 'Mejlutskick':
        canActivate = module?.emailAccountId !== 0 && !!dispatch.subject && !!dispatch.content;
        break;
      case 'Chattbot':
      case 'E-post':
        canActivate = module?.emailAccountId !== 0 && !!module?.contactPerson;
        break;
      default:
        canActivate = true;
    }

    if (!canActivate && !module?.active) {
      toast.error('Alla obligatoriska fält måste vara ifyllda innan modulen kan aktiveras.', {
        position: 'bottom-right',
      });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/toggle-module`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
        body: JSON.stringify({
          id: module?.id,
          active: !module?.active,
        }),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, { position: 'bottom-right' });
        onModuleUpdate({ ...module, active: !module?.active, timestamp: Date.now() });
      } else {
        toast.error(result.message, { position: 'bottom-right' });
      }
    } catch (error) {
      toast.error('Ett fel uppstod vid uppdatering av modulstatus.', { position: 'bottom-right' });
    }
  };
  const resetDispatch = async () => {
    if (!window.confirm('Är du säker på att du vill starta om utskicket?')) {
      return; 
    }
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/reset-dispatch?id=${module.id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.token}`
            }
        });

        const result = await response.json();

        if (response.ok) {
            toast.success(result.message, {
                position: 'bottom-right'
            });
            // Update the module's active status in the local state
            const updatedModule = { ...module, finished: !module.finished };
            onModuleUpdate(updatedModule); // Update the parent component's state
        } else {
            toast.error(result.message, {
                position: 'bottom-right'
            });
        }
    } catch (error) {
        toast.error('Ett fel uppstod vid omstart av utskicket.', {
            position: 'bottom-right'
        });
    }
  };

  return (
    <div className='mb-2'>
        <Panel>
          <div className='d-flex align-items-center'>
            <h4 style={{ marginBottom: 0 }}>{module?.name}</h4>
            <div className='ms-auto d-flex'>
            {module?.moduleType === 'Mejlutskick' && module?.finished === true && (
            <>
            <>
                <IconButton
                icon={<FaRedo />}
                appearance="subtle"
                onClick={resetDispatch}
                title="Förnya mejlutskick"
              />
              </>
            </>
          )}
              {(module?.moduleType === 'Chattbot' || module?.moduleType === 'E-post') && (
              <>
                <IconButton
                icon={<IoChatbubbleOutline />}
                appearance="subtle"
                onClick={handleChatModalShow}
                title="Skriv med mig!"
              />
              </>
              )}
              <IconButton
                icon={<EditIcon />}
                appearance="subtle"
                onClick={handleEditModalShow}
                title="Redigera modul"
              />
            </div>
          </div>
          <Divider style={{ margin: '10px 0' }} />
        <div>
        {module?.moduleType === 'Chattbot' && (
          <>
            {module?.active ? (
              <Badge className='bg-success p-1 rounded' content="Chattboten visas på hemsidan" />
            ) : (
              <Badge className='bg-warning p-1 rounded' content="Chattboten visas inte på hemsidan" />
            )}
          </>
        )}

        {module?.moduleType === 'E-post' && (
            <>
              {module?.active ? (
                <>
                  <Badge className='bg-success p-1 rounded' content="Modulen läser aktivt av inkorgen" />
                  <Badge
                    className='bg-secondary p-1 rounded ms-1'
                    content={`Fr. o.m. ${
                      module?.timestamp
                        ? new Date(module?.timestamp).toLocaleString('se-SE', {
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                          })
                        : 'N/A'
                    }`}
                  />
                </>
              ) : (
                <Badge className='bg-warning p-1 rounded' content="Modulen läser inte av inkorgen" />
              )}
            </>
          )}

          {module?.moduleType === 'Mejlutskick' && (
            <>
              {module?.finished ? (
                <Badge className='bg-success p-1 rounded' content="Färdigt mejlutskick" />
              ) : (
                <>
                  {module?.active ? (
                    dispatch?.dispatchDate && new Date(dispatch.dispatchDate) > new Date() ? (
                      <Badge className='bg-warning p-1 rounded' content="Mejlutskicket inväntar startdatum" />
                    ) : (
                      <>
                        <Badge className='bg-success p-1 rounded' content="Mejlutskicket är aktivt" />
                        <p className="text-muted mb-0" style={{ fontStyle: 'italic' }}>
                          Vardagar 8:00-17:00
                        </p>
                      </>
                    )
                  ) : (
                    <Badge className='bg-warning p-1 rounded' content="Mejlutskicket är pausat" />
                  )}
                </>
              )}
            </>
          )}
        </div>
        <div className='mt-1 mb-1'>
          <Toggle
            checked={module?.active}
            onChange={toggleModuleStatus}
            size="md"
            checkedChildren="Aktiv"
            unCheckedChildren="Inaktiv"
          />
        </div>
        <div className='d-flex'>
            <p className='ms-1 mb-0'>{module?.label}</p>
            <div 
            onClick={handleLabelModalShow} 
            style={{ cursor: 'pointer', marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
          >
            <i className="bi bi-pen ms-auto"></i>
          </div>
        </div>
        <Divider style={{margin : "10px 0"}}/>
        <Nav
  vertical
  activeKey={selectedFragment}
  onSelect={(eventKey) => setSelectedFragment(eventKey)}
>
  <Nav.Item eventKey="Översikt" className='card-title'>
  <i className="bi bi-eye me-2"></i>
  Översikt
  </Nav.Item>

  {module?.moduleType === 'Chattbot' || module?.moduleType === 'E-post' ? (
    <>
      <Nav.Item eventKey="Flöden" className='card-title'>
      <i className="bi bi-arrow-repeat me-2"></i>
      Flöden
      </Nav.Item>
      <Nav.Item eventKey="Konversationer" className='card-title'>
      <i className="bi bi-people-fill me-2"></i>
      Konversationer
      </Nav.Item>
    </>
  ) : module?.moduleType === 'Mejlutskick' ? (
    <Nav.Item eventKey="Kontaktlista" className='card-title' >
      <i className="bi bi-list-columns-reverse me-2"></i>
      Kontaktlista
    </Nav.Item>
  ) : null}

  {auth.role === 'ROLE_ADMIN' && (
    <Nav.Item eventKey="Priser" className='card-title'>
      <i className="bi bi-cash me-2"></i>
      Priser
    </Nav.Item>
  )}

  <Divider style={{margin : "10px 0"}}/>
  <Nav.Item eventKey="E-post konto" className='card-title'>
    <i className="bi bi-envelope-at me-2"></i>
    E-post konto
  {module?.emailAccountId !== 0 ? (
    <Badge className='bg-success p-1 rounded' content="Tillämpad" style={{ float: 'right', marginLeft: 'auto' }} />
  ) : (
    <Badge className='bg-danger p-1 rounded' content="Saknas" style={{ float: 'right', marginLeft: 'auto' }} />
  )}
</Nav.Item>


      {module?.moduleType === 'E-post' && module?.emailAccountId !== 0 && (
        <Nav.Item eventKey="E-post mapp" className='card-title'>
          <i className="bi bi-archive me-2"></i>
          E-post mapp
        </Nav.Item>
      )}

  <Divider style={{margin : "10px 0"}}/>

  {(module?.moduleType === 'Chattbot' || module?.moduleType === 'E-post') && (
    <Nav.Item eventKey="Kontaktperson" className='card-title'>
      <i className="bi bi-person-lines-fill me-2"></i>
      Kontaktperson
    </Nav.Item>
  )}

  {module?.moduleType === 'Mejlutskick' && (
    <>
      <Nav.Item eventKey="Mejlmall" className='card-title'>
      <i className="bi bi-envelope-paper me-2"></i>
      Mejlmall
        {dispatch && dispatch.subject && dispatch.content ? (
          <Badge content="Tillämpad" className='bg-success p-1 rounded' style={{ float: 'right', marginLeft: 'auto' }} />
        ) : (
          <Badge content="Saknas" className='bg-danger p-1 rounded' style={{ float: 'right', marginLeft: 'auto' }} />
        )}
      </Nav.Item>

      <Nav.Item eventKey="Startdatum" className='card-title'>
      <i className="bi bi-calendar-event me-2"></i>
      Datum för utskick
      </Nav.Item>
    </>
  )}

  {(module?.moduleType === 'Chattbot' || module?.moduleType === 'E-post') && (
    <>
      <Nav.Item eventKey="Rollbeskrivning" className='card-title'>
      <i className="bi bi-person-bounding-box me-2"></i>
      Rollbeskrivning
      </Nav.Item>

      <Nav.Item eventKey="Personlighet" className='card-title'>
      <i className="bi bi-emoji-smile me-2"></i>
      Personlighet
      </Nav.Item>

      <Nav.Item eventKey="GPT Version" className='card-title'>
      <i className="bi bi-gear me-2"></i>
      GPT Version
      </Nav.Item>

      {module?.moduleType === 'Chattbot' && (
        <>
          <Nav.Item eventKey="Design" className='card-title'>
          <i className="bi bi-palette me-2"></i>
          Design & Avatar
          </Nav.Item>

          <Nav.Item eventKey="Hälsningsmeddelande" className='card-title'>
          <i className="bi bi-chat-left-text  me-2"></i>
          Hälsningsmeddelande
          </Nav.Item>
        </>
      )}
    </>
  )}

  {module?.moduleType === 'E-post' && (
    <>
      <Nav.Item eventKey="Ignore" className='card-title'>
      <i className="bi bi-ban me-2"></i>
      Mejl att ignorera
      </Nav.Item>

      <Nav.Item eventKey="Blockera e-poster" className='card-title'>
      <i className="bi bi-ban me-2"></i>
      Blockera e-poster
      </Nav.Item>

      <Nav.Item eventKey="Whitelist" className='card-title'>
      <i className="bi bi-card-checklist me-2"></i>
      Whitelista e-poster
      </Nav.Item>
    </>
  )}

  {(module?.moduleType === 'Chattbot' || module?.moduleType === 'E-post')&& (
    <>
      <Nav.Item eventKey="Regler" className='card-title'>
        <i className="bi bi-clipboard2-check me-2"></i>
        Regler
      </Nav.Item>
    </>
  )}

  {module?.moduleType === 'Chattbot' && (
    <>
      <Divider style={{margin : "10px 0"}}/>
      <button className='btn btn-primary mb-0' onClick={handleCopyScriptTag}>
                    <i className="bi bi-filetype-html me-2"></i>
                    Kopiera script tagg
                  </button>
    </>
  )}
</Nav>
      </Panel>

      <EditModuleModal show={showEditModal} handleClose={handleEditModalClose} module={module} onModuleEdited={onModuleUpdate} />
      <ChatModal show={showChatModal} handleClose={handleChatModalClose} module={module} />
      <EditLabelModal show={showLabelModal} handleClose={handleLabelModalClose} module={module} onModuleEdited={onModuleUpdate} />
    </div>
  );
}

export default ModuleCard;
