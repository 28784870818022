import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from 'rsuite';

function CreateBlacklistAddressModal({ show, onHide, onBlacklistedAdressCreated }) {
  const { auth } = useAuth();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!email) {
      toast.error('E-postadress måste fyllas i.', { position: 'bottom-right' });
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/create-blacklist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify({ email })
      });

      if (response.ok) {
        const result = await response.json();
        onHide();  // Close the modal on success
        onBlacklistedAdressCreated()
      } else {
        const result = await response.json();
        toast.error(result.message, { position: 'bottom-right' });
      }
      
    } catch (error) {
      console.error('Error:', error);
      toast.error('Ett fel uppstod vid skapandet av en svartlistad e-postadress.', { position: 'bottom-right' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>Svartlista ny e-postadress</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label htmlFor="email" className='form-label'>E-post</label>
          <input
            type="text"
            className='form-control'
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={loading}>
          Stäng
        </Button>
        <Button variant="primary" className="d-flex justify-content-center align-items-center" onClick={handleSubmit} disabled={loading}>
            {loading ? (
              <>
                <Loader className="me-2" /> Sparar...
              </>
            ) : (
              'Spara'
            )}
          </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateBlacklistAddressModal;
