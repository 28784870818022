import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Modal, Button } from 'react-bootstrap'; // Importing Modal and Button from react-bootstrap
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet-async';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { Popover, Whisper, IconButton, Breadcrumb, Loader } from 'rsuite';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import SearchIcon from '@rsuite/icons/Search';

function ModuleEmailTemplate({ project, dispatch, module, onDispatchEdited }) {
  const { auth } = useAuth();
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [header, setHeader] = useState([]); // State to store variables (DataRow headers)
  const [firstRow, setFirstRow] = useState([]); // State to store the first row of values
  const [showModal, setShowModal] = useState(false); // State for controlling modal visibility

  useEffect(() => {
    if (dispatch) {
      setSubject(dispatch.subject);
      setContent(dispatch.content);
      fetchVariables(); // Fetch variables on component mount
    }
  }, [dispatch]);

  const handleSave = async () => {
    if (!subject || !content) {
      toast.error('Ämne och innehåll måste fyllas i.', {
        position: 'bottom-right'
      });
      return;
    }
    setLoading(true);

    const dispatchData = {
      id: dispatch.id,
      subject,
      content
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/edit-dispatch`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify(dispatchData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        const updatedDispatch = { ...dispatch, ...dispatchData };
        onDispatchEdited(updatedDispatch);
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.error('Misslyckades att spara ändringar: ', error);
      toast.error('Misslyckades att spara ändringar.', {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch variables from the backend
  const fetchVariables = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/variables?dispatchId=${dispatch.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });
  
      if (response.ok) {
        const data = await response.json(); // Extract the JSON data
        // Access dataRowHeader and dataRow from the parsed JSON response
        const { dataRowHeader, dataRow } = data;
  
        // Set the header and first row based on the response data
        setHeader(dataRowHeader);  // Set the header (dataRowHeader from backend)
        setFirstRow(dataRow);  // Set the first row (dataRow from backend)
      } else {
        console.log("Ladda upp en kontaktlista för att se variabler.");
        toast.warning('Ladda upp en kontaktlista för att se variabler.', { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Misslyckades att hämta variabler: ', error);
      toast.error('Misslyckades att hämta variabler.', { position: 'bottom-right' });
    }
  };
  
  // Function to replace variables in the text
  const replaceVariables = (text) => {
    return (text || '').replace(/\{([\wäöåÄÖÅ]+)\}/g, (match, variableName) => {
      const headerItem = header.find(item => item.name === variableName);
      if (headerItem) {
        const valueObject = firstRow[headerItem.index];
        if (valueObject && valueObject.name) {
          return valueObject.name; // Replace with the value's name
        }
      }
      return match; // If the variable doesn't exist, don't replace
    });
  };
  // Function to check if the content contains HTML tags
  const containsHTML = (str) => {
    const htmlRegex = /<[^>]*>/g;
    return htmlRegex.test(str);
  };

  // Function to replace line breaks (\n) with <br> tags, but only when they are outside of any HTML tags
  const replaceLineBreaksWithBR = (text) => {
    if (containsHTML(text)) {
      return text.replace(/(\r\n|\n|\r)/gm, "<br />");
    }
    return text;
  };

  // Preview with variables replaced and line breaks handled
  const previewSubject = replaceVariables(subject);
  const previewContent = replaceLineBreaksWithBR(replaceVariables(content));


  return (
    <>
    <Helmet>
          <title>{`${module?.name} - Mejlmall`}</title>
        </Helmet>
        <div className='d-flex'>

        <Breadcrumb className='mb-0'>
              <Breadcrumb.Item>
                <Link to={`/user/projekt/${project?.id}`}>{project?.name}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{module?.name}</Breadcrumb.Item>
              <Breadcrumb.Item active>Mejlmall</Breadcrumb.Item>
        </Breadcrumb>

        </div>
        <hr />
  
      <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Mejlmall</h5>
            <p className='mb-2'>Här kan du skriva en mall som används i ditt utskick.</p>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Mejlmall
          </IconButton>
        </Whisper>
        {header.length > 0 && (
          <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Variabler</h5>
            <p className='mb-2'>Variabler kan användas i ditt utskick, dessa byts ut mot det riktiga värdet från din kontaktlista.</p>
            <p className='mb-2'>Variabler tillgängliga:</p>
            <ul>
            {header.map((variable, index) => (
            <li>
              {`{${variable.name}}`}
            </li>
            ))}
            </ul>
            </Popover>}
        > 
          <IconButton className='mb-2 ms-2' icon={<InfoOutlineIcon className='bg-primary' color='white' />}>
          Variabler
          </IconButton>
        </Whisper>
      )}
      <IconButton className='mb-2 ms-2' onClick={() => setShowModal(true)} icon={<SearchIcon className='bg-primary' color='white' />}>
          Förhandsvisa mall
      </IconButton>
  
      <input
        type="text"
        placeholder="Ämne"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        className="form-control mb-2"
      />
  
      <textarea
        className="form-control mb-2"
        id="content"
        value={content}
        placeholder="Hej! Mitt namn är ..."
        onChange={(e) => setContent(e.target.value)}
        rows="10"
      />

      <button className='btn btn-primary d-flex justify-content-center align-items-center' onClick={handleSave} disabled={loading}>
          {loading ? (
            <>
              <Loader className="me-2" /> Sparar...
            </>
          ) : (
            'Spara'
          )}
        </button>
  
      {/* Modal for preview using react-bootstrap */}
      <Modal show={showModal} onHide={() => setShowModal(false)} animation={true} size="lg">
        <Modal.Header closeButton>
        <Modal.Title>Förhandsvisning {"(exempel)"}</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <h6>Ämne:</h6>
          <p>{previewSubject ? previewSubject : 'Inget ämne har skrivits.'}</p>

          <h6>Innehåll:</h6>
          <div className="html-content">
            {containsHTML(previewContent) ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(previewContent)
                }}
              />
            ) : (
              <div>{previewContent ? previewContent.split('\n').map((item, key) => <span key={key}>{item}<br/></span>) : 'Inget innehåll har skrivits.'}</div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Stäng
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModuleEmailTemplate;
