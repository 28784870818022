import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const UserRoute = ({ children }) => {
  const { auth } = useAuth();

  const hasToken = !!auth.token;
  const isAdmin = (auth.role === 'ROLE_USER' || auth.role === 'ROLE_ADMIN' || auth.role === 'ROLE_SUPERADMIN');


  if (hasToken && isAdmin) {
    return children;
  } else {
    console.warn('User access denied. Redirecting to login.'); // Debug statement
    return <Navigate to="/login" />;
  }
};

export default UserRoute;
