import React, { useState, useEffect } from 'react';
import usImage from '../../../images/personal.png';
import { useAuth } from '../../../AuthContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Helmet } from 'react-helmet-async';
import FolderIcon from '@rsuite/icons/legacy/FolderOpen'; // Use FolderOpen for a better representation
import CubeIcon from '@rsuite/icons/legacy/Cube'; // Equivalent to moduler

function CompanyDashboardPage() {
  const { auth } = useAuth();
  const [projectsCount, setProjectsCount] = useState(0);
  const [modulesCount, setModulesCount] = useState(0);
  const [conversationsCount, setConversationsCount] = useState(0);
  const [leadsCount, setLeadsCount] = useState(0); // New state for leads count
  const [loadingProjects, setLoadingProjects] = useState(true);
  const [loadingModules, setLoadingModules] = useState(true);
  const [loadingConversations, setLoadingConversations] = useState(true); // New loading state for leads
  const [teamLoaded, setTeamLoaded] = useState(false); // New state for checking if the team is loaded


  // Check if the team data is available in localStorage
  useEffect(() => {
    const checkTeam = () => {
      const team = localStorage.getItem('team');
      if (team) {
        setTeamLoaded(true); // Set the team as loaded once available
      } else {
        setTimeout(checkTeam, 100); // Retry after a short delay if not available
      }
    };

    checkTeam(); // Start checking for the team in localStorage
  }, []);

  // Fetch data only when team is loaded
  useEffect(() => {
    if (teamLoaded) {
      fetchProjectsCount();
      fetchModulesCount();
      fetchConversationsCount();
    }
  }, [teamLoaded]);

  const fetchProjectsCount = async () => {
    setLoadingProjects(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/projects`, {
        headers: {
          'Authorization': `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
        },
        params: {
          size: 100, // Fetch up to 100 customers
          companyId: JSON.parse(localStorage.getItem('team')).id
        },
      });

      if (response.status === 200) {
        setProjectsCount(response.data.totalElements);
      } else {
        toast.error('Misslyckades att hämta antalet projekt.', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      toast.error('Misslyckades att hämta antalet projekt.', {
        position: 'bottom-right',
      });
    } finally {
      setLoadingProjects(false);
    }
  };

  const fetchModulesCount = async () => {
    setLoadingModules(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/modules`, {
        headers: {
          'Authorization': `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
        },
        params: {
          size: 100, // Fetch up to 100 modules
          companyId: JSON.parse(localStorage.getItem('team')).id
        },
      });

      if (response.status === 200) {
        const modules = response.data.content;
        
        // Total modules count
        const totalModules = modules.length;

        setModulesCount(totalModules);
      } else {
        toast.error('Misslyckades att hämta antalet moduler.', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      toast.error('Misslyckades att hämta antalet moduler.', {
        position: 'bottom-right',
      });
    } finally {
      setLoadingModules(false);
    }
  };
  const fetchConversationsCount = async () => {
    setLoadingConversations(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/conversations`, {
        headers: {
          'Authorization': `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
        },
        params: {
          size: 100, // Fetch up to 100 conversations
          companyId: JSON.parse(localStorage.getItem('team')).id
        },
      });

      if (response.status === 200) {
        const conversations = response.data.content;
        
        // Count conversations where leadGenerated is true
        const totalLeadsFromConversations = conversations.filter(convo => convo.leadGenerated === true).length;

        // Set the leadsCount to the number of conversations where leadGenerated is true

        setConversationsCount(conversations.length)
        setLeadsCount(totalLeadsFromConversations);
      } else {
        toast.error('Misslyckades att hämta antalet konversationer.', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      toast.error('Misslyckades att hämta antalet konversationer.', {
        position: 'bottom-right',
      });
    } finally {
      setLoadingConversations(false);
    }
};


  return (
    <>
    <Helmet>
          <title>{`Översikt`}</title>
        </Helmet>
        <div className='px-5 pt-4 w-100'>
        <h4 className="mb-2">Välkommen Tillbaka, {auth.firstName} 👋</h4>
        <p className="text-muted">Här är {localStorage.getItem('team') 
                    ? JSON.parse(localStorage.getItem('team')).companyName + "'s"
                    : '...'} översikt</p>
        <hr />
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
            <div className="card shadow p-3">
              <div className="d-flex align-items-center">
                  <FolderIcon className='me-2' />
                  <p className='mb-0'>Projekt</p>
              </div>
              <h2 className='mb-0'>
                {loadingProjects ? <Skeleton width={50} height={40} /> : projectsCount}
              </h2>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
            <div className="card shadow p-3">
              <div className="d-flex align-items-center">
                  <CubeIcon className='me-2' />
                  <p className='mb-0'>Moduler</p>
              </div>
              <h2 className='mb-0'>
                {loadingModules ? <Skeleton width={50} height={40} /> : modulesCount}
              </h2>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
            <div className="card shadow p-3">
              <div className="d-flex">
                  <i className="bi bi-people-fill me-2"></i>
                  <p className='mb-0'>Konversationer</p>
              </div>
              <h2 className='mb-0'>
                {loadingConversations ? <Skeleton width={50} height={40} /> : conversationsCount}
              </h2>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
            <div className="card shadow p-3">
              <div className="d-flex">
                  <i className="bi bi-info-circle me-2"></i>
                  <p className='mb-0'>Leads genererade</p>
              </div>
              <h2 className='mb-0'>
                {loadingConversations ? <Skeleton width={50} height={40} /> : leadsCount}
              </h2>
            </div>
          </div>
        </div>

          <section className="pt-5">
                <h4 className="large-text">Behöver du support?</h4>
                <div className="contact">

                    <p className="pt-2 text-muted pb-0 mb-1">+46(0)70-738 18 31</p>
                    <p className="text-muted">elliot.heathnyberg@gmail.com</p>
                </div>
                </section>

                <section className="pt-1 pb-3">
                  <img
                    style={{ width: "500px", borderRadius: "200px" }}
                    src={usImage}
                    alt="personal"
                  />
                </section>

        </div>
    </>
  );
}

export default CompanyDashboardPage;
