import React, { useState, useEffect } from 'react';
import LicenceOverview from '../licence/AdminLicenceOverview';
import LicenceUsers from '../licence/AdminLicenceUsers';
import LicenceCard from '../cards/LicenceCard';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../../AuthContext';
import CommentsCard from '../../Comments'
import { Helmet } from 'react-helmet-async';
import { Loader } from 'rsuite';

const ContentWrapper = ({ children }) => (
  <div className='px-5 pt-4 w-100 p-3'>
    {children}
  </div>
);

function AdminDetailsPage() {
  const { licenceId } = useParams();
  const { auth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [selectedFragment, setSelectedFragment] = useState('Översikt');
  const [company, setCompany] = useState(null);


  const fetchCompany = async () => {
    if (!licenceId) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/superadmin/licences?id=${licenceId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setCompany(data.content[0]);
      } else {
        const errorText = await response.text();
        throw new Error(`Misslyckades att hämta företag: ${errorText}`);
      }
    } catch (error) {
      console.error('Misslyckades att hämta företag: ', error);
      toast.error(error.message, {
        position: 'bottom-right'
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchCompany();
      setLoading(false);
    };
    fetchData();
  }, [licenceId, auth.token]);

  const handleCompanyUpdate = (updatedCompany) => {
    setCompany(prevCompany => ({
      ...prevCompany,
      ...updatedCompany // Only update the specific fields that are changed
    }));
  };


  return (
    <>
    <div className="d-flex">
        {loading ? (
          <ContentWrapper>
            <Loader speed='fast' /> Laddar...
          </ContentWrapper>
        ) : (
          <>
            <ContentWrapper>
              {selectedFragment === 'Översikt' && <LicenceOverview company={company} />}
              {selectedFragment === 'Användare' && <LicenceUsers company={company} />}
            </ContentWrapper>
            <div className="side-nav-right d-flex flex-column p-3">
              <LicenceCard 
                company={company} 
                selectedFragment={selectedFragment} 
                setSelectedFragment={setSelectedFragment} 
                onCompanyUpdate={handleCompanyUpdate} 
              />
              <CommentsCard 
                label={"licens"}
                identifierId={licenceId}
                maxHeight={370}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AdminDetailsPage;
