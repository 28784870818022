import React from 'react';
import { Panel, Nav, Divider } from 'rsuite';

// Import icons from @rsuite/icons
import PersonIcon from '@rsuite/icons/legacy/User';
import ListIcon from '@rsuite/icons/legacy/List';

function ProspectingCard({ selectedFragment, setSelectedFragment }) {
  return (
    <div className='mb-2'>
      <Panel>
        <div className='d-flex align-items-center'>
          <h4 style={{ marginBottom: 0 }}>Prospektering</h4>
        </div>
        <Divider style={{margin : "10px 0"}}/>
        <Nav
          vertical
          activeKey={selectedFragment}
          onSelect={(eventKey) => setSelectedFragment(eventKey)}
        >
          <Nav.Item
            eventKey="Prospektera"
            className='card-title'
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <i className="bi bi-person-fill-down me-2"></i>
            Prospektera
          </Nav.Item>
          <Nav.Item
            eventKey="Prospektlistor"
            className='card-title'
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <i className="bi bi-list-ol me-2"></i>
            Prospektlistor
          </Nav.Item>
        </Nav>
      </Panel>
    </div>
  );
}

export default ProspectingCard;
