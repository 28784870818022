import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import { IconButton, Popover, Whisper, Breadcrumb, Loader } from 'rsuite';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';

function ModulePrices({ company, onCompanyEdited }) { // Added onModuleEdited as prop
  const { auth } = useAuth(); // Get auth token from context
  const [tokenPrice, setTokenPrice] = useState('');
  const [flatPrice, setFlatPrice] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (company) {
        setTokenPrice(company.tokenPrice);
        setFlatPrice(company.flatPrice);
    }
  }, [company]);

  const handleSave = async () => {
    setLoading(true);

    const companyData = {
      id: company.id,
      tokenPrice,
      flatPrice
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/edit-licence`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify(companyData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        const updatedCompany = { ...company, ...companyData };
        onCompanyEdited(updatedCompany); // Use the merged module object
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.error('Misslyckades att spara ändringar: ', error);
      toast.error('Misslyckades att spara ändringar.', {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
        <Helmet>
          <title>{`${company?.companyName} - Modulpriser`}</title>
        </Helmet>
      <div className='d-flex'>
      <Breadcrumb className='mb-0'>
                <Breadcrumb.Item>
                    <Link to="/admin/kunder">Kunder</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{company?.companyName}</Breadcrumb.Item>
                <Breadcrumb.Item active>Priser</Breadcrumb.Item>
            </Breadcrumb>
      </div>
      <hr />
      
      <Whisper
        placement="rightStart"
        trigger="click"
        speaker={<Popover>
            <h5>Priser</h5>
            <p className='mb-2'>Här kan du specifiera kundens priser.</p>
            <p className='mb-2'>Nettopris per token:</p>
            <ul>
              <li>0.00005 SEK</li>
            </ul>
            </Popover>}
        > 
        <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
        Priser
        </IconButton>
      </Whisper>

      <div>
        <div className='mb-2'>
            <label htmlFor="flatPrice">Fast pris</label>
            <input
                type="number"
                className='form-control me-2 w-25'
                id="flatPrice"
                value={flatPrice}
                placeholder='1000'
                onChange={(e) => setFlatPrice(Math.max(0, e.target.value))}
            />
        </div>
        <div className='mb-2'>
            <label htmlFor="tokenPrice">Token pris</label>
            <input
                type="number"
                className='form-control me-2 w-25'
                id="tokenPrice"
                value={tokenPrice}
                placeholder='0.0005'
                step="0.0001"
                onChange={(e) => setTokenPrice(Math.max(0, e.target.value))}
            />
        </div>
        <button className='btn btn-primary d-flex justify-content-center align-items-center' onClick={handleSave} disabled={loading}>
          {loading ? (
            <>
              <Loader className="me-2" /> Sparar...
            </>
          ) : (
            'Spara'
          )}
        </button>

       </div>
    </>
  );
}

export default ModulePrices;
