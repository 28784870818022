// CustomerNavBar.jsx
import React from 'react';
import { useAuth } from '../../../AuthContext';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Sidenav, Nav, Divider } from 'rsuite';
import 'rsuite/dist/rsuite.css';
import axios from 'axios'; // To handle API requests
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import rsuite icons
import DashboardIcon from '@rsuite/icons/legacy/Dashboard';
import CubeIcon from '@rsuite/icons/legacy/Cube';
import SignOutIcon from '@rsuite/icons/legacy/SignOut';

function NavBar({ licence, loading, expanded, setExpanded }) { // Receive props
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Function to handle logout
  const handleLogout = () => {
    logout();
    navigate('/login'); // Redirect to login page after logout
  };

  // Function to determine the active path
  const getActiveKey = () => {
    if (location.pathname.startsWith('/customer/dashboard')) {
      return 'dashboard';
    }
    if (location.pathname.startsWith('/customer/moduler')) {
      return 'moduler';
    }
    return '';
  };

  const activeKey = getActiveKey();

  return (
    <div className="navbar-fixed">
      <Sidenav
        appearance="subtle"
        expanded={expanded} // Use expanded state to control the sidenav
        activeKey={activeKey}
        style={{ width: expanded ? 240 : 56, height: '100vh' }}
      >
        <Sidenav.Header>
          <div
            className="d-flex align-items-center justify-content-center text-center p-3"
            style={{ height: '50px', borderBottom: '1px solid #e5e5e5' }}
          >
            <h5 className="mb-0">
            {loading ? 'Laddar...' : (expanded ? licence?.companyName || 'Sonnet' : licence?.companyName?.charAt(0) || 'S')}

            </h5>
          </div>
        </Sidenav.Header>

        <Sidenav.Body>
          <Nav>
            {/* Dashboard Link */}
            <Nav.Item
              eventKey="dashboard"
              icon={<DashboardIcon />}
              as={Link}
              to="/customer/dashboard"
            >
              {expanded && 'Översikt'}
            </Nav.Item>

            {/* Moduler Link */}
            <Nav.Item
              eventKey="moduler"
              icon={<CubeIcon />}
              as={Link}
              to="/customer/moduler"
            >
              {expanded && 'Moduler'}
            </Nav.Item>

            <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
          </Nav>
        </Sidenav.Body>
        {/* Toggle Button */}
        <Sidenav.Toggle onToggle={() => setExpanded(!expanded)} />
      </Sidenav>
    </div>
  );
}

export default NavBar;
