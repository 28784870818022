import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Helmet } from 'react-helmet-async';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { Popover, Whisper, IconButton, Breadcrumb, Loader } from 'rsuite';

function ModuleGPTVersion({ project, module, onModuleEdited }) { 
  const { auth } = useAuth(); 
  const [gptModel, setGptModel] = useState('');
  const [availableModels, setAvailableModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (module) {
      setGptModel(module.gptModel);
      fetchGptModels(); // Fetch available models when the component mounts
    }
  }, []);

  const fetchGptModels = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/gpt-versions`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${auth.token}`
        }
      });

      if (response.ok) {
        const models = await response.json();
        setAvailableModels(models);
      } else {
        toast.error('Misslyckades att hämta GPT modeller.', { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Error fetching GPT models: ', error);
      toast.error('Error fetching GPT models.', { position: 'bottom-right' });
    } finally {
        setLoading(false)
    }
  };

  const handleSave = async () => {
    setSaving(true);

    const moduleData = {
      id: module.id,
      gptModel,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/edit-module`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify(moduleData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        const updatedModule = { ...module, ...moduleData };
        onModuleEdited(updatedModule); 
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.error('Misslyckades att spara ändringar: ', error);
      toast.error('Misslyckades att spara ändringar.', {
        position: 'bottom-right'
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
    <Helmet>
          <title>{`${module?.name} - GPT version`}</title>
        </Helmet>
        <div className='d-flex'>

        <Breadcrumb className='mb-0'>
              <Breadcrumb.Item>
                <Link to={`/user/projekt/${project?.id}`}>{project?.name}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{module?.name}</Breadcrumb.Item>
              <Breadcrumb.Item active>GPT Version</Breadcrumb.Item>
        </Breadcrumb>

        </div>
        <hr />

      <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>GPT Version</h5>
            <p className='mb-2'>Här kan du välja vilken GPT version som modulerna ska ha som motor.</p>
            <p className='mb-2'>De rekommenderade versionerna är GPT-4</p>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          GPT Version
          </IconButton>
        </Whisper>

      {loading ? (
          <>
            <div className="d-flex">
            <Skeleton height={35} width={300} />  
            <Skeleton className='ms-2' height={35} width={80} />  
            </div>
          </>
      ) : (
        <>
      <div className="d-flex align-items-center mb-2">
        <select
          className='form-control me-2 w-25'
          value={gptModel}
          onChange={(e) => setGptModel(e.target.value)}
        >
          {availableModels.map((model) => (
            <option key={model.id} value={model.id}>
              {model.id}
            </option>
          ))}
        </select>
        <button className='btn btn-primary d-flex justify-content-center align-items-center' onClick={handleSave} disabled={saving}>
          {saving ? (
            <>
              <Loader className="me-2" /> Sparar...
            </>
          ) : (
            'Spara'
          )}
        </button>
      </div>
        </>
      )}
    </>
  );
}

export default ModuleGPTVersion;
