import React, { useState } from 'react';
import { Panel, IconButton, Nav, Divider, Loader } from 'rsuite';
import EditProjectModal from '../../../modals/company/project/EditProject';
import axios from 'axios';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import icons from @rsuite/icons
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/legacy/Trash';
import FolderIcon from '@rsuite/icons/legacy/FolderOpen'; // Use FolderOpen for a better representation
import CubeIcon from '@rsuite/icons/legacy/Cube'; // Equivalent to moduler

function ProjectCard({ project, selectedFragment, setSelectedFragment, onProjectUpdate, onProjectDeleted }) {
  const { auth } = useAuth();
  const [showEditModal, setShowEditModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleEditModalClose = () => setShowEditModal(false);
  const handleEditModalShow = () => setShowEditModal(true);
  const handleProjectEdited = (updatedProject) => {
    // Update the project state with the new data
    onProjectUpdate(updatedProject);
    setShowEditModal(false);
  };

  const deleteProject = async () => {
    if (!window.confirm('Är du säker på att du vill radera detta projekt?')) {
      return;
    }
    setLoadingDelete(true);

    try {
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/user/delete-project`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
        },
        data: {
          id: project.id,
        },
      });
  
      if (response.status === 200) {
        toast.warning(response.data.message, {
          position: 'bottom-right',
        });
        onProjectDeleted();
      } else {
        toast.error('Misslyckades att radera projektet.', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      toast.error('Misslyckades att radera projektet.', {
        position: 'bottom-right',
      });
    } finally {
      setLoadingDelete(false);
    }
  };

  return (
    <div className='mb-2'>
      <Panel>
        <div className='d-flex align-items-center'>
          <h4 style={{ marginBottom: 0 }}>{project?.name}</h4>
          <div className="ms-auto">
            <IconButton
              icon={loadingDelete ? <Loader speed='fast' size="sm" /> : <TrashIcon />}
              appearance="subtle"
              onClick={deleteProject}
              title="Radera projekt"
              disabled={loadingDelete} // Disable button while loading
            />
            <IconButton
              icon={<EditIcon />}
              appearance="subtle"
              onClick={handleEditModalShow}
              title="Redigera projekt"
            />
          </div>
        </div>
        <Divider style={{ margin: '10px 0' }} />
        <Nav
          vertical
          activeKey={selectedFragment}
          onSelect={(eventKey) => setSelectedFragment(eventKey)}
        >
          <Nav.Item eventKey="Modules" className="card-title">
            <CubeIcon className="me-2" />
            Moduler
          </Nav.Item>
          <Nav.Item eventKey="TrainingData" className="card-title">
            <i className="bi bi-database me-2"></i>
            Träningsdata
          </Nav.Item>
          <Nav.Item eventKey="Funktioner" className="card-title">
            <i className="bi bi-code me-2"></i>
            Anpassade funktioner
          </Nav.Item>
        </Nav>
      </Panel>

      <EditProjectModal
        show={showEditModal}
        handleClose={handleEditModalClose}
        project={project}
        onProjectEdited={handleProjectEdited}
      />
    </div>
  );
}

export default ProjectCard;
