import React, { useEffect, useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import CreateUserModal from '../../../modals/admin/users/CreateUser'; 
import EditUserModal from '../../../modals/admin/users/EditUser'; 
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ClipLoader } from 'react-spinners'; // Assuming you're using react-spinners
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Helmet } from 'react-helmet-async';

import { Pagination } from 'react-bootstrap'; 

import { Dropdown, IconButton, Badge, Loader, Popover, Whisper, Breadcrumb } from 'rsuite';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { Gear } from '@rsuite/icons';


function LicenceUsers({ company }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); 
  const { auth } = useAuth();

  const [page, setPage] = useState(0);  
  const [totalPages, setTotalPages] = useState(1);  
  const [searchQuery, setSearchQuery] = useState('');

  const [deletingRowIds, setDeletingRowIds] = useState([]);
  const [inviteUserIds, setInviteUserIds] = useState([]);

  const fetchUsers = async (currentPage = 0, emailQuery = '') => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/users?licenceId=${company.licenceId}&email=${emailQuery}&page=${currentPage}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setUsers(data.content);
        setPage(data.number);  
        setTotalPages(data.totalPages);
      } else {
        const errorText = await response.text();
        toast.error("Misslyckades att hämta användare", {
          position: 'bottom-right'
        });
        throw new Error(`Misslyckades att hämta användare: ${errorText}`);
      }
    } catch (error) {
      console.error('Misslyckades att hämta användare: ', error);
      toast.error(error.message, {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [company.id]);

  const handleEdit = (user) => {
    setSelectedUser(user); 
    setShowEditModal(true); 
  };

  const handleDelete = async (userId) => {
    if (window.confirm('Är du säker på att du vill radera denna användare?')) {

      setDeletingRowIds((prev) => [...prev, userId]);

      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/delete-user?id=${userId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.token}`
          }
        });

        if (response.ok) {
          const result = await response.json();
          toast.warning(result.message, {
            position: 'bottom-right'
          });
          setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
        } else {
          const errorData = await response.json();
          toast.error(errorData.message, {
            position: 'bottom-right'
          });
        }
      } catch (error) {
        console.error('Ett fel inträffade vid borttagningen av användaren: ', error);
        toast.error('Ett fel inträffade vid borttagningen av användaren.', {
          position: 'bottom-right'
        });
      } finally {
        setDeletingRowIds((prev) => prev.filter((id) => id !== userId));
      }
    }
  };

  const handleInviteUser = async (userId) => {
    setInviteUserIds((prev) => [...prev, userId]);
    try {
      const currentUrl = window.location.origin; // This gives you the base URL of the current frontend page
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/invite-user?userId=${userId}&baseUrl=${currentUrl}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${auth.token}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (response.ok) {
        const result = await response.json();
        toast.success(result.message, {
          position: 'bottom-right'
        });
        setUsers(users.map(user => user.id === userId ? { ...user, invited:true } : user)); 
      } else {
        const errorData = await response.json();
        toast.error(errorData.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.error('Ett fel inträffade vid skickandet av inbjudan: ', error);
      toast.error('Ett fel inträffade vid skickandet av inbjudan.', {
        position: 'bottom-right'
      });
    } finally {
      setInviteUserIds((prev) => prev.filter((id) => id !== userId));
    }
  };
  
  // Handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchUsers(newPage, searchQuery);
  };

  const handleToggleEnabled = async (userId, currentEnabled) => {
    try {
      const response = await fetch('http://localhost:8080/admin/toggle-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify({ id: userId, enabled: !currentEnabled }) // Invert the current status
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        setUsers(users.map(user => user.id === userId ? { ...user, enabled: !currentEnabled } : user)); // Update user status in state
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.error('Ett fel inträffade vid ändring av användarens status: ', error);
      toast.error('Ett fel inträffade vid ändring av användarens status.', {
        position: 'bottom-right'
      });
    }
  };

  // Handle search input change
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle search submission
  const handleSearchSubmit = (e) => {
    if (e.key === 'Enter') {  
      setPage(0);
      fetchUsers(0, searchQuery);  
    }
  };

  return (
    <>
        <Helmet>
        <title>{`${company?.companyName} - Användare`}</title>
        </Helmet>
      <div className='d-flex'>
        <Breadcrumb className='mb-0'>
                <Breadcrumb.Item>
                    <Link to="/admin/kunder">Kunder</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{company?.companyName}</Breadcrumb.Item>
                <Breadcrumb.Item active>Medarbetare</Breadcrumb.Item>
          </Breadcrumb>
      </div>
      <hr />
      
      <div className="d-flex">

      <Whisper
        placement="rightStart"
        trigger="click"
        speaker={<Popover>
            <h5>Medarbetare</h5>
            <p className='mb-2'>Här kan du bjuda in medarbetare till samma licens.</p>
            <p className='mb-2'>Roller:</p>
            <ul>
              <li>Observatör</li>
              <li>Administratör</li>
            </ul>

            <p>Administratör har följande behörigheter:</p>
            <ul>
              <li>Skapa moduler</li>
              <li>Redigera moduler</li>
              <li>Skapa team</li>
              <li>Tilldela medarbetare som observatörer till team</li>
              <li>Skapa e-postkonton</li>
              <li>Verifiera domäner</li>
              <li>Pausa moduler</li>
              <li>Återuppta moduler</li>
            </ul>
            <p>Observatör har följande behörigheter:</p>
            <ul>
              <li>Se moduler för tilldelat team</li>
              <li>Pausa moduler</li>
              <li>Återuppta moduler</li>
            </ul>
            </Popover>}
        > 
        <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
        Medarbetare
        </IconButton>
      </Whisper>

      <div className='ms-auto'>
            <div className="input-group">
                <input 
                  className='form-control' 
                  type="text" 
                  placeholder='Sök e-post' 
                  value={searchQuery}
                  onChange={handleSearch}
                  disabled={loading}
                  onKeyDown={handleSearchSubmit}  
                />
                <span className="input-group-text">
                    <i className="bi bi-search"></i>
                </span>
            </div>
        </div>
      </div>

      <div 
        className="card dashed shadow border-1 rounded mb-2" 
        style={{ cursor: 'pointer' }}
        onClick={() => setShowCreateModal(true)}
      >
        <div className='p-4 text-center'>
          <p className='mb-0'>
            <i className="bi bi-plus me-1"></i>
            Skapa ny medarbetare
          </p>
        </div>
      </div>

      {loading ? (
          <>
            <div className="row">
                    {[...Array(2)].map((_, index) => (
                        <div key={index} className="col-sm-12 col-md-12 col-lg-6">
                          <div className="card p-2">
                              <div className="d-flex">
                              <Skeleton width={300} height={20} />
                              </div>
                          </div>
                        </div>
                    ))}
                </div>
          </>
      ) : (
        <>
          <div className="row mb-2">
      {users.map((user) => (
        <div className="col-md-12 col-lg-6" key={user.id}>
          <div className="card border-1 mb-2">
          <div className="p-2">
            <div className="d-flex align-items-center">

              <div style={{cursor: "pointer"}} onClick={(e) => {handleToggleEnabled(user.id, user.enabled)}}>
                <i className={`ms-3 bi bi-circle-fill ${user.enabled ? 'text-success' : 'text-danger'}`}></i>
              </div>

              <p className='mb-1 ms-3'>{user.email}</p>
              <div className="ms-auto me-2">
                    {user.role === "ROLE_USER" ? (
                      <Badge className='bg-primary p-1 rounded me-2' content="Administrator" />
                    ) : (
                      <>
                      {user.teamIds.length > 0 ? (
                        <Badge className='bg-primary p-1 rounded me-2' content={`${user.teamIds.length} st team`} />
                      ) : (
                        <>
                        {user.role === "ROLE_CUSTOMER" && (
                          <Badge className='bg-warning p-1 rounded me-2' content="Inget Team" />
                        )}
                        </>
                      )}
                      <Badge className='bg-secondary p-1 rounded me-2' content="Observatör" />
                      </>
                    )}
                    {user.invited ? (
                      <Badge className='bg-success p-1 rounded me-2' content="Inbjuden" />
                    ) : (
                      <Badge className='bg-danger p-1 rounded me-2' content="Oinbjuden" />
                    )}
              </div>

                <Dropdown 
                      trigger={['click']}  // Open on both hover and click
                      renderToggle={(props, ref) => (
                        <IconButton {...props} ref={ref} icon={
                          (deletingRowIds.includes(user.id) || inviteUserIds.includes(user.id)) ? (  // Check if the module is being deleted
                            <Loader speed='fast' size="sm" />
                          ) : (
                            <Gear />
                          )
                        } appearance="subtle" />
                      )} 
                      placement="bottomEnd" 
                    >
                      <Dropdown.Item onClick={() => handleEdit(user)}>Ändra</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleInviteUser(user.id)}>Skicka inbjudan</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleDelete(user.id)}>Radera</Dropdown.Item>
                    </Dropdown>
            </div>
          </div>
        </div>
        </div>
      ))}
      </div>
        </>
      )}

<div className="d-flex my-4">
        {totalPages > 1 && (
        <div className="d-flex my-4">
            <Pagination>
            <Pagination.Prev 
                onClick={() => handlePageChange(page - 1)} 
                disabled={page === 0}
            />
            
            {(() => {
                const totalDisplayedPages = 10;  
                let startPage = Math.max(0, page - Math.floor(totalDisplayedPages / 2));
                let endPage = startPage + totalDisplayedPages;

                if (endPage > totalPages) {
                endPage = totalPages;
                startPage = Math.max(0, totalPages - totalDisplayedPages);
                }

                return [...Array(endPage - startPage).keys()].map((p) => (
                <Pagination.Item 
                    key={startPage + p} 
                    active={startPage + p === page} 
                    onClick={() => handlePageChange(startPage + p)}
                >
                    {startPage + p + 1}
                </Pagination.Item>
                ));
            })()}

            <Pagination.Next 
                onClick={() => handlePageChange(page + 1)} 
                disabled={page === totalPages - 1}
            />
            </Pagination>
        </div>
            )}
        </div>

      {/* Render the NewUserModal */}
      <CreateUserModal
        show={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        company={company}
        onUserCreated={fetchUsers} // Refresh user list after user creation
      />

      {/* Render the EditUserModal */}
      {selectedUser && (
        <EditUserModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          user={selectedUser}
          onUserUpdated={fetchUsers} // Refresh user list after user update
        />
      )}
    </>
  );
}

export default LicenceUsers;
