import React, { useState, useRef } from 'react';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-multi-date-picker';
import Button from 'react-bootstrap/Button';
import { jsPDF } from "jspdf";



function CustomFooter({ onFetch, loading, selectedDates }) {
  return (
    <div style={{ padding: "10px", textAlign: "center" }}>
      <Button onClick={onFetch} disabled={loading || selectedDates.length === 0}>
        {loading ? (
            "Laddar..."
        ) : (
            <>
            <i className="bi bi-wallet me-2" style={{ marginRight: "5px" }}></i> {/* Wallet icon */}
            Hämta kostnad
            </>
        )}
        </Button>


      {/* Display selected months below the button */}
      {selectedDates.length > 0 && (
        <div className='mt-2'>
          <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
            {selectedDates.map((date, index) => (
              <li key={index}>
                {date.format("YYYY-MM")} {/* Display formatted month */}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

function TokenTracker({ licenceId, companyId, projectId, moduleId, showType, label }) {
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [usageData, setUsageData] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]); // State to store selected dates
  const datePickerRef = useRef(null); // Reference to the date picker

  // Function to generate and download the PDF
const generatePDF = (data) => {
    console.log(data);
    const items = Array.isArray(data.content) ? data.content : [data.content]; // Ensure it's always an array
    const doc = new jsPDF();
    doc.setFontSize(16);
    
    doc.text("Token kostnad", 10, 10); // Title
    doc.setFontSize(8);
    doc.text(`Kund ID: ${items[0].licenceId || 'N/A'}`, 10, 20);
    doc.text(`Omfattning: ${label || 'N/A'}`, 10, 25);
    doc.text(`1000 tokens = cirka 750 ord i context`, 10, 35);
    doc.setFontSize(10);
    doc.text(`_________________________________________________________________________________________________`, 10, 40);
  
    let lineHeight = 55;
  
    // Headers
    doc.text(`Modul`, 10, lineHeight);
    doc.text(`Modultyp`, 40, lineHeight);
    doc.text(`Period`, 70, lineHeight);
    doc.text(`Tokens`, 95, lineHeight);
    doc.text(`Pris per token`, 120, lineHeight);
    doc.text(`Pris per modul`, 150, lineHeight);
    doc.text(`Totalt`, 180, lineHeight);
    
    lineHeight += 10;
  
    let totalPriceSum = 0; // Initialize the total price sum outside the loop

    items.forEach((item) => {
    const date = new Date(item.timePeriod); // Create a date from timePeriod

    date.setMonth(date.getMonth() + 1); // Add 1 month to the date

    // Format the date to YYYY-MM
    const formattedTimePeriod = date.toISOString().slice(0, 7); // Format to YYYY-MM

    // Calculate the totalPrice for this iteration
    const totalPrice = (item.tokenPrice * item.totalTokens) + item.flatPrice;

    // Add the totalPrice to the running sum
    totalPriceSum += totalPrice;

    // Output the data
    doc.text(`${item.moduleName}`, 10, lineHeight);
    doc.text(`${item.moduleType}`, 40, lineHeight);
    doc.text(`${formattedTimePeriod}`, 70, lineHeight); // Only Year-Month
    doc.text(`${item.totalTokens}`, 95, lineHeight);
    doc.text(`${item.tokenPrice} SEK`, 120, lineHeight);
    doc.text(`${item.flatPrice} SEK`, 150, lineHeight);
    doc.text(`${totalPrice.toFixed(2)} SEK`, 180, lineHeight); // Display totalPrice for this iteration
    
    lineHeight += 5; // Increment line height for the next line
    });
  
    // Calculate the total number of tokens
    const totalTokens = items.reduce((acc, item) => acc + item.totalTokens, 0);
  
    lineHeight += 20; // Increment line height for the next line
  
    // Headers for totals
    doc.text(`Total summa`, 10, lineHeight);
    doc.text(`Antal moduler`, 40, lineHeight);
    doc.text(`Totala tokens`, 70, lineHeight);
  
    lineHeight += 10;
    
    // Print the total number of tokens and the number of modules
    doc.text(`${totalPriceSum.toFixed(2)} SEK`, 10, lineHeight); // Total number of modules
    doc.text(`${items.length} st`, 40, lineHeight); // Total number of modules
    doc.text(`${totalTokens}`, 70, lineHeight); // Total tokens calculated

    doc.save("usage_data.pdf"); // Save the PDF
  };
  
  
  

  // Function to handle the fetch request
  const handleFetchUsage = async () => {
    setLoading(true);

    // Close the date picker after initiating the fetch
  if (datePickerRef.current) {
    datePickerRef.current.closeCalendar();
  }

    try {
      // Build query string with optional parameters and selected dates
      const queryParams = new URLSearchParams({
        ...(licenceId && { licenceId }), // Add if licenceId is present
        ...(companyId && { companyId }), // Add if companyId is present
        ...(projectId && { projectId }), // Add if projectId is present
        ...(moduleId && { moduleId }), // Add if moduleId is present
        ...(selectedDates.length > 0 && { dates: selectedDates.join(',') }), // Add selected dates as CSV
      });

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/usage?${queryParams.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`, // Include token in the request
        },
      });

      const result = await response.json();

      if (response.ok) {
        setUsageData(result); // Save the usage data
        toast.success(result.message, {
          position: 'bottom-right',
        });

        // Generate and download the PDF with the fetched data
        generatePDF(result);
      } else {
        toast.error(result.message, {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Ett fel uppstod när användningen hämtades.', {
        position: 'bottom-right',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {/* Clickable wallet icon that opens the date picker */}
      <div
        onClick={() => datePickerRef.current.openCalendar()} // Opens the date picker popup
        style={{ cursor: 'pointer', display: 'inline-block' }}
      >
        {showType === 'Text' ? (
          <span>Kostnad</span>
        ) : (
          <i className="bi bi-wallet"></i>
        )}
      </div>

      {/* Custom DatePicker with hidden input field and month picker */}
      <DatePicker
        ref={datePickerRef}
        multiple
        value={selectedDates}
        onChange={setSelectedDates} // Update state when months are selected
        format="YYYY-MM" // Format to display year and month
        onlyMonthPicker
        portal // Ensures the date picker opens as a popup
        style={{ display: 'none'}} // Hide the input field
        plugins={[
          <CustomFooter 
            key="footer" 
            onFetch={handleFetchUsage} 
            loading={loading} 
            selectedDates={selectedDates} // Pass selected dates to the footer
          />
        ]}
      />
    </div>
  );
}

export default TokenTracker;
