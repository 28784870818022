import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../../AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from 'rsuite';

function CreateCompanyModal({ onCompanyCreated, handleShow, handleClose }) {
  const { auth } = useAuth();
  const [companyName, setCompanyName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!companyName || !contactEmail) {
      toast.error('Företagsnamn, organisationsnummer och kontakt e-post måste fyllas i.', {
        position: 'bottom-right'
      });
      return;
    }

    setLoading(true);
    const companyData = {
      companyName,
      contactEmail,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/superadmin/create-licence`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}` // Include token in the request
        },
        body: JSON.stringify(companyData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message, {
          position: 'bottom-right'
        });
        handleClose();
        onCompanyCreated(); // Call the function to update the company list
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      toast.error('Misslyckades att skapa företaget.', {
        position: 'bottom-right'
      });
    } finally {
        setLoading(false);
    }
  };

  return (
    <>
      <Modal show={handleShow} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Ny licens</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label htmlFor="companyName" className='form-label'>Företagsnamn</label>
            <input
              type="text"
              className='form-control'
              id="companyName"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>

          <div className='mt-2'>
            <label htmlFor="contactEmail" className='form-label'>Kontakt e-post</label>
            <input
              type="text"
              className='form-control'
              id="contactEmail"
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
            />
          </div>    
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Stäng
          </Button>
          <Button variant="primary" className="d-flex justify-content-center align-items-center" onClick={handleSubmit} disabled={loading}>
            {loading ? (
              <>
                <Loader className="me-2" /> Sparar...
              </>
            ) : (
              'Spara'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateCompanyModal;
