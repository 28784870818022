import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useAuth } from '../../../AuthContext';
import CreateFlowModal from '../../../modals/company/module/CreateFlow'; // Adjust the import path as necessary
import EditFlowModal from '../../../modals/company/module/EditFlow'; // Adjust the import path as necessary
import SpecifyFlowModal from '../../../modals/company/module/SpecifyFlow'; // Adjust the import path as necessary
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';

import { Dropdown, IconButton, Badge, Loader, Popover, Whisper, Breadcrumb } from 'rsuite';
import { Gear } from '@rsuite/icons';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import PlusIcon from '@rsuite/icons/Plus';

function ModuleFlows({ project, module }) {
  const { auth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [flows, setFlows] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSpecifyModal, setShowSpecifyModal] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [selectedFlowForSpecify, setSelectedFlowForSpecify] = useState(null);

  const [deletingRowIds, setDeletingRowIds] = useState([]);

  const fetchFlows = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/flows?moduleId=${module.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setFlows(data.content); // Assuming your backend returns the flows in a "content" field
      } else {
        const errorText = await response.text();
        throw new Error(`Error fetching flows: ${errorText}`);
      }
    } catch (error) {
      console.error('Misslyckades att hämta flöden: ', error);
      toast.error(error.message, {
        position: 'bottom-right'
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteFlow = async (flowId) => {
    if (!window.confirm('Är du säker på att du vill radera detta flöde?')) {
      return; // User canceled the deletion
    }

    setDeletingRowIds((prev) => [...prev, flowId]);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/delete-flow`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify({ id: flowId })
      });
      const result = await response.json();
      if (response.ok) {
        toast.warning(result.message, {
          position: 'bottom-right'
        });
        setFlows((prevFlows) => prevFlows.filter((flow) => flow.id !== flowId));
      } else {
        toast.error(result.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.error('Missyckades att radera flöde: ', error);
      toast.error('Ett fel uppstod vid raderingen av flödet.', {
        position: 'bottom-right'
      });
    } finally {
      setDeletingRowIds((prev) => prev.filter((id) => id !== flowId));
    }
  };

  const handleFlowCreated = async () => {
    setLoading(true); // Start loading before creation operation
    try {
      await fetchFlows(); // Refresh the list after creation
    } finally {
      setLoading(false); // Stop loading after creation operation
    }
  };

  const handleFlowUpdated = async (updatedFlow) => {
    setLoading(true); // Start loading before update operation
    try {
      setFlows((prevFlows) =>
        prevFlows.map((flow) => (flow.id === updatedFlow.id ? updatedFlow : flow))
      );
    } finally {
      setLoading(false); // Stop loading after update operation
    }
  };

  useEffect(() => {
    fetchFlows();
  }, [module.id]);

  return (
    <>
    <Helmet>
          <title>{`${module?.name} - Flöden`}</title>
        </Helmet>
        <div className='d-flex'>

        <Breadcrumb className='mb-0'>
              <Breadcrumb.Item>
                <Link to={`/user/projekt/${project?.id}`}>{project?.name}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{module?.name}</Breadcrumb.Item>
              <Breadcrumb.Item active>Flöden</Breadcrumb.Item>
        </Breadcrumb>

        </div>
        <hr />

      <Whisper
          placement="rightStart"
          trigger="click"
          speaker={<Popover>
            <h5>Flöden</h5>
            <p className='mb-2'>Flöden kan användas för att specifiera mer precist hur modulen hanterar ett ärende från personen den skriver med.</p>

            <p className='mb-2'>Exempelnamn på ett flöde:</p>
            <ul>
              <li>Orderstatus</li>
            </ul>
            <p className='mb-2'>Exempelbeskrivning på orderstatus flöde:</p>
            <ul>
              <li>Använd detta flöde om kunden frågar efter orderstatus.</li>
            </ul>
            <p className='mb-2'>Ett flöde innehåller en eller flera instruktioner för att modellen ska veta exakt hur den ska hantera det specifika ärendet.</p>
            <p className='mb-2'>Modulen väljer anpassade funktioner som passar för uppgiften som stöd i valt flöde.</p>
            </Popover>}
        > 
          <IconButton className='mb-2' icon={<HelpOutlineIcon className='bg-primary' color='white' />}>
          Flöden
          </IconButton>
        </Whisper>

      <div
        className="card dashed shadow border-1 rounded mb-2"
        style={{ cursor: 'pointer' }}
        onClick={() => setShowCreateModal(true)}
      >
        <div className='p-4 text-center'>
          <p className='mb-1'>
            <i className="bi bi-plus me-2"></i>
            Skapa nytt flöde
          </p>
        </div>
      </div>

      {loading ? (
          <>
            <Skeleton height={60} />  
          </>
      ) : (
        <>
        <div className="row mb-2">
        {flows.map((flow) => (
          <div className="col-md-12 col-lg-12">
            <div key={flow.id} className="card border-1 mb-2" style={{ cursor: 'pointer' }} onClick={(e) => {
                    e.preventDefault();
                    setSelectedFlowForSpecify(flow);
                    setShowSpecifyModal(true);
                }}>
            <div className="p-2">
              <div className="d-flex align-items-center">

                <i className={`ms-3 bi bi-circle-fill ${flow.specifications && JSON.parse(flow.specifications).length > 0 ? 'text-success' : 'text-danger'}`}></i>

                <h5 className='mb-1 ms-3'>{flow.name}</h5>  {/* Added `me-3` to create space */}
                
                <div className="ms-auto me-2">
                    {!flow.specifications || JSON.parse(flow.specifications).length == 0 ? (
                      <Badge className='bg-danger p-1 rounded me-2' content="Detaljer saknas" />
                    ) : (
                      <>
                      <Badge className='bg-primary p-1 rounded me-2' content={`${JSON.parse(flow.specifications).length} Instruktioner`} />
                      </>
                    )}
                </div>

                <div
                  onClick={(e) => e.stopPropagation()}
                >
                  <Dropdown
                    trigger={['click']}
                    renderToggle={(props, ref) => (
                      <IconButton
                        {...props}
                        ref={ref}
                        icon={
                          deletingRowIds.includes(flow.id) ? (  // Check if the module is being deleted
                            <Loader speed='fast' size="sm" />
                          ) : (
                            <Gear />
                          )
                        }
                        appearance="subtle"
                      />
                    )}
                    placement="bottomEnd"
                  >
                    <Dropdown.Item
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedFlow(flow);
                        setShowEditModal(true);
                    }}
                    >
                      Ändra namn
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedFlowForSpecify(flow);
                        setShowSpecifyModal(true);
                    }}
                    >
                      Specifiera
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        e.preventDefault();
                        deleteFlow(flow.id);
                    }}
                    >
                      Radera
                    </Dropdown.Item>
                  </Dropdown>
                </div>
              </div>

            </div>
          </div>
          </div>
        ))}
      </div>
        </>
      )}

      <CreateFlowModal
        show={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        moduleId={module.id}
        onFlowCreated={handleFlowCreated}
      />

      <EditFlowModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        flow={selectedFlow}
        onFlowUpdated={handleFlowUpdated}
      />

      <SpecifyFlowModal
        show={showSpecifyModal}
        handleClose={() => setShowSpecifyModal(false)}
        flow={selectedFlowForSpecify}
        onFlowUpdated={handleFlowUpdated}
      />
    </>
  );
}

export default ModuleFlows;
