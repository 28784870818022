import React, { useState, useEffect } from 'react';
import { Dropdown, Button, IconButton, Loader, Badge, Divider } from 'rsuite';
import { useAuth } from '../../../AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import CreateCompanyModal from '../../../modals/company/general/CreateTeam';
import EditTeamModal from '../../../modals/company/general/EditTeam';
import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/sv';
import BellIcon from '@rsuite/icons/legacy/Bell';
import PlusIcon from '@rsuite/icons/Plus';
import TrashIcon from '@rsuite/icons/legacy/Trash';
import PeopleBranchIcon from '@rsuite/icons/PeopleBranch';
import TeamsAddUser from '../../../modals/company/users/TeamsAddUser'; // Import the modal component
import EditIcon from '@rsuite/icons/Edit';

const CompanyHeader = ({ company, loading, onTeamUpdated }) => {
  const { auth, logout } = useAuth();
  const [companies, setCompanies] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [deletingCompanyId, setDeletingCompanyId] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showTeamAddUserModal, setShowTeamAddUserModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [showEditTeamModal, setShowEditTeamModal] = useState(false);

  const [deletingNotificationIds, setDeletingNotificationIds] = useState([]);

  const handleOpenEditTeamModal = (team) => {
    setSelectedTeam(team);
    setShowEditTeamModal(true);
};

const handleCloseEditTeamModal = () => {
  setShowEditTeamModal(false);
};

  const navigate = useNavigate();

  const handleOpenTeamUserModal = (team) => {
    setSelectedTeam(team); // Set the selected team to pass into modal
    setShowTeamAddUserModal(true); // Open the modal
  };

  const handleCloseTeamUserModal = () => {
    setShowTeamAddUserModal(false); // Close the modal
  };

  const handleAdminClick = () => {
    localStorage.removeItem('team');
    navigate('/admin/dashboard');
  };
  const handleTeamEdited = (updatedCompany) => {
    setCompanies((prevCompanies) => 
      prevCompanies.map(company => 
        company.id === updatedCompany.id ? updatedCompany : company
      )
    );
    localStorage.removeItem('team');
    localStorage.setItem('team', JSON.stringify(updatedCompany));
  };
  

  dayjs.extend(relativeTime);
  dayjs.locale('sv');

  const formatNotificationDate = (createdAt) => {
    const notificationDate = dayjs(createdAt);
    const now = dayjs();
    return now.isSame(notificationDate, 'day')
      ? notificationDate.format('HH:mm')
      : notificationDate.fromNow();
  };

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/companies`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      });
      if (response.status === 200) {
        const companies = response.data.content;
        setCompanies(companies);
        const storedCompany = localStorage.getItem('team');
        if (!storedCompany && companies.length > 0) {
          localStorage.setItem('team', JSON.stringify(companies[0]));
        }
      } else {
        toast.error('Misslyckades att hämta företag', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      toast.error('Misslyckades att hämta företag', {
        position: 'bottom-right',
      });
    }
  };

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/notifications`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      });
      if (response.status === 200) {
        setNotifications(response.data.content);
      } else {
        toast.error('Misslyckades att hämta notiser', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      toast.error('Misslyckades att hämta notiser', {
        position: 'bottom-right',
      });
    }
  };

  useEffect(() => {
    fetchCompanies();
    fetchNotifications();
    const interval = setInterval(fetchNotifications, 30000); // Fetch every 30 seconds
    return () => clearInterval(interval);
  }, []);

  const handleCloseModal = () => setShowCreateModal(false);
  const handleShowModal = () => setShowCreateModal(true);

  const handleDeleteCompany = async (companyId) => {
    if (!window.confirm('Är du säker på att du vill radera detta team?')) {
      return;
    }
    setDeletingCompanyId(companyId);
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/user/delete-company`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
        },
        data: { id: companyId },
      });
      if (response.status === 200) {
        toast.warning(response.data.message, {
          position: 'bottom-right',
        });
        fetchCompanies();
      } else {
        toast.error('Misslyckades att radera teamet.', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      toast.error('Misslyckades att radera teamet.', {
        position: 'bottom-right',
      });
    } finally {
      setDeletingCompanyId(null);
    }
  };

  const deleteNotification = async (notificationId) => {
    try {
      setDeletingNotificationIds((prev) => [...prev, notificationId]);
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/user/delete-notification`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
        },
        data: { id: notificationId },
      });
      if (response.status === 200) {
        toast.warning(response.data.message, {
          position: 'bottom-right',
        });
        fetchNotifications();
      } else {
        toast.error('Misslyckades att radera notis.', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      toast.error('Misslyckades att radera notis.', {
        position: 'bottom-right',
      });
    } finally {
      setDeletingNotificationIds((prev) => prev.filter((id) => id !== notificationId));
    }
  };

  const navigateToEvent = (notification) => {
    // Get the currently selected team from localStorage
    const currentTeam = JSON.parse(localStorage.getItem('team'));
  
    // Find the correct company from the companies list that matches notification.companyId
    const selectedCompany = companies.find(company => company.id === notification.companyId);
  
    // Only pass the company to handleSelectTeam if it's not the currently selected one
    if (selectedCompany && currentTeam.id !== selectedCompany.id) {
      handleSelectTeam(selectedCompany); // Switch to the selected company
    }
  
    // Handle different notification types and navigate to the appropriate route
    if (notification.type === "new_contact") {
      navigate(`/user/projekt/${notification.projectId}/moduler/${notification.moduleId}/konversationer/${notification.contactId}`);
    } else if (notification.type === "dispatch_finished") {
      navigate(`/user/projekt/${notification.projectId}/moduler/${notification.moduleId}`);
    }
    else if (notification.type === "new_invoice") {
      navigate(`/user/fakturor`);
    }
  };

  const deleteNotifications = async () => {
    if (!window.confirm('Är du säker på att du vill rensa notiser?')) {
      return;
    }
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/user/delete-notifications`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      if (response.status === 200) {
        toast.warning(response.data.message, { position: 'bottom-right' });
        fetchNotifications();
      } else {
        toast.error('Misslyckades att rensa notiser.', { position: 'bottom-right' });
      }
    } catch (error) {
      toast.error('Misslyckades att rensa notiser.', { position: 'bottom-right' });
    }
  };

  const handleSelectTeam = (selectedTeam) => {
    const currentTeam = JSON.parse(localStorage.getItem('team'))

    if(currentTeam.id === selectedTeam.id){
        return;
    }

    localStorage.setItem('team', JSON.stringify(selectedTeam));
    toast.success(`Team bytt till: ${selectedTeam.companyName}`, {
      position: 'bottom-right',
    });

    onTeamUpdated();
    
    // Check if the current URL starts with /user/projekt
    if (window.location.pathname.startsWith('/user/projekt') || window.location.pathname.startsWith('/user/prospektera')) {
      navigate('/user/dashboard');
    }
    
  };

  return (
    <header className="bg-light py-2 px-4 border-bottom shadow-sm header-fixed">
      <div className="d-flex justify-content-between align-items-center">

        {/* Right: Notifications and Team Dropdowns */}
        <div className="d-flex align-items-center ms-auto">
          
          {/* Notifications */}
          <Badge content={notifications.length > 9 ? '+9' : notifications.length} style={{ zIndex: "999" }}>
          <Dropdown
            title="Notiser"
            trigger={['hover', 'click']}  // Open on both hover and click
            icon={<BellIcon />}
          >
              <Dropdown.Item
                className="d-flex justify-content-between align-items-center"
                style={{ minWidth: "300px", pointerEvents: "none", cursor:"default" }} // Simulate disabled state
              >
                <div className="d-flex align-items-center" style={{ pointerEvents: "all" }}>
                  <BellIcon className="me-2" />
                  <h5 className="mb-0">Notiser</h5>
                  <div className="ms-auto">
                    <a
                      href="#"
                      className=""
                      onClick={(e) => {
                        e.preventDefault(); // Prevent default link behavior if needed
                        deleteNotifications(); // Your function to delete notifications
                      }}
                    >
                      Rensa notiser
                    </a>
                  </div>
                </div>
              </Dropdown.Item>

              <Divider style={{marginTop:"0px", marginBottom:"0px"}}/>

              {loading ? (
                <Dropdown.Item>
                  <Loader speed='fast' content="Laddar notiser..." />
                </Dropdown.Item>
              ) : notifications.length === 0 ? (
                <Dropdown.Item disabled>
                  Inga notiser att visa
                </Dropdown.Item>
              ) : (
                notifications.map((notification, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => navigateToEvent(notification)}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <div className='d-flex'>
                      <p className="mb-0 me-2">{notification.message}</p>
                      <IconButton icon={
                            deletingNotificationIds.includes(notification.id) ? (  // Check if the module is being deleted
                                <Loader speed='fast' />
                            ) : (
                                <TrashIcon />
                            )
                            }
                            className='ms-auto ' size="xs" 
                            onClick={(e) => {
                              e.preventDefault(); // Prevent default link behavior if needed
                              e.stopPropagation(); // Stop the event from bubbling up
                              deleteNotification(notification.id)
                            }}
                        />
                      </div>
                      <p className="mb-0 text-muted">{formatNotificationDate(notification.created)}</p>
                    </div>
                  </Dropdown.Item>
                ))
              )}
          </Dropdown>
          </Badge>


          {/* Divider between icons */}
          <Divider vertical style={{ height: '40px', margin: '0 16px' }} />

          {/* Teams Dropdown */}
          <Badge content={companies.length} color="blue" style={{zIndex:"999"}}>
          <Dropdown
            title="Team"
            trigger={['hover', 'click']}  // Open on both hover and click
            placement="bottomEnd"
          >
            {loading ? (
                <Dropdown.Item>
                  <Loader speed='fast' content="Laddar team..." />
                </Dropdown.Item>
              ) : companies.length === 0 ? (
                <Dropdown.Item>
                  Inga team tillgängliga
                </Dropdown.Item>
              ) : (
                companies.map((company) => (
                  <Dropdown.Item
                    key={company.id}
                    onClick={() => handleSelectTeam(company)}
                    style={{ cursor: 'pointer', minWidth: '300px' }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="flex-grow-1">
                        <span>{company.companyName}</span>
                        {JSON.parse(localStorage.getItem('team')).id === company.id && (
                          <small className="text-success d-block">Valt team</small>
                        )}
                      </div>
                      {/*
                      <IconButton
                        icon={<PeopleBranchIcon />} 
                        size="xs" // Match the size to "xs" like your other IconButton
                        className="ms-auto"
                        title="Tilldela användare"
                        disabled={deletingCompanyId === company.id}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenTeamUserModal(company)
                        }}
                      />
                      */}
                      {JSON.parse(localStorage.getItem('team')).id !== company.id && (
                        <IconButton
                        icon={deletingCompanyId === company.id ? <Loader speed='fast' size="xs" /> : <TrashIcon />} 
                        size="xs"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteCompany(company.id);
                        }}
                        disabled={deletingCompanyId === company.id || companies.length === 1}
                      />
                      )}
                        <IconButton
                        icon={<EditIcon />}
                        className='ms-2'
                        size="xs"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenEditTeamModal(company)
                        }}
                      />
                    </div>
                  </Dropdown.Item>
                ))
              )}
              <div className="d-flex justify-content-center px-2 mt-2">
                <Button appearance="primary" className='bg-primary' block onClick={handleShowModal}>
                  <PlusIcon className="me-2" /> Nytt team
                </Button>
              </div>
          </Dropdown>
          </Badge>

          {/* Divider between icons */}
          <Divider vertical style={{ height: '40px', margin: '0 16px' }} />

          {/* User Dropdown */}
          <Dropdown
              title={
                <div className='text-start'>
                  <span>{auth ? auth.firstName + ' ' +auth.lastName : 'Laddar...'}</span>
                  <br />
                  <small style={{ fontSize: '12px', color: '#888' }}>Team: {localStorage.getItem('team') 
                    ? JSON.parse(localStorage.getItem('team')).companyName 
                    : 'Inget valt team'}</small> {/* Subtitle */}
                </div>
              }
              trigger={['hover', 'click']}  // Open on both hover and click
              placement="bottomEnd"
            >
                {(auth.role === 'ROLE_ADMIN' || auth.role === 'ROLE_SUPERADMIN') && (
                  <Dropdown.Item onClick={handleAdminClick}>Admin vy</Dropdown.Item>
                )}
                <Dropdown.Item as={Link} to="/user/medarbetare">Medarbetare</Dropdown.Item>
                <Dropdown.Item as={Link} to="/user/epost-konton">E-post konton</Dropdown.Item>
                <Dropdown.Item as={Link} to="/user/svartlista">Svartlista e-poster</Dropdown.Item>
                <Dropdown.Item as={Link} to="/user/domaner">Domäner</Dropdown.Item>
                <Dropdown.Item as={Link} to="/user/fakturor">Fakturor</Dropdown.Item>
                <Divider style={{marginTop:"0px", marginBottom:"0px"}}/>
                <Dropdown.Item onClick={logout}>
                  <i className='bi bi-box-arrow-left me-2 text-danger'></i> Logga ut
                </Dropdown.Item>
            </Dropdown>
        </div>
      </div>

      <TeamsAddUser
        show={showTeamAddUserModal}
        handleClose={handleCloseTeamUserModal}
        team={selectedTeam} // Pass the selected team as a prop
      />
      <EditTeamModal
        show={showEditTeamModal}
        handleClose={handleCloseEditTeamModal}
        company={selectedTeam} // Pass the selected team as a prop
        onTeamEdited={handleTeamEdited}
      />

      {/* Create Company Modal */}
      <CreateCompanyModal show={showCreateModal} handleClose={handleCloseModal} onCompanyCreated={fetchCompanies} />
    </header>
  );
};

export default CompanyHeader;
