import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'; // Import Helmet
import { ToastContainer } from 'react-toastify'; // Import ToastContainer globally
import LoginPage from './components/LoginPage';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import ForgotPassword from './components/ForgotPassword';
import LayoutUser from './components/company/layout/CompanyLayout'
import LayoutAdmin from './components/admin/layout/AdminLayout'
import LayoutSuperAdmin from './components/superadmin/layout/AdminLayout'
import LayoutCustomer from './components/customer/layout/CustomerLayout'
import ResetPassword from './components/ResetPassword';
import RegisterPage from './components/RegisterPage';
import CreateAccountPage from './components/CreateAccountPage';
import AdminDashboardPage from './components/admin/general/AdminDashboardPage';
import SuperAdminDashboardPage from './components/superadmin/general/AdminDashboardPage';
import CustomerDashboardPage from './components/customer/general/CustomerDashboardPage';
import CustomerModules from './components/customer/general/CustomerModules';
import CustomerModulePage from './components/customer/fragments/CustomerModulePage';
import CustomerConversationPage from './components/customer/module/ModuleConversationThread';
import CompanyDashboardPage from './components/company/general/CompanyDashboardPage';
import CompanyEmailAccounts from './components/company/general/EmailAccounts';
import CompanyDomains from './components/company/general/Domains';
import CompanyColleagues from './components/company/general/CompanyColleagues';
import CompanyInvoices from './components/company/general/CompanyInvoices';
import CompanyProjectPage from './components/company/fragments/ProjectPage';
import ModulePage from './components/company/fragments/ModulePage';
import ConversationThread from './components/company/module/CompanyConversationThread';
import AdminCompanyPage from './components/admin/general/AdminLicensePage';
import SuperAdminCompanyPage from './components/superadmin/general/AdminLicensePage';
import AdminCompanyDetailsPage from './components/admin/fragments/AdminCompanyDetailsPage';
import SuperAdminCompanyDetailsPage from './components/superadmin/fragments/AdminCompanyDetailsPage';
import FunctionPage from './components/company/fragments/FunctionPage';
import BlacklistPage from './components/company/general/CompanyBlacklist';
import { AuthProvider } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';
import AdminRoute from './AdminRoute';
import SuperAdminRoute from './SuperAdminRoute';
import UserRoute from './UserRoute';
import CustomerRoute from './CustomerRoute';
import AdminDomains from './components/admin/general/Domains';
import AdminEmailAccounts from './components/admin/general/EmailAccounts';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import ProspectingPage from './components/company/fragments/ProspectingPage';
import 'bootstrap-icons/font/bootstrap-icons.css';

function App() {
  return (
  <AuthProvider>
    <Router>
    <ToastContainer autoClose={3000} />
      <Routes>

        <Route path="/login" element={<LoginPage />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/registrera" element={<RegisterPage />} />
        <Route path="/skapa-konto" element={<CreateAccountPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/" element={<ProtectedRoute><LoginPage /></ProtectedRoute>} /> {/* Default route */}

        <Route element={<ProtectedRoute><LayoutSuperAdmin /></ProtectedRoute>}>
          <Route path="/superadmin/dashboard" element={<ProtectedRoute><SuperAdminRoute><SuperAdminDashboardPage /></SuperAdminRoute></ProtectedRoute>} />
          <Route path="/superadmin/licenser" element={<ProtectedRoute><SuperAdminRoute><SuperAdminCompanyPage /></SuperAdminRoute></ProtectedRoute>} />
          <Route path="/superadmin/licenser/:licenceId" element={<ProtectedRoute><SuperAdminRoute><SuperAdminCompanyDetailsPage /></SuperAdminRoute></ProtectedRoute>} />
        </Route>

        <Route element={<ProtectedRoute><LayoutAdmin /></ProtectedRoute>}>
          <Route path="/admin/dashboard" element={<ProtectedRoute><AdminRoute><AdminDashboardPage /></AdminRoute></ProtectedRoute>} />
          <Route path="/admin/domaner" element={<ProtectedRoute><AdminRoute><AdminDomains /></AdminRoute></ProtectedRoute>} />
          <Route path="/admin/epost-konton" element={<ProtectedRoute><AdminRoute><AdminEmailAccounts /></AdminRoute></ProtectedRoute>} />
          <Route path="/admin/kunder" element={<ProtectedRoute><AdminRoute><AdminCompanyPage /></AdminRoute></ProtectedRoute>} />
          <Route path="/admin/kunder/:licenceId" element={<ProtectedRoute><AdminRoute><AdminCompanyDetailsPage /></AdminRoute></ProtectedRoute>} />
        </Route>

        <Route element={<ProtectedRoute><LayoutUser /></ProtectedRoute>}>
          <Route path="/user/dashboard" element={<ProtectedRoute><UserRoute><CompanyDashboardPage /></UserRoute></ProtectedRoute>} />
          <Route path="/user/fakturor" element={<ProtectedRoute><UserRoute><CompanyInvoices /></UserRoute></ProtectedRoute>} />
          <Route path="/user/epost-konton" element={<ProtectedRoute><UserRoute><CompanyEmailAccounts /></UserRoute></ProtectedRoute>} />
          <Route path="/user/domaner" element={<ProtectedRoute><UserRoute><CompanyDomains /></UserRoute></ProtectedRoute>} />
          <Route path="/user/prospektera" element={<ProtectedRoute><UserRoute><ProspectingPage /></UserRoute></ProtectedRoute>} />
          <Route path="/user/svartlista" element={<ProtectedRoute><UserRoute><BlacklistPage /></UserRoute></ProtectedRoute>} />
          <Route path="/user/medarbetare" element={<ProtectedRoute><UserRoute><CompanyColleagues /></UserRoute></ProtectedRoute>} />
          <Route path="/user/projekt/:projectId" element={<ProtectedRoute><UserRoute><CompanyProjectPage /></UserRoute></ProtectedRoute>} />
          <Route path="/user/projekt/:projectId/moduler/:moduleId" element={<ProtectedRoute><UserRoute><ModulePage /></UserRoute></ProtectedRoute>} />
          <Route path="/user/projekt/:projectId/funktioner/:funktionId" element={<ProtectedRoute><UserRoute><FunctionPage /></UserRoute></ProtectedRoute>} />
          <Route path="/user/projekt/:projectId/moduler/:moduleId/konversationer/:contactId" element={<ProtectedRoute><UserRoute><ConversationThread /></UserRoute></ProtectedRoute>} />
        </Route>

        <Route element={<ProtectedRoute><LayoutCustomer /></ProtectedRoute>}>
          <Route path="/customer/dashboard" element={<ProtectedRoute><CustomerRoute><CustomerDashboardPage /></CustomerRoute></ProtectedRoute>} />
          <Route path="/customer/moduler" element={<ProtectedRoute><CustomerRoute><CustomerModules /></CustomerRoute></ProtectedRoute>} />
          <Route path="/customer/moduler/:moduleId" element={<ProtectedRoute><CustomerRoute><CustomerModulePage /></CustomerRoute></ProtectedRoute>} />
          <Route path="/customer/moduler/:moduleId/konversationer/:contactId" element={<ProtectedRoute><CustomerRoute><CustomerConversationPage /></CustomerRoute></ProtectedRoute>} />
        </Route>

      </Routes>
    </Router>
  </AuthProvider>
);

}

export default App;
